import { 
    OPEN_ICONS,
    LIST_ICONS,
    LIST_ICONS_SUCCESS,
    LIST_ICONS_ERROR,
    CREATE_ICONS
} from "../actions";

export const openIconsModal = (payload: boolean) => ({
    type: OPEN_ICONS,
    payload: payload
})

export const iconsList = (proyecto: object) => ({
    type: LIST_ICONS,
    payload: {proyecto}
})

export const iconsListSuccess = (icons: any) => ({
    type: LIST_ICONS_SUCCESS,
    payload: icons
})

export const iconsListError = (message: any) => ({
    type: LIST_ICONS_ERROR,
    payload: {message}
})

export const iconsCreate = (icon: any) => ({
    type: CREATE_ICONS,
    payload: {icon}
})