import React from 'react'
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import { Backdrop, CircularProgress } from "@mui/material";

import Navbar from '../navbar';
import Sidebar from '../sidebar';

import UploadNew from './uploadNew.component';
import Coordinates from './coordinates.component';


import dashBackground from './../../UI/backgrounds/BG_PUBLIC.webp'


import {
    logoutUser,
    amenidadesList,
    spinList,
    spinListSuccess,
    spinSet,
    spinUpdate,
    spinDelete,
    scriptUpdate,
    amenidadesUpdate,
    scriptList
} from "../../redux/actions";


interface Props{
    logoutUser: any,
    spinList: any,
    spinListSuccess: any,
    spinSet: any,
    scriptList: any,
    spin: any,
    amenidadesList: any,
    amenities: any,
    details: any,
    script: any
    spinUpdate: any,
    spinDelete: any,
    scriptUpdate: any,
    loading: any
    projectSelected: any,
    selectedBuilder: any,
    params: any,
    amenidadesUpdate: any
}

interface State{
    userString: any,
    user: any,
    idProject: any,
    idBuilder: any,
    isFormatCorrect: boolean,
    isSizeCorrect: boolean,
    isLengthCorrect: boolean,
    areCoordinateCorrect: boolean,
    isValidating: boolean,
    validationFinished: boolean,

    initFramePOI: number,
    cameraAngle: number,
    isPreTransition: boolean,
    mainCameraAngle: number,
    hasControl: boolean,
    loadingCanvas: boolean,
    links: any,
    zoom: any,
    leftOffset: number,
    topOffset: number,
    hasGeneral: boolean,
    hasTownPlanning: boolean
    hasGeneralMobile: boolean,
    hasTownPlanningMobile: boolean
}

function withParams(Component: any) {
    return (props: any) => <SpinsViews {...props} params={useParams()} />;
}

class SpinsViews extends React.Component<Props,State>{
    constructor(props: Props){
        super(props);
        this.state = {
            userString: localStorage.getItem("user"),
            user: "",
            idProject: this.props.params.id,
            idBuilder: this.props.projectSelected.builder,
            isFormatCorrect: false,
            isSizeCorrect: false,
            isLengthCorrect: false,
            areCoordinateCorrect: true,
            isValidating: false,
            validationFinished: false,

            initFramePOI: 0,
            cameraAngle: 0,
            isPreTransition: false,
            mainCameraAngle: 0,
            hasControl: false,
            loadingCanvas: false,
            links: [],
            zoom: 1,
            leftOffset: 0,
            topOffset: 0,

            hasGeneral: false,
            hasTownPlanning: false,
            hasGeneralMobile: false,
            hasTownPlanningMobile: false
        }
    }
    

    componentDidMount(){
        if(this.state.userString){
            this.setState({
                user: JSON.parse(this.state.userString)
            }, () => {
                this.props.amenidadesList({user: this.state.user.id, project: this.state.idProject})
                this.props.spinList({user: this.state.user.id, project: this.state.idProject})
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {

        if(this.props.spin !== prevProps.spin && this.props.spin.length > 0){
            this.props.spin.forEach((data: any) => {
                if(data.type === 'General Landscape'){
                    this.setState({
                        hasGeneral: true
                    })
                }
                if(data.type === 'General Portrait'){
                    this.setState({
                        hasGeneralMobile: true
                    })
                }
                if(data.type === 'Urbanismo Landscape'){
                    this.setState({
                        hasTownPlanning: true
                    })
                }
                if(data.type === 'Urbanismo Portrait'){
                    this.setState({
                        hasTownPlanning: true
                    })
                }
            })
        }
    }

    handleUpdateIsValidating = (payload: boolean) => {
        this.setState({
            isValidating: payload
        }, () => {
            if(!this.state.isValidating ){
                this.handleValidationFinished(true)
            }
        })
    }

    handleChangeStatusAfterValidation = (format: boolean, length: boolean, size: boolean) => {
        this.setState({
            isFormatCorrect: format,
            isLengthCorrect: length,
            isSizeCorrect: size,
        })
    }

    handleValidationFinished = (validationFinished: boolean) => {
        this.setState({
            validationFinished: validationFinished
        })
    }

    handleMainCameraAngleUpdate = (angle: number) => {
        this.setState({
            mainCameraAngle: angle,
        });
    };

    handleStartTransicion = ():void => {

        this.handleEndTransicion();
    };
    
    handleEndTransicion = () => {
        this.setState({
            isPreTransition: false
        })
    };

    notifyZoomAndOffsets = (zoom: number, leftOffset: any, topOffset: any) => {
        this.setState({
            zoom: zoom,
            leftOffset: leftOffset,
            topOffset: topOffset
        });
    }

    updateLoadingCanvas = (payload: boolean) => {
        this.setState({
            loadingCanvas: payload
        })
    }

    handleUploadSpin = (name: string, spin: any, type: any, count: number) => {
        //CONNECT TO UPLOAD SPIN

        let FileFormData = new FormData();
        FileFormData.append("zip", spin);
        FileFormData.append("name", name.toLowerCase().split(" ").join("-"));
        FileFormData.append("route", `galleries/${this.props.selectedBuilder.id}/${this.state.idProject}/spin/${name.toLowerCase().split(" ").join("-")}/`);
        this.props.spinSet({
            data: {
                user: this.state.user.id,
                project: this.state.idProject,
                name: name,
                slug: name.toLowerCase().split(" ").join("-"),
                route: `galleries/${this.props.selectedBuilder.id}/${this.state.idProject}/spin/${name.toLowerCase().split(" ").join("-")}/`,
                type: type,
                count: count
            },
            zip: FileFormData
        })
    }

    handleUpdateScriptFile = (id: string, type: string) => {
        this.props.scriptUpdate({id, type, user: this.state.user.id, project: this.state.idProject, group: null})
    }

    handleUpdateDetailScriptFile = (groupId: string, type: string, scriptId: string) => {
        this.props.scriptUpdate({id: scriptId, type, user: this.state.user.id, project: this.state.idProject, group: groupId})
    }

    handleSelectSpinForDetail = (spinId: string, groupId: string) => {
        this.props.scriptUpdate({spin: spinId, user: this.state.user.id, project: this.state.idProject, group: groupId})
    }

    handleSelectSpinPortraitForDetail = (spinId: string, groupId: string) => {
        this.props.scriptUpdate({spinPortrait: spinId, user: this.state.user.id, project: this.state.idProject, group: groupId})
    }

    handleDeleteSpin = (id: string) => {
        this.props.spinDelete({
            id, user: this.state.user.id, project: this.state.idProject
        })
    }

    handleUpdateSpinName = (name: string, id: string) => {
        // UPDATE SPIN
        //console.log(name, id)
    }

    handleShowScript = (data: any) => {
        this.props.scriptList({
            user: this.state.user.id,
            project: this.state.idProject,
            type: data.type,
            indice: data.indice
        })
    }

    handleUploadCompleteSpin = (data: any) => {

        this.props.spinSet(data)
    }

    handleCreateAmenitiesGroup = (payload: any) => {
        this.props.amenidadesUpdate({
            user: this.state.user.id,
            project: this.state.idProject,
            id: payload, 
            spinState: 1
        })
    }

    render(): React.ReactNode {
        
        return (
            <div className='spins-contaienr'>
                <img className='dashboard-background' src={dashBackground} alt=""/>
                <Sidebar
                    project={this.props.projectSelected}
                />
                <UploadNew
                    spins={this.props.spin}
                    amenities={this.props.amenities}
                    details={this.props.details}
                    handleUploadCompleteSpin = {this.handleUploadCompleteSpin}
                    handleCreateAmenitiesGroup  = {this.handleCreateAmenitiesGroup }
                    user = {this.state.user.id}
                    project = {this.state.idProject}
                    builder = {this.props.projectSelected.builder}
                />
                <Coordinates
                    files = {this.props.script}
                    handleUpdateScriptFile={this.handleUpdateScriptFile}
                    handleUpdateDetailScriptFile = {this.handleUpdateDetailScriptFile}
                    spins = {this.props.spin}

                />
                <Navbar
                    logout={this.props.logoutUser}
                />
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.props.loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

const mapStateToProps = ({
    projectReducer,
    builderReducer,
    spinReducer,
    amenitiesReducer
  }: any) => {
    const { spin, script, details, amenities, loading } = spinReducer;
    const { projectSelected } = projectReducer;
    const { selectedBuilder } = builderReducer;
    return { spin, script, loading, projectSelected, selectedBuilder, amenities, details };
  };

export default connect(mapStateToProps, {
    logoutUser,
    amenidadesList,
    spinList,
    spinListSuccess,
    spinSet,
    spinUpdate,
    spinDelete,
    scriptUpdate,
    scriptList,
    amenidadesUpdate
})(withParams(SpinsViews));