import React, {useState, useEffect} from 'react'

import { Link } from "react-router-dom";

import { useNavigate } from 'react-router-dom';

import { sidebarData } from '../../data/sidebar.data'

/*eslint-disable*/

const ProjectsSidebar = ({project, currentIndexReducer, currentSubIndex, handleChange, handleChangeSubIndex, pois, idProject, user}: any) => {

    const [projectData, setProjectData]: any = useState(sidebarData())
    const [show, setShow] = useState(true)

    var arrayToAdd: any = []
    var projectDataWithPois: any = projectData

    const navigate = useNavigate();

    /* useEffect(() => {
        if(user.name && user.name !== 'ADMIN'){
            handleChange(8)
            handleChangeSubIndex(0)
            navigate(`/modulos/disponibilidad/${project.id}`);
        }
    }, [user]) */

    useEffect(() => {
        setShow(true)
    })

    useEffect(() => {
        setShow(false)
        pois.map((poi: any, index: number) => {
            if(poi.state === 1){
                var name = poi.name
                var link = name.toLowerCase().replace(" ","-")
                arrayToAdd.push(
                    {
                        "name": name,
                        "linkTo":'/poi-category/id/'+link
                    }
                )
            }
        })

        projectDataWithPois[4].subMenu = arrayToAdd

        setProjectData(projectDataWithPois)

    }, [pois])

    const handleClickIndex = (index: number) => {
        handleChange(index)
    }

    return(
        <div className='projects-data-container'>
            <div className='sidebar-projects-container'>
            {
                projectData.map((data: any,index: number) => {
                    if(data.rol && data.rol.includes(user.name)){
                        return(
                            <React.Fragment>
                            <div className='project-data-btn-container' key={data.name + index}>
                                {
                                    data.subMenu.length === 0 ?
                                    <Link to={data.linkTo + `${project.id}`}>
                                        <div className={currentIndexReducer === index ? 'sidebar-selected project-data-main-title-no-sub-menu':"project-data-main-title-no-sub-menu"} onClick={() => {
                                            handleClickIndex(currentIndexReducer === index ? index:index)
                                            }}>
                                            {
                                                data.name
                                            }
                                        </div>
                                    </Link>:
                                    data.name !== "Módulos" ? 
                                    <Link to={data.linkTo + `${project.id}`}>
                                        <div className="project-data-main-title" onClick={() => {
                                            handleClickIndex(currentIndexReducer === index ? index:index)
                                            handleChangeSubIndex(-1)
                                            }}>
                                            <div className={currentIndexReducer === index ? 'sidebar-selected title-submenu':"title-submenu"}>
                                                {data.name}
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.364" height="13.365" viewBox="0 0 13.364 13.365" className={currentIndexReducer === index ? 'selected-arrow':"deselected-arrow"}>
                                                <path id="Path_2902" data-name="Path 2902" d="M-16742.293-11739.949v8.45h8.449" transform="translate(-3531.217 -20127.328) rotate(-135)" fill="none" stroke="#616161" stroke-width="2"/>
                                            </svg>
                                        </div>
                                    </Link>:
                                    <div className="project-data-main-title" onClick={() => {
                                        handleClickIndex(currentIndexReducer === index ? index:index)
                                        handleChangeSubIndex(-1)
                                        }}>
                                        <div className={currentIndexReducer === index ? 'sidebar-selected title-submenu':"title-submenu"}>
                                            {data.name}
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.364" height="13.365" viewBox="0 0 13.364 13.365" className={currentIndexReducer === index ? 'selected-arrow':"deselected-arrow"}>
                                            <path id="Path_2902" data-name="Path 2902" d="M-16742.293-11739.949v8.45h8.449" transform="translate(-3531.217 -20127.328) rotate(-135)" fill="none" stroke="#616161" stroke-width="2"/>
                                        </svg>
                                    </div>
                                }
                            </div>
                            {
                                data.subMenu.length > 0 && currentIndexReducer === index && show  ?
                                <div className='sub-menu-container'>
                                    {
                                        data.subMenu.map((subData: any, subIndex: number) => {
                                            return(
                                                data.rol &&  data.rol.includes(user.name) ?
                                            <Link to={subData.linkTo.includes("/id/") ? subData.linkTo.replace("id",`${project.id}`):subData.linkTo + `${project.id}`}  className='submenu-btn' key={subData.name + subIndex}>
                                                <div 
                                                    className={currentSubIndex === subIndex ? "submenu-selected":""}
                                                    onClick={() => {handleChangeSubIndex(currentSubIndex === subIndex ? subIndex:subIndex)}}
                                                >
                                                    {subData.name}
                                                </div>
                                            </Link>:null
                                            )
                                        }
                                        
                                        )
                                    }
                                </div>:null
                            }
                        </React.Fragment>
                        )
                    }
                    
                }
                )
            }
            </div>
        </div>
    )
}

export default ProjectsSidebar