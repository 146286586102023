import React from 'react'
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import galleryIco from './../../UI/general/gallery.svg'
import CloseWhite from './../../UI/general/Close_white.svg'

import {
  logoutUser,
  inmueblesList,
  inmueblesSet,
  inmueblesUpdate,
  inmueblesCopy,
  inmueblesDelete,
  inmueblesGalleries,
  inmueblesAttributesGalleries,
  galleriesOpen,
} from "../../redux/actions";

interface Props {
  isGalleryPropertiesModalOpened: any,
  handleOpenGalleryPropertiesModal: any,
  logoutUser: any;
  inmueblesList: any;
  inmueblesSet: any;
  inmueblesUpdate: any;
  inmueblesCopy: any;
  inmueblesDelete: any;
  inmueblesGalleries: any,
  galleriesOpen: any;
  loading: boolean;
  projectSelected: any;
  params: any;
  properties: any;
  attributes: any;
  modalGalleries: any;
  inmueblesAttributesGalleries: any;
  galleries: any
}

interface State {
  userString: any;
  user: any;
  idProject: any,
  attributesToShow: any,
  currentAttributeSelected: any,
  currentAttributeValueSelected: any,
  currentFolderSelected: any,
  currentSubFolderSelected: any,
  currentSubFolderIndex: number,
  tempGalleryArray: any
}

function withParams(Component: any) {
  return (props: any) => <GalleryModalPropertiesContainer {...props} params={useParams()} />;
}

class GalleryModalPropertiesContainer extends React.Component<Props,State>{
  constructor(props: Props){
    super(props);
    this.state = {
      userString: localStorage.getItem("user"),
      user: "",
      idProject: this.props.params.id,
      attributesToShow: [],
      currentAttributeSelected: '',
      currentAttributeValueSelected: '',
      currentFolderSelected: '',
      currentSubFolderSelected: '',
      currentSubFolderIndex: -1,
      tempGalleryArray: []
    }
  }

  componentDidMount(): void {
    if(this.state.userString){
        this.setState({
            user: JSON.parse(this.state.userString)
        })
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if(prevProps.attributes !== this.props.attributes && this.props.attributes[0] && this.props.attributes[0].id){
      this.setState({
        attributesToShow: this.props.attributes,
        currentAttributeSelected: this.state.currentAttributeSelected === '' ? this.props.attributes[0].id:this.state.currentAttributeSelected
      })
    }
    if((prevState.currentAttributeSelected !== this.state.currentAttributeSelected )){
      if(this.state.currentAttributeSelected !== ''){
        this.props.attributes.map((attributes: any) => {
          if(attributes.id === this.state.currentAttributeSelected){
            this.setState({
              currentAttributeValueSelected: attributes.values[0].id
            })
          }
          return null
        })
      }
      if(this.state.currentAttributeSelected === ''){
        this.props.attributes.map((attributes: any) => {
          if(attributes.id === this.state.currentAttributeSelected){
            this.setState({
              currentAttributeSelected: this.props.attributes[0].id,
              currentAttributeValueSelected: this.props.attributes[0].values[0].id
            })
          }
          return null
        })
      }
      if(this.state.currentAttributeSelected !== '' && this.state.currentAttributeValueSelected !== ''){
        this.props.attributes.map((attributes: any) => {
          if(attributes.id === this.state.currentAttributeSelected){
            attributes.values.map((value: any) => {
              if(value.id === this.state.currentAttributeValueSelected){
                var tempArray: any = []
                value.galleries.map((gallery: any) => {
                  tempArray.push(gallery)
                  return null
                })
                this.setState({
                  tempGalleryArray: tempArray
                })
              }
              return null
            })
          }
          return null
        })
      }
    }
    if(prevState.currentAttributeValueSelected !== this.state.currentAttributeValueSelected){
      if(this.state.currentAttributeSelected !== '' && this.state.currentAttributeValueSelected !== ''){
        this.props.attributes.map((attributes: any) => {
          if(attributes.id === this.state.currentAttributeSelected){
            attributes.values.map((value: any) => {
              if(value.id === this.state.currentAttributeValueSelected){
                var tempArray: any = []
                value.galleries.map((gallery: any) => {
                  tempArray.push(gallery)
                  return null
                })
                this.setState({
                  tempGalleryArray: tempArray
                })
              }
              return null
            })
          }
          return null
        })
      }
    }
  }

  handleUpdateGalleryArray = (payload: any) => {
    var tempArray = this.state.tempGalleryArray
    if(tempArray.includes(payload)){
      tempArray.splice(tempArray.indexOf(payload),1)
    }
    else{
      tempArray.push(payload)
    }
    this.setState({
      tempGalleryArray: tempArray
    })
  }

  handleUploadGalleries = () => {
    if(this.state.currentAttributeSelected === ''){
      window.alert('Para asignar una galería a un atributo debes seleccionar un atributo')
    }
    else if(this.state.currentAttributeValueSelected === ''){
      window.alert('Para asignar una galería a un valor de atributo debes seleccionar un valor de atributo')
    }
    else {
      var arrayToSend: any = []

      this.state.tempGalleryArray.forEach((element: any) => {
        if(element.gallerie && element.gallerie !== ''){
          arrayToSend.push(element.gallerie)
        }
        else{
          arrayToSend.push(element.id)
        }
      });

      this.props.inmueblesAttributesGalleries({
        project: this.state.idProject,
        user: this.state.user.id,
        attribute: this.state.currentAttributeSelected,
        value: this.state.currentAttributeValueSelected,
        galleries: arrayToSend
      })
    }
  }
  
  render(): React.ReactNode {
      return(
        <div className={this.props.isGalleryPropertiesModalOpened ? 'gallery-modal-properties-container fade-in':'gallery-modal-properties-container fade-out'}>
          <div className={'gallery-modal-properties-main-container'}>
            <div className='gallery-modal-properties-title'>Editar galería por atributo</div>
            <div className='gallery-modal-properties-text'>Podrá asignar y editar multimedia en el valor de un atributo.</div>
            <div className='gallery-modal-attributes-container'>

              <div className='gallery-modal-attributes-individual-container'>
                <div className='gallery-modal-attributes-individual-title'>Atributo</div>
                <div className='gallery-modal-attributes-individual-select'>
                  <select onChange={(e) => {
                    if(this.state.currentAttributeSelected !== '' && this.state.currentAttributeValueSelected !== '' && this.state.tempGalleryArray.length > 0){
                      this.handleUploadGalleries()
                    }
                    setTimeout(() => {
                      this.setState({
                        currentAttributeSelected: e.target.value
                      })
                    }, 200);
                  }}>
                    {
                      this.state.attributesToShow.length > 0?
                      this.state.attributesToShow.map((attribute: any) => 
                        <option value={attribute.id}>{attribute.name}</option>
                      ):null 
                    }
                  </select>
                </div>
              </div>

              <div className='gallery-modal-attributes-individual-container'>
                <div className='gallery-modal-attributes-individual-title'>Valor</div>
                <div className='gallery-modal-attributes-individual-select'>
                  <select disabled={this.state.currentAttributeSelected === ''} onChange={(e) => {
                    if(this.state.currentAttributeSelected !== '' && this.state.currentAttributeValueSelected !== '' && this.state.tempGalleryArray.length > 0){
                      this.handleUploadGalleries()
                    }
                    setTimeout(() => {
                      this.setState({currentAttributeValueSelected: e.target.value})
                    }, 200);
                    }}>
                    {this.props.attributes.length > 0 ?
                      this.props.attributes.map((att: any, index: number) => 
                        att.id === this.state.currentAttributeSelected ?
                        att.values.map((value: any, indexValue: number) => 
                          <option value={value.id}>{value.value}</option>
                        ):null
                      ):null
                    }
                  </select>
                </div>
              </div>

            </div>

            <div className='gallery-modal-container-galleries'>
              <div className='gallery-container-breadcrumbs-cards'>
                <div className='breadcrumbs-container-gallery-modal-properties'>
                  <div 
                  className='bread-crumbs-container-text'
                  onClick={() => {
                    this.setState({
                      currentFolderSelected: '',
                      currentSubFolderIndex: -1,
                      currentSubFolderSelected: ''
                    })
                  }}
                  >/ Galeria</div>
                  <div 
                  className={this.state.currentFolderSelected !== '' ? 'bread-crumbs-container-text fade-in':'bread-crumbs-container-text fade-out'}
                  onClick={() => {
                    this.setState({
                      currentSubFolderIndex: -1,
                      currentSubFolderSelected: ''
                    })
                  }}
                  style={{
                    fontFamily: this.state.currentFolderSelected !== '' && this.state.currentSubFolderSelected === ''  ? 'Montserrat Semibold':'Montserrat Regular'
                  }}

                  >/ {this.state.currentFolderSelected}</div>
                  <div 
                  className={this.state.currentSubFolderSelected !== '' ? 'bread-crumbs-container-text fade-in':'bread-crumbs-container-text fade-out'}
                  style={{
                    fontFamily: this.state.currentSubFolderSelected !== '' ? 'Montserrat Semibold':'Montserrat Regular'
                  }}
                  >/ {this.state.currentSubFolderSelected}</div>
                </div>
                {
                  this.state.currentFolderSelected === '' ?
                  <div className='gallery-modal-gallery-container'>
                    {
                      Object.keys(this.props.galleries).map((gallery: any) => 
                        this.props.galleries[gallery].length > 0 ?
                        <div className='gallery-modal-gallery-container-individual' onClick={() => {this.setState({currentFolderSelected: gallery})}}>
                          <div className='gallery-modal-gallery-icon'>
                            <img src={galleryIco} alt=''/>
                          </div>
                          <div className='gallery-modal-text'>{gallery}</div>
                        </div>:null
                      )
                    }
                  </div>:null
                }
                {
                  this.state.currentFolderSelected !== '' && this.state.currentSubFolderSelected === '' ?
                  <div className='gallery-modal-gallery-container'>
                    {
                      this.props.galleries[this.state.currentFolderSelected].map((gallery: any, index: number) => 
                        <div className='gallery-modal-gallery-container-individual' onClick={() => {this.setState({currentSubFolderSelected: gallery.folder, currentSubFolderIndex: index})}}>
                          <div className='gallery-modal-gallery-icon'>
                            <img src={galleryIco} alt=''/>
                          </div>
                          <div className='gallery-modal-text'>{gallery.folder}</div>
                        </div>
                      )
                    }
                  </div>:null
                }
                {
                  this.state.currentSubFolderSelected !== '' ?
                  <div className='gallery-modal-gallery-container-previews'>
                    {
                      this.props.galleries[this.state.currentFolderSelected][this.state.currentSubFolderIndex].galleries.map((gallery: any) => 
                      this.props.galleries[this.state.currentFolderSelected][this.state.currentSubFolderIndex].type === 'card' ?
                        <div className='preview-image-container' onClick={() => {this.handleUpdateGalleryArray(gallery)}}>
                          <div className='preview-iamge-gallery-container'>
                            <img src={'https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/'+gallery.link} alt=''/>
                          </div>
                          <div className='preview-image-title'>{gallery.name}</div>
                        </div>:
                        <div className='preview-link-container' onClick={() => {this.handleUpdateGalleryArray(gallery)}}>
                          <div className='preview-link-title'>{gallery.name}</div>
                        </div>
                      )
                    }
                  </div>:null
                }
              </div>
              <div className='gallery-container-breadcrumbs-cards'>
                <div className='gallery-container-breadcrumbs-title'>
                  Material seleccionado
                </div>

                <div className='gallery-container-images-selected'>
                  {
                    this.state.tempGalleryArray.map((gallery: any) => 
                      gallery.type === 'card' ?
                      <div className='preview-image-container' >
                        <div className='gallery-modal-delete-btn' onClick={() => {
                          this.handleUpdateGalleryArray(gallery)
                          }}>
                          <img src={CloseWhite} alt=''/>
                        </div>
                        <div className='preview-iamge-gallery-container'>
                          <img src={'https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/'+gallery.link} alt=''/>
                        </div>
                        <div className='preview-image-title'>{gallery.name}</div>
                      </div>:
                      <div className='preview-image-container' onClick={() => {
                        this.handleUpdateGalleryArray(gallery)
                        }}>
                        <div className='gallery-modal-delete-btn' >
                          <img src={CloseWhite} alt=''/>
                        </div>
                        <div className='preview-image-title' style={{height: '100%'}}>{gallery.name}</div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>

            <div className='save-cancel-gallery-modal-properties'>
              <div className='cancel-btn-gallery-modal' onClick={() => {
                this.handleUploadGalleries()
                this.setState({
                  currentAttributeSelected: '',
                  currentAttributeValueSelected: '',
                  currentFolderSelected: '',
                  currentSubFolderSelected: '',
                  currentSubFolderIndex: -1,
                  tempGalleryArray: []
                })
                this.props.handleOpenGalleryPropertiesModal()}
                }>
                Cerrar
              </div>
              {/*<div className='save-btn-gallery-modal' onClick={() => {}}>
                Aplicar cambios
              </div>*/}
            </div>
          </div>
        </div>
      )
  }
}

const mapStateToProps = ({
  projectReducer,
  builderReducer,
  moduleReducer,
  galleriesReducer,
  amenitiesReducer,
  propertiesReducer,
  attributeReducer,
}: any) => {
  const { galleries, modalGalleries } = galleriesReducer;
  const { amenities, loading } = amenitiesReducer;
  const { modules } = moduleReducer;
  const { attributes } = attributeReducer;
  const { projectSelected } = projectReducer;
  const { selectedBuilder } = builderReducer;
  const { properties } = propertiesReducer;
  return {
    attributes,
    modules,
    projectSelected,
    loading,
    selectedBuilder,
    galleries,
    modalGalleries,
    amenities,
    properties,
  };
};

export default connect(mapStateToProps, {
  logoutUser,
  inmueblesList,
  inmueblesSet,
  inmueblesUpdate,
  inmueblesCopy,
  inmueblesDelete,
  inmueblesGalleries,
  inmueblesAttributesGalleries,
  galleriesOpen,
})(withParams(GalleryModalPropertiesContainer));