import axios from 'axios';
import { ApiConfig } from '../constants/defaultValues';

export async function list(contact: any) {

    return axios.post(ApiConfig.URL2 + "contacts/get", contact);
}
export async function set(e: any) {
    return await axios.post(ApiConfig.URL2 + "contacts/set", e);
}
export async function update(e: any) {
    return axios.post(ApiConfig.URL2 + "contacts/update", e);
}
export async function remove(proyecto: any) {
    return axios.post(ApiConfig.URL2 + "contacts/delete", proyecto);
}