import React from 'react'

import Kermit from './../../UI/general/Kermit.png'
import collapse from "./../../UI/general/collapse.svg";
import Edit from './../../UI/general/Edit.svg'
import Delete from './../../UI/general/close.svg'

interface Props {
  salasList: any,
  handleDeleteSala: any,
  handleOpenEditionModal: any
}

interface State {
  openModal: any,
  modalIndex: any,
  salaToShow: any
}

class ListNew extends React.Component<Props,State>{
  constructor(props: Props){
    super(props);
    this.state = {
      openModal: false,
      modalIndex: -1,
      salaToShow: {}
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
      if(prevProps.salasList !== this.props.salasList){
        this.setState({
          salaToShow: this.props.salasList
        }, () => {
          console.log(this.state.salaToShow)
        })
      }
  }


  render(): React.ReactNode {
      return(
        <div className='contact-list-main-container'>
          <div className='contact-list-main-container-title'>Información de contacto</div>
         
            {
              this.state.salaToShow.length > 0 ?
              <div className='sala-list-data-container'>
                {
                  this.state.salaToShow.map((sala: any, index: number) => 
                  <div className='sala-individual-main-container' onClick={() => {this.setState({modalIndex: this.state.modalIndex === index ? -1:index})}}>
                    <div className='sala-individual-brief-container' >
                      <div className='sala-individual-brief-name' onClick={() => {this.setState({modalIndex: this.state.modalIndex === index ? -1:index})}}>{sala.name}</div>
                      <div className='sala-indiivudal-brief-arrow' onClick={() => {this.setState({modalIndex: this.state.modalIndex === index ? -1:index})}}>
                        <img src={collapse} alt=''/>
                      </div>
                      <div className='sala-individual-brief-actions-container'>
                        <div className='sala-individual-brief-individual-action' onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          this.props.handleOpenEditionModal(sala, true)
                          }}>
                          <img src={Edit} alt=''/>
                        </div>
                        <div className='sala-individual-brief-individual-action' onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          this.props.handleDeleteSala(index)
                          }}>
                          <img src={Delete} alt=''/>
                        </div>
                      </div>
                    </div>
                    {
                      this.state.modalIndex === index ?
                      <div className='sala-individual-info-container'>
                        <div><strong>Nombre: </strong><br/>{sala.name}</div>
                        <div><strong>Dirección: </strong><br/>{sala.location}</div>
                        <div>
                          <strong>Horario de atención: </strong>
                          <br/>
                          {sala.hourAtencion && sala.hourAtencion.length > 0?
                            sala.hourAtencion.map((hour: any) => 
                              <div>{hour.dias} de  {hour.hour_from} a {hour.hour_to}</div>
                            ):null 
                          }
                        </div>
                        <div><strong>URL Waze: </strong><br/>{sala.URLWaze}</div>
                        <div><strong>URL Google Maps: </strong><br/>{sala.URLMaps}</div>
                        <div>
                          <strong>Correo electrónico: </strong><br/>
                          {
                            sala.emails.map((email: any) => 
                              <div>{email.email}</div>
                            )
                          }
                        </div>
                        {
                          sala.phones.map((phone: any) => 
                            <div><strong>Número de contacto: </strong><br/>{phone.number} {phone.whatsapp ? ' - Habilitado para Whatsapp':''}</div>
                          )
                        }
                        
                      </div>:null 
                    }
                  </div>
                  )
                }
              </div>:
              <div className='kermit-container'>
                <img src={Kermit} alt=''/>
                <div className='kermit-warning'>Aún no has creado un directorio.</div>
              </div> 
            }
          
        </div>
      )
  }
}

export default ListNew