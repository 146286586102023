import axios from 'axios';
import { ApiConfig } from '../constants/defaultValues';

export async function list(user: string) {
    return axios.post(ApiConfig.URL2 + "users/get", user);
}
export async function create(user: string) {
    return axios.post(ApiConfig.URL + "user/set", user);
}
export async function update(user: string, id: number) {
    return axios.put(ApiConfig.URL + "user/update/" + id, user);
}
export async function remove(id: number) {
    return axios.put(ApiConfig.URL + "user/delete/" + id);
}