import React from 'react'

import gallery from './../../UI/general/GalleryGeneral.svg'

const IconSelect = ({logo_ubicacion, handleUploadMapImage}: any) => {
    return(
        <div className='poi-icon-select-main'>
            <div className='poi-icon-select-title'>
                Puntos de interés
            </div>
            <div className='poi-icon-select-container'>
                <div className='poi-icon-selected-container'>
                    <img src={logo_ubicacion} alt=""/>
                </div>
                <div className='poi-icon-text-container'>
                    <div className='pois-info-title'>
                        Ícono de puntos de intéres
                    </div>
                    <div className='pois-info-subtitle'>
                        Se mostrará en el menú principal
                    </div>
                    <div className='pois-info-text'>
                        El archivo debe estar en .SVG
                    </div>
                    <input
                        type="file"
                        name="imagen"
                        id="upload-photo-nuevo-icons"
                        className="upload-photo-nuevo-icons inputfile-icons"
                        //onChange={(e: any) => {handleAddLogo(e)}}
                        accept="image/svg+xml"
                    />
                    <div className='input-icons-btn-poi'>
                        Seleccionar
                    </div>
                </div>
            </div>
            <div className='poi-icon-select-container'>
                <div className='poi-icon-selected-container'>
                    <img src={gallery} alt=""/>
                </div>
                <div className='poi-icon-text-container'>
                    <div className='pois-info-title'>
                        Subir Mapa ilustrado
                    </div>
                    <div className='pois-info-text'>
                    El archivo debe estar en formato .WEBP <br/> y tener una resolución de 3000 x 3000 px
                    </div>
                    <div className='pois-info-text' style={{marginTop: '0.5rem',marginBottom: '0.5rem'}}>
                    Debe pesar máximo 3MB
                    </div>
                    <input
                        type="file"
                        name="imagen"
                        id="upload-photo-nuevo-icons"
                        className="upload-photo-nuevo-icons inputfile-icons"
                        onChange={(e: any) => {handleUploadMapImage(e.target.files[0])}}
                        accept="image/webp"
                    />
                    <div className='input-icons-btn-poi'>
                        Subir archivo
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IconSelect