import axios from 'axios'
import { borrarCacheImagenes } from '../helpers/clearCache';
import {
    ApiConfig
} from './../constants/defaultValues';

export async function list(e: any){
    return axios.post(ApiConfig.URL2 + "icons/get", e)
}

export async function create(e: any){
    let result = await axios.post(ApiConfig.URL2 + "icons/set", {data: e.data});
    let count = 0
    if(result.status === 200){
        e.icons.forEach((icon:any, index: number) => {
            let InstFormData = new FormData()
            InstFormData.append("myFile", icon.ico)
            InstFormData.append("name", e.data[index].icon)
            axios.post(`${ApiConfig.URL3}coord/`, InstFormData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            count++
            console.log("aqui");
            if(count === e.icons.length){
                console.log("aca");
                borrarCacheImagenes();
                return axios.post(ApiConfig.URL2 + "icons/get", e.data)
            }
        })

    }
}