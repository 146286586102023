
import {
    LIST_CONTACT,
    LIST_CONTACT_SUCCESS,
    LIST_CONTACT_ERROR,
    SET_CONTACT,
    UPDATE_CONTACT,
    DELETE_CONTACT
} from '../actions'

/*eslint-disable*/

const INIT_STATE = {
    contact: [],
    loading: false,
    message: '',
}

export default (state = INIT_STATE, action: any) => {
switch (action.type) {
    case  LIST_CONTACT:
    return { ...state, loading: true, message: '' }
    case LIST_CONTACT_SUCCESS:
    return {
        ...state,
        contact: action.payload,
        loading: false,
        message: "Exito",
    }
    case LIST_CONTACT_ERROR:
    return {
        ...state,
        contact: [],
        loading: false,
        message: action.payload.message,
    }
    case  SET_CONTACT:
    return { ...state, loading: true, message: '' }
    case  UPDATE_CONTACT:
    return { ...state, loading: true, message: '' }
    case  DELETE_CONTACT:
    return { ...state, loading: true, message: '' }
    default:
    return { ...state }
}
}