import {
    LIST_SPIN,
    LIST_SPIN_SUCCESS,
    LIST_SPIN_ERROR,
    SET_SPIN,
    DELETE_SPIN,
    UPDATE_SPIN,
    UPDATE_SCRIPT,
    LIST_SCRIPT
} from './../actions'

export const spinList = (proyecto: any) => ({
    type: LIST_SPIN,
    payload: {proyecto}
})

export const spinListSuccess = (spin: any) => ({
    type: LIST_SPIN_SUCCESS,
    payload: spin
})

export const spinListError = (message: any) => ({
    type: LIST_SPIN_ERROR,
    payload: message
})

export const spinSet = (proyecto: any) => ({
    type: SET_SPIN,
    payload: {proyecto}
})

export const spinUpdate = (proyecto: any) => ({
    type: UPDATE_SPIN,
    payload: {proyecto}
})

export const spinDelete = (proyecto: any) => ({
    type: DELETE_SPIN,
    payload: {proyecto}
})

export const scriptUpdate = (proyecto: any) => ({
    type: UPDATE_SCRIPT,
    payload: {proyecto}
})

export const scriptList = (proyecto: any) => ({
    type: LIST_SCRIPT,
    payload: {proyecto}
})