import axios from "axios";
import { ApiConfig } from "../constants/defaultValues";
import { borrarCacheImagenes } from "../helpers/clearCache";

export async function list(e: any) {
  return axios.post(ApiConfig.URL2 + "project/all", e);
}
export async function listOne(
  builder: any,
  pageSize: any,
  currentPage: any,
  orderBy: any,
  search: any,
  user: any
) {
  return axios.post(ApiConfig.URL2 + "project/get", {
    builder,
    pageSize,
    currentPage,
    orderBy,
    search,
    user,
  });
}
export async function one(project: any) {
  return axios.post(ApiConfig.URL2 + "project/one", project);
}
export async function create(e: any) {
  let result = await axios.post(ApiConfig.URL2 + "project/set", e.data);
  e.assets.append(
    "name",
    `galleries/${e.data.builder}/${result.data.last_project}/logo.svg`
  );
  if (result.status === 200) {
    if (e.assets.has("myFile") && e.assets.get("myFile") !== "null") {
      let resultFormData = await axios.post(
        `${ApiConfig.URL3}coord/`,
        e.assets,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (resultFormData.status === 200) {
        borrarCacheImagenes();
        return result;
      }
    } else {
      borrarCacheImagenes();
      return result;
    }
  }
  //return axios.post(ApiConfig.URL2 + "project/set", e);
}
export async function addLogo(e: any) {
  let result = await axios.post(ApiConfig.URL2 + "project/add-logo", e);
  if (result.status === 200) {
    let resultFormData = await axios.post(`${ApiConfig.URL3}coord/`, e.assets, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (resultFormData.status === 200) {
      borrarCacheImagenes();
      return result;
    }
  }
  //return axios.post(ApiConfig.URL2 + "project/set", e);
}
export async function update(e: any, id: any) {
  let result = await axios.post(ApiConfig.URL2 + "project/update", e.data);
  if (result.status === 200) {
    if (e.assets.has("myFile") && e.assets.get("myFile") !== "null") {
      let resultFormData = await axios.post(
        `${ApiConfig.URL3}coord/`,
        e.assets,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (resultFormData.status === 200) {
        borrarCacheImagenes();
        return result;
      }
    } else {
      borrarCacheImagenes();
      return result;
    }
  }
  //return axios.put(ApiConfig.URL + "project/update/" + id, e);
}

export async function updateTemplate(e: any) {
  let result = await axios.post(ApiConfig.URL2 + "project/update/template", e);
  return result;

  //return axios.put(ApiConfig.URL + "project/update/" + id, e);
}

export async function downloadTemplate(e: any) {
  let result = await axios.post(ApiConfig.URL3 + "offline/create", e);
  return result;
}

export async function updateInside(e: any) {
  let result = await axios.post(ApiConfig.URL2 + "project/update", e.data);
  if (result.status === 200) {
    if (e.type !== undefined && e.type === "Ico") {
      console.log(e);
      
        let resultFormData = await axios.post(
          ApiConfig.URL3 + "file/ico",
          e.assets,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        
        if (resultFormData.status === 200) {
          borrarCacheImagenes();
          return result;
        }
    } else if (e.type !== undefined && e.type === "Intro") {
      let resultFormData = await axios.post(
        `${ApiConfig.URL3}coord/`,
        e.assets,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (resultFormData.status === 200) {
        borrarCacheImagenes();
        return result;
      }
    }else{
      return result;
    }
  }
  //return axios.put(ApiConfig.URL + "project/update/" + id, e);
}

export async function eliminar(e: any, id: any) {
  return await axios.post(ApiConfig.URL2 + "project/delete", e);
}
export async function updateBaseModules(e: any, id: any) {
  return axios.post(ApiConfig.URL2 + "project/update/base-modules", {
    id: id,
    data: e,
  });
}
