import React, {useEffect, useState} from 'react'
import { SketchPicker } from 'react-color'
import urbanismo from "./../../UI/general/Urbanismo.svg"

/*eslint-disable*/

const ColorComponent = ({proyecto, paletteChromaticUpdate, user}: any) => {

    const [mainColor, setMainColor] = useState(proyecto.main_buttons)
    const [secColor, setSecColor] = useState(proyecto.secondary_buttons)
    const [mainText, setMainText] = useState(proyecto.main_text)
    const [secText, setSecText] = useState(proyecto.secondary_text)
    const [showMainPicker, setShowMainPicker] = useState(false)
    const [showSecPicker, setShowSecPicker] = useState(false)
    const [showMainTextPicker, setShowMainTextPicker] = useState(false)
    const [showSecTextPicker, setShowSecTextPicker] = useState(false)

    const handleOpenMainPicker = (e: any) => {
        
        setShowMainPicker(!showMainPicker)
        setShowSecPicker(false)
        setShowMainTextPicker(false)
        setShowSecTextPicker(false)
        if (!showMainPicker === false) {
            paletteChromaticUpdate({
                user: JSON.parse(user).id,
                project: proyecto.id,
                main_buttons: mainColor,
                secondary_buttons: secColor,
                main_text: mainText,
                secondary_text: secText
            })
        }
    }

    useEffect(() => {
        setMainColor(proyecto.main_buttons)
        setSecColor(proyecto.secondary_buttons)
        setMainText(proyecto.main_text)
        setSecText(proyecto.secondary_text)
    }, [proyecto])
    

    const handleChangeMainColor = (color: any) => {
        console.log("aca");
        
        setMainColor(color.hex)
    }

    const handleChangeMainColorBlur = (e: any) => {
        console.log("Aqui");
        
        console.log(e);
        
        paletteChromaticUpdate({
            user: JSON.parse(user).id,
            project: proyecto.id,
            main_buttons: mainColor,
            secondary_buttons: secColor,
            main_text: mainText,
            secondary_text: secText
        })
    }

    const handleChangeSecColorBlur = () => {
        paletteChromaticUpdate({
            user: JSON.parse(user).id,
            project: proyecto.id,
            main_buttons: mainColor,
            secondary_buttons: secColor,
            main_text: mainText,
            secondary_text: secText
        })
    }

    const handleChangeMainTextBlur = () => {
        paletteChromaticUpdate({
            user: JSON.parse(user).id,
            project: proyecto.id,
            main_buttons: mainColor,
            secondary_buttons: secColor,
            main_text: mainText,
            secondary_text: secText
        })
    }

    const handleChangeSecTextBlur = () => {
        paletteChromaticUpdate({
            user: JSON.parse(user).id,
            project: proyecto.id,
            main_buttons: mainColor,
            secondary_buttons: secColor,
            main_text: mainText,
            secondary_text: secText
        })
    }

    const handleOpenSecPicker = () => {
        setShowMainPicker(false)
        setShowSecPicker(!showSecPicker)
        setShowMainTextPicker(false)
        setShowSecTextPicker(false)
        if (!showSecPicker === false) {
            paletteChromaticUpdate({
                user: JSON.parse(user).id,
                project: proyecto.id,
                main_buttons: mainColor,
                secondary_buttons: secColor,
                main_text: mainText,
                secondary_text: secText
            })
        }
    }

    const handleChangeSecColor = (color: any) => {
        setSecColor(color.hex)
    }

    const handleOpenMainTextPicker = () => {
        setShowMainPicker(false)
        setShowSecPicker(false)
        setShowMainTextPicker(!showMainTextPicker)
        setShowSecTextPicker(false)
        if (!showMainTextPicker === false) {
            paletteChromaticUpdate({
                user: JSON.parse(user).id,
                project: proyecto.id,
                main_buttons: mainColor,
                secondary_buttons: secColor,
                main_text: mainText,
                secondary_text: secText
            })
        }
    }

    const handleChangeMainTextColor = (color: any) => {
        setMainText(color.hex)
    }

    const handleOpenSecTextPicker = () => {
        setShowMainPicker(false)
        setShowSecPicker(false)
        setShowMainTextPicker(false)
        setShowSecTextPicker(!showSecTextPicker)
        if (!showSecTextPicker === false) {
            paletteChromaticUpdate({
                user: JSON.parse(user).id,
                project: proyecto.id,
                main_buttons: mainColor,
                secondary_buttons: secColor,
                main_text: mainText,
                secondary_text: secText
            })
        }
    }

    const handleChangeSecTextColor = (color: any) => {
        setSecText(color.hex)
    }

    const handleCloseAll = () => {
        setShowMainPicker(false)
        setShowSecPicker(false)
        setShowMainTextPicker(false)
        setShowSecTextPicker(false)
    }

    return(
        <div className='color-palette-container-main'>
            <div className='color-palette-title'>
                Paleta cromática
            </div>
            <div className='color-palette-inputs-container'>
                <div className='color-palette-individual-container'>
                    <div className='color-palette-individual-title'>Principales botones</div>
                    <div className='color-palette-preview' style={{backgroundColor: mainColor}} onClick = {(e) => handleOpenMainPicker(e)}/>
                    {showMainPicker ?
                        <SketchPicker 
                        color={mainColor}
                        disableAlpha={true}
                        className="colorPicker-palette"
                        onChangeComplete={handleChangeMainColor}
                        />:null
                    }
                    <input 
                        type={"text"} 
                        className="color-palette-input"
                        value={mainColor}
                        onChange = {(e) => {setMainColor(e.target.value.toUpperCase())}}
                        onBlur = {(e) => {handleChangeMainColorBlur(e)}}
                    />
                </div>
                <div className='color-palette-individual-container'>
                    <div className='color-palette-individual-title'>Variante botones</div>
                    <div className='color-palette-preview' style={{backgroundColor: secColor}} onClick = {handleOpenSecPicker}/>
                    {showSecPicker ?
                        <SketchPicker 
                        color={secColor}
                        disableAlpha={true}
                        className="colorPicker-palette"
                        onChangeComplete={handleChangeSecColor}
                        />:null
                    }
                    <input 
                        type={"text"} 
                        className="color-palette-input"
                        value={secColor}
                        onChange = {(e) => {setSecColor(e.target.value.toUpperCase())}}
                        onBlur = {() => {handleChangeSecColorBlur()}}
                    />
                </div>
                <div className='color-palette-individual-container'>
                    <div className='color-palette-individual-title'>Principal textos</div>
                    <div className='color-palette-preview' style={{backgroundColor: mainText}} onClick = {handleOpenMainTextPicker}/>
                    {showMainTextPicker ?
                        <SketchPicker 
                        color={mainText}
                        disableAlpha={true}
                        className="colorPicker-palette"
                        onChangeComplete={handleChangeMainTextColor}
                        />:null
                    }
                    <input 
                        type={"text"} 
                        className="color-palette-input"
                        value={mainText}
                        onChange = {(e) => {setMainText(e.target.value.toUpperCase())}}
                        onBlur = {() => {handleChangeMainTextBlur()}}
                    />
                </div>
                <div className='color-palette-individual-container'>
                    <div className='color-palette-individual-title'>Variante textos</div>
                    <div className='color-palette-preview' style={{backgroundColor: secText}} onClick = {handleOpenSecTextPicker}/>
                    {showSecTextPicker ?
                        <SketchPicker 
                        color={secText}
                        disableAlpha={true}
                        className="colorPicker-palette"
                        onChangeComplete={handleChangeSecTextColor}
                        />:null
                    }
                    <input 
                        type={"text"} 
                        className="color-palette-input"
                        value={secText}
                        onChange = {(e) => {setSecText(e.target.value.toUpperCase())}}
                        onBlur = {() => {handleChangeSecTextBlur()}}
                    />
                </div>
            </div>
            <div className='color-palette-previews-container-main'>
                <div className='color-palette-previews-title'> Vista previa de los principales UI de la maqueta</div>
                <div className='color-palette-previews-container'>

                    <div className='color-palette-individual-preview'>
                        <div className='color-palette-individual-img-container' style={{backgroundColor: "#E0E0E0"}}>
                            <svg id="Group_1627" xmlns="http://www.w3.org/2000/svg">
                                <path id="Path_3498" data-name="Path 3498" style={{fill: mainText}} d="M15.168,301.045H.648a.647.647,0,1,0,0,1.295H14.521v4.014a.647.647,0,0,0,1.295,0v-4.66a.647.647,0,0,0-.646-.648Z" transform="translate(0 -273.86)"/>
                                <path id="Path_3499" data-name="Path 3499" style={{fill: mainText}} d="M194.457,301.045a.647.647,0,0,0-.646.648v4.66a.647.647,0,0,0,1.295,0V302.34h13.874a.647.647,0,1,0,0-1.295H194.457Z" transform="translate(-176.309 -273.86)"/>
                                <path id="Path_3500" data-name="Path 3500" style={{fill: mainText}} d="M11.812.006a.647.647,0,0,0-.647.647v4.66H.647a.647.647,0,0,0,0,1.295H11.811a.647.647,0,0,0,.647-.647V.653a.647.647,0,0,0-.646-.647" transform="translate(0 -0.005)"/>
                                <path id="Path_3501" data-name="Path 3501" style={{fill: mainText}} d="M4.461,110.387h-3.1a.647.647,0,1,0,0,1.295H3.816v11.263H1.359a.647.647,0,1,0,0,1.295h3.1a.647.647,0,0,0,.647-.647V111.034a.647.647,0,0,0-.647-.647" transform="translate(-0.648 -100.419)"/>
                                <path id="Path_3502" data-name="Path 3502" style={{fill: mainText}} d="M318.271,111.034v12.559a.647.647,0,0,0,.647.647h3.1a.647.647,0,1,0,0-1.295h-2.457V111.682h2.457a.647.647,0,1,0,0-1.295h-3.1a.647.647,0,0,0-.647.647" transform="translate(-289.53 -100.419)"/>
                                <path id="Path_3503" data-name="Path 3503" style={{fill: mainText}} d="M169.868,0a.647.647,0,0,0-.647.647v4.66h-9.657V.647a.647.647,0,0,0-1.295,0V5.955a.647.647,0,0,0,.647.647h10.952a.647.647,0,0,0,.647-.647V.647A.647.647,0,0,0,169.868,0" transform="translate(-143.977)"/>
                                <path id="Path_3504" data-name="Path 3504" style={{fill: mainText}} d="M318.045,5.308H315.2V.648a.647.647,0,1,0-1.295,0V5.955a.647.647,0,0,0,.647.647h3.5a.647.647,0,1,0,0-1.295Z" transform="translate(-285.555)"/>
                                <path id="Path_3505" data-name="Path 3505" style={{fill: mainText}} d="M96.286,106.365a.577.577,0,0,0-.058-.181l.042-.042-1.605-1.192v-4.807a.577.577,0,0,0-.576-.576H90.674a.577.577,0,0,0-.576.576v1.414l-2.965-2.2a.579.579,0,0,0-.687,0l-8.927,6.631a.576.576,0,1,0,.687.925l.708-.526v3.284a.576.576,0,0,0,1.152,0v-4.14l6.724-4.995,6.723,4.994v7.716H89.819v-2.854a3.03,3.03,0,1,0-6.059,0v2.854h-4.27a.576.576,0,0,0,0,1.152h14.6a.577.577,0,0,0,.576-.576v-7.436l.707.526a.572.572,0,0,0,.343.114.579.579,0,0,0,.463-.233.572.572,0,0,0,.107-.428m-7.62,4.51a.7.7,0,1,0,0,.766v1.6H84.912v-2.854a1.877,1.877,0,1,1,3.755,0Zm2.584-8.462v-1.694h2.262v3.375Z" transform="translate(-70.307 -90.28)"/>
                                <path id="Path_3506" data-name="Path 3506" style={{fill: mainText}} d="M165.605,137.811a1.864,1.864,0,1,0-1.864,1.864,1.866,1.866,0,0,0,1.864-1.864m-2.576,0a.712.712,0,1,1,.712.712.713.713,0,0,1-.712-.712" transform="translate(-147.259 -123.671)"/>
                            </svg>
                            <div className='color-palette-individual-inner-text' style={{color: mainText}}>Urbanismo</div>
                        </div>
                        <div className='color-palette-individual-text'>Base</div>
                    </div>

                    <div className='color-palette-individual-preview'>
                        <div className='color-palette-individual-img-container' style={{backgroundColor: secColor}}>
                            <svg id="Group_1627" xmlns="http://www.w3.org/2000/svg">
                                <g id="Group_1627-2" data-name="Group 1627" style={{clipPath: "url(#clip-path)"}}>
                                    <path id="Path_3498" data-name="Path 3498" style={{fill: mainText}} d="M15.168,301.045H.648a.647.647,0,1,0,0,1.295H14.521v4.014a.647.647,0,0,0,1.295,0v-4.66a.647.647,0,0,0-.646-.648Z" transform="translate(0 -273.86)"/>
                                    <path id="Path_3499" data-name="Path 3499" style={{fill: mainText}} d="M194.457,301.045a.647.647,0,0,0-.646.648v4.66a.647.647,0,0,0,1.295,0V302.34h13.874a.647.647,0,1,0,0-1.295H194.457Z" transform="translate(-176.309 -273.86)"/>
                                    <path id="Path_3500" data-name="Path 3500" style={{fill: mainText}} d="M11.812.006a.647.647,0,0,0-.647.647v4.66H.647a.647.647,0,0,0,0,1.295H11.811a.647.647,0,0,0,.647-.647V.653a.647.647,0,0,0-.646-.647" transform="translate(0 -0.005)"/>
                                    <path id="Path_3501" data-name="Path 3501" style={{fill: mainText}} d="M4.461,110.387h-3.1a.647.647,0,1,0,0,1.295H3.816v11.263H1.359a.647.647,0,1,0,0,1.295h3.1a.647.647,0,0,0,.647-.647V111.034a.647.647,0,0,0-.647-.647" transform="translate(-0.648 -100.419)"/>
                                    <path id="Path_3502" data-name="Path 3502" style={{fill: mainText}} d="M318.271,111.034v12.559a.647.647,0,0,0,.647.647h3.1a.647.647,0,1,0,0-1.295h-2.457V111.682h2.457a.647.647,0,1,0,0-1.295h-3.1a.647.647,0,0,0-.647.647" transform="translate(-289.53 -100.419)"/>
                                    <path id="Path_3503" data-name="Path 3503" style={{fill: mainText}} d="M169.868,0a.647.647,0,0,0-.647.647v4.66h-9.657V.647a.647.647,0,0,0-1.295,0V5.955a.647.647,0,0,0,.647.647h10.952a.647.647,0,0,0,.647-.647V.647A.647.647,0,0,0,169.868,0" transform="translate(-143.977)"/>
                                    <path id="Path_3504" data-name="Path 3504" style={{fill: mainText}} d="M318.045,5.308H315.2V.648a.647.647,0,1,0-1.295,0V5.955a.647.647,0,0,0,.647.647h3.5a.647.647,0,1,0,0-1.295Z" transform="translate(-285.555)"/>
                                    <path id="Path_3505" data-name="Path 3505" style={{fill: mainText}} d="M96.286,106.365a.577.577,0,0,0-.058-.181l.042-.042-1.605-1.192v-4.807a.577.577,0,0,0-.576-.576H90.674a.577.577,0,0,0-.576.576v1.414l-2.965-2.2a.579.579,0,0,0-.687,0l-8.927,6.631a.576.576,0,1,0,.687.925l.708-.526v3.284a.576.576,0,0,0,1.152,0v-4.14l6.724-4.995,6.723,4.994v7.716H89.819v-2.854a3.03,3.03,0,1,0-6.059,0v2.854h-4.27a.576.576,0,0,0,0,1.152h14.6a.577.577,0,0,0,.576-.576v-7.436l.707.526a.572.572,0,0,0,.343.114.579.579,0,0,0,.463-.233.572.572,0,0,0,.107-.428m-7.62,4.51a.7.7,0,1,0,0,.766v1.6H84.912v-2.854a1.877,1.877,0,1,1,3.755,0Zm2.584-8.462v-1.694h2.262v3.375Z" transform="translate(-70.307 -90.28)"/>
                                    <path id="Path_3506" data-name="Path 3506" style={{fill: mainText}} d="M165.605,137.811a1.864,1.864,0,1,0-1.864,1.864,1.866,1.866,0,0,0,1.864-1.864m-2.576,0a.712.712,0,1,1,.712.712.713.713,0,0,1-.712-.712" transform="translate(-147.259 -123.671)"/>
                                </g>
                            </svg>
                            <div className='color-palette-individual-inner-text' style={{color: mainText}}>Urbanismo</div>
                        </div>
                        <div className='color-palette-individual-text'>Hover</div>
                    </div>

                    <div className='color-palette-individual-preview'>
                        <div className='color-palette-individual-img-container' style={{backgroundColor: mainColor}}>
                            <svg id="Group_1627" xmlns="http://www.w3.org/2000/svg">
                                <g id="Group_1627-2" data-name="Group 1627" style={{clipPath: "url(#clip-path)"}}>
                                    <path id="Path_3498" data-name="Path 3498" style={{fill: secText}} d="M15.168,301.045H.648a.647.647,0,1,0,0,1.295H14.521v4.014a.647.647,0,0,0,1.295,0v-4.66a.647.647,0,0,0-.646-.648Z" transform="translate(0 -273.86)"/>
                                    <path id="Path_3499" data-name="Path 3499" style={{fill: secText}} d="M194.457,301.045a.647.647,0,0,0-.646.648v4.66a.647.647,0,0,0,1.295,0V302.34h13.874a.647.647,0,1,0,0-1.295H194.457Z" transform="translate(-176.309 -273.86)"/>
                                    <path id="Path_3500" data-name="Path 3500" style={{fill: secText}} d="M11.812.006a.647.647,0,0,0-.647.647v4.66H.647a.647.647,0,0,0,0,1.295H11.811a.647.647,0,0,0,.647-.647V.653a.647.647,0,0,0-.646-.647" transform="translate(0 -0.005)"/>
                                    <path id="Path_3501" data-name="Path 3501" style={{fill: secText}} d="M4.461,110.387h-3.1a.647.647,0,1,0,0,1.295H3.816v11.263H1.359a.647.647,0,1,0,0,1.295h3.1a.647.647,0,0,0,.647-.647V111.034a.647.647,0,0,0-.647-.647" transform="translate(-0.648 -100.419)"/>
                                    <path id="Path_3502" data-name="Path 3502" style={{fill: secText}} d="M318.271,111.034v12.559a.647.647,0,0,0,.647.647h3.1a.647.647,0,1,0,0-1.295h-2.457V111.682h2.457a.647.647,0,1,0,0-1.295h-3.1a.647.647,0,0,0-.647.647" transform="translate(-289.53 -100.419)"/>
                                    <path id="Path_3503" data-name="Path 3503" style={{fill: secText}} d="M169.868,0a.647.647,0,0,0-.647.647v4.66h-9.657V.647a.647.647,0,0,0-1.295,0V5.955a.647.647,0,0,0,.647.647h10.952a.647.647,0,0,0,.647-.647V.647A.647.647,0,0,0,169.868,0" transform="translate(-143.977)"/>
                                    <path id="Path_3504" data-name="Path 3504" style={{fill: secText}} d="M318.045,5.308H315.2V.648a.647.647,0,1,0-1.295,0V5.955a.647.647,0,0,0,.647.647h3.5a.647.647,0,1,0,0-1.295Z" transform="translate(-285.555)"/>
                                    <path id="Path_3505" data-name="Path 3505" style={{fill: secText}} d="M96.286,106.365a.577.577,0,0,0-.058-.181l.042-.042-1.605-1.192v-4.807a.577.577,0,0,0-.576-.576H90.674a.577.577,0,0,0-.576.576v1.414l-2.965-2.2a.579.579,0,0,0-.687,0l-8.927,6.631a.576.576,0,1,0,.687.925l.708-.526v3.284a.576.576,0,0,0,1.152,0v-4.14l6.724-4.995,6.723,4.994v7.716H89.819v-2.854a3.03,3.03,0,1,0-6.059,0v2.854h-4.27a.576.576,0,0,0,0,1.152h14.6a.577.577,0,0,0,.576-.576v-7.436l.707.526a.572.572,0,0,0,.343.114.579.579,0,0,0,.463-.233.572.572,0,0,0,.107-.428m-7.62,4.51a.7.7,0,1,0,0,.766v1.6H84.912v-2.854a1.877,1.877,0,1,1,3.755,0Zm2.584-8.462v-1.694h2.262v3.375Z" transform="translate(-70.307 -90.28)"/>
                                    <path id="Path_3506" data-name="Path 3506" style={{fill: secText}} d="M165.605,137.811a1.864,1.864,0,1,0-1.864,1.864,1.866,1.866,0,0,0,1.864-1.864m-2.576,0a.712.712,0,1,1,.712.712.713.713,0,0,1-.712-.712" transform="translate(-147.259 -123.671)"/>
                                </g>
                            </svg>
                            <div className='color-palette-individual-inner-text' style={{color: secText}}>Urbanismo</div>
                        </div>
                        <div className='color-palette-individual-text'>Seleccionado</div>
                    </div>

                    <div className='color-palette-outline-container'>
                        <div 
                            className='color-palette-outline-btn' 
                            style={{
                                border: `1px solid ${mainColor}`,
                                color: mainText
                            }}
                        >
                            Cotizar
                        </div>
                        <div 
                            className='color-palette-outline-btn'
                            style={{
                                border: `1px solid ${mainColor}`,
                                color: mainText
                            }}
                        >
                            Enviar
                        </div>
                        <div className='palette-individual-text'>Outline</div>
                    </div>

                    <div className='color-palette-text-states-container'>
                        <div className='color-palette-text-individual'>
                            <img src={urbanismo} alt=""/>
                            <div className='color-palette-text-modal' style={{color: mainText}}><b>Salud</b></div>
                        </div>
                        <div className='color-palette-text-individual'>
                            <img src={urbanismo} alt=""/>
                            <div className='color-palette-text-modal' style={{color: mainText}}>Comercio</div>
                        </div>
                        <div className='color-palette-individual-text'>Estados de texto</div>
                    </div>

                    <div className='color-palette-bilboards-container'>
                        <div className='svg-container'>
                        <div className='color-palette-amenities-billboard'>
                            <svg>
                                <circle xmlns="http://www.w3.org/2000/svg"
                                    cx={"50%"}
                                    cy={"50%"}
                                    r="12.5%" 
                                    style={{
                                        fill: mainColor,
                                        stroke: secText,
                                        strokeWidth: 1,
                                        filter: "drop-shadow(2px 3px 2px rgb(0 0 0 / 0.4))"
                                    }}
                                >
                                </circle>
                                <svg id="Group_1627" xmlns="http://www.w3.org/2000/svg" x="44.5%" y="39.5%" style={{width: "80%"}} width="50px">
                                    <g id="Group_1627-2" data-name="Group 1627" style={{clipPath: "url(#clip-path)"}}>
                                        <path id="Path_3498" data-name="Path 3498" style={{fill: secText}} d="M15.168,301.045H.648a.647.647,0,1,0,0,1.295H14.521v4.014a.647.647,0,0,0,1.295,0v-4.66a.647.647,0,0,0-.646-.648Z" transform="translate(0 -273.86)"/>
                                        <path id="Path_3499" data-name="Path 3499" style={{fill: secText}} d="M194.457,301.045a.647.647,0,0,0-.646.648v4.66a.647.647,0,0,0,1.295,0V302.34h13.874a.647.647,0,1,0,0-1.295H194.457Z" transform="translate(-176.309 -273.86)"/>
                                        <path id="Path_3500" data-name="Path 3500" style={{fill: secText}} d="M11.812.006a.647.647,0,0,0-.647.647v4.66H.647a.647.647,0,0,0,0,1.295H11.811a.647.647,0,0,0,.647-.647V.653a.647.647,0,0,0-.646-.647" transform="translate(0 -0.005)"/>
                                        <path id="Path_3501" data-name="Path 3501" style={{fill: secText}} d="M4.461,110.387h-3.1a.647.647,0,1,0,0,1.295H3.816v11.263H1.359a.647.647,0,1,0,0,1.295h3.1a.647.647,0,0,0,.647-.647V111.034a.647.647,0,0,0-.647-.647" transform="translate(-0.648 -100.419)"/>
                                        <path id="Path_3502" data-name="Path 3502" style={{fill: secText}} d="M318.271,111.034v12.559a.647.647,0,0,0,.647.647h3.1a.647.647,0,1,0,0-1.295h-2.457V111.682h2.457a.647.647,0,1,0,0-1.295h-3.1a.647.647,0,0,0-.647.647" transform="translate(-289.53 -100.419)"/>
                                        <path id="Path_3503" data-name="Path 3503" style={{fill: secText}} d="M169.868,0a.647.647,0,0,0-.647.647v4.66h-9.657V.647a.647.647,0,0,0-1.295,0V5.955a.647.647,0,0,0,.647.647h10.952a.647.647,0,0,0,.647-.647V.647A.647.647,0,0,0,169.868,0" transform="translate(-143.977)"/>
                                        <path id="Path_3504" data-name="Path 3504" style={{fill: secText}} d="M318.045,5.308H315.2V.648a.647.647,0,1,0-1.295,0V5.955a.647.647,0,0,0,.647.647h3.5a.647.647,0,1,0,0-1.295Z" transform="translate(-285.555)"/>
                                        <path id="Path_3505" data-name="Path 3505" style={{fill: secText}} d="M96.286,106.365a.577.577,0,0,0-.058-.181l.042-.042-1.605-1.192v-4.807a.577.577,0,0,0-.576-.576H90.674a.577.577,0,0,0-.576.576v1.414l-2.965-2.2a.579.579,0,0,0-.687,0l-8.927,6.631a.576.576,0,1,0,.687.925l.708-.526v3.284a.576.576,0,0,0,1.152,0v-4.14l6.724-4.995,6.723,4.994v7.716H89.819v-2.854a3.03,3.03,0,1,0-6.059,0v2.854h-4.27a.576.576,0,0,0,0,1.152h14.6a.577.577,0,0,0,.576-.576v-7.436l.707.526a.572.572,0,0,0,.343.114.579.579,0,0,0,.463-.233.572.572,0,0,0,.107-.428m-7.62,4.51a.7.7,0,1,0,0,.766v1.6H84.912v-2.854a1.877,1.877,0,1,1,3.755,0Zm2.584-8.462v-1.694h2.262v3.375Z" transform="translate(-70.307 -90.28)"/>
                                        <path id="Path_3506" data-name="Path 3506" style={{fill: secText}} d="M165.605,137.811a1.864,1.864,0,1,0-1.864,1.864,1.866,1.866,0,0,0,1.864-1.864m-2.576,0a.712.712,0,1,1,.712.712.713.713,0,0,1-.712-.712" transform="translate(-147.259 -123.671)"/>
                                    </g>
                                </svg>
                            </svg>
                        </div>
                        <div className='color-palette-lotes-billboard'>
                            <svg>
                                <circle xmlns="http://www.w3.org/2000/svg"
                                    cx={"50%"}
                                    cy={"50%"}
                                    r="12.5%" 
                                    style={{
                                        fill: secText,
                                        stroke: mainColor,
                                        strokeWidth: 1,
                                        filter: "drop-shadow(2px 3px 2px rgb(0 0 0 / 0.4))"
                                    }}
                                >
                                </circle>
                                <text
                                    x={"50%"}
                                    y={"51%"}
                                    fill={mainText}
                                    fontSize={"1.5rem"}
                                    dominant-baseline="middle" 
                                    text-anchor="middle"
                                    fontFamily="Montserrat Regular" 
                                    >21</text>
                            </svg>
                        </div>
                        </div>
                        <div className='color-palette-individual-text'>Billboards</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ColorComponent