import React from 'react'

import U3DS from "./../../UI/general/Placeholder_02.svg";

const DefaultAmenitie = ({amenitiesSuggested, currentAmenitieDefaultIcon, currentAmenitieDefaultName, handleDefaultAmenitieSelect, handleCreateAmenitie}: any) => {

    const handleGetSelected = () => {
        var getSelect: any = document.getElementById("amenities-select")!
        var getValue: any = getSelect.value
        handleDefaultAmenitieSelect(amenitiesSuggested[getValue])
    }

    return(
        <div className='default-amenities-container'>
            <div className='default-amenities-title'>Seleccionar una amenidad predefinida</div>
            <div className='default-amenties-image-info'>
                <div className='default-amenities-icon-container'>
                    <img src={
                        currentAmenitieDefaultIcon !== "" ? 
                        `https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${currentAmenitieDefaultIcon}`:
                        U3DS
                    } alt=""/>
                </div>
                <div className='default-amenties-info-container'>
                    <div className='default-amenities-info-title'>Seleccionar ícono</div>
                    <div className='default-amenities-info-text'>El logo debe estar en formato .SVG</div>
                    <input
                        type="file"
                        name="imagen"
                        id="upload-photo-nuevo-amenities"
                        className="default-amenities-input"
                        //onChange={(e: any) => {handleAddLogo(e)}}
                        accept="image/svg+xml"
                    />
                    <div className='default-amenities-select' /*onClick={() => {handleSelectIcon(true)}}*/>
                        Modificar
                    </div>
                </div>
            </div>
            <div className='default-amenities-select-text'>Predefinidas</div>
            <select 
                name="default-amenities-selection" 
                className='default-amenities-selection'
                id = "amenities-select"
                onChange={() => {handleGetSelected()}}
            >
                <option defaultValue={""} hidden disabled selected>Selecciona la amenidad</option>
                {
                    amenitiesSuggested.map((amenitie: any, index: number) => 
                        <option defaultValue={""} value={index}>{amenitie.name}</option>
                    )
                }
            </select>
            <div className='default-amenities-save' onClick={() => handleCreateAmenitie()}>Guardar</div>
        </div>
    )
}

export default DefaultAmenitie