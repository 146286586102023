import {
  LIST_ATTRIBUTES,
  LIST_ATTRIBUTES_SUCCESS,
  LIST_ATTRIBUTES_ERROR,
  CREATE_ATTRIBUTES,
  //UPDATE_ATTRIBUTES_HIERARCHICAL
} from '../actions'

/*eslint-disable*/

const INIT_STATE = {
  attributes: [],
  loading: false,
  message: '',
}

export default (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case LIST_ATTRIBUTES:
      return { ...state, loading: true, message: '' }
    case LIST_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        attributes: action.payload,
        loading: false,
        message: "Exito",
      }
    case LIST_ATTRIBUTES_ERROR:
      return {
        ...state,
        attributes: [],
        loading: false,
        message: action.payload.message,
      }
    case CREATE_ATTRIBUTES:
      return { ...state, loading: true, message: '' }
    default:
      return { ...state }
  }
}
