import { 
    OPEN_ICONS,
    LIST_ICONS,
    LIST_ICONS_SUCCESS,
    LIST_ICONS_ERROR,
    CREATE_ICONS
} from "../actions";

/*eslint-disable*/

const INIT_STATE = {
    isIconsModalOpened: false,
    icons: [],
    loading: false,
    message: ""
}

export default (state = INIT_STATE, action: any) => {
    switch (action.type){
        case OPEN_ICONS:
            return{
                ...state,
                isIconsModalOpened: action.payload
            }
        case LIST_ICONS:
            return {...state, loading: true, message: ""}
        case LIST_ICONS_SUCCESS:
            return {
                ...state,
                icons: action.payload,
                loading: false,
                message: ""
            }
        case LIST_ICONS_ERROR:
            return {
                ...state,
                icons: [],
                loading: false,
                message: action.payload.message
            }
        case CREATE_ICONS:
            return {...state, loading: true, message: ""}
        default:
            return {
                ...state
            }
    }
}