import React from 'react'

import Delete from './../../UI/general/close.svg'

import { SketchPicker } from 'react-color'

interface Props {
  currentLogo: any
  handleAddLogo: any,
  handleCreateLote: any, 
  handleStopLote: any, 
  handleUpdateColorLote: any, 
  currentColorLote: any,  
  currentLoteArray: any, 
  handleDeleteLote: any
}

interface State {
  openColorModal: boolean
}

class LoteNew extends React.Component<Props,State>{
  constructor(props: Props){
    super(props);
    this.state = {
      openColorModal: false
    }
  }

  render(): React.ReactNode {
      return(
        <div className='lote-icon-creation-container'>
          <div className='lote-icon-creation-title'>Información del proyecto</div>
          <div className='icon-creation-container'>
            <div className='icon-creation-image-container'>
              <img src={this.props.currentLogo} alt=''/>
            </div>
            <div className='icon-creation-information-container'>
              <div className='icon-creation-information-title'>Ícono de proyecto en el mapa dinámico</div>
              <div className='icon-creation-information-text'>El material debe estar en formato .SVG</div>
              <div className='icon-creation-information-input-container'>
                <div className='icon-creation-information-btn'>Modificar</div>
                <input
                  type='file'
                  onChange={(e: any) => {this.props.handleAddLogo(e)}}
                  accept="image/svg+xml"
                >
                </input>
              </div>
            </div>
          </div>
          <div className='lote-creation-container'>
            <div className='lote-creation-title'>Contorno</div>
            <div className='lote-creation-options'>
              <div 
              style={{backgroundColor: this.props.currentColorLote}} 
              className='lote-color-container-thumb'
              onClick={() => {this.setState({openColorModal: !this.state.openColorModal})}}
              
              />
              {
                this.state.openColorModal ?
                <SketchPicker
                  color={this.props.currentColorLote}
                  disableAlpha={true}
                  onChange={(color) => {this.props.handleUpdateColorLote(color.hex)}}
                />:null
              }
              <input type='text' defaultValue={this.props.currentColorLote} value={this.props.currentColorLote} onChange={(e) => {this.props.handleUpdateColorLote(e.target.value)}}/>
              <div className='lote-draw-btn-creation' onClick={() => {this.props.handleCreateLote()}}>
                Dibujar en mapa
              </div>
              <div className='lote-save-btn-creation' onClick={() => {this.props.handleStopLote(this.props.currentColorLote)}}>
                Guardar
              </div>
            </div>
          </div>
          {
            this.props.currentLoteArray !== '' && this.props.currentLoteArray.length > 0 ?
            <div className='lote-container-created'>
              <div className='lote-container-name'>Contorno 1</div>
              <img src={Delete} alt='' onClick={() => {this.props.handleDeleteLote()}}/>
            </div>:null
          }
        </div>
      )
  }
}

export default LoteNew