import React, {useState, useEffect} from 'react'
import {
    IconButton,
} from "@mui/material";

import { Link } from "react-router-dom"
import { IconEdit, IconTrash } from "@tabler/icons";

const CardListBuilders = ({elements, editEvent, deleteEvent, builderSelectedSuccess, user}: any) => {

    const [cards, setCards] = useState(elements)

    useEffect(() => {
        setCards(elements)
    }, [elements]) 
    
    return(
        <div className='builders-card-grid'>
            {
                cards.map((element: any, index: number) => 
                <div className='builders-cards' key={index}>
                    <div className='buiders-image-container'>
                        <img width={100} src={`https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${element.logo}`} alt="builders" className="builder-image" />
                    </div>
                    <div className='builders-info-container'>
                        <div className='builders-name'>{element.name}</div>
                        <div className='builders-projects'>{element.projects} proyectos</div>
                        {
                            user.name === 'ADMIN' ?
                            <div className='builders-options-container'>
                                <IconButton  onClick={() => editEvent(element)}>
                                    <IconEdit />
                                </IconButton>
                                <IconButton  onClick={() => deleteEvent(element)}>
                                    <IconTrash />
                                </IconButton>
                            </div>:null
                        }
                        {
                            user.name === 'ADMIN' ?
                            <div className='builders-divider'/>:null
                        }
                        <Link to={`/projects/${element.id}`}>
                            <div className="builders-see-project" onClick={() => builderSelectedSuccess(element)}>
                                Ver
                            </div>
                        </Link>
                    </div>
                </div>
                )
            }
        </div>
    )
}

export default CardListBuilders