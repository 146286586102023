import React from 'react'

import U3DS from "./../../UI/general/Placeholder_02.svg";

interface IconsPOISCategoryProps {
    currentPOI: string,
    currentLogo: string,
    handleAddLogo: any
}

const IconsPOISCategory = ({currentPOI, currentLogo, handleAddLogo}: IconsPOISCategoryProps) => {
    return(
        <div className='pois-category-icons-container'>
            <div className='pois-category-icon-container'>
                <img 
                    src={currentLogo !== "" ? currentLogo:U3DS} 
                    alt=""
                />
            </div>
            <div className='pois-category-icon-text-container'>
                <div className='pois-category-icon-title'>
                    Ícono de Punto de interés - {currentPOI}
                </div>
                <div className='pois-category-icon-text'>
                    El archivo debe estar en formato .SVG
                </div>
                <input
                    type="file"
                    name="imagen"
                    id="upload-photo-nuevo-icons"
                    className="upload-photo-nuevo-icons inputfile-icons"
                    onChange={(e: any) => {handleAddLogo(e)}}
                    accept="image/svg+xml"
                />
                <div className='input-icons-btn-poi'>
                    Seleccionar
                </div>
            </div>
        </div>
    )
}

export default IconsPOISCategory