import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { list, create, setGalleries, update, eliminar } from "./../../models/amenities";

import {
  LIST_AMENITIES,
  CREATE_AMENITIES,
  UPDATE_AMENITIES,
  DELETE_AMENITIES,
  SET_GALLERIES_AMENITIES,
} from "../actions";

import { amenidadesListSuccess, amenidadesListError } from "./actions";

import {
  projectSelectedSuccess,
  projectSelectedError,
} from "../project/actions";

import {
  builderSelectedSuccess,
  builderSelectedError,
} from "../builder/actions";

interface amenidadesResponse {
  amenidadItf: any;
}

/*eslint-disable*/

export function* watchamenidadesList() {
  yield takeEvery(LIST_AMENITIES, amenidadesList);
}

export function* watchAmenidadesCreate() {
  yield takeEvery(CREATE_AMENITIES, amenitiesCreate);
}

export function* watchAmenidadesUpdate() {
  yield takeEvery(UPDATE_AMENITIES, amenitiesUpdate);
}

export function* watchAmenidadesDelete() {
  yield takeEvery(DELETE_AMENITIES, amenitiesDelete);
}

export function* watchSetGalleries() {
  yield takeEvery(SET_GALLERIES_AMENITIES, amenitiesSetGalleries);
}

const amenidadesListAsync = async (
  proyecto: any
): Promise<amenidadesResponse> =>
  await list(proyecto)
    .then((amenidadItf) => amenidadItf)
    .catch((error) => error);

function* amenidadesList({ payload }: any): any {
  const { proyecto } = payload;
  try {
    const amenidadesListData = yield call(amenidadesListAsync, proyecto);
    if (amenidadesListData.status === 200) {
      yield put(amenidadesListSuccess(amenidadesListData.data));
      yield put(projectSelectedSuccess(amenidadesListData.data.project));
      yield put(builderSelectedSuccess(amenidadesListData.data.builder));
    } else {
      yield put(amenidadesListError(amenidadesListData));
      yield put(projectSelectedError(amenidadesListData));
      yield put(builderSelectedError(amenidadesListData));
    }
  } catch (error) {
    yield put(amenidadesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const amenidadesCreateAsync = async (amenidad: any) =>
  await create(amenidad)
    .then((amenidad) => amenidad)
    .catch((error) => error);

function* amenitiesCreate({ payload }: any): any {
  const { amenidad } = payload;
  try {
    const amenidadesCreateData = yield call(amenidadesCreateAsync, amenidad);
    if (amenidadesCreateData.status === 200) {
      yield put(amenidadesListSuccess(amenidadesCreateData.data));
    } else {
      yield put(amenidadesListError(amenidadesCreateData));
    }
  } catch (error) {
    return;
  }
}

const amenitiesUpdateAsync = async (
  proyecto: any
): Promise<amenidadesResponse> =>
  await update(proyecto)
    .then((amenidadItf) => amenidadItf)
    .catch((error) => error);

function* amenitiesUpdate({ payload }: any): any {
  const { amenidad } = payload;

  
  try {
    const amenidadesListData = yield call(amenitiesUpdateAsync, amenidad);
    if (amenidadesListData.status === 200) {
      yield put(amenidadesListSuccess(amenidadesListData.data));
      yield put(projectSelectedSuccess(amenidadesListData.data.project));
      yield put(builderSelectedSuccess(amenidadesListData.data.builder));
    } else {
      yield put(amenidadesListError(amenidadesListData));
      yield put(projectSelectedError(amenidadesListData));
      yield put(builderSelectedError(amenidadesListData));
    }
  } catch (error) {
    yield put(amenidadesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const amenitiesDeleteAsync = async (
  proyecto: any
): Promise<amenidadesResponse> =>
  await eliminar(proyecto)
    .then((amenidadItf) => amenidadItf)
    .catch((error) => error);

function* amenitiesDelete({ payload }: any): any {
  const { amenidad } = payload;
  try {
    const amenidadesListData = yield call(amenitiesDeleteAsync, amenidad);
    if (amenidadesListData.status === 200) {
      yield put(amenidadesListSuccess(amenidadesListData.data));
      yield put(projectSelectedSuccess(amenidadesListData.data.project));
      yield put(builderSelectedSuccess(amenidadesListData.data.builder));
    } else {
      yield put(amenidadesListError(amenidadesListData));
      yield put(projectSelectedError(amenidadesListData));
      yield put(builderSelectedError(amenidadesListData));
    }
  } catch (error) {
    yield put(amenidadesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const amenidadesGalleriesAsync = async (amenidad: any) =>
  await setGalleries(amenidad)
    .then((amenidad) => amenidad)
    .catch((error) => error);

function* amenitiesSetGalleries({ payload }: any): any {
  const { amenidad } = payload;

  try {
    const amenidadesGalleriesData = yield call(
      amenidadesGalleriesAsync,
      amenidad
    );
    if (amenidadesGalleriesData.status === 200) {
      yield put(amenidadesListSuccess(amenidadesGalleriesData.data));
      yield put(projectSelectedSuccess(amenidadesGalleriesData.data.project));
      yield put(builderSelectedSuccess(amenidadesGalleriesData.data.builder));
    } else {
      yield put(amenidadesListError(amenidadesGalleriesData));
      yield put(projectSelectedSuccess(amenidadesGalleriesData));
      yield put(builderSelectedSuccess(amenidadesGalleriesData));
    }
  } catch (error) {

  }
}

export default function* rootSage() {
  yield all([
    fork(watchamenidadesList),
    fork(watchAmenidadesCreate),
    fork(watchAmenidadesUpdate),
    fork(watchAmenidadesDelete),
    fork(watchSetGalleries),
  ]);
}
