import React from 'react'

interface State {
  currentImage: string,
  file: any
}

interface Props {
  openEditionMode: boolean,
  imageToEdit: any,
  infoLevel: any,
  handleUpdateImage: any
}

class GalleryEdit extends React.Component<Props,State>{
  constructor(props: Props){
    super(props);
    this.state = {
      currentImage: '',
      file: null
    }
  }

  componentDidMount(): void {
    this.setState({
      currentImage: 'https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/'+this.props.imageToEdit.link
    })
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
      if(this.props.imageToEdit !== prevProps.imageToEdit){
        this.setState({
          currentImage: 'https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/'+this.props.imageToEdit.link
        })
      }
  }

  handleChangeImage = (e: any) => {
    var image = URL.createObjectURL(e.target.files[0])
    this.setState({
      currentImage: image,
      file: e.target.files[0]
    })
  }

  render(): React.ReactNode {
      return(
        <div className={this.props.openEditionMode ? 'edition-modal-gallery fade-in':'edition-modal-gallery fade-out'}>
          <div className='edit-image-title'>Editar {this.props.infoLevel[this.props.infoLevel.length - 1]}</div>
          <div className='edit-image-container'>
            <div className='edit-image-container-preview'>
              <img src={this.state.currentImage} alt=''></img>
            </div>
            <div className='info-edit-container'>
              <div className='info-title-gallery'>
                Actualizar imagen
              </div>
              <div className='info-subtitle-gallery'>
                El archivo debe estar en formato .WEBP
              </div>
              <div className='info-upload-new-image'>
                <input
                  type={"file"}
                  accept={"image/webp"}
                  className="asoleacion-upload-input"
                  onChange = {(e) => {this.handleChangeImage(e)}}
                />
                <div className='asoleacion-upload-btn'>
                  Subir Archivo
                </div>
              </div>
            </div>
          </div>
          <div className='edit-gallery-save' onClick={() => {this.props.handleUpdateImage(this.state.file,this.state.currentImage, this.props.imageToEdit.id, this.props.imageToEdit.link)}}>
            Guardar
          </div>
        </div>
      )
  }
}

export default GalleryEdit