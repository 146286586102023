import React from 'react'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from "@mui/material/Checkbox";

const TableDispo = ({
    inmueblesList, 
    attributesList,
    handleUpdateDispo,
    isDispoActive,
    dispoObject,
    attributesObject
}: any) => {

    console.log(inmueblesList);
    

    return(
        <div className='dispo-table-container'>
            <TableContainer
                component={Paper} 
                sx={{
                    height: "100%",
                    overflow:"auto",
                    boxShadow: "none"}}
            >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox/>
                            </TableCell>
                            <TableCell
                                sx={{
                                    width: "20%"
                                }}
                            >Inmuebles</TableCell>
                            {
                                attributesList && attributesList.length > 0 && attributesList !== undefined ? attributesList?.map((attribute: any, index: number) => 
                                    attribute.type === "Hierarchical" ? <TableCell sx={{width: "20%"}}>{attribute.name}</TableCell>:null
                                ): null
                            }
                            <TableCell sx={{width: "20%"}}>
                                Estado
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            inmueblesList.map((inmueble: any, index: number) => {
                                if(dispoObject[inmueble.availability] === 1){
                                    return(
                                        <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
    
                                            />
                                        </TableCell>
                                        <TableCell>{inmueble.property}</TableCell>
                                        {
                                            inmueble.values ?
                                            inmueble.values.map((att: any, indexAtt: number) => 
                                                att.type === "Hierarchical" ? <TableCell>{att.value}</TableCell>:null
                                            ):null
                                        } 
                                        <TableCell>
                                            <select 
                                            id={inmueble.property}
    
                                            disabled = {!isDispoActive}
                                            >
                                                <option selected={inmueble.availability === "DISPONIBLE" ? true : false} value={"DISPONIBLE"}>DISPONIBLE</option>
                                                <option selected={inmueble.availability === "VENDIDO" ? true : false} value={"VENDIDO"}>VENDIDO</option>
                                                <option selected={inmueble.availability === "RESERVADO" ? true : false} value={"RESERVADO"}>RESERVADO</option>
                                                <option selected={inmueble.availability === "NO DISPONIBLE" ? true : false} value ={"NO DISPONIBLE"}>NO DISPONIBLE</option>
                                            </select>
                                        </TableCell>
                                        <TableCell sx={{height: "80%"}}>
                                            {
                                                isDispoActive ? 
                                                <div className='dispo-actaulizar-btn' onClick={() => {
                                                    var text = document.getElementById(inmueble.property) as any
    
                                                    handleUpdateDispo(inmueble, text.value)
                                                }}>Actualizar</div>:null
                                            }
                                        </TableCell>
                                    </TableRow>
                                    )
                                }

                                return null
                            }
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default TableDispo 