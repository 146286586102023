import React from 'react'

import U3DS from "./../../UI/general/Placeholder_02.svg";

const SidebarLogo = ({projectSelected, logoDefault, handleAddLogo, logo}: any) => {
    return(
        <div className='amenitites-logo-container'>
            <div className="amenities-logo-image">
                <img
                    src={
                        logo
                    }
                    alt=""
                    width={"100%"}
                    id="favicon"
                    onError={() => {
                        var image: any = document.getElementById("favicon")
                        if(image){
                        image.src = U3DS
                        }
                    }}
                />
            </div>
            <div className='amenities-logo-information'>
                <div className='logo-name-title-amenities'>
                    Seleccionar Ícono de Amenidades
                </div>
                <div className='logo-format-dubtitle-amenities'>
                    El archivo debe estar en formato .SVG
                </div>
                <input
                    type="file"
                    name="imagen"
                    id="upload-photo-nuevo-amenities"
                    className="upload-photo-nuevo-amenities inputfile-amenities"
                    onChange={(e: any) => {handleAddLogo(e)}}
                    accept="image/svg+xml"
                />
                <div className='input-amenities-icon-menu'>
                    Modificar
                </div>
            </div>
        </div>
    )
}

export default SidebarLogo

