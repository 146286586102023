import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { list, set, copy, update, remove, setGalleries, setTypefilter, updateActiveAvailability } from './../../models/properties';

import {
    LIST_INMUEBLES,
    SET_INMUEBLES,
    UPDATE_INMUEBLES,
    COPY_INMUEBLES,
    DELETE_INMUEBLES,
    SET_GALLERIES_INMUEBLES,
    UPDATE_INMUEBLES_TYPE_FILTER,
    SET_GALLERIES_ATTRIBUTES,
    ACTIVE_AVAILABILITY
} from '../actions';

import {
    inmueblesListSuccess,
    inmueblesListError,
    inmueblesUpdateSuccess,
} from './actions';
import {
    projectSelectedSuccess,
    projectSelectedError,
} from '../project/actions';
import {
    builderSelectedSuccess,
} from '../builder/actions';

import { attributesListSuccess } from "../attribute/actions";
import { setAttributesGalleries } from '../../models/attributes';


interface inmuebleResponse {
    inmuebleItf: any;
  }

export function* watchactiveAvailability() {
    yield takeEvery(ACTIVE_AVAILABILITY, activeAvailability);
}
export function* watchinmueblesList() {
    yield takeEvery(LIST_INMUEBLES, inmueblesList);
}
export function* watchinmueblesSet() {
    yield takeEvery(SET_INMUEBLES, inmueblesSet);
}
export function* watchinmueblesUpdate() {
    yield takeEvery(UPDATE_INMUEBLES, inmueblesUpdate);
}
export function* watchinmueblesCopy() {
    yield takeEvery(COPY_INMUEBLES, inmueblesCopy);
}
export function* watchinmueblesDelete() {
    yield takeEvery(DELETE_INMUEBLES, inmueblesDelete);
}
export function* watchinmueblesGalleries() {
    yield takeEvery(SET_GALLERIES_INMUEBLES, inmueblesGalleries);
}
export function* watchinmueblesAttributesGalleries() {
    yield takeEvery(SET_GALLERIES_ATTRIBUTES, inmueblesAttributesGalleries);
}
export function* watchinmueblesTypeFilter() {
    yield takeEvery(UPDATE_INMUEBLES_TYPE_FILTER, inmueblesTypeFilter);
}


const activeAvailabilityAsync = async (properties: any): Promise<inmuebleResponse> =>
    await updateActiveAvailability(properties)
        .then(inmuebleItf => inmuebleItf)
        .catch(error => error);

function* activeAvailability({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const inmueblesListData = yield call(activeAvailabilityAsync, proyecto);
        if (inmueblesListData.status === 200) {
            yield put(inmueblesListSuccess(inmueblesListData.data));
            yield put(attributesListSuccess(inmueblesListData.data.attributes));
            yield put(projectSelectedSuccess(inmueblesListData.data.projects));
            yield put(builderSelectedSuccess(inmueblesListData.data.builders));
        } else {
            yield put(inmueblesListError(inmueblesListData));
            yield put(attributesListSuccess(inmueblesListData));
            yield put(projectSelectedError(inmueblesListData));
            yield put(builderSelectedSuccess(inmueblesListData));
        }
    } catch (error) {
        yield put(inmueblesListError(error));
        yield put(attributesListSuccess(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}

const inmueblesListAsync = async (properties: any): Promise<inmuebleResponse> =>
    await list(properties)
        .then(inmuebleItf => inmuebleItf)
        .catch(error => error);

function* inmueblesList({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const inmueblesListData = yield call(inmueblesListAsync, proyecto);
        if (inmueblesListData.status === 200) {
            yield put(inmueblesListSuccess(inmueblesListData.data));
            yield put(attributesListSuccess(inmueblesListData.data.attributes));
            yield put(projectSelectedSuccess(inmueblesListData.data.projects));
            yield put(builderSelectedSuccess(inmueblesListData.data.builders));
        } else {
            yield put(inmueblesListError(inmueblesListData));
            yield put(attributesListSuccess(inmueblesListData));
            yield put(projectSelectedError(inmueblesListData));
            yield put(builderSelectedSuccess(inmueblesListData));
        }
    } catch (error) {
        yield put(inmueblesListError(error));
        yield put(attributesListSuccess(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


const inmueblesSetAsync = async (properties: any): Promise<inmuebleResponse> =>
    await set(properties)
        .then(inmuebleItf => inmuebleItf)
        .catch(error => error);

function* inmueblesSet({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const inmueblesListData = yield call(inmueblesSetAsync, proyecto);
        if (inmueblesListData.status === 200) {
            yield put(inmueblesListSuccess(inmueblesListData.data));
            yield put(attributesListSuccess(inmueblesListData.data.attributes));
            yield put(projectSelectedSuccess(inmueblesListData.data.projects));
            yield put(builderSelectedSuccess(inmueblesListData.data.builders));
        } else {
            yield put(inmueblesListError(inmueblesListData));
            yield put(attributesListSuccess(inmueblesListData));
            yield put(projectSelectedError(inmueblesListData));
            yield put(builderSelectedSuccess(inmueblesListData));
        }
    } catch (error) {
        yield put(inmueblesListError(error));
        yield put(attributesListSuccess(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


const inmueblesUpdateAsync = async (properties: any): Promise<inmuebleResponse> =>
    await update(properties)
        .then(inmuebleItf => inmuebleItf)
        .catch(error => error);

function* inmueblesUpdate({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const inmueblesListData = yield call(inmueblesUpdateAsync, proyecto);
        if (inmueblesListData.status === 200) {
            yield put(inmueblesUpdateSuccess(inmueblesListData.data, payload.proyecto.id));
            yield put(attributesListSuccess(inmueblesListData.data.attributes));
            yield put(projectSelectedSuccess(inmueblesListData.data.projects));
            yield put(builderSelectedSuccess(inmueblesListData.data.builders));
        } else {
            yield put(inmueblesListError(inmueblesListData));
            yield put(attributesListSuccess(inmueblesListData));
            yield put(projectSelectedError(inmueblesListData));
            yield put(builderSelectedSuccess(inmueblesListData));
        }
    } catch (error) {
        yield put(inmueblesListError(error));
        yield put(attributesListSuccess(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


const inmueblesCopyAsync = async (properties: any): Promise<inmuebleResponse> =>
    await copy(properties)
        .then(inmuebleItf => inmuebleItf)
        .catch(error => error);

function* inmueblesCopy({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const inmueblesListData = yield call(inmueblesCopyAsync, proyecto);
        if (inmueblesListData.status === 200) {
            yield put(inmueblesListSuccess(inmueblesListData.data));
            yield put(attributesListSuccess(inmueblesListData.data.attributes));
            yield put(projectSelectedSuccess(inmueblesListData.data.projects));
            yield put(builderSelectedSuccess(inmueblesListData.data.builders));
        } else {
            yield put(inmueblesListError(inmueblesListData));
            yield put(attributesListSuccess(inmueblesListData));
            yield put(projectSelectedError(inmueblesListData));
            yield put(builderSelectedSuccess(inmueblesListData));
        }
    } catch (error) {
        yield put(inmueblesListError(error));
        yield put(attributesListSuccess(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


const inmueblesDeleteAsync = async (properties: any): Promise<inmuebleResponse> =>
    await remove(properties)
        .then(inmuebleItf => inmuebleItf)
        .catch(error => error);

function* inmueblesDelete({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const inmueblesListData = yield call(inmueblesDeleteAsync, proyecto);
        if (inmueblesListData.status === 200) {
            yield put(inmueblesListSuccess(inmueblesListData.data));
            yield put(attributesListSuccess(inmueblesListData.data.attributes));
            yield put(projectSelectedSuccess(inmueblesListData.data.projects));
            yield put(builderSelectedSuccess(inmueblesListData.data.builders));
        } else {
            yield put(inmueblesListError(inmueblesListData));
            yield put(attributesListSuccess(inmueblesListData));
            yield put(projectSelectedError(inmueblesListData));
            yield put(builderSelectedSuccess(inmueblesListData));
        }
    } catch (error) {
        yield put(inmueblesListError(error));
        yield put(attributesListSuccess(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


const inmueblesGalleriesAsync = async (properties: any): Promise<inmuebleResponse> =>
    await setGalleries(properties)
        .then(inmuebleItf => inmuebleItf)
        .catch(error => error);

function* inmueblesGalleries({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const inmueblesListData = yield call(inmueblesGalleriesAsync, proyecto);
        if (inmueblesListData.status === 200) {
            yield put(inmueblesListSuccess(inmueblesListData.data));
            yield put(attributesListSuccess(inmueblesListData.data.attributes));
            yield put(projectSelectedSuccess(inmueblesListData.data.projects));
            yield put(builderSelectedSuccess(inmueblesListData.data.builders));
        } else {
            yield put(inmueblesListError(inmueblesListData));
            yield put(attributesListSuccess(inmueblesListData));
            yield put(projectSelectedError(inmueblesListData));
            yield put(builderSelectedSuccess(inmueblesListData));
        }
    } catch (error) {
        yield put(inmueblesListError(error));
        yield put(attributesListSuccess(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


const inmueblesAttributesGalleriesAsync = async (properties: any): Promise<inmuebleResponse> =>
    await setAttributesGalleries(properties)
        .then(inmuebleItf => inmuebleItf)
        .catch(error => error);

function* inmueblesAttributesGalleries({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const inmueblesListData = yield call(inmueblesAttributesGalleriesAsync, proyecto);
        if (inmueblesListData.status === 200) {
            yield put(inmueblesListSuccess(inmueblesListData.data));
            yield put(attributesListSuccess(inmueblesListData.data.attributes));
            yield put(projectSelectedSuccess(inmueblesListData.data.projects));
            yield put(builderSelectedSuccess(inmueblesListData.data.builders));
        } else {
            yield put(inmueblesListError(inmueblesListData));
            yield put(attributesListSuccess(inmueblesListData));
            yield put(projectSelectedError(inmueblesListData));
            yield put(builderSelectedSuccess(inmueblesListData));
        }
    } catch (error) {
        yield put(inmueblesListError(error));
        yield put(attributesListSuccess(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


const inmueblesTypeFilterAsync = async (properties: any): Promise<inmuebleResponse> =>
    await setTypefilter(properties)
        .then(inmuebleItf => inmuebleItf)
        .catch(error => error);

function* inmueblesTypeFilter({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const inmueblesListData = yield call(inmueblesTypeFilterAsync, proyecto);
        if (inmueblesListData.status === 200) {
            yield put(inmueblesListSuccess(inmueblesListData.data));
            yield put(attributesListSuccess(inmueblesListData.data.attributes));
            yield put(projectSelectedSuccess(inmueblesListData.data.projects));
            yield put(builderSelectedSuccess(inmueblesListData.data.builders));
        } else {
            yield put(inmueblesListError(inmueblesListData));
            yield put(attributesListSuccess(inmueblesListData));
            yield put(projectSelectedError(inmueblesListData));
            yield put(builderSelectedSuccess(inmueblesListData));
        }
    } catch (error) {
        yield put(inmueblesListError(error));
        yield put(attributesListSuccess(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}



export default function* rootSaga() {
    yield all([
        fork(watchinmueblesList),
        fork(watchinmueblesSet),
        fork(watchinmueblesUpdate),
        fork(watchinmueblesCopy),
        fork(watchinmueblesDelete),
        fork(watchinmueblesGalleries),
        fork(watchinmueblesAttributesGalleries),
        fork(watchinmueblesTypeFilter),
        fork(watchactiveAvailability)
    ]);
}
