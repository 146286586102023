import logo3DS from "./../../UI/logos/logoUmbra.svg";
import logoCP from "./../../UI/logos/logoCloudPlatform.svg";

import { Box } from "@mui/material";

/*eslint-disable*/

function LogosNavBar({}: any): any {
  return (
    <Box sx={{ float: "left", display: "flex" }}>
      <Box sx={{mr: 1, mt: 1}}>
        <img width={80} src={logo3DS} />
      </Box>
      <Box sx={{ml: 1, mt: 1}}>
        <img width={80} src={logoCP} />
      </Box>
    </Box>
  );
}

export default LogosNavBar;
