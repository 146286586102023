import React from "react";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";

import Navbar from "../navbar";
import Sidebar from "../sidebar";
import HeaderDispo from "./header.component";
import TableDispo from "./table.component";

import {
  logoutUser,
  inmueblesList,
  inmueblesUpdate,
  activeAvailability
} from "../../redux/actions";

import dashBackground from "./../../UI/backgrounds/BG_PUBLIC.webp";

interface Props {
  logoutUser: any;
  loading: any;
  projectSelected: any;
  selectedBuilder: any;
  params: any;
  inmueblesList: any;
  inmueblesUpdate: any;
  activeAvailability: any;
  properties: any;
  attributes: any;
}

interface State {
  userString: any;
  user: any;
  idProject: any;
  isDispoActive: any;
  dispoObject: any;
  attributesObject: any;
  selectedOptionsSeconds: any;
  inmuebles: any;
}

function withParams(component: any) {
  return (props: any) => (
    <DisponibilidadViews {...props} params={useParams()} />
  );
}

class DisponibilidadViews extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userString: localStorage.getItem("user"),
      user: "",
      idProject: this.props.params.id,
      isDispoActive: true,
      selectedOptionsSeconds: {},
      inmuebles: [],
      dispoObject: {
        DISPONIBLE: 1,
        VENDIDO: 1,
        RESERVADO: 1,
        "NO DISPONIBLE": 1,
      },
      attributesObject: {},
    };
  }

  componentDidMount(): void {
    if (this.state.userString) {
      this.setState(
        {
          user: JSON.parse(this.state.userString),
        },
        () => {
          this.props.inmueblesList({
            user: this.state.user.id,
            project: this.state.idProject,
          });
        }
      );
    }
    var tempObject: any = {};
    this.props.attributes.map((attribute: any) => {
      if (attribute.type === "Hierarchical") {
        attribute.values.map((value: any) => {
          tempObject[value.value] = 1;
          return 0;
        });
      }
      return 0;
    });
    this.setState({
      attributesObject: tempObject,
      inmuebles: this.props.properties,
    });
  }

  handleUpdateDispo = (selectedInmuebles: any, value: string) => {
    this.props.inmueblesUpdate({
      user: this.state.user.id,
      project: this.state.idProject,
      id: selectedInmuebles.id,
      availability: value,
    });
  };

  handleActiveDispo = (availabilities: any) => {
    availabilities.user = this.state.user.id
    availabilities.project = this.state.idProject
    this.props.activeAvailability(availabilities);
  };

  handleActivateDispo = (dispo: boolean) => {
    this.setState({
      isDispoActive: dispo,
    });
  };

  handleChangeDispoFilter = (payload: any) => {
    var tempObject = this.state.dispoObject;
    tempObject[payload] = tempObject[payload] === 1 ? 0 : 1;
    console.log(tempObject);
    this.setState({
      dispoObject: tempObject,
    });
  };

  handleChangeAttributeFilter = (selectedOptions: any, attr: any) => {
    console.log(selectedOptions);
    console.log(attr);

    const { properties } = this.props;
    const { selectedOptionsSeconds } = this.state;

    let temp_inmuebles: any = [];

    if (selectedOptions.length === 0) {
      // Si selectedOptions está vacío, verifica si los valores de selectedOptionsSeconds también están vacíos
      let allEmpty = true;
      for (const key in selectedOptionsSeconds) {
        if (key !== attr) {
          if (selectedOptionsSeconds[key].length > 0) {
            allEmpty = false;
              selectedOptionsSeconds[key].forEach((opt: any) => {
                if (properties.length > 0) {
                  properties.forEach((attributes: any) => {
                    if (attributes.values.length > 0) {
                      let values = attributes.values.filter(
                        (e: any) => e.value === opt.value
                      );
        
                      if (values.length > 0) {
                        temp_inmuebles.push(attributes);
                      }
                    }
                  });
                }
              });
            break;
          }
        }
      }
console.log(allEmpty);

      if (allEmpty) {
        // Si selectedOptionsSeconds también está vacío, mostrar todos los inmuebles
        console.log("aqui");
        
        this.setState({
          selectedOptionsSeconds: {
            ...selectedOptionsSeconds,
            [attr]: selectedOptions,
          },
          inmuebles: properties,
        });
        return;
      }
    }

    if (selectedOptions.length > 0) {
      selectedOptions.forEach((opt: any) => {
        if (properties.length > 0) {
          properties.forEach((attributes: any) => {
            if (attributes.values.length > 0) {
              let values = attributes.values.filter(
                (e: any) => e.value === opt.value
              );

              if (values.length > 0) {
                temp_inmuebles.push(attributes);
              }
            }
          });
        }
      });
    }

    let tem_attributes: any = {
      ...selectedOptionsSeconds,
      [attr]: selectedOptions,
    };

    this.setState({
      selectedOptionsSeconds: tem_attributes,
      inmuebles: temp_inmuebles,
    });
  };

  render(): React.ReactNode {
    return (
      <div className="disponibilidad-main-container">
        <img className="dashboard-background" src={dashBackground} alt="" />
        <Sidebar project={this.props.projectSelected} />
        <div className="disponibilidad-table-container">
          <HeaderDispo
            attributesList={this.props.attributes}
            project={this.props.projectSelected}
            handleActivateDispo={this.handleActivateDispo}
            handleActiveDispo={this.handleActiveDispo}
            user={this.state.user}
            dispoObject={this.state.dispoObject}
            handleChangeDispoFilter={this.handleChangeDispoFilter}
            handleChangeAttributeFilter={this.handleChangeAttributeFilter}
            selectedOptionsSeconds={this.state.selectedOptionsSeconds}
          />
          <TableDispo
            inmueblesList={this.state.inmuebles.length > 0 ? this.state.inmuebles : this.props.properties}
            attributesList={this.props.attributes}
            handleUpdateDispo={this.handleUpdateDispo}
            isDispoActive={this.state.isDispoActive}
            dispoObject={this.state.dispoObject}
            attributesObject={this.state.attributesObject}
          />
        </div>
        <Navbar logout={this.props.logoutUser} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

const mapStateToProps = ({
  projectReducer,
  builderReducer,
  propertiesReducer,
  attributeReducer,
}: any) => {
  const { projectSelected } = projectReducer;
  const { selectedBuilder } = builderReducer;
  const { properties } = propertiesReducer;
  const { attributes } = attributeReducer;
  return { projectSelected, selectedBuilder, properties, attributes };
};

export default connect(mapStateToProps, {
  logoutUser,
  inmueblesList,
  inmueblesUpdate,
  activeAvailability
})(withParams(DisponibilidadViews));
