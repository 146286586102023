import React from 'react'
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import { Backdrop, CircularProgress} from "@mui/material";

import Navbar from '../navbar';
import ProjectsHeader from './header.component';
import CardListBuilders from './card.component';
import DeleteComponent from '../generals/delete.component';
import CreateProjects from './create.component';

import dashBackground from './../../UI/backgrounds/BG_PUBLIC.webp'

import {
    projectList,
    projectCreate,
    projectUpdate,
    projectDelete,
    projectSelectedSuccess,
    logoutUser,
    showAlert,
    closeAlert
  } from "../../redux/actions";

interface Props{
    projectList: any,
    projectCreate: any,
    projectUpdate: any,
    projectDelete: any,
    project: any,
    loading: boolean,
    selectedBuilder: any,
    projectSelectedSuccess: any,
    params: any,
    logoutUser: any,
    showAlert: any
    closeAlert: any
}

interface State{
    userString: any,
    user: any,
    open: boolean,
    idBuilder: any,
    projectSelected: any,
    showDelete: boolean,
    deleteParams: any
}

function withParams(Component: any) {
    return (props: any) => <ProjectsView {...props} params={useParams()} />;
  }

class ProjectsView extends React.Component<Props,State>{
    constructor(props: Props){
        super(props)
        this.state = {
            userString: localStorage.getItem("user"),
            user: {},
            open: false,
            idBuilder: this.props.params.id,
            projectSelected: {},
            showDelete: false,
            deleteParams: {}
        }
    }

    async componentDidMount() {
        if(this.state.userString){
            this.setState({
                user: JSON.parse(this.state.userString)
            }, () => {
                this.props.projectList({ user: this.state.user.id, builder: this.state.idBuilder })
            })
        }
    }

    toggleSettings = (params: boolean) => {
        this.setState({
            open: params
        }, () => {
            if(params){
                this.setState({
                    projectSelected: {}
                })
            }
        })
    }

    handleEdit = (params: object) => {
        this.setState({
            open: true,
            projectSelected: params
        })
    }

    handleDelete = (params: any) => {
        this.setState({
            showDelete: !this.state.showDelete,
            deleteParams: params
        })
    }

    handleConfirmDelete = () => {
        let user: any = localStorage.getItem("user");
        this.props.projectDelete({
            id: this.state.deleteParams.id,
            user: JSON.parse(user).id,
            slug: this.state.deleteParams.slug,
            builder: this.state.idBuilder
        });
        this.setState({
            showDelete: false
        })
    }

    render(): React.ReactNode {
        return(
            <div className='builders-main-page'>
                <img className='dashboard-background' src={dashBackground} alt=""/>
                <div className={this.state.open ? 'builders-container-opened':'builders-container'}>
                    <ProjectsHeader
                        title={"Proyectos"}
                        data={this.props}
                        event={this.toggleSettings}
                        user = {this.state.user}
                    />
                    <CardListBuilders
                        elements={this.props.project}
                        editEvent={this.handleEdit}
                        deleteEvent={this.handleDelete}
                        builderSelectedSuccess={this.props.projectSelectedSuccess}
                        user = {this.state.user}
                    />
                </div>
                <DeleteComponent
                    show={this.state.showDelete}
                    handleHide = {this.handleDelete}
                    handleConfirmDelete = {this.handleConfirmDelete}
                />
                <CreateProjects
                    data={this.state.projectSelected}
                    event={this.toggleSettings}
                    handleEdit={this.props.projectUpdate}
                    handleSave={this.props.projectCreate}
                    open={this.state.open}
                    builder = {this.props.selectedBuilder}
                    showAlert={this.props.showAlert}
                    closeAlert={closeAlert}
                />
                <Navbar
                    logout={this.props.logoutUser}
                />
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.props.loading}
                    //onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

const mapStateToProps = ({ projectReducer,  builderReducer }: any) => {
    const { project, loading, } = projectReducer;
    const { selectedBuilder } = builderReducer;
    return { project, loading, selectedBuilder };
};

export default 
    connect(mapStateToProps, {
        projectList,
        projectCreate,
        projectUpdate,
        projectDelete,
        projectSelectedSuccess,
        logoutUser,
        showAlert,
        closeAlert
})(withParams(ProjectsView))


