import React from 'react'
import { Box, Divider, Grid } from "@mui/material";

import LogosNavBar from './logos.component';
import OptionsNavBar from './options.component';
import ProfileNavBar from './profile.component';

interface Props{
    logout: any
}

interface State{}

class Navbar extends React.Component<Props,State>{
    constructor(props: Props){
        super(props);
        this.state = {}
    }

    render(): React.ReactNode {
        return(
            <Box sx={{backgroundColor: "#fff", position: "absolute", minWidth: "600", width:"100%", height: "10%"}} className="nav-bar-container">
                <Grid container spacing={2} sx={{maxWidth:"95%", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-40%) !important'}}>
                    <Grid item xs={8} md={10} sx={{display: 'flex'}}>
                        <LogosNavBar />
                    <Divider orientation="vertical" sx={{ mx: 4 }}/>
                        <OptionsNavBar />
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <ProfileNavBar  logOut={this.props.logout}/>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default Navbar