import React from 'react'
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';

import Navbar from '../navbar';
import Sidebar from '../sidebar';
import BreadCrumbs from './breadcrumbs.component';
import Cards from './cards.component';
import ShowGalleriesImages from './show.component';
import GalleryFullscreen from './fullscreen.component';
import UploadGallery from './upload.component';
import LinkCreate from './links.component';
import ShowGalleriesLinks from './showlinks.component';
import GalleryEdit from './galleryEditImage.component';

import { galleryStructureData } from '../../data/gallery.data';

import dashBackground from './../../UI/backgrounds/BG_PUBLIC.webp'

import {
    logoutUser,
    galleriesList,
    galleriesUploadFiles,
    projectUpdate,
    galleriesDelete,
    galleriesUpdate
} from "../../redux/actions";

import {
    Backdrop,
    CircularProgress,
} from "@mui/material";

/*eslint-disable*/

interface Props{
    logoutUser: any,
    changeCurrentIndex: any,
    changeCurrentSubIndex: any
    projectSelected: any,
    selectedBuilder: any,
    galleriesList: any,
    galleriesUploadFiles: any,
    projectUpdate: any,
    galleriesDelete: any,
    galleriesUpdate: any,
    params: any,
    galleries: any,
    loading: any
}

interface State{
    userString: any,
    user: any,
    idProject: any,
    breadCrumbs: any,
    currentSelectedArray: any,
    openImagesContainer: boolean,
    openLinksContainer: boolean,
    openFullscreen: boolean,
    currentIndexSubfolder: number,
    currentImageSelected: string,
    imagesArray: any,
    openUploadContainer: boolean,
    selectedType: string,
    selectedFolder: string,
    currentGalleries: any,
    openEditionMode: boolean,
    imageToEdit: any,
    openEditionModeLinks: boolean
    linkToEdit: any,
    PDFFile: any,
    folderPadre: string,
    currentPayload: string
}

function withParams(Component: any) {
    return (props: any) => <GalleryView {...props} params={useParams()} />;
}

class GalleryView extends React.Component<Props, State>{
    constructor(props: Props){
        super(props)
        this.state = {
            userString: localStorage.getItem("user"),
            user: "",
            idProject: this.props.params.id,
            breadCrumbs: ["Galeria"],
            currentSelectedArray: [],
            openImagesContainer: false,
            openLinksContainer: false,
            openFullscreen: false,
            currentIndexSubfolder: -1,
            currentImageSelected: "",
            imagesArray: [],
            openUploadContainer: false,
            selectedType: "",
            selectedFolder: "",
            currentGalleries: [],
            openEditionMode: false,
            imageToEdit: {},
            openEditionModeLinks: false,
            linkToEdit: {},
            PDFFile:'',
            folderPadre: '',
            currentPayload: ''
        }
    }

    componentDidMount(): void {
        if(this.state.userString){
            this.setState({
                user: JSON.parse(this.state.userString)
            }, () => {
                this.props.galleriesList({user: this.state.user.id, project: this.state.idProject})
            })
        }
        var tempArray: any = []
        galleryStructureData().map((element: any, index: number) => {
            tempArray.push(element)
        })
        this.setState({
            currentSelectedArray: tempArray
        })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(this.props.galleries !== prevProps.galleries){
            if (this.state.folderPadre !== '') {
                if (this.props.galleries[this.state.folderPadre].map((e: any) => e.folder).indexOf(this.state.currentPayload) !== -1) {
                    let indice: any = this.props.galleries[this.state.folderPadre].map((e: any) => e.folder).indexOf(this.state.currentPayload)

                    this.setState({
                        currentGalleries: this.props.galleries[this.state.folderPadre][indice].galleries
                    })
                }
                
                
            }
        }
    }

    handleSelectCurrentGallery = (name: string, payload: any, payloadArray: any, indexSubfolder: number, isImage: boolean, slug: string, folderPadre: string) => {

        if(payloadArray){
            var tempArray: any = []
            let breadCrumbs = [...this.state.breadCrumbs]
            breadCrumbs.push(name)
            payloadArray.forEach((element: any) => {
                tempArray.push(element)
            });

            this.setState({
                currentSelectedArray: tempArray,
                breadCrumbs: breadCrumbs,
                currentIndexSubfolder: indexSubfolder,
                selectedType: slug,
                currentPayload: payload
            })
        }
        else{
            let breadCrumbs = [...this.state.breadCrumbs]
            breadCrumbs.push(name)
            this.setState({
                currentSelectedArray: [],
                breadCrumbs: breadCrumbs,
                openLinksContainer: !isImage,
                openImagesContainer: isImage,
                selectedFolder: slug,
                folderPadre: folderPadre,
                currentPayload: payload
            })
            if (folderPadre !== undefined) {
                if (this.props.galleries[folderPadre].map((e: any) => e.folder).indexOf(payload) !== -1) {
                    let indice: any = this.props.galleries[folderPadre].map((e: any) => e.folder).indexOf(payload)
                    
                    this.setState({
                        currentGalleries: this.props.galleries[folderPadre][indice].galleries
                    })
                }
                
                
            }
        }
    }

    handleGoToTypes = () => {
        var tempArray: any = []
        let breadCrumbs = [...this.state.breadCrumbs]
        breadCrumbs.pop()
        galleryStructureData().map((element: any, index: number) => {
            tempArray.push(element)
        })
        this.setState({
            currentSelectedArray: tempArray,
            breadCrumbs: ['Galeria'],
            openLinksContainer: false,
            openImagesContainer: false,
            currentGalleries: [],
            openEditionMode: false
        })
        
    }

    handleGoToSubfolder = (name: string, index: number ) => {
        var tempArray: any = []
        let breadCrumbs = [...this.state.breadCrumbs]
        breadCrumbs.pop()
        this.setState({
            currentSelectedArray: galleryStructureData()[this.state.currentIndexSubfolder].innerArray,
            breadCrumbs: breadCrumbs,
            openLinksContainer: false,
            openImagesContainer: false,
            currentGalleries: [],
            openEditionMode: false
        })
    }

    handleOpenFullScreen = (payload: boolean, image: string) => {
        this.setState({
            openFullscreen: payload,
            currentImageSelected: image
        })
    }

    handleOpenUpload = () => {
        this.setState({
            openUploadContainer: !this.state.openUploadContainer
        })
    }

    handleAddGallery = (imagesArray: any) => {
        this.setState({
            imagesArray: imagesArray
        })
    }
    
    handleUploadGalleries = () => {
        let user: any = localStorage.getItem("user");
        var imagesToAdd = this.state.imagesArray
        var imagesToDrop: any = []
        imagesToAdd.forEach((element: any) => {
            if(element.size > 550000 && !this.state.breadCrumbs[2].includes('360')){
                imagesToDrop.push(element.name)
                imagesToAdd.splice(imagesToAdd.indexOf(element),1)
            }
            else if(element.size > 4000000){
                imagesToDrop.push(element.name)
                imagesToAdd.splice(imagesToAdd.indexOf(element),1)
            }
        });

        this.setState({
            //openUploadContainer: false,
            imagesArray: []
        }, () => {
            if(imagesToDrop.length > 0){
                alert('Las imágenes ' + imagesToDrop.join(', ') +' superan el peso máximo de 500kb y no seran agregadas a la maqueta, ¿qué paso ahí papu?')
            }
            
            this.props.galleriesUploadFiles({
                data:{
                    value: `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/galleries/${this.state.selectedType.toLowerCase()}/${this.state.selectedFolder.toLowerCase()}/`,
                    description: "",
                    name: '',
                    type: "card",
                    project: this.props.projectSelected.id,
                    folder: `${this.state.breadCrumbs[1].toLowerCase()}/${this.state.breadCrumbs[2].toLowerCase()}`,
                    user: JSON.parse(user).id,
                },
                assets: imagesToAdd,
                type: "card",
            })

            this.setState({
                openUploadContainer: false
            })
        })
        
    }

    handleUploadLink = (name: string, desc: string, type: string, value: string) => {
        let user: any = localStorage.getItem("user");
        this.props.galleriesUploadFiles({
            data:{
                value: value,
                name: name,
                description: desc,
                type: type,
                project: this.props.projectSelected.id,
                folder: `${this.state.breadCrumbs[1].toLowerCase()}/${this.state.breadCrumbs[2].toLowerCase()}`,
                user: JSON.parse(user).id,
            },
            type: "list",
        })
    }

    handleDeleteGallery = (id: string) => {
        let user: any = localStorage.getItem("user");
        this.props.galleriesDelete({
            user: JSON.parse(user).id,
            project: this.props.projectSelected.id,
            id: id
        })
    }

    handleOpenEditionMode = (gallery: any) => {
        this.setState({
            openEditionMode: true,
            imageToEdit: gallery
        })
    } 

    handleUpdateImage = (file: any, image: any, id: string, prevLink: string) => {
        let newLink = prevLink.split(`${this.props.selectedBuilder.id}`)
        
        let FileFormData = new FormData();
        FileFormData.append("myFile", file);
        FileFormData.append("name", `galleries/${this.props.selectedBuilder.id}${newLink[1]}`);
        this.props.galleriesUpdate({
            type: "card",
            asset: FileFormData,
            data: {user: this.state.user.id, project: this.state.idProject}
        })
    }

    handleOpenEditionModeLink = (link: any) => {
        this.setState({
            openEditionModeLinks: true,
            linkToEdit: link
        })
    }

    handleUpdateLink = (name: string, desc: string, link: string, id: string) => {
        this.props.galleriesUpdate({
            type: "list",
            data: {id: id, name: name, description: desc, link: link, user: this.state.user.id, project: this.state.idProject}
        })
    }

    handleAddFile = (linkName:string, LinkDesc: string, linkType: string ,linkFile: any) => {
        //PDF FILE DATA
        //console.log(linkName, LinkDesc, linkFile, linkType)

        let user: any = localStorage.getItem("user");
        this.props.galleriesUploadFiles({
            data:{
                value: `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/galleries/${this.state.selectedType.toLowerCase()}/${this.state.selectedFolder.toLowerCase()}/`,
                description: LinkDesc,
                name: linkName,
                type: "list",
                project: this.props.projectSelected.id,
                folder: `${this.state.breadCrumbs[1].toLowerCase()}/${this.state.breadCrumbs[2].toLowerCase()}`,
                user: JSON.parse(user).id,
            },
            assets: linkFile,
            type: "card",
        })
        
       /* let FileFormData = new FormData();
        FileFormData.append("myFile", linkFile);
        FileFormData.append("name", `galleries/${this.props.selectedBuilder.id}/${this.state.idProject}/galleries/brochure/${linkName}.pdf`);
        this.props.galleriesUpdate({
            type: "card",
            asset: FileFormData,
            data: {user: this.state.user.id, project: this.state.idProject}
        })*/
    }

    render(): React.ReactNode {
        return(
            <div className='gallery-container-main'>
                <img className='dashboard-background' src={dashBackground} alt=""/>
                <Sidebar
                    project={this.props.projectSelected}
                />
                <div className={this.state.openLinksContainer || this.state.openEditionMode  ? 'gallery-container-links':'gallery-container'}>
                    <BreadCrumbs
                        breadCrumbsArray={this.state.breadCrumbs}
                        handleGoToTypes = {this.handleGoToTypes}
                        handleGoToSubfolder = {this.handleGoToSubfolder}
                    />
                    <Cards
                        currentArraySelected = {this.state.currentSelectedArray}
                        handleSelectedGallery = {this.handleSelectCurrentGallery}
                    />
                    {
                        this.state.openImagesContainer ?
                        <ShowGalleriesImages
                            galleries={this.state.currentGalleries}
                            handleOpenFullScreen = {this.handleOpenFullScreen}
                            handleDeleteGallery = {this.handleDeleteGallery}
                            handleOpenEditionMode = {this.handleOpenEditionMode}
                        />: null
                    }
                    {
                        this.state.openLinksContainer ?
                        <ShowGalleriesLinks
                            galleries = {this.state.currentGalleries}
                            handleDeleteGallery = {this.handleDeleteGallery}
                            handleOpenEditionModeLink = {this.handleOpenEditionModeLink}
                        />: null
                    }
                    {
                        this.state.breadCrumbs.length === 3 && !this.state.openLinksContainer ?
                        <div className='gallery-upload-btn' onClick={() => {this.handleOpenUpload()}}>
                            Subir imágenes
                        </div>:null
                    }
                    
                </div>
                {
                    this.state.openFullscreen ?
                    <GalleryFullscreen
                        currentImage = {this.state.currentImageSelected}
                        handleOpenFullScreen = {this.handleOpenFullScreen}
                    />:null
                }
                {
                    this.state.openUploadContainer ?
                    <UploadGallery
                        handleOpenUpload = {this.handleOpenUpload}
                        handleAddGallery = {this.handleAddGallery}
                        handleUpload = {this.handleUploadGalleries}
                        imagesArray = {this.state.imagesArray}
                    />: null
                }
                {
                    this.state.openLinksContainer ? 
                    <LinkCreate
                        breadCrumbs = {this.state.breadCrumbs}
                        handleUploadLink = {this.handleUploadLink}
                        openEditionModeLinks = {this.state.openEditionModeLinks}
                        linkToEdit = {this.state.linkToEdit}
                        handleUpdateLink = {this.handleUpdateLink}
                        handleAddFile = {this.handleAddFile}
                    />:null
                }
                {
                    this.state.openEditionMode ?
                    <GalleryEdit
                        openEditionMode = {this.state.openEditionMode}
                        imageToEdit = {this.state.imageToEdit}
                        infoLevel={this.state.breadCrumbs}
                        handleUpdateImage = {this.handleUpdateImage}
                    />:null
                }
                <Navbar
                    logout={this.props.logoutUser}
                />
                <Backdrop
                    sx={{ color: "#fff"}}
                    open={this.props.loading}
                    >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

const mapStateToProps = ({
    projectReducer,
    builderReducer,
    moduleReducer,
    galleriesReducer,
}: any) => {
    const { galleries, loading } = galleriesReducer;
    const { modules } = moduleReducer;
    const { projectSelected } = projectReducer;
    const { selectedBuilder } = builderReducer;
    return { modules, projectSelected, loading, selectedBuilder, galleries };
};

export default connect(mapStateToProps, {
    logoutUser,
    galleriesList,
    galleriesUploadFiles,
    projectUpdate,
    galleriesDelete,
    galleriesUpdate
})(withParams(GalleryView));