import React from "react";

import collapse from './../../UI/general/collapse.svg'

interface Props {
  files: any
  handleUpdateScriptFile: any
  handleUpdateDetailScriptFile: any
  spins: any
}

interface State {
  openSpinGeneral: boolean,
  openUrbanismo: boolean,
  openHotspots: boolean
  openAmenidades: boolean,
  openDetalle: boolean,

  currentShowGeneral: number,
  currentShowUrbanismo: number
  currentShowHotspots: number,
  currentShowAmenidades: number,
  currentDetailedObject: any
}

class Coordinates extends React.Component<Props,State>{
  constructor(props: Props){
    super(props);
    this.state = {
      openSpinGeneral: false,
      openUrbanismo: false,
      openHotspots: false,
      openAmenidades: false,
      openDetalle: false,
      currentShowGeneral: 0,
      currentShowUrbanismo: 0,
      currentShowHotspots: 0,
      currentShowAmenidades: 0,
      currentDetailedObject: {}
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if(prevProps.files !== this.props.files && this.props.files !== undefined){
      if(this.props.files.properties && this.props.files.properties.length > 0){
        this.setState({
          currentShowGeneral: Math.min(3, this.props.files.properties.length)
        })
      }
      if(this.props.files.townplanning && this.props.files.townplanning.length > 0){
        this.setState({
          currentShowUrbanismo: Math.min(3, this.props.files.townplanning.length)
        })
      }
      if(this.props.files.hotspots && this.props.files.hotspots.length > 0){
        this.setState({
          currentShowHotspots: Math.min(3, this.props.files.hotspots.length)
        })
      }
      if(this.props.files.amenities && this.props.files.amenities.length > 0){
        this.setState({
          currentShowAmenidades: Math.min(3, this.props.files.amenities.length)
        })
      }
      if(this.props.files.details && this.props.files.details.length > 0){
        var objectToCreate: any = {}
        this.props.files.details.map((detail: any) => {
          objectToCreate[detail.groupID] = Math.min(3, detail.scripts.length) 
          return null
        })

        this.setState({
          currentDetailedObject: objectToCreate
        })
      }
    }
  }

  handleChangeObject = (id: any, number: number) => {
    var ObjectToChange = this.state.currentDetailedObject
    ObjectToChange[id] = Math.min(ObjectToChange[id] + 3, number)
    this.setState({
      currentDetailedObject: ObjectToChange
    })
  }

  handleResetObject = () => {
    if(this.props.files.details && this.props.files.details.length > 0){
      var objectToCreate: any = {}
      this.props.files.details.map((detail: any) => {
        objectToCreate[detail.groupID] = Math.min(3, detail.scripts.length) 
        return null
      })

      this.setState({
        currentDetailedObject: objectToCreate
      })
    }
  }

  render(): React.ReactNode {
      return(
        <div className="coordinates-main-container">
          <div className="coordinates-main-title">Historial de coordenadas</div>
          <div className="coordinates-main-text">
            Activa o desactiva la versión correcta de las coordenadas. Recuerda que esta característica se sincroniza con 3D Max.
          </div>
          <div className="coordinates-category-container">

            <div className="coordinates-individual-category">
              <div className="coordiantes-name-icon-container" 
              onClick={() => {
                this.setState({openSpinGeneral: !this.state.openSpinGeneral}, () => {
                  if(this.state.openSpinGeneral === false){
                    this.setState({
                      currentShowGeneral: Math.min(3, this.props.files.properties.length)
                    })
                  }
                })
                }}>
                <div className="coordinates-individual-name">
                  Spin General
                </div>
                <img src={collapse} alt='' className="coordinates-closed-icon"></img>
              </div>
              {
                this.state.openSpinGeneral ?
                <div className="coordinates-files-container">
                  {
                    this.props.files && this.props.files.properties && this.props.files.properties.length > 0 ?
                    this.props.files.properties.slice(0,this.state.currentShowGeneral).map((property: any, index: number) => 
                      <div className="coordinate-file-individual-container">
                        <div className="coordinate-file-input-container">
                          <input type="radio" value={property.id} checked={property.state === 1} onChange = {() => {this.props.handleUpdateScriptFile(property.id, property.type)}}/>
                        </div>
                        <div className="coordinate-file-individual-info">
                          <div className="coordinate-file-individual-name">
                            {property.usuario}
                          </div>
                          <div className="coordinate-file-individual-date">
                            {property.date}
                          </div>
                        </div>
                      </div>
                    ):null
                  }

                  {
                    this.props.files && this.props.files.properties && this.props.files.properties.length > 0 &&
                    this.state.currentShowGeneral < this.props.files.properties.length ?
                    <div
                      onClick={() => {
                        this.setState({
                          currentShowGeneral: Math.min(this.state.currentShowGeneral + 3, this.props.files.properties.length)
                        })
                      }}
                      className="show-more-coordinates"
                    >
                      Ver anteriores
                    </div>:null
                  }
                </div>:null
              }
            </div>

            <div className="coordinates-individual-category">
              <div className="coordiantes-name-icon-container" onClick={() => {
                this.setState({
                  openUrbanismo: !this.state.openUrbanismo,
                  currentShowUrbanismo:  Math.min(3, this.props.files.properties.length)
                  })}}>
                <div className="coordinates-individual-name">
                  Urbanismo
                </div>
                <img src={collapse} alt='' className="coordinates-closed-icon"></img>
              </div>

              {
                this.state.openUrbanismo ?
                <div className="coordinates-files-container">
                  {
                    this.props.files && this.props.files.townplanning && this.props.files.townplanning.length > 0 ?
                    this.props.files.townplanning.slice(0,this.state.currentShowUrbanismo).map((property: any, index: number) => 
                      <div className="coordinate-file-individual-container">
                        <div className="coordinate-file-input-container">
                          <input type="radio" value={property.id} checked={property.state === 1} onChange = {() => {this.props.handleUpdateScriptFile(property.id, property.type)}}/>
                        </div>
                        <div className="coordinate-file-individual-info">
                          <div className="coordinate-file-individual-name">
                            {property.usuario}
                          </div>
                          <div className="coordinate-file-individual-date">
                            {property.date}
                          </div>
                        </div>
                      </div>
                    ):null
                  }

                  {
                    this.props.files && this.props.files.townplanning && this.props.files.townplanning.length > 0 &&
                    this.state.currentShowUrbanismo < this.props.files.townplanning.length ?
                    <div
                      onClick={() => {
                        this.setState({
                          currentShowUrbanismo: Math.min(this.state.currentShowUrbanismo + 3, this.props.files.townplanning.length)
                        })
                      }}
                      className="show-more-coordinates"
                    >
                      Ver anteriores
                    </div>:null
                  }
                </div>:null
              }
            </div>

            <div className="coordinates-individual-category">
              <div className="coordiantes-name-icon-container" onClick={() => {this.setState({
                openHotspots: !this.state.openHotspots,
                currentShowHotspots: Math.min(3, this.props.files.townplanning.length)
                })}}>
                <div className="coordinates-individual-name">
                  Hotspots
                </div>
                <img src={collapse} alt='' className="coordinates-closed-icon"></img>
              </div>

              {
                this.state.openHotspots ?
                <div className="coordinates-files-container">
                  {
                    this.props.files && this.props.files.hotspots && this.props.files.hotspots.length > 0 ?
                    this.props.files.hotspots.slice(0,this.state.currentShowHotspots).map((property: any, index: number) => 
                      <div className="coordinate-file-individual-container">
                        <div className="coordinate-file-input-container">
                          <input type="radio" value={property.id} checked={property.state === 1} onChange = {() => {this.props.handleUpdateScriptFile(property.id, property.type)}}/>
                        </div>
                        <div className="coordinate-file-individual-info">
                          <div className="coordinate-file-individual-name">
                            {property.usuario}
                          </div>
                          <div className="coordinate-file-individual-date">
                            {property.date}
                          </div>
                        </div>
                      </div>
                    ):null
                  }

                  {
                    this.props.files && this.props.files.hotspots && this.props.files.hotspots.length > 0 &&
                    this.state.currentShowHotspots < this.props.files.hotspots.length ?
                    <div
                      onClick={() => {
                        this.setState({
                          currentShowHotspots: Math.min(this.state.currentShowHotspots + 3, this.props.files.hotspots.length)
                        })
                      }}
                      className="show-more-coordinates"
                    >
                      Ver anteriores
                    </div>:null
                  }
                </div>:null
              }
            </div>

            <div className="coordinates-individual-category">
              <div className="coordiantes-name-icon-container" onClick={() => {this.setState({
                openAmenidades: !this.state.openAmenidades,
                currentShowAmenidades: Math.min(3, this.props.files.amenities.length)
                })}}>
                <div className="coordinates-individual-name">
                  Amenidades
                </div>
                <img src={collapse} alt='' className="coordinates-closed-icon"></img>
              </div>

              {
                this.state.openAmenidades ?
                <div className="coordinates-files-container">
                  {
                    this.props.files && this.props.files.amenities && this.props.files.amenities.length > 0 ?
                    this.props.files.amenities.slice(0,this.state.currentShowAmenidades).map((property: any, index: number) => 
                      <div className="coordinate-file-individual-container">
                        <div className="coordinate-file-input-container">
                          <input type="radio" value={property.id} checked={property.state === 1} onChange = {() => {this.props.handleUpdateScriptFile(property.id, property.type)}}/>
                        </div>
                        <div className="coordinate-file-individual-info">
                          <div className="coordinate-file-individual-name">
                            {property.usuario}
                          </div>
                          <div className="coordinate-file-individual-date">
                            {property.date}
                          </div>
                        </div>
                      </div>
                    ):null
                  }

                  {
                    this.props.files && this.props.files.amenities && this.props.files.amenities.length > 0 &&
                    this.state.currentShowAmenidades < this.props.files.amenities.length ?
                    <div
                      onClick={() => {
                        this.setState({
                          currentShowAmenidades: Math.min(this.state.currentShowAmenidades + 3, this.props.files.amenities.length)
                        })
                      }}
                      className="show-more-coordinates"
                    >
                      Ver anteriores
                    </div>:null
                  }
                </div>:null
              }
            </div>

            <div className="coordinates-individual-category">
              <div className="coordiantes-name-icon-container" onClick={() => {
                this.setState({openDetalle: !this.state.openDetalle})
                this.handleResetObject()
                }}>
                <div className="coordinates-individual-name">
                  Spins de detalle
                </div>
                <img src={collapse} alt='' className="coordinates-closed-icon"></img>
              </div>

              {
                this.state.openDetalle ?
                <div className="coordinates-files-container">
                  {
                    this.props.files && this.props.files.details && this.props.files.details.length > 0 ?
                    this.props.files.details.map((property: any, index: number) => 
                      <div className="detailed-coordinates-mayor-container">
                        <div className="script-name-spin-container">
                          <div className="coordinates-group-name">{property.group.replace(/\d+/g, (match: any) => ` ${match} `)}</div>
                        </div>
                        {
                          property.scripts.slice(0,this.state.currentDetailedObject[property.groupID]).map((script: any, indexScript: number) => 
                          <div className="coordinate-file-individual-container">
                            <div className="coordinate-file-input-container">
                              <input type="radio" value={script.id} checked={script.state === 1} onChange = {() => {this.props.handleUpdateDetailScriptFile(property.groupID, script.type, script.id)}}/>
                            </div>
                            <div className="coordinate-file-individual-info">
                              <div className="coordinate-file-individual-name">
                                {script.usuario}
                              </div>
                              <div className="coordinate-file-individual-date">
                                {script.date}
                              </div>
                            </div>
                          </div>
                          )
                        }
                        {
                          this.props.files && this.props.files.details && this.props.files.details.length > 0 &&
                          this.state.currentDetailedObject[property.groupID] < this.props.files.details[index].scripts.length ?
                          <div
                            onClick={() => {
                              this.handleChangeObject(property.groupID, this.props.files.details[index].scripts.length)
                            }}
                            className="show-more-coordinates"
                          >
                            Ver anteriores
                          </div>:null
                        }
                      </div>
                    ):null
                  }
                </div>:null
              }

            </div>

            
          </div>
        </div>
      )
  }
}

export default Coordinates 