import React, {useRef} from 'react'

import drag from './../../UI/general/drag.svg'

const ListPOIS = ({categories, handleUpdateCheckState, handleUpdateListOrder}: any) => {

    const dragItem: any = useRef()
    const dragOverItem: any = useRef();

    const handleUpdateState = (e: any, category: any) => {
        handleUpdateCheckState(e.target.checked ? 1:0, category.id)
    }

    const dragStart = (e: any, position: number) => {
        dragItem.current = position;
    }

    const dragEnter = (e: any, position: number) => {
        dragOverItem.current = position;
    };

    const drop = (e: any) => {
        const copyListItems = [...categories];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        handleUpdateListOrder(copyListItems)
    };

    return(
        <div className='poi-category-container'>
            {
                categories.map((category: any, index: number) => {
                    return(
                        <div 
                        className='poi-category-individual' 
                        draggable 
                        onDragStart={(e) => {dragStart(e,index)}}
                        onDragEnter={(e) => dragEnter(e, index)}
                        onDragEnd={drop}
                        >
                            <div className='draggable-controller' >
                                <img src={drag} alt=""></img>
                            </div>
                            <div className='poi-category-individual-name'>{category.name}</div>
                            <input
                                className='poi-category-input'
                                type="checkbox"
                                checked={category.state === 1}
                                onChange = {(e) => {handleUpdateState(e, category)}}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ListPOIS