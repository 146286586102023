import React from 'react'
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import { Backdrop, CircularProgress } from "@mui/material";

import Navbar from '../navbar';
import Sidebar from '../sidebar';

import dashBackground from './../../UI/backgrounds/BG_PUBLIC.webp'

import {
    logoutUser,
} from "../../redux/actions";

interface Props{
    logoutUser: any,
    loading: any
    projectSelected: any,
    params: any,
}

interface State{}

function withParams(Component: any) {
    return (props: any) => <UIViews {...props} params={useParams()} />;
}

class UIViews extends React.Component<Props,State>{
    constructor(props: Props){
        super(props);
        this.state = {

        }
    }

    render(): React.ReactNode {
        return (
            <div className='spins-contaienr'>
                <img className='dashboard-background' src={dashBackground} alt=""/>
                <Sidebar
                    project={this.props.projectSelected}
                />
                <Navbar
                    logout={this.props.logoutUser}
                />
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.props.loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

const mapStateToProps = ({
    projectReducer,
    builderReducer,
    attributeReducer,
  }: any) => {
    const { attributes, loading } = attributeReducer;
    const { projectSelected } = projectReducer;
    const { selectedBuilder } = builderReducer;
    return { attributes, loading, projectSelected, selectedBuilder };
  };

export default connect(mapStateToProps, {
    logoutUser
})(withParams(UIViews));