export const defaultMenuType = "menu-default menu-sub-hidden main-hidden sub-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "es";
export const localeOptions = [
    { id: "en", name: "English", direction: "ltr" },
    { id: "es", name: "Español", direction: "ltr" },
];

export const ApiConfig = {
    apiKey: "",
    authDomain: "umbraint.com",
    /* URL: "https://hyundai-api-test.herokuapp.com/api/", */
    URL: "http://localhost:5000/api/", 
    //URL2: "https://yrohz49ap2.execute-api.us-east-1.amazonaws.com/api/",
    URL2: "https://vqr3hys2bh.execute-api.us-east-1.amazonaws.com/api/",
    //URL3: "http://3.82.35.174:3000/api/",
    //URL3: "https://container-3d-studio.msa25g8eplbds.us-east-1.cs.amazonlightsail.com/api/",
    URL3: "https://service-cloud-admin-container.etm6qeo1eo20q.us-east-1.cs.amazonlightsail.com/api/",
    URL4: "http://localhost:4000/api/",
    projectId: "Platform-analytic"
};

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";