import {
    LIST_LOCATION,
    LIST_LOCATION_SUCCESS,
    LIST_LOCATION_ERROR,
    UPDATE_LOCATION,
    LIST_ONE_LOCATION,
    DELIMITACION_LOTE,
    SET_ROADS_ACCESS,
    SET_POIS_CATEGORY,
    UPDATE_POIS_CATEGORY,
    SET_POIS,
    UPDATE_ROADS_ACCESS,
    DELETE_ROADS_ACCESS,
    UPDATE_POIS,
    DELETE_POIS
  } from "../actions";

/*eslint-disable*/

export const locationsList = (
    proyecto: any,
) => ({
    type: LIST_LOCATION,
    payload: {proyecto}
})

export const locationsListSuccess = (location: any) => ({
    type: LIST_LOCATION_SUCCESS,
    payload: location
})

export const locationsListError = (message: any) => ({
    type: LIST_LOCATION_ERROR,
    payload: {message}
})

export const locationsListOne = (
    proyecto: any,
) => ({
    type: LIST_ONE_LOCATION,
    payload: {proyecto}
})

export const locationsUpdate = (location: any) => ({
    type: UPDATE_LOCATION,
    payload: {location}
})

export const locationsDelimitationLote = (location: any) => ({
    type: DELIMITACION_LOTE,
    payload: {location}
})

export const locationSetRoadsAccess = (location: any) => ({
    type: SET_ROADS_ACCESS,
    payload: {location}
})

export const locationUpdateRoadsAccess = (location: any) => ({
    type: UPDATE_ROADS_ACCESS,
    payload: {location}
})

export const locationDeleteRoadsAccess = (location: any) => ({
    type: DELETE_ROADS_ACCESS,
    payload: {location}
})

export const locationSetPoisCategory = (location: any) => ({
    type: SET_POIS_CATEGORY,
    payload: {location}
})

export const locationUpdatePoisCategory = (location: any) => ({
    type: UPDATE_POIS_CATEGORY,
    payload: {location}
})

export const locationSetPois = (location: any) => ({
    type: SET_POIS,
    payload: {location}
})

export const locationUpdatePois = (location: any) => ({
    type: UPDATE_POIS,
    payload: {location}
})

export const locationDeletePois = (location: any) => ({
    type: DELETE_POIS,
    payload: {location}
})
