import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { list, listOne, update, delimitation, setRoadsAccess, updateRoadsAccess, deleteRoadsAccess, setPoisCategory, updatePoisCategory, setPois, deletePois } from "./../../models/locations";

import {
  LIST_LOCATION,
  UPDATE_LOCATION,
  LIST_ONE_LOCATION,
  DELIMITACION_LOTE,
  SET_ROADS_ACCESS,
  SET_POIS_CATEGORY,
  UPDATE_POIS_CATEGORY,
  SET_POIS,
  UPDATE_ROADS_ACCESS,
  DELETE_ROADS_ACCESS,
  //UPDATE_POIS,
  DELETE_POIS,
} from "../actions";

import { locationsListSuccess, locationsListError } from "./actions";

import {
  projectSelectedSuccess,
  projectSelectedError,
} from "../project/actions";

import {
  builderSelectedSuccess,
  builderSelectedError,
} from "../builder/actions";

import {
  modulesListSuccess,
  modulesListError,
} from "../module/actions";

interface locationsResponse {
  locationItf: any;
}

/*eslint-disable*/

export function* watchlocationsList() {
  yield takeEvery(LIST_LOCATION, locationsList);
}
export function* watchlocationsListOne() {
  yield takeEvery(LIST_ONE_LOCATION, locationsListOne);
}
export function* watchLocationsUpdate() {
  yield takeEvery(UPDATE_LOCATION, locationsUpdate);
}
export function* watchLocationsDelimitation() {
  yield takeEvery(DELIMITACION_LOTE, locationsDelimitation);
}
export function* watchSetRoadsAccess() {
  yield takeEvery(SET_ROADS_ACCESS, locationsSetRoadsAccess);
}
export function* watchUpdateRoadsAccess() {
  yield takeEvery(UPDATE_ROADS_ACCESS, locationsUpdateRoadsAccess);
}
export function* watchDeleteRoadsAccess() {
  yield takeEvery(DELETE_ROADS_ACCESS, locationsDeleteRoadsAccess);
}

//POIS


export function* watchSetPoisCategory() {
  yield takeEvery(SET_POIS_CATEGORY, locationsSetPoisCategory);
}
export function* watchUpdatePoisCategory() {
  yield takeEvery(UPDATE_POIS_CATEGORY, locationsUpdatePoisCategory);
}
export function* watchSetPois() {
  yield takeEvery(SET_POIS, locationsSetPois);
}
export function* watchDeletePois() {
  yield takeEvery(DELETE_POIS, locationsDeletePois);
}

const locationsListAsync = async (
  proyecto: any
): Promise<locationsResponse> =>
  await list(proyecto)
    .then((locationItf) => locationItf)
    .catch((error) => error);

function* locationsList({ payload }: any): any {
  const { proyecto } = payload;
  try {
    const locationsListData = yield call(locationsListAsync, proyecto);
    if (locationsListData.status === 200) {
      yield put(locationsListSuccess(locationsListData.data.data));
      yield put(projectSelectedSuccess(locationsListData.data.project));
      yield put(builderSelectedSuccess(locationsListData.data.builder));
    } else {
      yield put(locationsListError(locationsListData));
      yield put(projectSelectedError(locationsListData));
      yield put(builderSelectedError(locationsListData));
    }
  } catch (error) {
    yield put(locationsListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const locationsListOneAsync = async (
  proyecto: any
): Promise<locationsResponse> =>
  await listOne(proyecto)
    .then((locationItf) => locationItf)
    .catch((error) => error);

function* locationsListOne({ payload }: any): any {
  const { proyecto } = payload;
  try {
    const locationsListData = yield call(locationsListOneAsync, proyecto);
    if (locationsListData.status === 200) {
      yield put(locationsListSuccess(locationsListData.data.data));
      yield put(modulesListSuccess(locationsListData.data.modules));
    } else {
      yield put(locationsListError(locationsListData));
      yield put(modulesListError(locationsListData));
    }
  } catch (error) {
    yield put(locationsListError(error));
    yield put(modulesListError(error));
  }
}

const locationsUpdateAsync = async (
  location: any
): Promise<locationsResponse> =>
  await update(location)
    .then((locationItf) => locationItf)
    .catch((error) => error);

function* locationsUpdate({ payload }: any): any {
  const { location } = payload;
  try {
    const locationsListData = yield call(locationsUpdateAsync, location);
    if (locationsListData.status === 200) {
      yield put(locationsListSuccess(locationsListData.data.data));
      yield put(projectSelectedSuccess(locationsListData.data.project));
      yield put(builderSelectedSuccess(locationsListData.data.builder));
    } else {
      yield put(locationsListError(locationsListData));
      yield put(projectSelectedError(locationsListData));
      yield put(builderSelectedError(locationsListData));
    }
  } catch (error) {
    yield put(locationsListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const locationsDelimitationAsync = async (
  location: any
): Promise<locationsResponse> =>
  await delimitation(location)
    .then((locationItf) => locationItf)
    .catch((error) => error);

function* locationsDelimitation({ payload }: any): any {
  const { location } = payload;
  try {
    const locationsListData = yield call(locationsDelimitationAsync, location);
    if (locationsListData.status === 200) {
      yield put(locationsListSuccess(locationsListData.data.data));
      yield put(projectSelectedSuccess(locationsListData.data.project));
      yield put(builderSelectedSuccess(locationsListData.data.builder));
    } else {
      yield put(locationsListError(locationsListData));
      yield put(projectSelectedError(locationsListData));
      yield put(builderSelectedError(locationsListData));
    }
  } catch (error) {
    yield put(locationsListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const locationsSetRoadsAccessAsync = async (
  location: any
): Promise<locationsResponse> =>
  await setRoadsAccess(location)
    .then((locationItf) => locationItf)
    .catch((error) => error);

function* locationsSetRoadsAccess({ payload }: any): any {
  const { location } = payload;
  try {
    const locationsListData = yield call(locationsSetRoadsAccessAsync, location);
    if (locationsListData.status === 200) {
      yield put(locationsListSuccess(locationsListData.data.data));
      yield put(projectSelectedSuccess(locationsListData.data.project));
      yield put(builderSelectedSuccess(locationsListData.data.builder));
    } else {
      yield put(locationsListError(locationsListData));
      yield put(projectSelectedError(locationsListData));
      yield put(builderSelectedError(locationsListData));
    }
  } catch (error) {
    yield put(locationsListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const locationsUpdateRoadsAccessAsync = async (
  location: any
): Promise<locationsResponse> =>
  await updateRoadsAccess(location)
    .then((locationItf) => locationItf)
    .catch((error) => error);

function* locationsUpdateRoadsAccess({ payload }: any): any {
  const { location } = payload;
  try {
    const locationsListData = yield call(locationsUpdateRoadsAccessAsync, location);
    if (locationsListData.status === 200) {
      yield put(locationsListSuccess(locationsListData.data.data));
      yield put(projectSelectedSuccess(locationsListData.data.project));
      yield put(builderSelectedSuccess(locationsListData.data.builder));
    } else {
      yield put(locationsListError(locationsListData));
      yield put(projectSelectedError(locationsListData));
      yield put(builderSelectedError(locationsListData));
    }
  } catch (error) {
    yield put(locationsListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const locationsDeleteRoadsAccessAsync = async (
  location: any
): Promise<locationsResponse> =>
  await deleteRoadsAccess(location)
    .then((locationItf) => locationItf)
    .catch((error) => error);

function* locationsDeleteRoadsAccess({ payload }: any): any {
  const { location } = payload;
  try {
    const locationsListData = yield call(locationsDeleteRoadsAccessAsync, location);
    if (locationsListData.status === 200) {
      yield put(locationsListSuccess(locationsListData.data.data));
      yield put(projectSelectedSuccess(locationsListData.data.project));
      yield put(builderSelectedSuccess(locationsListData.data.builder));
    } else {
      yield put(locationsListError(locationsListData));
      yield put(projectSelectedError(locationsListData));
      yield put(builderSelectedError(locationsListData));
    }
  } catch (error) {
    yield put(locationsListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

//POIS


const locationsSetPoisCategoryAsync = async (
  location: any
): Promise<locationsResponse> =>
  await setPoisCategory(location)
    .then((locationItf) => locationItf)
    .catch((error) => error);

function* locationsSetPoisCategory({ payload }: any): any {
  const { location } = payload;
  try {
    const locationsListData = yield call(locationsSetPoisCategoryAsync, location);
    if (locationsListData.status === 200) {
      yield put(locationsListSuccess(locationsListData.data.data));
      yield put(projectSelectedSuccess(locationsListData.data.project));
      yield put(builderSelectedSuccess(locationsListData.data.builder));
    } else {
      yield put(locationsListError(locationsListData));
      yield put(projectSelectedError(locationsListData));
      yield put(builderSelectedError(locationsListData));
    }
  } catch (error) {
    yield put(locationsListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const locationsUpdatePoisCategoryAsync = async (
  location: any
): Promise<locationsResponse> =>
  await updatePoisCategory(location)
    .then((locationItf) => locationItf)
    .catch((error) => error);

function* locationsUpdatePoisCategory({ payload }: any): any {
  const { location } = payload;
  try {
    const locationsListData = yield call(locationsUpdatePoisCategoryAsync, location);
    if (locationsListData.status === 200) {
      yield put(locationsListSuccess(locationsListData.data.data));
      yield put(projectSelectedSuccess(locationsListData.data.project));
      yield put(builderSelectedSuccess(locationsListData.data.builder));
    } else {
      yield put(locationsListError(locationsListData));
      yield put(projectSelectedError(locationsListData));
      yield put(builderSelectedError(locationsListData));
    }
  } catch (error) {
    yield put(locationsListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const locationsSetPoisAsync = async (
  location: any
): Promise<locationsResponse> =>
  await setPois(location)
    .then((locationItf) => locationItf)
    .catch((error) => error);

function* locationsSetPois({ payload }: any): any {
  const { location } = payload;
  try {
    const locationsListData = yield call(locationsSetPoisAsync, location);
    if (locationsListData.status === 200) {
      yield put(locationsListSuccess(locationsListData.data.data));
      yield put(projectSelectedSuccess(locationsListData.data.project));
      yield put(builderSelectedSuccess(locationsListData.data.builder));
    } else {
      yield put(locationsListError(locationsListData));
      yield put(projectSelectedError(locationsListData));
      yield put(builderSelectedError(locationsListData));
    }
  } catch (error) {
    yield put(locationsListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const locationsDeletePoisAsync = async (
  location: any
): Promise<locationsResponse> =>
  await deletePois(location)
    .then((locationItf) => locationItf)
    .catch((error) => error);

function* locationsDeletePois({ payload }: any): any {
  const { location } = payload;
  try {
    const locationsListData = yield call(locationsDeletePoisAsync, location);
    if (locationsListData.status === 200) {
      yield put(locationsListSuccess(locationsListData.data.data));
      yield put(projectSelectedSuccess(locationsListData.data.project));
      yield put(builderSelectedSuccess(locationsListData.data.builder));
    } else {
      yield put(locationsListError(locationsListData));
      yield put(projectSelectedError(locationsListData));
      yield put(builderSelectedError(locationsListData));
    }
  } catch (error) {
    yield put(locationsListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

export default function* rootSage() {
  yield all([
    fork(watchlocationsList),
    fork(watchlocationsListOne),
    fork(watchLocationsUpdate),
    fork(watchLocationsDelimitation),
    fork(watchSetRoadsAccess),
    fork(watchUpdateRoadsAccess),
    fork(watchDeleteRoadsAccess),
    fork(watchSetPoisCategory),
    fork(watchUpdatePoisCategory),
    fork(watchSetPois),
    fork(watchDeletePois),
  ]);
}
