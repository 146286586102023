import React, {useState} from 'react'

const CreatePOI = ({handleCreateCategory}: any) => {

    const [currentName, setCurrentName] = useState("")

    const handleChangeName = (e: any) => {
        setCurrentName(e.target.value)
    }

    return(
        <div className='create-poi-container'>
            <div className='create-poi-title'>
                Nuevo tipo de puntos de interés
            </div>
            <div className='crate-poi-input-container'>
                <input 
                    type={"text"}
                    value = {currentName}
                    onChange = {(e) => {handleChangeName(e)}}
                    maxLength = {30}
                />
                <div className='poi-create-btn' onClick={() => {
                    handleCreateCategory(currentName)
                    setCurrentName("")
                    }}>
                    Crear
                </div>
            </div>
        </div>
    )
}

export default CreatePOI