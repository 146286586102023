import Alert from "react-bootstrap/Alert";

const AlertUmbra = ({ variant, message, show, setShow }: any) => {

  console.log(variant, message, show, setShow);
  

  if (show) {
    return (
      <Alert
        style={{
          position: "absolute",
          zIndex: 99999,
          width: "40%",
          right: "30%",
          top: "2%",
        }}
        variant={variant}
        onClose={() => setShow(false)}
        dismissible
      >
        {message}
      </Alert>
    );
  }
  return null;
};

export default AlertUmbra;
