import React from 'react'

import Delete from './../../UI/general/close.svg'

interface ListPOISCategoryProps {
    currentPOI: string,
    listPOIs: any,
    selectedCategoryID: any
    handleDeletePOI: any
}

const ListPOISCategory = ({currentPOI, listPOIs, selectedCategoryID, handleDeletePOI}: ListPOISCategoryProps) => {

    return(
        <div className='pois-category-list-container'>
            <div className='pois-ccategory-list-title'>Tus puntos de {currentPOI}</div>
            <div className='pois-category-list'>
                {
                    listPOIs.map((POI: any, index: number) => 
                        POI.category === selectedCategoryID ?
                        <div className='poi-individual-container'>
                            <div className='poi-individual-name'>
                                {POI.name}
                            </div>
                            <div className='delete-road' onClick={() => {handleDeletePOI(POI.id)}}>
                                <img src={Delete} alt=""/>
                            </div>
                        </div>:null
                    )
                }
            </div>
        </div>
    )
}

export default ListPOISCategory