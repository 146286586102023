import asset_scan_qr from "./../../UI/instructions/asset_scan_qr.svg";

import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

const MFaQR = ({user, toggleValidation}: any) => {
    return (
        <Box className="MFaQR-container">
            <Divider />
            <Box sx={{ flexGrow: 1, py: 3 }}>
                <Grid container spacing={2}>
                <Grid item xs={3}>
                    <img width={100} src={asset_scan_qr} alt=""/>
                </Grid>
                <Grid item xs={9}>
                    <Box>
                    <Typography sx={{ mb: 2, fontSize: "0.8rem" }}>Configuración a través de un autenticador</Typography>
                    <Typography sx={{ mb: 2, fontSize: "0.8rem" }}>
                        Por favor use su aplicación de autenticación para escanear este
                        código QR.
                    </Typography>
                    </Box>
                </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box sx={{ flexGrow: 1, py: 6, px: 2 }}>
                <Grid container spacing={2}>
                <Grid item xs={3}>
                    <img width={100} src={user.qr} alt=""/>
                </Grid>
                <Grid item xs={9} sx={{ my: 1 }}>
                    <Box sx={{ textAlign: "center" }}>
                    <Typography sx={{ mb: 2, fontSize: "0.8rem" }}>
                        O ingresa este código en la aplicación de autenticación.
                    </Typography>
                    <Typography sx={{ fontSize: "0.5rem" }}>
                        {user.secret}
                    </Typography>
                    </Box>
                </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box sx={{ flexGrow: 1, py: 2 }}>
                <Grid container spacing={2}>
                <Grid item xs={7}>
                    <Typography sx={{ fontSize: "0.5rem" }}>
                    Ayuda, no tengo una <Typography sx={{color: '#ED0874', fontSize: "0.5rem"}}>App de autenticación</Typography>
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Box sx={{ textAlign: "center", display: "flex" }}>
                        <Box sx={{mx: 1}}>
                            <Button variant="contained" color="inherit" sx={{
                                
                            }}>
                                Cancel
                            </Button>
                        </Box>
                        <Box sx={{mx: 1}}>
                            <Button variant="contained" sx={{ 
                                bgcolor: "#ED0874",
                                '&:hover': {
                                    backgroundColor: 'rgb(191, 30, 96)'
                                }
                            }} onClick={() => toggleValidation(true)}>
                            Next
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default MFaQR