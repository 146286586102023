import React from "react";

import CreatableSelect from "react-select/creatable";
import Kermit from "./../../UI/general/Kermit.png";
import gallery from "./../../UI/general/GalleryGeneral.svg";
import Delete from "./../../UI/general/close.svg";
import collapse from "./../../UI/general/collapse.svg";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Backdrop, CircularProgress } from "@mui/material";
import ReactSelect from "react-select";
/*eslint-disable*/
interface Props {
  attributes: any;
  inmuebles: any;
  handleGetInmuebleId: any;
  galleriesOpen: any;
  handleUpdateInmuebleNum: any;
  handleUpdateAttValue: any;
  handleSaveNewInmueble: any;
  handleUpdateSelectionArray: any;
  handleDeleteInmuebles: any;
  handleUpdateAttributes: any;
  handleGenerateCopies: any;
  handleDuplicateAndEditProperties: any;
  handleOpenGalleryPropertiesModal: any;
}

interface FilmOptionType {
  inputValue?: string;
  value: string;
  attribute?: string;
  id?: string;
  year?: number;
}
interface State {
  openCreateNew: boolean;
  openActionsMenu: boolean;
  currentEditNumber: string;
  loading: boolean;
  currentCreatePropertyName: string;
  currentCreateAttributes: any;
  currentPropertiesSelected: any;
  openMakeCopies: boolean;
  openEditAttribute: boolean;

  currentAttributeIdSelected: string;
  currentAttributeValueIdSelected: string;

  currentNumberOfCopies: number;

  openEditAndDuplicate: boolean;
  currentEditAttributeIdSelected: string;
  currentEditAttributeValueIdSelected: string;

  inmueblesToShow: any;
  showInmuebles: boolean;

  attributesFilters: any;
  attributesIndexes: any;
  show: boolean;
  values: ValuesState;
  isLoading: boolean;
}
interface ValuesState {
  [index: number]: {
    [indexValue: number]: FilmOptionType | null;
  };
}
interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string, attribute: string) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
  attribute,
});

const filter = createFilterOptions<FilmOptionType>();

class TableNew extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openCreateNew: false,
      openActionsMenu: false,
      currentEditNumber: "",
      loading: true,
      currentCreatePropertyName: "Nuevo inmueble",
      currentCreateAttributes: [],
      currentPropertiesSelected: [],
      openMakeCopies: false,
      openEditAttribute: false,

      currentAttributeIdSelected: "",
      currentAttributeValueIdSelected: "",
      currentNumberOfCopies: 1,

      openEditAndDuplicate: false,
      currentEditAttributeIdSelected: "",
      currentEditAttributeValueIdSelected: "",

      inmueblesToShow: [],
      showInmuebles: true,
      attributesFilters: {},
      attributesIndexes: {},
      show: true,
      values: {} as ValuesState,
      isLoading: false,
    };
  }

  componentDidMount(): void {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.setState({
          openCreateNew: false,
          currentCreatePropertyName: "",
          currentCreateAttributes: [],
        });
      }
    });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.attributes !== this.props.attributes) {
      this.setState(
        {
          loading: true,
        },
        () => {
          var tempAttributes: any = [];
          var tempAttributesIndexes: any = {};
          var tempAttributesFilters: any = {};
          if (this.props.attributes.length > 0) {
            this.props.attributes.map((att: any, index: number) => {
              tempAttributes.push({
                name: att.name,
                id: att.id,
                value: att.values[0].id,
              });

              tempAttributesIndexes[att.name] = -1;
              tempAttributesFilters[att.name] = [];

              return null;
            });

            this.setState({
              currentCreateAttributes: tempAttributes,
              attributesFilters: tempAttributesFilters,
              attributesIndexes: tempAttributesIndexes,
            });
          }
        }
      );
    }
    if (
      prevProps.inmuebles !== this.props.inmuebles &&
      this.props.inmuebles !== undefined &&
      this.props.inmuebles.length > 0
    ) {
      this.setState(
        {
          loading: true,
          showInmuebles: false,
          inmueblesToShow: this.props.inmuebles,
        },
        () => {
          this.setState({
            showInmuebles: true,
          });
        }
      );
    }
  }

  handleCompileCreateInfo = () => {
    this.setState(
      {
        loading: false,
        openCreateNew: false,
      },
      () => {
        this.props.handleSaveNewInmueble(
          this.state.currentCreatePropertyName,
          this.state.currentCreateAttributes
        );
      }
    );
  };

  handleSelectCreationAttribute = (name: any, id: any, event: any) => {
    var tempAttributesArray = this.state.currentCreateAttributes;
    for (let i = 0; i < tempAttributesArray.length; i++) {
      if (id === tempAttributesArray[i].id) {
        tempAttributesArray[i].value = event.target.value;
      }
    }

    this.setState({
      currentCreateAttributes: tempAttributesArray,
    });
  };

  handleSelectAll = () => {
    if (
      this.state.currentPropertiesSelected.length !==
      this.state.inmueblesToShow.length
    ) {
      var tempArray: any = [];
      this.state.inmueblesToShow.forEach((element: any) => {
        tempArray.push(element.id);
      });

      this.setState({
        currentPropertiesSelected: tempArray,
      });
    } else {
      this.setState({
        currentPropertiesSelected: [],
      });
    }
  };

  handleSelectIndividual = (id: any) => {
    var tempArray = this.state.currentPropertiesSelected;
    if (tempArray.includes(id)) {
      tempArray.splice(tempArray.indexOf(id), 1);
    } else {
      tempArray.push(id);
    }

    this.setState({
      currentPropertiesSelected: tempArray,
    });
  };

  handleDeleteEvent = () => {
    if (this.state.currentPropertiesSelected.length > 0) {
      this.props.handleUpdateSelectionArray(
        this.state.currentPropertiesSelected
      );
      this.props.handleDeleteInmuebles();
      this.setState({
        openActionsMenu: false,
        loading: false,
        currentPropertiesSelected: [],
      });
    } else {
      this.setState({
        openActionsMenu: false,
      });
      window.alert(
        "Para eliminar inmuebles debes tener algún inmueble seleccionado"
      );
    }
  };

  handleMakeCopiesEvent = () => {
    if (this.state.currentPropertiesSelected.length > 0) {
      this.setState({
        openActionsMenu: false,
        openMakeCopies: true,
      });
    } else {
      this.setState({
        openActionsMenu: false,
      });
      window.alert(
        "Para copiar inmuebles debes tener algún inmueble seleccionado"
      );
    }
  };

  handleOpenEditAttributes = () => {
    if (this.state.currentPropertiesSelected.length > 0) {
      this.setState({
        openActionsMenu: false,
        openEditAttribute: true,
      });
    } else {
      this.setState({
        openActionsMenu: false,
      });
      window.alert(
        "Para editar el atributo de un inmueble debes tener algún inmueble seleccionado"
      );
    }
  };

  handleOpenEditAndDuplicate = () => {
    if (this.state.currentPropertiesSelected.length > 0) {
      this.setState({
        openActionsMenu: false,
        openEditAndDuplicate: true,
      });
    } else {
      this.setState({
        openActionsMenu: false,
      });
      window.alert(
        "Para duplicar y editar inmuebles debes tener algún inmueble seleccionado"
      );
    }
  };

  handleUpdateAttributes = () => {
    this.props.handleUpdateAttributes(
      this.state.currentPropertiesSelected,
      this.state.currentAttributeIdSelected,
      this.state.currentAttributeValueIdSelected
    );
    this.setState({
      loading: true,
      openEditAttribute: false,
    });
  };

  handleDuplicateAndEditProperties = () => {
    this.props.handleDuplicateAndEditProperties(
      this.state.currentPropertiesSelected,
      this.state.currentEditAttributeIdSelected,
      this.state.currentEditAttributeValueIdSelected
    );
    this.setState({
      loading: true,
      openEditAndDuplicate: false,
    });
  };

  handleFilterProperty = (attribute: any, value: any) => {
    var tempArrayAttributes = this.state.attributesFilters;
    if (value === "") {
      tempArrayAttributes[attribute] = [];
    } else if (tempArrayAttributes[attribute].includes(value)) {
      tempArrayAttributes[attribute].splice(
        tempArrayAttributes[attribute].indexOf(value),
        1
      );
    } else {
      tempArrayAttributes[attribute].push(value);
    }
    this.setState(
      {
        attributesFilters: tempArrayAttributes,
      },
      () => {
        var inmueblesToShow = this.props.inmuebles;
        Object.keys(this.state.attributesFilters).map(
          (key: any, index: number) => {
            var tempArray: any = [];
            inmueblesToShow.map((property: any) => {
              if (this.state.attributesFilters[key].length > 0) {
                if (property.hasOwnProperty("values")) {
                  property.values.map((value: any) => {
                    if (value.name === key) {
                      if (
                        this.state.attributesFilters[key].includes(value.value)
                      ) {
                        tempArray.push(property);
                      }
                    }

                    return null;
                  });
                }
              } else {
                tempArray.push(property);
              }

              return null;
            });

            inmueblesToShow = tempArray;

            return null;
          }
        );

        this.setState(
          {
            inmueblesToShow: inmueblesToShow,
            show: false,
          },
          () => {
            this.setState({
              show: true,
            });
          }
        );
      }
    );
  };

  handleEditGallery = () => {
    this.props.handleOpenGalleryPropertiesModal();
  };

  handleCreate = (
    index: number,
    indexValue: number,
    newValue: string,
    prope: string,
    attr: string
  ) => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      let newOption = createOption(newValue, attr);
      //console.log(newOption);

      this.props.handleUpdateAttValue(
        undefined,
        newOption?.attribute,
        prope,
        newOption?.value
      );
      this.setState((prevState) => ({
        isLoading: false,
        values: {
          ...prevState.values,
          [index]: {
            ...prevState.values[index],
            [indexValue]: newOption,
          },
        },
        //options: [...prevState.options, newOption],
        //value: newOption,
      }));
    }, 1000);
  };

  handleChange = (
    index: number,
    indexValue: number,
    newValue: FilmOptionType | null,
    prope: string
  ) => {
    /* console.log(index);
    console.log(indexValue);
    console.log(newValue);
    console.log(prope); */
    if (
      newValue?.id === undefined &&
      newValue?.value !== undefined &&
      (newValue?.value.split("Crear ")).length > 0
    ) {
      newValue = {
        value: (newValue?.value.split("Crear "))[1],
        attribute: newValue?.attribute,
      };
    }
    this.props.handleUpdateAttValue(
      newValue?.id,
      newValue?.attribute,
      prope,
      newValue?.value
    );
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [index]: {
          ...prevState.values[index],
          [indexValue]: newValue,
        },
      },
    }));
  };

  filterOptions = (options: FilmOptionType[], params: any, attr: string) => {
    const filtered = filter(options, params);
    console.log(attr);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.value);
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        value: `Crear ${inputValue}`,
        attribute: attr,
      });
    }

    return filtered;
  };

  render(): React.ReactNode {
    return (
      <React.Fragment>
        <div
          className="properties-main-table-container"
          onClick={() => {
            this.setState({ openActionsMenu: false, attributesIndexes: -1 });
          }}
        >
          <div className="properties-table-header">
            <div className="properties-table-title">Inmuebles</div>
            <div className="porperties-table-btns-container">
              <div
                className="properties-create-new-btn"
                onClick={() => {
                  this.setState({ openCreateNew: !this.state.openCreateNew });
                }}
              >
                Nuevo inmueble
              </div>
              <div
                className="properties-table-actions-btn"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({
                    openActionsMenu: !this.state.openActionsMenu,
                    attributesIndexes: -1,
                  });
                }}
              >
                Acción
              </div>
            </div>
            {this.state.openActionsMenu ? (
              <div className="properties-action-container">
                <div
                  className="properties-action-options"
                  onClick={() => {
                    this.handleMakeCopiesEvent();
                  }}
                >
                  Generar copias
                </div>

                <div
                  className="properties-action-options"
                  onClick={() => {
                    this.handleOpenEditAttributes();
                  }}
                >
                  Editar un atributo
                </div>

                <div
                  className="properties-action-options"
                  onClick={() => {
                    this.handleOpenEditAndDuplicate();
                  }}
                >
                  Duplicar y editar
                </div>

                <div
                  className="properties-action-options"
                  onClick={() => {
                    this.handleEditGallery();
                  }}
                >
                  Editar galería
                </div>

                <div
                  className="properties-action-options"
                  onClick={() => {
                    this.handleDeleteEvent();
                  }}
                >
                  Eliminar selección
                </div>
              </div>
            ) : null}
            {this.state.openMakeCopies ? (
              <div className="properties-make-copies-container">
                <div
                  className="properties-close-container"
                  onClick={() => {
                    this.setState({ openMakeCopies: false });
                  }}
                >
                  <img src={Delete} alt="" />
                </div>
                <div className="properties-copies-title">
                  Cantidad de copias
                </div>
                <div className="properties-copies-input-container">
                  <div className="properties-copies-input">
                    <input
                      type="number"
                      value={this.state.currentNumberOfCopies}
                      onChange={(e) => {
                        this.setState({
                          currentNumberOfCopies: e.target.valueAsNumber,
                        });
                      }}
                    />
                  </div>
                  <div
                    className="propeties-copies-input-btn"
                    onClick={() => {
                      this.props.handleGenerateCopies(
                        this.state.currentPropertiesSelected,
                        this.state.currentNumberOfCopies
                      );
                      this.setState({
                        loading: false,
                        openMakeCopies: false,
                      });
                    }}
                  >
                    ¡Copiar!
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div
            className="properties-table-general-container"
            onClick={() => {
              this.setState({ openActionsMenu: false, attributesIndexes: -1 });
            }}
          >
            <div className="propeties-table-header-container">
              <div className="checkbox-container">
                <input
                  type="checkbox"
                  checked={
                    this.state.currentPropertiesSelected !== undefined &&
                    this.state.inmueblesToShow &&
                    this.state.currentPropertiesSelected.length ===
                      this.state.inmueblesToShow.length
                  }
                  onChange={() => {
                    this.handleSelectAll();
                  }}
                />
              </div>
              <div className="table-header-attributes-container">
                <div className="table-header-item">Inmuebles</div>
                {this.props.attributes && this.props.attributes.length > 0
                  ? this.props.attributes.map(
                      (attribute: any, index: number) => (
                        <div className="table-header-item-selectable">
                          {this.state.attributesIndexes === index ? (
                            <div className="attributes-filter-container">
                              <div className="title-space-container"></div>
                              <div className="selector-attributes-container">
                                <div
                                  className="properties-individual-option"
                                  onClick={() => {
                                    this.handleFilterProperty(
                                      attribute.name,
                                      ""
                                    );
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={
                                      this.state.attributesFilters[
                                        attribute.name
                                      ].length === 0
                                    }
                                  ></input>
                                  <div>Seleccionar todos</div>
                                </div>
                                {attribute.values.map((value: any) => (
                                  <div
                                    className="properties-individual-option"
                                    onClick={() => {
                                      this.handleFilterProperty(
                                        attribute.name,
                                        value.value
                                      );
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={this.state.attributesFilters[
                                        attribute.name
                                      ].includes(value.value)}
                                    ></input>
                                    <div>{value.value}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : null}
                          <div
                            className="table-header-item-title"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState({
                                attributesIndexes:
                                  this.state.attributesIndexes === index
                                    ? -1
                                    : index,
                                openActionsMenu: false,
                              });
                            }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div>{attribute.name}</div>
                            <img
                              src={collapse}
                              alt=""
                              style={{
                                width: "0.75rem",
                                height: "0.75rem",
                                marginLeft: "0.5rem",
                              }}
                            />
                          </div>
                        </div>
                      )
                    )
                  : null}
                <div className="table-header-item">Galería</div>
              </div>
            </div>

            {this.state.openCreateNew ? (
              <div className="property-new-individual-container">
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    style={{ opacity: 0, pointerEvents: "none" }}
                  />
                </div>
                <div className="property-new-creation-container">
                  <div className="property-creation-individual-title">
                    <input
                      type="text"
                      defaultValue={"Nuevo inmueble"}
                      value={this.state.currentCreatePropertyName}
                      onChange={(e) => {
                        this.setState({
                          currentCreatePropertyName: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {this.props.attributes
                    ? this.props.attributes.map(
                        (attribute: any, index: number) => (
                          <div className="property-creation-individual-title">
                            <select
                              onChange={(e) => {
                                this.handleSelectCreationAttribute(
                                  attribute.name,
                                  attribute.id,
                                  e
                                );
                              }}
                            >
                              {attribute.values.map(
                                (attValue: any, indexAtt: number) => (
                                  <option value={attValue.id}>
                                    {attValue.value}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        )
                      )
                    : null}
                  <div className="property-creation-individual-title">
                    <div
                      className="property-creation-save-btn"
                      onClick={() => {
                        this.setState({
                          currentCreatePropertyName: "",
                          currentCreateAttributes: [],
                          openCreateNew: false,
                        });
                      }}
                      style={{
                        backgroundColor: "#E0E0E0",
                        color: "#000",
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem",
                      }}
                    >
                      Cancelar
                    </div>
                    <div
                      className="property-creation-save-btn"
                      onClick={() => {
                        this.handleCompileCreateInfo();
                      }}
                      style={{
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem",
                      }}
                    >
                      Guardar
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.inmueblesToShow &&
            this.state.inmueblesToShow.length > 0 &&
            this.state.showInmuebles &&
            this.state.show ? (
              this.state.inmueblesToShow.map((property: any, index: number) => (
                <React.Fragment>
                  <div className="properties-individual-container">
                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        checked={this.state.currentPropertiesSelected.includes(
                          property.id
                        )}
                        onChange={() => {
                          this.handleSelectIndividual(property.id);
                        }}
                      />
                    </div>
                    <div className="properties-individual-items-container">
                      <div className="propeties-individual-title">
                        <input
                          type="text"
                          defaultValue={property.property}
                          onChange={(e) => {
                            this.setState({
                              currentEditNumber: e.target.value,
                            });
                          }}
                          onBlur={() => {
                            this.setState(
                              {
                                loading: false,
                              },
                              () => {
                                if (
                                  property.property !==
                                    this.state.currentEditNumber &&
                                  this.state.currentEditNumber !== ""
                                ) {
                                  this.props.handleUpdateInmuebleNum(
                                    this.state.currentEditNumber,
                                    property.id
                                  );
                                } else if (property.property !== "") {
                                  this.props.handleUpdateInmuebleNum(
                                    property.property,
                                    property.id
                                  );
                                } else {
                                  this.props.handleUpdateInmuebleNum(
                                    this.state.currentEditNumber,
                                    property.id
                                  );
                                }

                                this.setState({
                                  currentEditNumber: "",
                                });
                              }
                            );
                          }}
                        />
                      </div>
                      {property.values
                        ? property.values.map(
                            (value: any, indexValue: number) => (
                              <div
                                className="propeties-individual-title"
                                key={indexValue + index}
                              >
                                {this.props.attributes[indexValue].type !==
                                "Hierarchical" ? (
                                  <CreatableSelect
                                    styles={{
                                      control: (provided) => ({
                                        ...provided,
                                        width: "150px",
                                        height: '10px', // Establece el ancho que desees
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                        fontSize: "12px", // Establece el tamaño de fuente que desees
                                      }),
                                      clearIndicator: (provided) => ({
                                        ...provided,
                                        display: "none", // Oculta la "x" de borrar
                                      }),
                                    }}
                                    isClearable
                                    isSearchable={true} // Evita que se cambie con la tecla "Enter"
                                    isDisabled={this.state.isLoading}
                                    isLoading={this.state.isLoading}
                                    onChange={(event: any, newValue: any) => {
                                      this.handleChange(
                                        index,
                                        indexValue,
                                        event,
                                        property?.values[indexValue]?.id
                                      ); // Pasa el índice para identificar cuál Autocomplete se actualiza
                                    }}
                                    onCreateOption={(event: any) => {
                                      this.handleCreate(
                                        index,
                                        indexValue,
                                        event,
                                        property?.values[indexValue]?.id,
                                        this.props.attributes[indexValue].id
                                      ); // Pasa el índice para identificar cuál Autocomplete se actualiza
                                    }}
                                    options={
                                      this.props.attributes[indexValue].values
                                    }
                                    getOptionLabel={(
                                      option: string | FilmOptionType
                                    ) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === "string") {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.value;
                                    }}
                                    value={
                                      this.state.values[index]?.[indexValue] ||
                                      this.props.attributes[
                                        indexValue
                                      ].values.find(
                                        (e: any) => e.id === value.IdValue
                                      )
                                    }
                                    defaultValue={this.props.attributes[
                                      indexValue
                                    ].values.find(
                                      (e: any) => e.id === value.IdValue
                                    )}
                                    id={`free-solo-with-text-demo-${index}-${indexValue}`}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                ) : (
                                  <ReactSelect
                                    styles={{
                                      control: (provided) => ({
                                        ...provided,
                                        width: "150px",
                                        height: '10px', // Establece el ancho que desees
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                        fontSize: "12px", // Establece el tamaño de fuente que desees
                                      }),
                                      clearIndicator: (provided) => ({
                                        ...provided,
                                        display: "none", // Oculta la "x" de borrar
                                      }),
                                    }}
                                    isSearchable={true} // Evita que se cambie con la tecla "Enter"
                                    options={
                                      this.props.attributes[indexValue].values
                                    }
                                    defaultValue={this.props.attributes[
                                      indexValue
                                    ].values.find(
                                      (e: any) => e.id === value.IdValue
                                    )}
                                    getOptionLabel={(
                                      option: string | FilmOptionType
                                    ) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === "string") {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.value;
                                    }}
                                    onChange={(e: any) =>
                                      this.handleChange(
                                        index,
                                        indexValue,
                                        e,
                                        property?.values[indexValue]?.id
                                      )
                                    }
                                    id={`free-solo-with-text-demo-${index}-${indexValue}`}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                )}
                              </div>
                            )
                          )
                        : null}
                      <div
                        className="propeties-individual-title"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.props.handleGetInmuebleId(
                            property.id,
                            property.galleries
                          );
                          this.props.galleriesOpen(true);
                        }}
                      >
                        <img src={gallery} alt="" />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div className="kermit-container">
                <img src={Kermit} alt="" />
                <div className="kermit-warning">
                  Aún no has creado inmuebles.
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.openEditAttribute ? (
          <div className="properties-edit-attribute-container">
            <div className="properties-edit-attribute">
              <div
                className="properties-edit-attribute-close"
                onClick={() => {
                  this.setState({ openEditAttribute: false });
                }}
              >
                <img src={Delete} alt="" />
              </div>

              <div className="properties-edit-attribute-title">
                Editar atributo
              </div>

              <div className="properties-edit-attribute-text">
                Podrá modificar un atributo de todos los inmuebles seleccionados
                de manera rápida.
              </div>

              <div className="properties-edit-attribute-setup">
                <div className="properties-edit-setup-input">
                  <div className="properties-edit-setup-title">Atributo</div>
                  <select
                    onChange={(e) => {
                      this.setState({
                        currentAttributeIdSelected: e.target.value,
                      });
                    }}
                  >
                    <option selected hidden>
                      Seleccionar
                    </option>
                    {this.props.attributes.map((att: any, index: number) => {
                      return <option value={att.id}>{att.name}</option>;
                    })}
                  </select>
                </div>

                <div className="properties-edit-setup-input">
                  <div className="properties-edit-setup-title">Valor</div>
                  <select
                    disabled={this.state.currentAttributeIdSelected === ""}
                    onChange={(e) => {
                      this.setState({
                        currentAttributeValueIdSelected: e.target.value,
                      });
                    }}
                  >
                    <option selected hidden>
                      Seleccionar
                    </option>

                    {this.props.attributes.map((att: any, index: number) =>
                      att.id === this.state.currentAttributeIdSelected
                        ? att.values.map((value: any, indexValue: number) => (
                            <option value={value.id}>{value.value}</option>
                          ))
                        : null
                    )}
                  </select>
                </div>
              </div>

              <div className="properties-edit-btns">
                <div
                  className="properties-edit-attributes-cancel"
                  onClick={() => {
                    this.setState({ openEditAttribute: false });
                  }}
                >
                  Cancelar
                </div>
                <div
                  className="properties-edit-attributes-apply"
                  onClick={() => {
                    this.handleUpdateAttributes();
                  }}
                >
                  Aplicar cambios
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {this.state.openEditAndDuplicate ? (
          <div className="properties-edit-attribute-container">
            <div className="properties-edit-attribute">
              <div
                className="properties-edit-attribute-close"
                onClick={() => {
                  this.setState({ openEditAndDuplicate: false });
                }}
              >
                <img src={Delete} alt="" />
              </div>

              <div className="properties-edit-attribute-title">
                Duplicar y editar
              </div>

              <div className="properties-edit-attribute-text">
                Podrá modificar un atributo y duplicar los inmuebles
                seleccionados de manera rápida.
              </div>

              <div className="properties-edit-attribute-setup">
                <div className="properties-edit-setup-input">
                  <div className="properties-edit-setup-title">Atributo</div>
                  <select
                    onChange={(e) => {
                      this.setState({
                        currentEditAttributeIdSelected: e.target.value,
                      });
                    }}
                  >
                    <option selected hidden>
                      Seleccionar
                    </option>
                    {this.props.attributes.map((att: any, index: number) => {
                      return <option value={att.id}>{att.name}</option>;
                    })}
                  </select>
                </div>

                <div className="properties-edit-setup-input">
                  <div className="properties-edit-setup-title">Valor</div>
                  <select
                    disabled={this.state.currentEditAttributeIdSelected === ""}
                    onChange={(e) => {
                      this.setState({
                        currentEditAttributeValueIdSelected: e.target.value,
                      });
                    }}
                  >
                    <option selected hidden>
                      Seleccionar
                    </option>

                    {this.props.attributes.map((att: any, index: number) =>
                      att.id === this.state.currentEditAttributeIdSelected
                        ? att.values.map((value: any, indexValue: number) => (
                            <option value={value.id}>{value.value}</option>
                          ))
                        : null
                    )}
                  </select>
                </div>
              </div>

              <div className="properties-edit-btns">
                <div
                  className="properties-edit-attributes-cancel"
                  onClick={() => {
                    this.setState({ openEditAndDuplicate: false });
                  }}
                >
                  Cancelar
                </div>
                <div
                  className="properties-edit-attributes-apply"
                  style={{ width: "10rem" }}
                  onClick={() => {
                    this.handleDuplicateAndEditProperties();
                  }}
                >
                  Duplicar inmuebles
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <Backdrop sx={{ color: "#fff" }} open={!this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </React.Fragment>
    );
  }
}

export default TableNew;
