import {
    LOGIN_USER,
    LOGIN_USER_STATE,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    LOGOUT_USER,
} from "../actions";


let users: any = localStorage.getItem("detalle-user");
let user_storage: any = localStorage.getItem("user");

console.log(user_storage);

  
const INIT_STATE = {
    user: localStorage.getItem("user") ? JSON.parse(users) : {},
    stateLogin: user_storage !== undefined && user_storage !== null ? true : false,
    loading: false,
    error: "",
};

const authReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
    case LOGIN_USER:
        return { ...state, loading: true, error: "" };
    case LOGIN_USER_STATE:
        return { ...state, stateLogin: action.payload };
    case LOGIN_USER_SUCCESS:
        return { ...state, loading: false, user: action.payload.user, error: "" };
    case LOGIN_USER_ERROR:
        return {
            ...state,
            loading: false,
            user: {},
            error: action.payload.message,
    };
    case LOGOUT_USER:
        return { ...state, user: {}, error: "" };
    default:
        return { ...state };
    }
};

export default authReducer