import React from 'react'
import CloseWhite from './../../UI/general/Close_white.svg'

const GalleryFullscreen = ({currentImage, handleOpenFullScreen}: any) => {
    return(
        <div className='fullscreen-component'>
            <div className='close-btn-gallery' onClick={() => {handleOpenFullScreen(false, "")}}>
            <img width={100} src={CloseWhite} alt="galleries" />
            </div>
            <img src={`https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${currentImage}`} alt=""/>
        </div>
    )
}

export default GalleryFullscreen