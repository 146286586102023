import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

/*eslint-disable*/

function MfaToken({ user, event }: any): any {
  const history = useNavigate();
  const [one, setOne] = useState("");
  const [two, setTwo] = useState("");
  const [three, setThree] = useState("");
  const [four, setFour] = useState("");
  const [five, setFive] = useState("");
  const [six, setSix] = useState("");

  const oneRef: any = useRef(null);
  const twoRef: any = useRef(null);
  const threeRef: any = useRef(null);
  const fourRef: any = useRef(null);
  const fiveRef: any = useRef(null);
  const sixRef: any = useRef(null);

  const handleEvent = () => {
    if (
      one !== "" &&
      two !== "" &&
      three !== "" &&
      four !== "" &&
      five !== "" &&
      six !== ""
    ) {
      event(
        {
          token_mfa: one + two + three + four + five + six,
          user: user,
        },
        history
      );
    }
  };

  return (
    <Box className="MFaQR-container">
      <Divider />
      <Box sx={{ py: 8, px: 5, textAlign: "center" }}>
        <Typography sx={{ fontSize: "0.9rem", mb: 2 }}>
          Please enter the code
        </Typography>

        <Box sx={{ flexGrow: 1, py: 2, maxWidth: 600 }}>
          <Grid container spacing={6}>
            <Grid item xs={2}>
              <input
                id="outlined-basic1"
                ref={oneRef}
                onChange={(e) => {
                  setOne(e.target.value);
                  e.target.value.length === 1 ? twoRef.current.focus() : {};
                }}
                style={{ width: "35px", height: "35px", textAlign: "center" }}
                maxLength={1}
              />
            </Grid>
            <Grid item xs={2}>
              <input
                ref={twoRef}
                id="outlined-basic2"
                onChange={(e) => {
                  setTwo(e.target.value);

                  e.target.value.length === 1 ? threeRef.current.focus() : {};
                }}
                style={{ width: "35px", height: "35px", textAlign: "center" }}
                maxLength={1}
              />
            </Grid>
            <Grid item xs={2}>
              <input
                ref={threeRef}
                id="outlined-basic3"
                onChange={(e) => {
                  setThree(e.target.value);

                  e.target.value.length === 1 ? fourRef.current.focus() : {};
                }}
                style={{ width: "35px", height: "35px", textAlign: "center" }}
                maxLength={1}
              />
            </Grid>
            <Grid item xs={2}>
              <input
                ref={fourRef}
                id="outlined-basic4"
                onChange={(e) => {
                  setFour(e.target.value);

                  e.target.value.length === 1 ? fiveRef.current.focus() : {}
                }}
                style={{ width: "35px", height: "35px", textAlign: "center" }}
                maxLength={1}
              />
            </Grid>
            <Grid item xs={2}>
              <input
                ref={fiveRef}
                id="outlined-basic5"
                onChange={(e) => {
                  setFive(e.target.value);
                  e.target.value.length === 1 ? sixRef.current.focus() : {}
                
                }}
                style={{ width: "35px", height: "35px", textAlign: "center" }}
                maxLength={1}
              />
            </Grid>
            <Grid item xs={2}>
              <input
                ref={sixRef}
                id="outlined-basic6"
                onChange={(e) => setSix(e.target.value)}
                style={{ width: "35px", height: "35px", textAlign: "center" }}
                maxLength={1}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ flexGrow: 1, py: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Typography sx={{ fontSize: "0.5rem" }}>
              Ayuda, no tengo una{" "}
              <Typography sx={{ color: "#ED0874", fontSize: "0.5rem" }}>
                App de autenticación
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Box sx={{ textAlign: "center", display: "flex" }}>
              <Box sx={{ mx: 1 }}>
                  <Button variant="contained"  color="inherit" sx={{}}>
                    Cancel
                  </Button>
              </Box>
              <Box sx={{ mx: 1 }}>
                  <Button
                    variant="contained"
                    sx={{ 
                      bgcolor: "#ED0874",
                      '&:hover': {
                        backgroundColor: 'rgb(191, 30, 96)'
                    }
                    }}
                    onClick={() => handleEvent()}
                  >
                    Validate
                  </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default MfaToken;
