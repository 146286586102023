import React from "react";

import {
  Box,
  Card,
  Grid,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";

// material-ui
import { IconApps, IconHome } from "@tabler/icons";
import { Link } from "react-router-dom";

/*eslint-disable*/

function OptionsNavBar({}: any): any {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseDownOpcion = (event: any) => {
    event.preventDefault();
  };
  return (
    <Box sx={{ float: "left", maxWidth:"500" }}>
      <IconButton
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        onMouseDown={handleMouseDownOpcion}
        edge="end"
        size="large"
        sx={{
            color: "rgb(237, 8, 116)",
            fill: "rgb(237, 8, 116)"
        }}
      >
        <IconApps size={"2rem"} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
            maxWidth:"600px",
            maxHeight: "420px"
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ textAlign: "center", py: 1, mx: 3, backgroundColor:"#F5F5F5", borderRadius:"3px" }}>
          <Typography sx={{fontFamily:"Montserrat Regular"}}>{"Menú".toUpperCase()}</Typography>
        </Box>
        <Box sx={{ flexGrow: 1, p: 2, overflowY: "hidden", paddingBottom: "0px" }}>
          <Grid container spacing={4} sx={{alignItems: "center"}}>
            <Grid item xs={12} md={12}>
              <Link to={"/"} style={{ textDecoration: "none" }}>
                <Card sx={{ mb: 1, boxShadow: "0px 3px 6px #00000029" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      p: 4.5,
                    }}
                  >
                    <Box sx={{ color: "inherit", display: "flex", flexDirection:"column", justifyContent:"center", alignItems:"center", maxWidth: "100%"  }}>
                      <IconHome />
                      <Typography sx={{marginTop:"10%", fontFamily:"Montserrat SemiBold"}}>Home</Typography>
                    </Box>
                  </Box>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </Box>
  );
}

export default OptionsNavBar;
