import {
    LIST_BUILDER,
    LIST_BUILDER_SUCCESS,
    LIST_BUILDER_ERROR,
    SELECTED_BUILDER_SUCCESS,
    SELECTED_BUILDER_ERROR,
    CREATE_BUILDER,
    UPDATE_BUILDER,
    ONE_BUILDER,
    DELETE_BUILDER
} from '../actions';

export const builderList = (user: any = null) => ({
    type: LIST_BUILDER,
    payload: { user }
});
export const builderListSuccess = (builder: any) => ({
    type: LIST_BUILDER_SUCCESS,
    payload: builder
});
export const builderListError = (message: any) => ({
    type: LIST_BUILDER_ERROR,
    payload: { message }
});
export const builderSelectedSuccess = (builder: any) => ({
    type: SELECTED_BUILDER_SUCCESS,
    payload: builder
});
export const builderSelectedError = (message: any) => ({
    type: SELECTED_BUILDER_ERROR,
    payload: { message }
});
export const builderCreate = (builder: any, history: any) => ({
    type: CREATE_BUILDER,
    payload: { builder, history }
});
export const builderUpdate = (builder: any, id: any, history: any = null) => ({
    type: UPDATE_BUILDER,
    payload: { builder, id, history }
});
export const builderDelete = (builder: any) => ({
    type: DELETE_BUILDER,
    payload: { builder }
});
export const builderOne = (builder: any, history: any) => ({
    type: ONE_BUILDER,
    payload: { builder, history }
});