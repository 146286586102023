
interface Builders {
    title: string,
    event: any,
    user: any
}

const BuildersHeader = ({title, event, user}: Builders) => {
    return(
        <div className='header-builder-container'>
            <div className='builder-title'>
                {title}
            </div>
            {
                user.name === 'ADMIN' ?
                <div className='new-builders-btn' onClick={() => {event(true)}}>
                    Nueva Constructora
                </div>:null 
            }
        </div>
    )
}

export  default BuildersHeader