import React from 'react'

import Kermit from './../../UI/general/Kermit.png'
import drag from './../../UI/general/drag.svg'
import Edit from './../../UI/general/Edit.svg'
import Delete from './../../UI/general/close.svg'

import SortableTree from "@nosferatu500/react-sortable-tree";
import "@nosferatu500/react-sortable-tree/style.css";

/*eslint-disable*/

interface TableNew {
  dragItem: any,
  dragOverItem: any
}

interface Props {
  data: any,
  updateHierarchical: any,
  project: any
  handleDeleteAttribute: any,
  handleOpenEditModal: any,
  handleUpdateListOrder: any,
  currentEdiIndex: number,
  handleChangeActive: any
}

interface State {
  dataToShow: any,
  treeData: any,
  last: any,
  showTree: any,
}

class TableNew extends React.Component<Props,State>{
  constructor(props: Props){
    super(props);
    this.state = {
      dataToShow: [],
      treeData: [],
      last: '',
      showTree: false,
    }

    this.dragItem = React.createRef()
    this.dragOverItem = React.createRef()
  }

  handleDragStart = (e: any, position: number) => {
    this.dragItem.current = position;
}

  handleDragEnter = (e: any, position: number) => {
    this.dragOverItem.current = position;
};

  handleDrop = (e: any) => {
    const copyListItems = [...this.props.data];
    const dragItemContent = copyListItems[this.dragItem.current];
    copyListItems.splice(this.dragItem.current, 1);
    copyListItems.splice(this.dragOverItem.current, 0, dragItemContent);
    this.dragItem.current = null;
    this.dragOverItem.current = null;
    this.setState({
      dataToShow: copyListItems
    })
    this.props.handleUpdateListOrder(copyListItems)
};

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if(prevProps.data !== this.props.data){
        this.setState({
          dataToShow: this.props.data
        }, () => {
          let attributesTree: any = [];
          this.state.dataToShow.map((attribute: any, index: number) => {

            if (attribute.type === "Hierarchical") {
              if (this.state.dataToShow.find((element: any) => element.padre === attribute.id) === undefined && attribute.padre !== null) {
                  this.setState({
                    last: attribute
                  })
              }
              if (attribute.padre === null) {
                attributesTree.push({
                  title: attribute.name,
                  id: attribute.id,
                  children: [],
                });
              } else {
                attributesTree.forEach((primero: any) => {
                  if (attribute.padre === primero.id) {
                    primero.children.push({
                      title: attribute.name,
                      id: attribute.id,
                      children: [],
                    });
                  }
                  if (primero.children.length > 0) {
                    primero.children.forEach((segundo: any) => {
                      if (attribute.padre === segundo.id) {
                        segundo.children.push({
                          title: attribute.name,
                          id: attribute.id,
                          children: [],
                        });
                      }
                      if (segundo.children.length > 0) {
                        segundo.children.forEach((tercero: any) => {
                          if (attribute.padre === tercero.id) {
                            tercero.children.push({
                              title: attribute.name,
                              id: attribute.id,
                              children: [],
                            });
                          }
                          if (tercero.children.length > 0) {
                            tercero.children.forEach((cuarto: any) => {
                              if (attribute.padre === cuarto.id) {
                                cuarto.children.push({
                                  title: attribute.name,
                                  id: attribute.id,
                                  children: [],
                                });
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            }
          });

          this.setState({
            treeData: attributesTree
          })
        })
      }
  }

  handleSetTreeData = (payload: any) => {
    this.setState({
      treeData: payload
    })
  }


  render(): React.ReactNode {
      return(
        <div className='attributes-table-container'>
          <div className='attributes-table-title-btn-container'>
            <div className='attributes-table-title'>Listado de atributos</div>
            <div className='attributes-table-btn' onClick={() => {this.setState({showTree: !this.state.showTree})}}>Establecer jerarquía</div>
          </div>
          {this.state.showTree ?
            <div className='attributes-table-tree-container'>
              <div className='attributes-table-text-container'>
                <div className='attributes-tree-title'>Jerarquía</div>
                <div className='attributes-tree-text'>Ordena la jerarquía del proyecto</div>
              </div>
              <div className='attributes-table-tree'>
                {
                  this.state.treeData.length > 0 ?
                  <SortableTree
                    treeData={this.state.treeData}
                    onChange={(treeData) => {
                      this.handleSetTreeData(treeData);
                    }}
                    onMoveNode={(data) => {
                      let user: any = localStorage.getItem("user");
                      this.props.updateHierarchical({
                        user: JSON.parse(user).id,
                        project: this.props.project.id,
                        hierarchical: data.treeData,
                      });
                      this.handleSetTreeData(data.treeData);
                    }}
                  />:null
                }
              </div>
            </div>:null
          }
          <div className='attributes-list-names-container'>
            <div>Atributo</div>
            <div>Mostrar en filtro</div>
            <div>Edición</div>
          </div>
          {
            this.state.dataToShow.length > 0 ?
            <div className='created-attributes-container'>
              {
                this.state.dataToShow.map((data: any, index: number) => 
                  <div 
                  className='attrbiutes-individual-container'
                  draggable
                  onDragStart={(e) => {this.handleDragStart(e,index)}}
                  onDragEnter={(e) => this.handleDragEnter(e, index)}
                  onDragEnd={this.handleDrop}
                  style={{
                    backgroundColor: this.props.currentEdiIndex === index ? '#E2E2E2':''
                  }}
                  >
                    <div className='attributes-individual-drag-controller'>
                      <img src={drag} alt=''/>
                    </div>
                    <div className='attributes-individual-name'>
                      {data.name}
                    </div>
                    <div className='attributes-edit-active'>
                      <input type='checkbox' checked={data.active} onChange={(e) => {this.props.handleChangeActive(data.id, e.currentTarget.checked)}}/> 
                    </div>
                    <div className='attributes-delete-edit-container'>
                      <div className='attrbiutes-icon-container' onClick={() => {this.props.handleOpenEditModal(data, index)}}>
                        <img src={Edit} alt=''/>
                      </div>
                      <div className='attrbiutes-icon-container' onClick={() => {this.props.handleDeleteAttribute([data.id])}}>
                        <img src={Delete} alt=''/>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>:
            <div className='kermit-container'>
              <img src={Kermit} alt=''/>
              <div className='kermit-warning'>Aún no has creado atributos.</div>
            </div> 
          }
        </div>
      )
  }
}

export default TableNew