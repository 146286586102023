
import {
    LIST_SPIN,
    LIST_SPIN_SUCCESS,
    LIST_SPIN_ERROR,
    SET_SPIN,
    UPDATE_SPIN,
    DELETE_SPIN,
    UPDATE_SCRIPT,
    //LIST_SCRIPT
} from '../actions'

/*eslint-disable*/

const INIT_STATE = {
    spin: [],
    script: [],
    details: [],
    amenities: [],
    loading: false,
    message: '',
}

export default (state = INIT_STATE, action: any) => {
switch (action.type) {
    case  LIST_SPIN:
    return { ...state, loading: true, message: '' }
    case LIST_SPIN_SUCCESS:
    return {
        ...state,
        spin: action.payload.data,
        script: action.payload.scripts,
        details: action.payload.details,
        amenities: action.payload.amenities,
        loading: false,
        message: "Exito",
    }
    case LIST_SPIN_ERROR:
    return {
        ...state,
        spin: [],
        loading: false,
        message: action.payload.message,
    }
    case  SET_SPIN:
    return { ...state, loading: true, message: '' }
    case  UPDATE_SPIN:
    return { ...state, loading: true, message: '' }
    case  DELETE_SPIN:
    return { ...state, loading: true, message: '' }
    case UPDATE_SCRIPT:
    return {...state, loading: true, message: ''}
    default:
    return { ...state }
}
}