import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";

import dashBackground from "./../../UI/backgrounds/BG_PUBLIC.webp";

import Navbar from "../navbar";
import Sidebar from "../sidebar";
import POIsHeaderComponent from "./header.component";
import IconsPOISCategory from "./icons.component";
import MapContainerPOICategory from "./mapContainer.component";
import ListPOISCategory from "./list.component";

import {
  logoutUser,
  locationsList,
  locationUpdatePoisCategory,
  locationSetPois,
  locationDeletePois
} from "../../redux/actions";

interface Props {
  logoutUser: any;
  loading: any;
  modules: any;
  projectSelected: any;
  selectedBuilder: any;
  params: any;
  locationsList: any;
  locations: any;
  categories_pois: any;
  POis: any;
  locationUpdatePoisCategory: any;
  locationSetPois: any;
  locationDeletePois: any;
}

interface State {
  userString: any;
  user: any;
  logo_ubicacion: string;
  idProject: any;
  currentPOI: string;
  selectedPOI: any;
  selectedCategoryPOI: any;
  selectedCategoryID: string;
  currentLogo: string;
  currentPOIName: string;
  currentPOISArray: any;
  rerender: boolean
}

/*eslint-disable*/

function withParams(Component: any) {
  return (props: any) => <POICategoryView {...props} params={useParams()} />;
}

class POICategoryView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userString: localStorage.getItem("user"),
      user: "",
      logo_ubicacion: "",
      idProject: this.props.params.id,
      currentPOI: '',
      selectedPOI: {},
      selectedCategoryPOI: {},
      selectedCategoryID: "",
      currentLogo: "",
      currentPOIName: "",
      currentPOISArray: [],
      rerender: true
    };
  }

  componentDidMount(): void {
    if (this.state.userString) {
      this.setState(
        {
          user: JSON.parse(this.state.userString),
        },
        () => {
          this.props.locationsList({
            user: this.state.user.id,
            project: this.state.idProject,
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps: any, prevState: any): void {
    if(this.props.params.poi !== this.state.currentPOI){
      this.setState({
        currentPOI: this.props.params.poi,
        rerender: false
      }, () => {
        this.props.categories_pois.forEach((category: any) => {
          if (
            category.name.toLowerCase() === this.state.currentPOI.toLowerCase().replace("-"," ")
          ) {
            this.setState({
              selectedCategoryPOI: category,
              selectedCategoryID: category.id,
              currentLogo: `https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${category.logo}`,
            }, () => {
              let poiss: any = [];
                this.props.POis.forEach((pois: any) => {
                  if (pois.category === this.state.selectedCategoryID) {
                    poiss.push({
                      lat: parseFloat(pois.latitud),
                      lng: parseFloat(pois.longitud),
                      name: pois.name
                    });
                  }
                });

                this.setState({
                  currentPOISArray: poiss,
                });
            });
          }
        });
      })
      setTimeout(() => {
        this.setState({
          rerender: true
        })
      },500)
    }
    if (prevProps.categories_pois !== this.props.categories_pois || this.props.params.poi !== this.state.currentPOI) {
      this.props.categories_pois.forEach((category: any) => {
        if (
          category.name.toLowerCase() === this.state.currentPOI.toLowerCase()
        ) {
          this.setState({
            selectedCategoryPOI: category,
            selectedCategoryID: category.id,
            currentLogo: `https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${category.logo}`,
          });
        }
      });
    }
    if (prevProps.POis !== this.props.POis || this.props.params.poi !== this.state.currentPOI) {
      let poiss: any = [];
      this.props.POis.forEach((pois: any) => {
        if (pois.category === this.state.selectedCategoryPOI.id) {
          poiss.push({
            lat: parseFloat(pois.latitud),
            lng: parseFloat(pois.longitud),
            name: pois.name
          });
        }
      });
      this.setState({
        currentPOISArray: poiss,
      });
    }
    if (prevProps.modules !== this.props.modules) {
      this.props.modules.forEach((module: any) => {
        if (module.name === "Puntos de Interes") {
          this.setState({
            logo_ubicacion: module.logo,
          });
        }
      });
    }
  }

  handleAddLogo = (e: any) => {
    this.setState({
      currentLogo: URL.createObjectURL(e.target.files[0]),
    });

    let InstFormData = new FormData();
    InstFormData.append("myFile", e.target.files[0]);
    InstFormData.append(
      "name",
      `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/POIS/${e.target.files[0].name}`
    );
    this.props.locationUpdatePoisCategory({
      data: {
        user: this.state.user.id,
        project: this.state.idProject,
        id: this.state.selectedCategoryPOI.id,
        logo: `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/POIS/${e.target.files[0].name}`,
      },
      assets: InstFormData,
      type: "logo",
    });
  };

  handleChangeName = (payload: string) => {

    this.setState({
      currentPOIName: payload,
    });
  };

  handleFixedMap = (payload: any) => {
    payload.user = this.state.user.id;
    payload.project = this.state.idProject;
    payload.id = this.state.selectedCategoryPOI.id;
    this.props.locationUpdatePoisCategory(payload);
  };

  handleUpdatePOISArray = (POISArray: any, POIS: any) => {
    if (this.state.currentPOIName !== "") {
      this.props.locationSetPois({
        user: this.state.user.id,
        project: this.state.idProject,
        category: this.state.selectedCategoryPOI.id,
        name: this.state.currentPOIName,
        latitud: POIS.lat,
        longitud: POIS.lng,
      });
      this.setState(
        {
          currentPOISArray: POISArray,
          currentPOIName: "",
        }
      );
    } else {
      alert("Y el Name Papu?");
    }
  };

  handleDeletePOI = (id: string) => {

    this.props.locationDeletePois({
      user: this.state.user.id,
        project: this.state.idProject,
        id: id
    })
  }

  render(): React.ReactNode {
    return (
      <div className="pois-container">
        <img className="dashboard-background" src={dashBackground} alt="" />
        <Sidebar project={this.props.projectSelected} />
        <div className="pois-category-container-main">
          <POIsHeaderComponent
            currentPOI={this.state.currentPOI}
            handleChange={this.handleChangeName}
            selectedName={this.state.currentPOIName}
          />
          { this.state.rerender ?
              <MapContainerPOICategory
              locations={this.props.locations}
              currentImage={this.state.currentLogo}
              handleUpdatePOISArray={this.handleUpdatePOISArray}
              selectedCategoryPOI={this.state.selectedCategoryPOI}
              locationUpdatePoisCategory={this.handleFixedMap}
              currentPOISArray={this.state.currentPOISArray}
            />:null
          }
        </div>
        <div className="pois-category-right-container-main">
          <IconsPOISCategory
            currentPOI={this.state.currentPOI}
            currentLogo={this.state.currentLogo}
            handleAddLogo={this.handleAddLogo}
          />
          <ListPOISCategory 
            currentPOI={this.state.currentPOI} 
            listPOIs = {this.props.POis}
            selectedCategoryID = {this.state.selectedCategoryID}
            handleDeletePOI = {this.handleDeletePOI}
          />
        </div>
        <Navbar logout={this.props.logoutUser} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    )
  }
}

const mapStateToProps = ({
  projectReducer,
  builderReducer,
  locationsReducer,
  moduleReducer
}: any) => {
  const { locations, categories_pois, POis, loading } = locationsReducer;
  const { modules } = moduleReducer;
  const { projectSelected } = projectReducer;
  const { selectedBuilder } = builderReducer;
  return {
    projectSelected,
    selectedBuilder,
    locations,
    categories_pois,
    POis,
    loading,
    modules
  };
};

export default connect(mapStateToProps, {
  logoutUser,
  locationsList,
  locationUpdatePoisCategory,
  locationSetPois,
  locationDeletePois
})(withParams(POICategoryView));
