import axios from "axios";
import { ApiConfig } from "../constants/defaultValues";
import { borrarCacheImagenes } from "../helpers/clearCache";

export async function list(user: any) {
  return axios.post(ApiConfig.URL2 + "builder/get", user);
}
export async function create(e: any) {
  let result = await axios.post(ApiConfig.URL2 + "builder/set", e.data);
  console.log(e.assets.get('myFile'));
  if (e.assets.has('myFile') && e.assets.get('myFile') !== "null") {
    if (result.status === 200) {
      e.assets.append("name", `galleries/${result.data.last_builder}/logo.svg`);
      let resultFormData = await axios.post(
        `${ApiConfig.URL3}coord/`,
        e.assets,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (resultFormData.status === 200) {
        borrarCacheImagenes();
        return result;
      }
    }
  } else {
    borrarCacheImagenes();
    return result;
  }
}
export async function update(e: any, id: any) {
  let result = await axios.post(ApiConfig.URL2 + "builder/update", e.data);
  if (result.status === 200) {
    if (e.assets.has('myFile') && e.assets.get('myFile') !== "null") {
    let resultFormData = await axios.post(`${ApiConfig.URL3}coord/`, e.assets, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (resultFormData.status === 200) {
      borrarCacheImagenes();
      return result;
    }
  }else{
    borrarCacheImagenes();
    return result;
  }
}
  //return axios.put(ApiConfig.URL + "builder/update/" + id, e);
}
export async function eliminar(builder: any) {
  return await axios.post(ApiConfig.URL2 + "builder/delete", builder);
  /* if (result.status === 200) {
        axios.post(`${ApiConfig.URL3}coord/`, e.assets, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return result
    } */
}
export async function one(id: any) {
  return axios.get(ApiConfig.URL + "builder/get/" + id);
}
