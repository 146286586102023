import {
  LIST_LOCATION,
  LIST_LOCATION_SUCCESS,
  LIST_LOCATION_ERROR,
  UPDATE_LOCATION,
  LIST_ONE_LOCATION,
  DELIMITACION_LOTE,
  SET_ROADS_ACCESS,
  SET_POIS_CATEGORY,
  UPDATE_POIS_CATEGORY,
  SET_POIS,
  UPDATE_ROADS_ACCESS,
  DELETE_ROADS_ACCESS,
  UPDATE_POIS,
  DELETE_POIS,
} from "../actions";

/*eslint-disable*/

const INIT_STATE = {
  locations: {},
  POis: [],
  categories_pois: [],
  delimitations: [],
  routes_access: [],
  logo_module: "",
  loading: false,
  message: "",
};

export default (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case LIST_LOCATION:
      return { ...state, loading: true, message: "" };
    case LIST_ONE_LOCATION:
      return { ...state, loading: true, message: "" };
    case LIST_LOCATION_SUCCESS:
      return {
        ...state,
        locations: action.payload.locations,
        POis: action.payload.POis,
        categories_pois: action.payload.categories_pois,
        delimitations: action.payload.delimitations,
        routes_access: action.payload.routes_access,
        logo_module: action.payload.logo_module,
        loading: false,
      };
    case LIST_LOCATION_ERROR:
      return {
        ...state,
        locations: {},
        loading: false,
        message: action.payload.message,
      };
    case UPDATE_LOCATION:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case DELIMITACION_LOTE:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case SET_ROADS_ACCESS:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case UPDATE_ROADS_ACCESS:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case DELETE_ROADS_ACCESS:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case SET_POIS_CATEGORY:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case UPDATE_POIS_CATEGORY:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case SET_POIS:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case UPDATE_POIS:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case DELETE_POIS:
      return {
        ...state,
        loading: true,
        message: "",
      };
    default:
      return { ...state };
  }
};
