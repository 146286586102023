export function borrarCacheImagenes() {
    // Genera una cadena única basada en la fecha actual
    var timeStamp = new Date().getTime();
  
    // Recorre todas las imágenes de la página
    var imagenes = document.getElementsByTagName('img');
    for (var i = 0; i < imagenes.length; i++) {
      var imagen = imagenes[i];
      
      // Obtiene la URL de la imagen
      var url = imagen.src;
  
      // Agrega un parámetro de consulta con la cadena única al final de la URL
      url += (url.indexOf('?') >= 0 ? '&' : '?') + 't=' + timeStamp;
  
      // Actualiza la URL de la imagen
      imagen.src = url;
    }
  }