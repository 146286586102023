import React, {useState} from 'react'

import U3DS from "./../../UI/general/Placeholder_02.svg";

const UploadNewIcon = ({handleSetIcon, handleSetIconName,showIconsUpload,handleOpenUploadModa}: any) => {

    const [uploadedImage, setUploadedIamge] = useState("")
    const [uploadedImageName, setUploadedIamgeName] = useState("")

    const handleAddLogo = (e: any) => {
        setUploadedIamge(URL.createObjectURL(e.target.files[0]));
        handleSetIcon(e.target.files[0])
    }

    const HandleChangeText = (e: any) => {
        setUploadedIamgeName(e.currentTarget.value)
        handleSetIconName(e.currentTarget.value)
    }

    return(
        <div className='upload-icon-main'>
            <div className='upload-icon-container'>
                <div className='upload-icon-image'>
                    <img
                        src={
                            uploadedImage ?
                            uploadedImage :
                            U3DS
                        }
                        alt=""
                        width={"100%"}
                        id=""
                        onError={() => {
                            var image: any = document.getElementById("favicon")
                            if(image){
                            image.src = U3DS
                            }
                        }}
                    />
                </div>
                <div className='upload-icon-info'>
                    <div className='upload-icon-info-main'>
                        Seleccionar ícono
                    </div>
                    <div className='upload-icon-info-format'>
                        El archivo debe estar en formato .SVG
                    </div>
                    <input
                        type="file"
                        name="imagen"
                        id="upload-photo-nuevo-icons"
                        className="upload-photo-nuevo-icons inputfile-icons"
                        onChange={(e: any) => {handleAddLogo(e)}}
                        accept="image/svg+xml"
                    />
                    <div className='input-icons-btn'>
                        Seleccionar
                    </div>
                </div>
            </div>
            <div className='upload-icon-name'>
                <div className='upload-name-main'>
                    Nombre del ícono
                </div>
                <input
                    type={"text"}
                    onChange={(e) => {HandleChangeText(e)}}
                    value={uploadedImageName}
                    className="input-icon-name"
                    maxLength={25}
                />
            </div>
        </div>
    )
}

export default UploadNewIcon