import React from 'react'
import { IconEdit, IconTrash } from "@tabler/icons";

const ShowGalleriesImages = ({galleries, handleOpenFullScreen, handleDeleteGallery, handleOpenEditionMode}: any) => {

    return(
        <div className='gallery-preview-main'>
            {
                galleries.map((image: any, index: any) => 
                    image.type === 'card' ?
                    <div className={'gallery-image-card'} key={image.name + index}>
                        <div className='gallery-image-container-links'>
                            <img width={100} src={`https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${image.link}`} alt="galleries" />
                        </div>
                        <div className='information-container'>
                            <div className='gallery-image-name'>{image.name}</div>
                            <div className='image-options'>
                                <div className='fullscreen-option' onClick={() => {handleOpenFullScreen(true, image.link)}}>Ver</div>
                                <div className='edit-gallery' onClick={() => {handleOpenEditionMode(image)}}>
                                    <IconEdit />
                                </div>
                                                            
                                <div className='delete-gallery' onClick={() => {handleDeleteGallery(image.id)}}>
                                    <IconTrash />
                                </div>
                            </div>
                        </div>
                    </div>:null
                )
            }
        </div>
    )
}

export default ShowGalleriesImages