module.exports = {
    galleryStructureData: () => {
        return[
            {
                "nombre":"Proyecto",
                "slug":"proyecto",
                "slugEn":"projects",
                "innerArray":[
                    {
                        "nombre":"Renders 2D",
                        "slug":"renders2d",
                        "slugEn":"2d renders",
                        "padre":"proyecto",
                        "isImage": true 
                    },
                    {
                        "nombre":"Fotos 2D",
                        "slug":"fotos2d",
                        "slugEn":"2d photos",
                        "padre":"proyecto",
                        "isImage": true 
                    },
                    {
                        "nombre":"Fotos 360",
                        "slug":"fotos360",
                        "slugEn":"360 photos",
                        "padre":"proyecto",
                        "isImage": true 
                    },
                    {
                        "nombre":"Renders 360",
                        "slug":"renders360",
                        "slugEn":"360 renders",
                        "padre":"proyecto",
                        "isImage": true 
                    },
                    {
                        "nombre":"Planos",
                        "slug":"planos",
                        "slugEn":"planes",
                        "padre":"proyecto",
                        "isImage": true 
                    },
                    {
                        "nombre":"Recorridos",
                        "slug":"recorridos",
                        "slugEn":"tours",
                        "padre":"proyecto",
                        "isImage": false
                    },
                    {
                        "nombre":"Brochures",
                        "slug":"brochures",
                        "slugEn":"brochures",
                        "padre":"proyecto",
                        "isImage": false
                    },
                    {
                        "nombre":"Videos",
                        "slug":"videos",
                        "slugEn":"videos",
                        "padre":"proyecto",
                        "isImage": false
                    }
                ]
            },
            {
                "nombre":"Inmuebles",
                "slug":"inmuebles",
                "slugEn":"properties",
                "innerArray": [
                    {
                        "nombre":"Renders 2D",
                        "slug":"renders2d",
                        "slugEn":"2d renders",
                        "padre":"inmuebles",
                        "isImage": true 
                    },
                    {
                        "nombre":"Fotos 2D",
                        "slug":"fotos2d",
                        "slugEn":"2d photos",
                        "padre":"inmuebles",
                        "isImage": true 
                    },
                    {
                        "nombre":"Fotos 360",
                        "slug":"fotos360",
                        "slugEn":"360 photos",
                        "padre":"inmuebles",
                        "isImage": true 
                    },
                    {
                        "nombre":"Renders 360",
                        "slug":"renders360",
                        "slugEn":"360 renders",
                        "padre":"inmuebles",
                        "isImage": true 
                    },
                    {
                        "nombre":"Planos",
                        "slug":"planos",
                        "slugEn":"planes",
                        "padre":"inmuebles",
                        "isImage": true 
                    },
                    {
                        "nombre":"Recorridos",
                        "slug":"recorridos",
                        "slugEn":"tours",
                        "padre":"inmuebles",
                        "isImage": false
                    },
                    {
                        "nombre":"Brochures",
                        "slug":"brochures",
                        "slugEn":"brochures",
                        "padre":"inmuebles",
                        "isImage": false
                    },
                    {
                        "nombre":"Videos",
                        "slug":"videos",
                        "slugEn":"videos",
                        "padre":"inmuebles",
                        "isImage": false
                    },
                    {
                        "nombre":"Vistas por piso 2D",
                        "slug":"vistas2D",
                        "slugEn":"vistas2D",
                        "padre":"inmuebles",
                        "isImage": true 
                    },
                    {
                        "nombre":"Vistas por piso 360",
                        "slug":"vistas360",
                        "slugEn":"vistas360",
                        "padre":"inmuebles",
                        "isImage": true 
                    },
                ]
            },
            {
                "nombre":"Amenidades",
                "slug":"amenidades",
                "slugEn":"amenities",
                "innerArray": [
                    {
                        "nombre":"Renders 2D",
                        "slug":"renders2d",
                        "slugEn":"2d renders",
                        "padre":"amenidades",
                        "isImage": true 
                    },
                    {
                        "nombre":"Fotos 2D",
                        "slug":"fotos2d",
                        "slugEn":"2d photos",
                        "padre":"amenidades",
                        "isImage": true 
                    },
                    {
                        "nombre":"Fotos 360",
                        "slug":"fotos360",
                        "slugEn":"360 photos",
                        "padre":"amenidades",
                        "isImage": true 
                    },
                    {
                        "nombre":"Renders 360",
                        "slug":"renders360",
                        "slugEn":"360 renders",
                        "padre":"amenidades",
                        "isImage": true 
                    },
                    {
                        "nombre":"Planos",
                        "slug":"planos",
                        "slugEn":"planes",
                        "padre":"amenidades",
                        "isImage": true 
                    },
                    {
                        "nombre":"Recorridos",
                        "slug":"recorridos",
                        "slugEn":"tours",
                        "padre":"amenidades",
                        "isImage": false
                    },
                    {
                        "nombre":"Brochures",
                        "slug":"brochures",
                        "slugEn":"brochures",
                        "padre":"amenidades",
                        "isImage": false
                    },
                    {
                        "nombre":"Videos",
                        "slug":"videos",
                        "slugEn":"videos",
                        "padre":"amenidades",
                        "isImage": false
                    }
                ]
            }
        ]
    }
}