import {
    LIST_CONTACT,
    LIST_CONTACT_SUCCESS,
    LIST_CONTACT_ERROR,
    SET_CONTACT,
    UPDATE_CONTACT,
    DELETE_CONTACT,
} from './../actions'

export const contactList = (proyecto: any) => ({
    type: LIST_CONTACT,
    payload: {proyecto}
})

export const contactListSuccess = (contact: any) => ({
    type: LIST_CONTACT_SUCCESS,
    payload: contact
})

export const contactListError = (message: any) => ({
    type: LIST_CONTACT_ERROR,
    payload: message
})

export const contactSet = (proyecto: any) => ({
    type: SET_CONTACT,
    payload: {proyecto}
})

export const contactUpdate = (proyecto: any) => ({
    type: UPDATE_CONTACT,
    payload: {proyecto}
})

export const contactDelete = (proyecto: any) => ({
    type: DELETE_CONTACT,
    payload: {proyecto}
})