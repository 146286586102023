import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import Navbar from "../navbar";
import Sidebar from "../sidebar";
//import HeaderProperties from "./header.component";
//import TableProperties from "./table.component";
import DeleteComponent from '../generals/delete.component';
import GalleryModal from '../galleryModal';

import TableNew from './tableNew.component'

import GalleryModalProperties from "../galleryModalProperties";

import dashBackground from "./../../UI/backgrounds/BG_PUBLIC.webp";

import { Backdrop, CircularProgress } from "@mui/material";

import {
  logoutUser,
  inmueblesList,
  inmueblesSet,
  inmueblesUpdate,
  inmueblesCopy,
  inmueblesDelete,
  inmueblesGalleries,
  galleriesOpen,
} from "../../redux/actions";


/*eslint-disable*/

interface PropertiesViews {
  tableRef: any
}
interface Props {
  logoutUser: any;
  inmueblesList: any;
  inmueblesSet: any;
  inmueblesUpdate: any;
  inmueblesCopy: any;
  inmueblesDelete: any;
  inmueblesGalleries: any,
  galleriesOpen: any;
  loading: boolean;
  projectSelected: any;
  params: any;
  properties: any;
  attributes: any;
  modalGalleries: any;
}

interface State {
  userString: any;
  user: any;
  idProject: any;
  openCreateNewInmueble: boolean;
  inmueblesSelectionArray: any;
  showDelete: boolean;
  selectedInmueble: any,
  currentAmenitieGallerie: any,
  isGalleryPropertiesModalOpened: boolean
}

function withParams(Component: any) {
  return (props: any) => <PropertiesViews {...props} params={useParams()} />;
}

class PropertiesViews extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userString: localStorage.getItem("user"),
      user: "",
      idProject: this.props.params.id,
      openCreateNewInmueble: false,
      inmueblesSelectionArray: [],
      showDelete: false,
      selectedInmueble: "",
      currentAmenitieGallerie: [],
      isGalleryPropertiesModalOpened: false
    };

    this.tableRef = React.createRef()
  }

  componentDidMount(): void {
    this.setState(
      {
        user: JSON.parse(this.state.userString),
      },
      () => {
        this.props.inmueblesList({
          user: this.state.user.id,
          project: this.state.idProject,
        });
      }
    );
  }

  handleCreateNewInmueble = () => {

    this.setState({
      openCreateNewInmueble: true,
    });
  };

  handleSaveNewInmueble = (name: string, selectedAttributes: any) => {
    //SAVE NEW INMUEBLE
    this.props.inmueblesSet({
        user: this.state.user.id,
        project: this.state.idProject,
        number: name,
        values: selectedAttributes
    })
    this.setState({
        openCreateNewInmueble: false
    })
    
  }

  handleUpdateSelectionArray = (payload: any) => {
    this.setState({
      inmueblesSelectionArray: payload,
    });
  };

  handleDeleteInmuebles = () => {
    this.setState({
      showDelete: !this.state.showDelete,
    })
  };

  handleConfirmDelete = () => {
    this.props.inmueblesDelete({
      user: this.state.user.id,
      project: this.state.idProject,
      properties: this.state.inmueblesSelectionArray,
    });
    this.setState({
        inmueblesSelectionArray: []
    })
    this.handleDeleteInmuebles()
}

  handleDuplicateInmuebles = () => {
    this.props.inmueblesCopy({
      user: this.state.user.id,
      project: this.state.idProject,
      properties: this.state.inmueblesSelectionArray.slice(0, Math.min(9,this.state.inmueblesSelectionArray.length)),
    });
    this.setState({
        inmueblesSelectionArray: []
    })
  };

  handleUpdateInmuebleNum = (num: string, id: string) => {
    this.props.inmueblesUpdate({
      user: this.state.user.id,
      project: this.state.idProject,
      id: id,
      numero: num
    })
  }

  handleUpdateAttValue = (value: string, attr: string, id: string, inputValue: string) => {
  
    this.props.inmueblesUpdate({
      user: this.state.user.id,
      project: this.state.idProject,
      id: id,
      attribute: attr,
      value: value,
      inputValue: inputValue
    })
  }

  handleGetInmuebleId = (id: any, selectedGallery: any) => {
    this.setState({
        selectedInmueble: id,
        currentAmenitieGallerie: selectedGallery
    })
  }

  handleSetImages = () => {
    //let stringsToSend: any = []
    //let user: any = localStorage.getItem("user");
    //HERE WE SEND THE IMAGES TO INMUBLES
    //inmueblesGalleries
    let stringsToSend: any = []
        let user: any = localStorage.getItem("user");
        this.props.modalGalleries.map((gallery: any) => {
            if(gallery.gallery){
              stringsToSend.push(gallery.gallery)
            }
            else {
              stringsToSend.push(gallery.id)
            }
        })
        let objectToSend = {
            user: JSON.parse(user).id, 
            project: this.state.idProject, 
            property: this.state.selectedInmueble,
            galleries: stringsToSend
        }
        this.props.inmueblesGalleries(objectToSend)
}

handleUpdateAttributes = (selectedProperties: any, selectedAttribute: any, selectedAttributeValue: any) => {

  this.props.inmueblesCopy({
    type: "UPDATE ATTRIBUTE",
    user: this.state.user.id,
    project: this.state.idProject,
    properties: selectedProperties,
    selectedAttribute: selectedAttribute,
    selectedAttributeValue: selectedAttributeValue
  })
}

handleGenerateCopies = (selectedProperties: any, numberOfCopies: number) => {

  this.props.inmueblesCopy({
    type: "COPY",
    user: this.state.user.id,
    project: this.state.idProject,
    properties: selectedProperties,
    numberOfCopies: numberOfCopies
  })
}

handleDuplicateAndEditProperties = (selectedProperties: any, selectedAttribute: any, selectedAttributeValue: any) => {

  this.props.inmueblesCopy({
    type: "DUPLICATE",
    user: this.state.user.id,
    project: this.state.idProject,
    properties: selectedProperties,
    selectedAttribute: selectedAttribute,
    selectedAttributeValue: selectedAttributeValue
  })
}

handleOpenGalleryPropertiesModal = () => {
  this.setState({
    isGalleryPropertiesModalOpened: !this.state.isGalleryPropertiesModalOpened
  })
}

  render(): React.ReactNode {
    return (
      <div className="properties-views-main">
        <img className="dashboard-background" src={dashBackground} alt="" />
        <Sidebar project={this.props.projectSelected} />
        {
            <TableNew
              attributes={this.props.attributes}
              inmuebles={this.props.properties}
              handleGetInmuebleId = {this.handleGetInmuebleId}
              galleriesOpen = {this.props.galleriesOpen}
              handleUpdateInmuebleNum = {this.handleUpdateInmuebleNum}
              handleUpdateAttValue = {this.handleUpdateAttValue}
              handleSaveNewInmueble = {this.handleSaveNewInmueble}
              handleUpdateSelectionArray = {this.handleUpdateSelectionArray}
              handleDeleteInmuebles = {this.handleDeleteInmuebles}
              handleUpdateAttributes = {this.handleUpdateAttributes}
              handleGenerateCopies = {this.handleGenerateCopies}
              handleDuplicateAndEditProperties = {this.handleDuplicateAndEditProperties}
              handleOpenGalleryPropertiesModal = {this.handleOpenGalleryPropertiesModal}
            />
          }
        {/*<div className="properties-container">
          <HeaderProperties
            handleCreateNewInmueble={this.handleCreateNewInmueble}
            handleDeleteInmuebles={this.handleDeleteInmuebles}
            handleDuplicateInmuebles={this.handleDuplicateInmuebles}
          />
          {<TableProperties
            ref = {this.tableRef}
            inmueblesList={this.props.properties}
            selectionArray = {this.state.inmueblesSelectionArray}
            attributesList={this.props.attributes}
            createInmuebleOpened={this.state.openCreateNewInmueble}
            handleUpdateSelectionArray={this.handleUpdateSelectionArray}
            handleSaveNewInmueble = {this.handleSaveNewInmueble}
            handleUpdateInmuebleNum = {this.handleUpdateInmuebleNum}
            handleUpdateAttValue = {this.handleUpdateAttValue}
            handleGetInmuebleId = {this.handleGetInmuebleId}
            galleriesOpen = {this.props.galleriesOpen}
            inmueblesSelectionArray = {this.state.inmueblesSelectionArray}
          />}
          
        </div>*/}
        <DeleteComponent
            show={this.state.showDelete}
            handleHide = {this.handleDeleteInmuebles}
            handleConfirmDelete = {this.handleConfirmDelete}
        />
        <GalleryModal
            handleSetImages = {this.handleSetImages}
            selectedGallery = {this.state.currentAmenitieGallerie}
        />
        <GalleryModalProperties
          isGalleryPropertiesModalOpened = {this.state.isGalleryPropertiesModalOpened}
          handleOpenGalleryPropertiesModal = {this.handleOpenGalleryPropertiesModal}
        />
        <Navbar logout={this.props.logoutUser} />
        <Backdrop sx={{ color: "#fff" }} open={this.props.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

const mapStateToProps = ({
  projectReducer,
  builderReducer,
  moduleReducer,
  galleriesReducer,
  amenitiesReducer,
  propertiesReducer,
  attributeReducer,
}: any) => {
  const { galleries, modalGalleries } = galleriesReducer;
  const { amenities, loading } = amenitiesReducer;
  const { modules } = moduleReducer;
  const { attributes } = attributeReducer;
  const { projectSelected } = projectReducer;
  const { selectedBuilder } = builderReducer;
  const { properties } = propertiesReducer;
  return {
    attributes,
    modules,
    projectSelected,
    loading,
    selectedBuilder,
    galleries,
    modalGalleries,
    amenities,
    properties,
  };
};

export default connect(mapStateToProps, {
  logoutUser,
  inmueblesList,
  inmueblesSet,
  inmueblesUpdate,
  inmueblesCopy,
  inmueblesDelete,
  inmueblesGalleries,
  galleriesOpen,
})(withParams(PropertiesViews));
