import axios from "axios";
import { ApiConfig } from "../constants/defaultValues";
import { borrarCacheImagenes } from "../helpers/clearCache";

export async function list(proyecto: any) {
  return axios.post(ApiConfig.URL2 + "locations/get", proyecto);
}
export async function listOne(proyecto: any) {
  return axios.post(ApiConfig.URL2 + "locations/one", proyecto);
}
export async function update(proyecto: any) {
  if (proyecto.type === undefined) {
    return axios.post(ApiConfig.URL2 + "locations/update", proyecto);
  }else{
    let result = await axios.post(ApiConfig.URL2 + "locations/update", proyecto.data);
    if (result.status === 200) {
      let resultFormData = await axios.post(`${ApiConfig.URL3}coord/`, proyecto.assets, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        if (resultFormData.status === 200) {
          borrarCacheImagenes();
          return result;
        }
    }
  }
}
export async function delimitation(proyecto: any) {
  return axios.post(ApiConfig.URL2 + "locations/delimitation", proyecto);
}
export async function setRoadsAccess(proyecto: any) {
  return axios.post(ApiConfig.URL2 + "locations/roads/set", proyecto);
}
export async function updateRoadsAccess(proyecto: any) {
  return axios.post(ApiConfig.URL2 + "locations/roads/update", proyecto);
}
export async function deleteRoadsAccess(proyecto: any) {
  return axios.post(ApiConfig.URL2 + "locations/roads/delete", proyecto);
}
export async function setPoisCategory(proyecto: any) {
  return axios.post(ApiConfig.URL2 + "locations/benefits/categories/set", proyecto);
}
export async function updatePoisCategory(proyecto: any) {
  if (proyecto.type === undefined) {
    return axios.post(ApiConfig.URL2 + "locations/benefits/categories/update", proyecto);
  }else{
    let result = await axios.post(ApiConfig.URL2 + "locations/benefits/categories/update", proyecto.data);
    if (result.status === 200) {
      let resultFormData = await axios.post(`${ApiConfig.URL3}coord/`, proyecto.assets, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        if (resultFormData.status === 200) {
          borrarCacheImagenes();
          return result;
        }
    }
  }
}
export async function setPois(proyecto: any) {
  return axios.post(ApiConfig.URL2 + "locations/benefits/set", proyecto);
}
export async function deletePois(proyecto: any) {
  return axios.post(ApiConfig.URL2 + "locations/benefits/delete", proyecto);
}

