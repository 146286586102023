import { all, call, delay, fork, put, takeEvery } from "redux-saga/effects";
import {
  list as projectListModel,
  listOne as projectListOneModel,
  create as projectCreateModel,
  update as projectUpdateModel,
  updateInside as projectUpdateInsideModel,
  eliminar as projectDeleteModel,
  one as projectOneModel,
  updateBaseModules as projectAddBaseModulesModel,
  updateTemplate,
  downloadTemplate,
  addLogo,
} from "../../models/projects";

import {
  LIST_PROJECT,
  LIST_PROJECT_ONE,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  ONE_PROJECT,
  ADD_BASE_MODULES_PROJECT,
  DELETE_PROJECT,
  UPDATE_INSIDE_PROJECT,
  UPDATE_TEMPLATE_PROJECT,
  DOWNLOAD_TEMPLATE_PROJECT,
  ADD_LOGO_ADICIONAL_PROJECT,
} from "../actions";

import {
  projectListSuccess,
  projectListSuccess2,
  projectListError,
  projectSelectedSuccess,
  projectSelectedError,
  projectUpdateTemplateSucces,
} from "./actions";
import { builderSelectedSuccess } from "../builder/actions";
import { showAlert } from "../general/actions";

/*eslint-disable*/

interface projectResponse {
  projectItf: any;
}

export function* watchProjectList() {
  yield takeEvery(LIST_PROJECT, projectList);
}

export function* watchProjectListOne() {
  yield takeEvery(LIST_PROJECT_ONE, projectListOne);
}

export function* watchProjectOne() {
  yield takeEvery(ONE_PROJECT, projectOneSaga);
}

export function* watchProjectCreate() {
  yield takeEvery(CREATE_PROJECT, projectCreate);
}

export function* watchProjectUpdate() {
  yield takeEvery(UPDATE_PROJECT, projectUpdate);
}

export function* watchProjectUpdateInside() {
  yield takeEvery(UPDATE_INSIDE_PROJECT, projectUpdateInside);
}

export function* watchProjectUpdateTemplate() {
  yield takeEvery(UPDATE_TEMPLATE_PROJECT, projectUpdateTemplate);
}

export function* watchProjectDownloadTemplateOffline() {
  yield takeEvery(DOWNLOAD_TEMPLATE_PROJECT, projectDownloadTemplateOffline);
}

export function* watchProjectDelete() {
  yield takeEvery(DELETE_PROJECT, projectDelete);
}

export function* watchProjectAddBaseModules() {
  yield takeEvery(ADD_BASE_MODULES_PROJECT, projectAddBaseModules);
}

export function* watchProjectAddLogoAdicional() {
  yield takeEvery(ADD_LOGO_ADICIONAL_PROJECT, projectAddLogoAdicional);
}

const projectListAsync = async (project: any): Promise<projectResponse> =>
  await projectListModel(project)
    .then((projectItf) => projectItf)
    .catch((error) => error);

function* projectList({ payload }: any): any {
  const { project } = payload;
  try {
    const projectListData = yield call(projectListAsync, project);
    if (projectListData.status === 200) {
      yield put(projectListSuccess(projectListData.data.data));
      yield put(builderSelectedSuccess(projectListData.data.builder));
    } else {
      yield put(projectListError(projectListData));
      yield put(builderSelectedSuccess(projectListData));
    }
  } catch (error) {
    yield put(projectListError(error));
    yield put(builderSelectedSuccess(error));
  }
}

const projectListOneAsync = async (
  builder: any,
  pageSize: any,
  currentPage: any,
  orderBy: any,
  search: any,
  user: any
): Promise<projectResponse> =>
  await projectListOneModel(
    builder,
    pageSize,
    currentPage,
    orderBy,
    search,
    user
  )
    .then((project) => project)
    .catch((error) => error);

function* projectListOne({ payload }: any): any {
  const { builder, pageSize, currentPage, orderBy, search, user } = payload;

  try {
    const projectListOneData = yield call(
      projectListOneAsync,
      builder,
      pageSize,
      currentPage,
      orderBy,
      search,
      user
    );
    if (projectListOneData.status === 200) {
      yield put(projectListSuccess(projectListOneData));
    } else {
      yield put(projectListError(projectListOneData));
    }
  } catch (error) {
    yield put(projectListError(error));
  }
}

const projectOneAsync = async (project: any): Promise<projectResponse> =>
  await projectOneModel(project)
    .then((project) => project)
    .catch((error) => error);

function* projectOneSaga({ payload }: any): any {
  try {
    const projectListOneData = yield call(projectOneAsync, payload.project);
    if (projectListOneData.status === 200) {
      yield put(projectSelectedSuccess(projectListOneData.data.data));
    } else {
      yield put(projectSelectedError(projectListOneData.statusText));
    }
  } catch (error) {
    yield put(projectSelectedError(error));
  }
}

const projectCreateAsync = async (project: any): Promise<projectResponse> =>
  await projectCreateModel(project)
    .then((project) => project)
    .catch((error) => error);

function* projectCreate({ payload }: any): any {
  const { project, history } = payload;
  try {
    const projectCreateData = yield call(projectCreateAsync, project);
    if (projectCreateData.status === 200) {
      yield put(projectListSuccess(projectCreateData.data.data));
      yield put(builderSelectedSuccess(projectCreateData.data.builder));
      yield put(
        showAlert({
          message: "Proyecto creado con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Proyecto creado con exito.",
          type: "success",
          show: false,
        })
      );
    } else {
      yield put(projectListError(projectCreateData));
      yield put(builderSelectedSuccess(projectCreateData));
      yield put(
        showAlert({
          message: "Error al crear el proyecto",
          type: "danger",
          show: true,
        })
      );
    }
  } catch (error) {
    yield put(projectListError(error));
    yield put(builderSelectedSuccess(error));
    yield put(
      showAlert({
        message: "Error al crear el proyecto",
        type: "danger",
        show: true,
      })
    );
  }
}

const projectUpdateAsync = async (
  project: any,
  id: any
): Promise<projectResponse> =>
  await projectUpdateModel(project, id)
    .then((project) => project)
    .catch((error) => error);

const projectUpdateInsideAsync = async (
  project: any
): Promise<projectResponse> =>
  await projectUpdateInsideModel(project)
    .then((project) => project)
    .catch((error) => error);

function* projectUpdate({ payload }: any): any {
  const { project, id, history } = payload;
  try {
    const projectCreateData = yield call(projectUpdateAsync, project, id);
    if (projectCreateData.status === 200) {
      yield put(projectListSuccess(projectCreateData.data.data));
      yield put(builderSelectedSuccess(projectCreateData.data.builder));
      yield put(projectSelectedSuccess(projectCreateData.data.project));
      yield put(
        showAlert({
          message: "Proyecto actualizado con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Proyecto actualizado con exito.",
          type: "success",
          show: false,
        })
      );
    } else {
      yield put(projectListError(projectCreateData));
      yield put(builderSelectedSuccess(projectCreateData));
      yield put(projectSelectedSuccess(projectCreateData));
      yield put(
        showAlert({
          message: "Error al actualizar el proyecto",
          type: "danger",
          show: true,
        })
      );
    }
  } catch (error) {
    yield put(projectListError(error));
    yield put(builderSelectedSuccess(error));
    yield put(projectSelectedSuccess(error));
    yield put(
      showAlert({
        message: "Error al actualizar el proyecto",
        type: "danger",
        show: true,
      })
    );
  }
}

function* projectUpdateInside({ payload }: any): any {
  const { project } = payload;
  try {
    const projectCreateData = yield call(projectUpdateInsideAsync, project);
    if (projectCreateData.status === 200) {
      yield put(projectListSuccess(projectCreateData.data.data));
      yield put(builderSelectedSuccess(projectCreateData.data.builder));
      yield put(projectSelectedSuccess(projectCreateData.data.project));
      yield put(
        showAlert({
          message: "Proyecto actualizado con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Proyecto actualizado con exito.",
          type: "success",
          show: false,
        })
      );
    } else {
      yield put(projectListError(projectCreateData));
      yield put(builderSelectedSuccess(projectCreateData));
      yield put(projectSelectedSuccess(projectCreateData));
      yield put(
        showAlert({
          message: "Error al actualizar el proyecto",
          type: "danger",
          show: true,
        })
      );
    }
  } catch (error) {
    yield put(projectListError(error));
    yield put(builderSelectedSuccess(error));
    yield put(projectSelectedSuccess(error));
    yield put(
      showAlert({
        message: "Error al actualizar el proyecto",
        type: "danger",
        show: true,
      })
    );
  }
}

const projectUpdateTemplateAsync = async (
  project: any
): Promise<projectResponse> =>
  await updateTemplate(project)
    .then((project) => project)
    .catch((error) => error);

function* projectUpdateTemplate({ payload }: any): any {
  const { project } = payload;
  try {
    const projectCreateData = yield call(projectUpdateTemplateAsync, project);
    if (projectCreateData.status === 200) {
      yield put(projectUpdateTemplateSucces());
      yield put(
        showAlert({
          message: "Template actualizado con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Template actualizado con exito.",
          type: "success",
          show: false,
        })
      );
    }
  } catch (error) {
    yield put(
      showAlert({
        message: "Error al actualizar el template",
        type: "danger",
        show: true,
      })
    );
  }
}

const projectDownloadTemplateOfflineAsync = async (
  project: any
): Promise<projectResponse> =>
  await downloadTemplate(project)
    .then((project) => project)
    .catch((error) => error);

function* projectDownloadTemplateOffline({ payload }: any): any {
  const { project } = payload;
  try {
    
    const projectCreateData = yield call(projectDownloadTemplateOfflineAsync, project);
    if (projectCreateData.status === 200) {
      localStorage.setItem("create_offline", "creando")
      localStorage.setItem("project_offline", project.project)
      yield put(projectUpdateTemplateSucces());
      yield put(
        showAlert({
          message: "Creando Maqueta Offline con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Creando Maqueta Offline con exito.",
          type: "success",
          show: false,
        })
      );
    }
  } catch (error) {
    localStorage.setItem("create_offline", "error")
    yield put(
      showAlert({
        message: "Error al crear Maqueta Offline",
        type: "danger",
        show: true,
      })
    );
  }
}

const projectDeleteAsync = async (
  builder: any,
  id: any
): Promise<projectResponse> =>
  await projectDeleteModel(builder, id)
    .then((project) => project)
    .catch((error) => error);

function* projectDelete({ payload }: any): any {
  const { builder, id } = payload;
  try {
    const projectCreateData = yield call(projectDeleteAsync, builder, id);
    if (projectCreateData.status === 200) {
      yield put(projectListSuccess2(projectCreateData));
      yield put(
        showAlert({
          message: "Proyecto eliminado con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Proyecto eliminado con exito.",
          type: "success",
          show: false,
        })
      );
    } else {
      yield put(projectListError(projectCreateData));
      yield put(
        showAlert({
          message: "Error al eliminar el proyecto",
          type: "danger",
          show: true,
        })
      );
    }
  } catch (error) {
    yield put(projectListError(error));
    yield put(
      showAlert({
        message: "Error al eliminar el proyecto",
        type: "danger",
        show: true,
      })
    );
  }
}

const projectAddBaseModulesAsync = async (
  baseModules: any,
  id: any
): Promise<projectResponse> =>
  await projectAddBaseModulesModel(baseModules, id)
    .then((project) => project)
    .catch((error) => error);

function* projectAddBaseModules({ payload }: any): any {
  const { baseModules, id } = payload;
  try {
    const projectCreateData = yield call(
      projectAddBaseModulesAsync,
      baseModules,
      id
    );
    if (projectCreateData.status === 200) {
      yield put(projectSelectedSuccess(projectCreateData.data.data));
      yield put(
        showAlert({
          message: "Modulo actualizado con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Modulo actualizado con exito.",
          type: "success",
          show: false,
        })
      );
    } else {
      yield put(projectSelectedError(projectCreateData));
      yield put(
        showAlert({
          message: "Error al actualizar el modulo",
          type: "danger",
          show: true,
        })
      );
    }
  } catch (error) {
    yield put(projectSelectedError(error));
    yield put(
      showAlert({
        message: "Error al actualizar el modulo",
        type: "danger",
        show: true,
      })
    );
  }
}

const projectAddLogoAdicionalAsync = async (
  project: any
): Promise<projectResponse> =>
  await addLogo(project)
    .then((project) => project)
    .catch((error) => error);

function* projectAddLogoAdicional({ payload }: any): any {
  const { project } = payload;
  try {
    const projectCreateData = yield call(projectAddLogoAdicionalAsync, project);
    if (projectCreateData.status === 200) {
      yield put(projectSelectedSuccess(projectCreateData.data.project));
      yield put(
        showAlert({
          message: "Logo adicional agregado con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Logo adicional agregado con exito.",
          type: "success",
          show: false,
        })
      );
    } else {
      yield put(projectSelectedError(projectCreateData));
      yield put(
        showAlert({
          message: "Error al agregar el logo adicional",
          type: "danger",
          show: true,
        })
      );
    }
  } catch (error) {
    yield put(projectSelectedError(error));
    yield put(
      showAlert({
        message: "Error al agregar el logo adicional",
        type: "danger",
        show: true,
      })
    );
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchProjectList),
    fork(watchProjectListOne),
    fork(watchProjectOne),
    fork(watchProjectCreate),
    fork(watchProjectUpdate),
    fork(watchProjectDelete),
    fork(watchProjectAddBaseModules),
    fork(watchProjectAddLogoAdicional),
    fork(watchProjectUpdateInside),
    fork(watchProjectUpdateTemplate),
    fork(watchProjectDownloadTemplateOffline),
  ]);
}
