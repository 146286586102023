import axios from "axios";
import { ApiConfig } from "../constants/defaultValues";

export async function list(e: any) {
  return axios.post(ApiConfig.URL2 + "properties/attributes/get", e);
}
export async function create(e: any) {
  let result = await axios.post(
    ApiConfig.URL2 + "properties/attributes/set",
    e
  );
  let index = 0;
  if (result.status === 200 && result.data.statusCode === 200) {
    console.log(result.data);
    
    for await (let value of e.values) {
      let res = await axios.post(
        ApiConfig.URL2 + "properties/attributes/value",
        {
          project: e.project,
          attribute: result.data.attribute,
          value: value.value,
          layoutColor: value.layoutColor,
          billboardFillColor: value.billboardFillColor,
          billboardStrokeColor: value.billboardStrokeColor,
        }
      );
      if (res.status === 200) {
        index++;
      }
    }

    if (index === e.values.length) {
      return axios.post(ApiConfig.URL2 + "properties/attributes/get", {
        project: e.project,
        user: e.user,
      });
    }
  }
}
export async function update(e: any) {
  let result: any = await axios.post(ApiConfig.URL2 + "properties/attributes/update", e);
  if (result.data.statusCode === 200) {
    return axios.post(ApiConfig.URL2 + "properties/attributes/get", {
      project: e.project,
      user: e.user,
    });
  }
}
export async function hierarchicalUpdate(e: any) {
  let result: any = await axios.post(ApiConfig.URL2 + "properties/attributes/update/hierarchical", e);

  
  if (result.status === 200) {

    return axios.post(ApiConfig.URL2 + "properties/attributes/get", {
      project: e.project,
      user: e.user,
    });
  }
}
export async function remove(e: any) {
  return axios.post(ApiConfig.URL2 + "properties/attributes/delete", e);
}
export async function valuesUpdate(e: any) {
  return axios.post(ApiConfig.URL2 + "properties/attributes/value-update", e);
}
export async function setAttributesGalleries(e: any) {
  return axios.post(ApiConfig.URL2 + "properties/attributes/galleries", e);
}

