import {
    UPDATE_ALERT,
    CLOSE_ALERT
} from './../actions'

export const showAlert = (proyecto: any) => ({
    type: UPDATE_ALERT,
    payload: proyecto
})

export const closeAlert = (state: any) => ({
    type: CLOSE_ALERT,
    payload: false
})