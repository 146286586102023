interface DeleteComponentInterface {
    show: boolean,
    handleHide: any
    handleConfirmDelete: any
}

const DeleteComponent = ({show, handleHide, handleConfirmDelete}: DeleteComponentInterface) => {
    return(
        <div className={show ? 'delete-screen-container fade-in':'delete-screen-container fade-out'}>
            <div className='delete-screen'>
                <div className='delete-icon'>
                    <div className="f-modal-icon f-modal-warning scaleWarning">
                        <span className="f-modal-body pulseWarningIns"></span>
                        <span className="f-modal-dot pulseWarningIns"></span>
                    </div>
                </div>
                <div className='delete-title'>
                    ¿Estás seguro?
                </div>
                <div className='delete-subtitle'>
                    Este cambio no puede ser revertido
                </div>
                <div className='accept-btn' onClick={() => {handleConfirmDelete()}}>
                    ¡Sí, Borrar!
                </div>
                <div className='cancel-btn' onClick={() => {handleHide()}}>
                    Cancelar
                </div>
            </div>
        </div>
    )
}

export default DeleteComponent