import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";

import Navbar from "../navbar";
import Sidebar from "../sidebar";
import IconSelect from "./iconSelect.component";
import HeaderComponent from "./header.component";
import CreatePOI from "./create.component";
import ListPOIS from "./list.component";

import dashBackground from "./../../UI/backgrounds/BG_PUBLIC.webp";

import {
  logoutUser,
  locationsList,
  locationSetPoisCategory,
  locationUpdatePoisCategory,
  locationsUpdate,
} from "../../redux/actions";

/*eslint-disable*/

interface Props {
  logoutUser: any;
  loading: any;
  modules: any;
  projectSelected: any;
  params: any;
  locations: any;
  POis: any;
  categories_pois: any;
  locationsList: any;
  locationSetPoisCategory: any;
  locationUpdatePoisCategory: any;
  locationsUpdate: any;
}

interface State {
  userString: any;
  user: any;
  idProject: any;
  logo_ubicacion: any;
}

function withParams(Component: any) {
  return (props: any) => <POISViews {...props} params={useParams()} />;
}

class POISViews extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userString: localStorage.getItem("user"),
      user: "",
      idProject: this.props.params.id,
      logo_ubicacion: "",
    };
  }

  componentDidMount(): void {
    if (this.state.userString) {
      this.setState(
        {
          user: JSON.parse(this.state.userString),
        },
        () => {
          this.props.locationsList({
            user: this.state.user.id,
            project: this.state.idProject,
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps: any, prevState: any): void {
    if (prevProps.modules !== this.props.modules) {
      this.props.modules.forEach((module: any) => {
        if (module.name === "Puntos de Interes") {
          this.setState({
            logo_ubicacion: module.logo,
          });
        }
      });
    }
  }

  handleCreateCategory = (name: any) => {
    var object = {
      project: this.state.idProject,
      user: JSON.parse(this.state.userString).id,
      category: name,
    };

    this.props.locationSetPoisCategory(object);
  };

  handleUpdateCheckState = (state: number, id: string) => {
    this.props.locationUpdatePoisCategory({
      project: this.state.idProject,
      user: JSON.parse(this.state.userString).id,
      state: state,
      id: id,
    });
  };

  handleUpdateListOrder = (list: any) => {
    this.props.locationUpdatePoisCategory({
      project: this.state.idProject,
      user: JSON.parse(this.state.userString).id,
      list: list,
    });
  };

  handleUploadMapImage = (payload: any) => {
    var file = payload;
    if (file.size / 1024 < 3200) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        const img: any = new Image();
        if (reader.result) {
          img.src = reader.result;
          img.addEventListener("load", () => {
            var width = img.width;
            var height = img.height;
            if (height === 3000 && width === 3000) {
              let InstFormData = new FormData();
              InstFormData.append("myFile", file);
              InstFormData.append(
                "name",
                `galleries/${this.props.projectSelected.builder}/${this.state.idProject}/mapa_ilustrado/${file.name}`
              );
              this.props.locationsUpdate({
                data: {
                  user: JSON.parse(this.state.userString).id,
                  project: this.state.idProject,
                  mapa_ilustrado_pois: `galleries/${this.props.projectSelected.builder}/${this.state.idProject}/mapa_ilustrado/${file.name}`,
                },
                assets: InstFormData,
                type: "mapa_ilustrado",
              });
            } else {
              alert(
                "La imagen tiene dimensiones incorrectas debe ser 3000 x 3000 y esta es de " +
                  width +
                  " x " +
                  height +
                  " ¿qué paso ahí papu?"
              );
            }
          });
        }
      });
    } else {
      alert("La imagen pesa mas de 3MB, ¿qué paso ahí papu?");
    }
  };

  render(): React.ReactNode {
    return (
      <div className="pois-container">
        <img className="dashboard-background" src={dashBackground} alt="" />
        <Sidebar project={this.props.projectSelected} />
        <div className="pois-container-main">
          <HeaderComponent />
          <ListPOIS
            categories={this.props.categories_pois}
            handleUpdateCheckState={this.handleUpdateCheckState}
            handleUpdateListOrder={this.handleUpdateListOrder}
          />
        </div>
        <IconSelect
          logo_ubicacion={this.state.logo_ubicacion}
          handleUploadMapImage={this.handleUploadMapImage}
        />
        <CreatePOI handleCreateCategory={this.handleCreateCategory} />
        <Navbar logout={this.props.logoutUser} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

const mapStateToProps = ({
  projectReducer,
  builderReducer,
  locationsReducer,
  moduleReducer,
}: any) => {
  const { locations, POis, categories_pois, loading } = locationsReducer;
  const { modules } = moduleReducer;
  const { projectSelected } = projectReducer;
  const { selectedBuilder } = builderReducer;
  return {
    locations,
    modules,
    POis,
    categories_pois,
    loading,
    projectSelected,
    selectedBuilder,
  };
};

export default connect(mapStateToProps, {
  logoutUser,
  locationsList,
  locationSetPoisCategory,
  locationUpdatePoisCategory,
  locationsUpdate,
})(withParams(POISViews));
