import {all} from 'redux-saga/effects'

import authSagas from './auth/saga'
import userSagas from './user/saga';
import builderSagas from './builder/saga';
import projectSagas from './project/saga';
import moduleSagas from './module/saga';
import paletteChromaticSaga from './palettechromatic/saga';
import attributeSagas from './attribute/saga';
import sidebarSagas from './sidebar/saga';
import galleriesSagas from './galleries/saga';
import amenitiesSagas from './amenities/saga';
import locationsSagas from './location/saga';
import iconsSagas from './icons/saga'
import propertiesSaga from './properties/saga'
import mailingSaga from './mailing/saga'
import solarSaga from './solar/saga'
import spinSaga from './spin/saga'
import contactSaga from './contact/saga'

function * rootSaga(): any {
    yield all([
        authSagas(),
        userSagas(),
        builderSagas(),
        projectSagas(),
        moduleSagas(),
        paletteChromaticSaga(),
        moduleSagas(),
        attributeSagas(),
        sidebarSagas(),
        galleriesSagas(),
        amenitiesSagas(),
        locationsSagas(),
        iconsSagas(),
        propertiesSaga(),
        mailingSaga(),
        solarSaga(),
        spinSaga(),
        contactSaga(),
    ])
}

export default rootSaga