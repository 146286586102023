import {
    LIST_AMENITIES,
    LIST_AMENITIES_SUCCESS,
    LIST_AMENITIES_ERROR,
    CREATE_AMENITIES,
    UPDATE_AMENITIES,
    DELETE_AMENITIES,
    SET_GALLERIES_AMENITIES
  } from "../actions";

/*eslint-disable*/

export const amenidadesList = (
    proyecto: any,
) => ({
    type: LIST_AMENITIES,
    payload: {proyecto}
})

export const amenidadesListSuccess = (amenidad: any) => ({
    type: LIST_AMENITIES_SUCCESS,
    payload: amenidad
})

export const amenidadesListError = (message: any) => ({
    type: LIST_AMENITIES_ERROR,
    payload: {message}
})

export const amenidadesCreate = (amenidad: any) => ({
    type: CREATE_AMENITIES,
    payload: {amenidad}
})

export const amenidadesUpdate = (amenidad: any) => ({
    type: UPDATE_AMENITIES,
    payload: {amenidad}
})

export const amenidadesDelete = (amenidad: any) => ({
    type: DELETE_AMENITIES,
    payload: {amenidad}
})

export const amenidadesSetGallery = (amenidad: any) => ({
    type: SET_GALLERIES_AMENITIES,
    payload: {amenidad}
})
