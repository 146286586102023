import React, {useEffect, useState} from 'react'

import fullscreen from './../../UI/general/fullscreen.svg'

const ShowGalleriesImages = ({galleries, handleOpenFullScreen, handleUpdateImagesArray, isGalleries,modalGalleries}: any) => {

    const [tempArraySelected, setTempArraySelected]: any = useState(modalGalleries && modalGalleries.length > 0 ? modalGalleries:[]) 

    const handleSetTempArray = (payload: object) => {
        if(tempArraySelected && tempArraySelected.includes(payload)){
            tempArraySelected.splice(tempArraySelected.indexOf(payload, 1))
        }
        else{
            setTempArraySelected([...tempArraySelected, payload])
        }
    } 

    useEffect(() => {
        handleUpdateImagesArray(tempArraySelected)
    })

    useEffect(() => {
        if(!isGalleries){
            setTempArraySelected([])
        }
    }, [isGalleries])

    return(
        <div className='gallery-preview-main-modal'>
            {
                galleries.map((image: any, index: any) => 
                    image.type === 'card' ?
                    <div className='gallery-image-card-modal' key={image.name + index} >
                        <div className='gallery-image-container-modal'>
                            <div className='fullscreen-image-contaienr-modal' onClick={() => {handleOpenFullScreen(true, image.link)}}>
                            <img width={100} src={fullscreen} alt="galleries" />
                            </div>
                            <img width={100} src={`https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${image.link}`} alt="galleries" onClick={() => {handleSetTempArray(image)}}/>
                        </div>
                        <div className='gallery-image-name-modal' onClick={() => {handleSetTempArray(image)}}>{image.name}</div>
                    </div>:null
                )
            }
        </div>
    )
}

export default ShowGalleriesImages