import {
    LIST_SOLAR,
    LIST_SOLAR_SUCCESS,
    LIST_SOLAR_ERROR,
    SET_SOLAR,
    UPDATE_SOLAR,
    DELETE_SOLAR,
    TXT_SOLAR,
} from './../actions'

export const solarList = (proyecto: any) => ({
    type: LIST_SOLAR,
    payload: {proyecto}
})

export const solarListSuccess = (solar: any) => ({
    type: LIST_SOLAR_SUCCESS,
    payload: solar
})

export const solarListError = (message: any) => ({
    type: LIST_SOLAR_ERROR,
    payload: message
})

export const solarSet = (proyecto: any) => ({
    type: SET_SOLAR,
    payload: {proyecto}
})

export const solarUpdate = (proyecto: any) => ({
    type: UPDATE_SOLAR,
    payload: {proyecto}
})

export const solarDelete = (proyecto: any) => ({
    type: DELETE_SOLAR,
    payload: {proyecto}
})

export const solarTxt = (proyecto: any) => ({
    type: TXT_SOLAR,
    payload: {proyecto}
})