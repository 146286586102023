import React from 'react'

import close from './../../UI/general/Close_white.svg'

import DropzoneGallery from './dropzone.component'

const UploadGallery = ({handleOpenUpload, handleAddGallery, handleRemoveGallery, handleUpload, imagesArray}: any) => {
    return(
        <div className='upload-main-container'>
            <div className='upload-card-container'>
                <div className='upload-card-header'>
                    <div className='upload-header-title'>Subir imágenes</div>
                    <div className='upload-close-btn' onClick={() => {handleOpenUpload()}}>
                        <img src={close} alt=''/>
                    </div>
                </div>
                <DropzoneGallery
                    handleAddGallery={handleAddGallery}
                    handleRemoveGallery={handleRemoveGallery} 
                    imagesArray = {imagesArray}
                />
                <div className='upload-gallery-btn' onClick={() => {handleUpload()}}>
                    Subir
                </div>
            </div>
        </div>
    )
}

export default UploadGallery