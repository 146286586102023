import React, { Component } from "react";
import DropzoneComponent from "react-dropzone-component";
import "dropzone/dist/min/dropzone.min.css";
//var ReactDOMServer = require("react-dom/server");

var dropzoneComponentConfig = {
  postUrl: "https://httpbin.org/post",
};
var dropzoneConfig = {
  thumbnailHeight: 160,
  iconFiletypes: ['.webp'],
  maxFilesize: 2,
  uploadMultiple: true,
  headers: { "My-Awesome-Header": "header value" },
};
let Files = []
export default class DropzoneGallery extends Component {

  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidUpdate(){
    if(this.props.imagesArray.length === 0){
      Files = []
    }
  }
  
  eventHandlers = {
    addedfile: (file) => {
      Files.push(file)
      this.props.handleAddGallery(Files);
    },
    removedfile: (file) => {
      let FilesNew = []
      Files.forEach(element => {
        if (element.upload.uuid !== file.upload.uuid) {
          FilesNew.push(element)
        }
      });
      Files = FilesNew
      this.props.handleRemoveGallery(Files);
    },
  };

  render() {
    return (
      <DropzoneComponent
        config={dropzoneComponentConfig}
        eventHandlers={this.eventHandlers}
        djsConfig={dropzoneConfig}
      />
    );
  }
}
