import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  login as loginModel,
  twoFactorValidateModel,
  twoFactorRegisterModel,
} from "../../models/auth";
import AuthToken from "./../../helpers/authToken";

import {
  LOGIN_USER,
  LOGOUT_USER,
  LOGIN_TWO_FACTOR_REGISTER,
  LOGIN_TWO_FACTOR_VALIDATE,
} from "../actions";

import { loginUserSuccess, loginUserError, loginUserState } from "./actions";

/*eslint-disable*/

interface userResponse {
  authUser: any;
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (
  email: string,
  password: string
): Promise<userResponse> =>
  await loginModel(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

function* loginWithEmailPassword({ payload }: any): any {
  const { email, password } = payload.user;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (
      loginUser.status === 200 &&
      loginUser.data.errorType === undefined &&
      loginUser.data.statusCode !== 400 &&
      loginUser.data.message === undefined
    ) {
      localStorage.setItem("token", loginUser.data.token);
      localStorage.setItem("key", loginUser.data.key);
      AuthToken(loginUser.data.token);
      yield put(loginUserSuccess(loginUser.data.data));
    } else {
      yield put(loginUserError(loginUser.data.message));
    }
  } catch (error: any) {
    yield put(loginUserError(error));
  }
}

export function* watchLoginTwoFactorValidate() {
  yield takeEvery(LOGIN_TWO_FACTOR_VALIDATE, loginTwoFactorValidate);
}

const loginTwoFactorValidateAsync = async (
  secrect: any
): Promise<userResponse> =>
  await twoFactorValidateModel(secrect)
    .then((authUser) => authUser)
    .catch((error) => error);

function* loginTwoFactorValidate({ payload }: any): any {
  const { secrect } = payload;
  try {
    const loginUser = yield call(loginTwoFactorValidateAsync, secrect);
    if (loginUser.status === 200 && loginUser.data.data !== false) {
      if (loginUser.data.message === "OK") {
        localStorage.setItem("user", JSON.stringify(loginUser.data.data));
        yield put(loginUserState(true));
        //history('/');
      } else {
        yield put(
          loginUserError(
            "Error de Autenticacion verifique el token de two factor o Intente mas tarde!!"
          )
        );
      }
    } else {
      yield put(
        loginUserError(
          "Error de Autenticacion verifique el token de two factor o Intente mas tarde!!"
        )
      );
    }
  } catch (error: any) {
    yield put(loginUserError(error));
  }
}

export function* watchLoginTwoFactorRegister() {
  yield takeEvery(LOGIN_TWO_FACTOR_REGISTER, loginTwoFactorRegister);
}

const loginTwoFactorRegisterAsync = async (
  secrect: any
): Promise<userResponse> =>
  await twoFactorRegisterModel(secrect)
    .then((authUser) => authUser)
    .catch((error) => error);

function* loginTwoFactorRegister({ payload }: any): any {
  const { secrect } = payload;
  try {
    const loginUser = yield call(loginTwoFactorRegisterAsync, secrect);
    if (
      loginUser.status === 200 &&
      loginUser.data.errorType === undefined &&
      loginUser.data.data.secret === true
    ) {
      localStorage.setItem(
        "detalle-user",
        JSON.stringify(loginUser.data.data.user)
      );
      localStorage.setItem("user", loginUser.data.data.user.email);
      localStorage.setItem("name", loginUser.data.data.user.firstName);
      localStorage.setItem("last_name", loginUser.data.data.user.lastName);
      localStorage.setItem("role", loginUser.data.data.user.role.name);
      localStorage.setItem(
        "permissions",
        JSON.stringify(loginUser.data.data.user.role.permissions)
      );
      yield put(loginUserState(loginUser.data.data.secret));
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error: any) {
    yield put(loginUserError(error));
  }
}

export function* watchLogoutUser(): any {
  yield takeEvery(LOGOUT_USER, logout);
}

function* logout({ payload }: any): any {
  try {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("key");
    yield put(loginUserState(false));
  } catch (error) {}
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchLoginTwoFactorValidate),
    fork(watchLoginTwoFactorRegister),
  ]);
}
