import React, {useState, useEffect} from 'react'

/*eslint-disable*/

const IconsCreateSelector = ({icons, handleSetIconsFolders}: any) => {

    const [folderToAdd, setFolderToAdd]: any = useState([])

    const handleAddToFoldersArray = (element: any) => {

        if(folderToAdd.includes(element)){
            folderToAdd.splice(folderToAdd.indexOf(element, 1))
            handleSetIconsFolders(folderToAdd)
        }
        else{
            setFolderToAdd([...folderToAdd, element])
        }
    }

    useEffect(() => {
        handleSetIconsFolders(folderToAdd)
    }, [folderToAdd])



    return (
        <div className='selector-container'>
            {
                icons.map((iconElement: any, index: number) => 
                    <div className='icon-individual-container' key={iconElement.folder + index}>
                        <label>
                            {iconElement.folder}
                            <input
                                type={"checkbox"}
                                value={iconElement.id}
                                className="selection-icons"
                                onChange={() => {handleAddToFoldersArray(iconElement)}}
                            />
                        </label>
                    </div>
                )
            }
        </div>
    )
}

export default IconsCreateSelector