import HeadGeneralInfo from './header.component'

const SetupGeneral = ({projectSelected, logoDefault, update}: any) => {
    return(
        <div className='setup-general-container'>
            <HeadGeneralInfo title={"Información General"} event={update}/>
        </div>
    )
}

export default SetupGeneral