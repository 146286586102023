import { INDEX_SIDEBAR, SUBINDEX_SIDEBAR } from './../actions';

export const changeCurrentIndex = (index: number) => ({
    type: INDEX_SIDEBAR,
    payload: index
})

export const changeCurrentSubIndex = (index: number) => ({
    type: SUBINDEX_SIDEBAR,
    payload: index
})