import React from 'react'
import { connect } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";

import Navbar from '../navbar';
import BuildersHeader from './header.component';
import CardListBuilders from './card.component';
import DeleteComponent from '../generals/delete.component';
import CreateBuilder from './create.component';

import dashBackground from './../../UI/backgrounds/BG_PUBLIC.webp'

import {
    builderList,
    builderCreate,
    builderUpdate,
    builderDelete,
    builderSelectedSuccess,
    logoutUser,
    showAlert,
    closeAlert
} from "../../redux/actions";

interface Props{
    builderList: any,
    builderCreate: any,
    builderUpdate: any,
    builderDelete: any
    builderSelectedSuccess: any
    logoutUser: any
    builder: any
    loading: boolean
    showAlert: any
    closeAlert: any
}

interface State{
    userString: any,
    user: any
    open: boolean,
    builderSelected: any,
    showDelete: boolean,
    deleteParams: any
}

class BuildersView extends React.Component<Props,State>{

    constructor(props: Props){
        super(props);
        this.state = {
            userString: localStorage.getItem("user"),
            user: {},
            open: false,
            builderSelected: {},
            showDelete: false,
            deleteParams: {}
        }
    }

    async componentDidMount() {
        
        if(this.state.userString){
            this.setState({
                user: JSON.parse(this.state.userString)
            }, () => {
                this.props.builderList({user: this.state.user.id})
            })
        }
    }

    toggleSettings = (params: boolean) => {
        this.setState({
            open: params
        }, () => {
            if(params){
                this.setState({
                    builderSelected: {}
                })
            }
        })
    }

    handleEdit = (params: object) => {
        this.setState({
            open: true,
            builderSelected: params
        })
    }

    handleDelete = (params: any) => {
        this.setState({
            showDelete: !this.state.showDelete,
            deleteParams: params
        })
    }

    handleConfirmDelete = () => {
        let user: any = localStorage.getItem("user");
        this.props.builderDelete({
            id: this.state.deleteParams.id,
            user: JSON.parse(user).id,
            slug: this.state.deleteParams.slug,
        });
        this.setState({
            showDelete: false
        })
    }

    render(): React.ReactNode {
        return (
            <div className='builders-main-page'>
                <img className='dashboard-background' src={dashBackground} alt=""/>
                <div className={this.state.open ? 'builders-container-opened':'builders-container'}>
                    <BuildersHeader
                        title={"Constructoras"}
                        event={this.toggleSettings}
                        user = {this.state.user}
                    />
                    <CardListBuilders
                        elements={this.props.builder}
                        editEvent={this.handleEdit}
                        deleteEvent={this.handleDelete}
                        builderSelectedSuccess={this.props.builderSelectedSuccess}
                        user = {this.state.user}
                    />
                </div>
                <DeleteComponent
                    show={this.state.showDelete}
                    handleHide = {this.handleDelete}
                    handleConfirmDelete = {this.handleConfirmDelete}
                />
                <CreateBuilder
                    data={this.state.builderSelected}
                    event={this.toggleSettings}
                    handleEdit={this.props.builderUpdate}
                    handleSave={this.props.builderCreate}
                    open={this.state.open}
                    showAlert={this.props.showAlert}
                    closeAlert={closeAlert}
                />
                <Navbar
                    logout={this.props.logoutUser}
                />
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.props.loading}
                    //onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

const mapStateToProps = ({ builderReducer }: any) => {
    const { builder, loading } = builderReducer;
    return { builder, loading };
};

export default connect(mapStateToProps, {
        builderList,
        builderCreate,
        builderUpdate,
        builderDelete,
        builderSelectedSuccess,
        logoutUser,
        showAlert,
        closeAlert
    })(BuildersView);