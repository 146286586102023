import axios from "axios";
import { ApiConfig } from "../constants/defaultValues";
import { borrarCacheImagenes } from "../helpers/clearCache";

export async function list(e: any) {
  return axios.post(ApiConfig.URL2 + "galleries/get", e);
}
export async function create(e: any) {
  let result = await axios.post(ApiConfig.URL2 + "amenities/set", e.data);
  if (result.status === 200) {
    e.gallery_files.forEach(async (file: any) => {
      let FileFormData = new FormData();
      FileFormData.append("myFile", file);
      FileFormData.append("asset", file.newName);
      FileFormData.append("format", "webp");
      FileFormData.append("route", file.routeAsset);
      axios.post(ApiConfig.URL3, FileFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      /*if (resultFormData.status === 200) {
        borrarCacheImagenes();
        return result;
      }*/
    });
    let resultFormData = await axios.post(ApiConfig.URL3, e.assets, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (resultFormData.status === 200) {
      borrarCacheImagenes();
      return result;
    }
  }
}
export async function update(e: any) {
    let result: boolean = false;
  if (e.type === "list") {
    await axios.post(ApiConfig.URL2 + "galleries/files/update", e.data);
    result = true
  } else {
    let resultFormData = await axios.post(ApiConfig.URL3, e.asset, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (resultFormData.status === 200) {
      result = true;
    }
  }
  if (result) {
    borrarCacheImagenes();
    return axios.post(ApiConfig.URL2 + "galleries/get", {user: e.data.user, project: e.data.project});
  }
}
export async function remove(proyecto: any) {
  return axios.post(ApiConfig.URL2 + "galleries/files/delete", proyecto);
}
export async function createFolder(e: any) {
  return axios.post(ApiConfig.URL2 + "galleries/folder/set", e);
}
export async function uploadFiles(e: any) {
  if (e.type === "list") {
    let result = await axios.post(
      ApiConfig.URL2 + "galleries/files/set",
      e.data
    );
    if (result.status === 200) {
      return result;
    }
  } else {
    if (e.data.type === "list") {
      let result: any = [];
    let formato = e.data.value;

      e.data.valu = `${formato}${e.assets.name}`;
      e.data.value = `https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${formato}${e.assets.name}`;
      result = await axios.post(ApiConfig.URL2 + "galleries/files/set", e.data);
      if (result.status === 200) {
        let FileFormData = new FormData();
        FileFormData.append("myFile", e.assets);
        FileFormData.append("name", e.data.valu);
        let resultFormData = await axios.post(`${ApiConfig.URL3}coord/`, FileFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (resultFormData.status === 200) {
          borrarCacheImagenes();
          return result;
        }
      }
    }else {
    let result: any = [];
    let formato = e.data.value;
    let count = 0;
    for await (let render of e.assets) {
      e.data.value = `${formato}${render.name}`;
      e.data.name = `${render.name}`;
      result = await axios.post(ApiConfig.URL2 + "galleries/files/set", e.data);
      if (result.status === 200) {
        let FileFormData = new FormData();
        FileFormData.append("myFile", render);
        FileFormData.append("name", e.data.value);
        let resultFormData = await axios.post(ApiConfig.URL3, FileFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (resultFormData.status === 200) {
          count++;
        }
      }
    }
    if (e.assets.length === count) {
      borrarCacheImagenes();
      return result;
    }
  }
  }
}
