import axios from 'axios';
import { ApiConfig } from '../constants/defaultValues';

export async function list(properties: any) {
    return axios.post(ApiConfig.URL2 + "properties/get", properties);
}
export async function set(e: any) {
    return axios.post(ApiConfig.URL2 + "properties/set", e);
}
export async function updateActiveAvailability(e: any) {
    return axios.post(ApiConfig.URL2 + "project/update/availability", e);
}
export async function update(e: any) {
    if (e?.attribute !== undefined && e?.value === undefined) {
        let res = await axios.post(
            ApiConfig.URL2 + "properties/attributes/value",
            {
              project: e.project,
              attribute: e.attribute,
              value: e.inputValue,
              layoutColor: "#000",
              billboardFillColor: "#fff",
              billboardStrokeColor: "#000",
            }
          );
          if (res.status === 200) {
            
            e.value = res.data.value
            return axios.post(ApiConfig.URL2 + "properties/update", e);
          }
    }else{
        return axios.post(ApiConfig.URL2 + "properties/update", e);
    }
}
export async function copy(e: any) {
    return axios.post(ApiConfig.URL2 + "properties/copy", e);
}
export async function remove(proyecto: any) {
    return axios.post(ApiConfig.URL2 + "properties/delete", proyecto);
}
export async function setGalleries(e: any){
    return axios.post(ApiConfig.URL2 + "properties/galleries", e)
}
export async function setTypefilter(e: any){
    return axios.post(ApiConfig.URL2 + "properties/filter", e)
}