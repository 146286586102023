import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
    Backdrop,
    CircularProgress,
  } from "@mui/material";

import Navbar from '../navbar';
import Sidebar from '../sidebar';

//import CreateSalas from './create.component';
//import EditSalas from './edit.component';

import ListNew from './listNew.component'
import CreateNew from './createNew.component';
import EditNew from './editNew.component';

import dashBackground from './../../UI/backgrounds/BG_PUBLIC.webp'

import {
    logoutUser,
    modulesList,
    changeCurrentIndex,
    changeCurrentSubIndex,
    contactList,
    contactSet,
    contactUpdate,
    contactDelete,
} from "../../redux/actions";


interface Props{
  logoutUser: any,
  changeCurrentIndex: any,
  changeCurrentSubIndex: any
  projectSelected: any,
  modulesList: any,
  modulesUpdate: any,
  paletteChromaticUpdate: any,
  projectUpdateInside: any,
  modules: any,
  loading: any,
  selectedBuilder: any,
  params: any,
  contactList: any,
  contactSet: any,
  contactUpdate: any,
  contactDelete: any,
  contact: any
}

interface State {
  userString: any,
  user: any,
  name: string,
  idProject: any,

  salasList: any,
  editionModalOpened: boolean,
  currentEditableSala: any
}

function withParams(Component: any) {
  return (props: any) => <ContactInformationView {...props} params={useParams()} />;
}

class ContactInformationView extends React.Component<Props,State>{
  constructor(props: Props){
    super(props);
    this.state = {
        userString: localStorage.getItem("user"),
        user: "",
        name: "",
        idProject: this.props.params.id,
        salasList: [],
        editionModalOpened: false,
        currentEditableSala: {}
    }
  }

  componentDidMount(): void {
    if(this.state.userString){
        this.setState({
            user: JSON.parse(this.state.userString)
        }, () => {
            this.props.changeCurrentIndex(1)
            this.props.changeCurrentSubIndex(-1)
            this.props.contactList({
              user: this.state.user.id,
              project: this.state.idProject,
            });
        })
    }
  }

  componentDidUpdate(prevProps: any, prevState: any): void {
    if (prevProps.contact !== this.props.contact) {
      this.setState({
        salasList: this.props.contact
      })
    }
  }

  handleAddSalaToArray = (payload: any) => {
    this.setState({
      salasList: [...this.state.salasList, payload]
    }, () => {
      this.props.contactSet({
        project: this.state.idProject,
        user: this.state.user.id,
        data: payload
      })
    })
  }

  handleDeleteSala = (index: number) => {
    
    
    this.props.contactDelete({
      project: this.state.idProject,
      user: this.state.user.id,
      data: this.state.salasList[index]
    })
  }

  handleOpenEditionModal = (payload: any, open: boolean) => {
    this.setState({
      editionModalOpened: open,
      currentEditableSala: payload
    })
  } 

  handleSaveEdition = (payload: any) => {
    this.props.contactUpdate({
      project: this.state.idProject,
      user: this.state.user.id,
      data: payload
    })
  }

  handleCreateSalaNew = () => {

  }
  
  render(): React.ReactNode {
      return(
        <div className='contact-information-container'>
          <img className='dashboard-background' src={dashBackground} alt=""/>
          <ListNew
            salasList={this.state.salasList}
            handleDeleteSala={this.handleDeleteSala}
            handleOpenEditionModal={this.handleOpenEditionModal}
          />
          <CreateNew
            handleAddSalaToArray={this.handleAddSalaToArray}
          />
          {
            this.state.editionModalOpened ?
            <EditNew
              handleSaveEdition={this.handleSaveEdition}
              currentSala={this.state.currentEditableSala}
              handleOpenEditionModal = {this.handleOpenEditionModal}
            />:null
          }
          <Sidebar
            project={this.props.projectSelected}
          />
          <Navbar
            logout={this.props.logoutUser}
            />
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.props.loading}
            //onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )
  }
}

const mapStateToProps = ({
  projectReducer,
  builderReducer,
  contactReducer
}: any) => {
  const { projectSelected } = projectReducer;
  const { selectedBuilder } = builderReducer;
  const { contact } = contactReducer;
  return { projectSelected, selectedBuilder, contact };
};

export default connect(mapStateToProps, {
  modulesList,
  logoutUser,
  changeCurrentIndex,
  changeCurrentSubIndex,
  contactList,
  contactSet,
  contactUpdate,
  contactDelete,
})(withParams(ContactInformationView))