import React from 'react'
import GeneralIconComponent from './generalIcon.comonent'
import GeneralMapComponent from './generalRoadsTest.component'

/*eslint-disable*/

interface GeneralRoadsProps{
    createRoad: boolean,
    createLote: boolean,
    logo: string,
    locations: any,
    locationsUpdate: any,
    user: any,
    project: any,
    currentLogo: string,
    currentLogoPosition: any,
    currentLoteArray: any,
    handleCurrentAddLogo: any,
    handleUpdateCurrentRoad: any,
    handleUpdateCurrentLote: any,
    handleUpdateCurrentLogoPosition: any,
    viasArray: any,
    currentColorLote: string,
    currentViaArray: any,
    handleUpdateZoom: any,
    handleUploadIllustratedMap: any
}

const GeneralRoadsComponent = ({
    createRoad, 
    createLote, 
    logo,
    user, 
    project, 
    locations, 
    locationsUpdate,
    currentLogo,
    currentLogoPosition,
    currentLoteArray,
    handleCurrentAddLogo,
    handleUpdateCurrentRoad,
    handleUpdateCurrentLote,
    handleUpdateCurrentLogoPosition,
    viasArray,
    currentColorLote,
    currentViaArray,
    handleUpdateZoom,
    handleUploadIllustratedMap
}: GeneralRoadsProps) => {

    return(
        <div className='general-roads-container'>
            <div className='general-roads-title'>Datos generales de ubicación</div>
            <GeneralIconComponent
                currentLogo={currentLogo}
                handleAddLogo = {handleCurrentAddLogo}
                logo={logo}
                handleUpdateZoom = {handleUpdateZoom}
                handleUploadIllustratedMap = {handleUploadIllustratedMap}
            />
            {Object.keys(locations).length > 0 ? 
            
            <GeneralMapComponent
                createRoad={createRoad}
                createLote = {createLote}
                currentLogo = {currentLogo}
                currentColorLote = {currentColorLote}
                user = {user} 
                project = {project}
                viasArray = {viasArray}
                locations={locations}
                currentLoteArray = {currentLoteArray}
                handleUpdateCurrentRoad = {handleUpdateCurrentRoad}
                handleUpdateCurrentLote = {handleUpdateCurrentLote}
                handleUpdateCurrentLogoPosition = {handleUpdateCurrentLogoPosition}
                locationsUpdate={locationsUpdate}/>
                : ""}
        </div>
    )
} 

{/* <GeneralMapComponent
                createRoad={createRoad}
                createLote = {createLote}
                locations={locations}
                locationsUpdate={locationsUpdate}
                user = {user} 
                project = {project}
                currentLogo = {currentLogo}
                currentLogoPosition={currentLogoPosition}
                currentLoteArray = {currentLoteArray}
                handleUpdateCurrentRoad = {handleUpdateCurrentRoad}
                handleUpdateCurrentLote = {handleUpdateCurrentLote}
                handleUpdateCurrentLogoPosition = {handleUpdateCurrentLogoPosition}
                viasArray = {viasArray}
                currentColorLote = {currentColorLote}
                currentViaArray = {currentViaArray}
            /> */}

export default GeneralRoadsComponent