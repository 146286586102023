import { all, call, delay, fork, put, takeEvery } from "redux-saga/effects";
import {
  list as builderListModel,
  create as builderCreateModel,
  update as builderUpdateModel,
  eliminar as builderDeleteModel,
  one as builderOneModel,
} from "../../models/builder";

import {
  LIST_BUILDER,
  CREATE_BUILDER,
  UPDATE_BUILDER,
  ONE_BUILDER,
  DELETE_BUILDER,
} from "../actions";

import { builderListSuccess, builderListError } from "./actions";

import { showAlert } from "../general/actions";

/*eslint-disable*/

interface builderResponse {
  builderItf: any;
}

export function* watchBuilderList() {
  yield takeEvery(LIST_BUILDER, builderList);
}

export function* watchBuilderCreate() {
  yield takeEvery(CREATE_BUILDER, builderCreate);
}

export function* watchBuilderUpdate() {
  yield takeEvery(UPDATE_BUILDER, builderUpdate);
}

export function* watchBuilderDelete() {
  yield takeEvery(DELETE_BUILDER, builderDelete);
}

export function* watchBuilderOne() {
  yield takeEvery(ONE_BUILDER, builderOne);
}

const builderListAsync = async (user: any): Promise<builderResponse> =>
  await builderListModel(user)
    .then((builderItf) => builderItf)
    .catch((error) => error);

function* builderList({ payload }: any): any {
  const { user } = payload;
  try {
    const builderListData = yield call(builderListAsync, user);
    if (builderListData.status === 200) {
      yield put(builderListSuccess(builderListData));
    } else {
      yield put(builderListError(builderListData));
    }
  } catch (error) {
    yield put(builderListError(error));
  }
}

const builderCreateAsync = async (builder: any): Promise<builderResponse> =>
  await builderCreateModel(builder)
    .then((builder) => builder)
    .catch((error) => error);

function* builderCreate({ payload }: any): any {
  const { builder, history } = payload;
  try {
    const builderCreateData = yield call(builderCreateAsync, builder);
    if (builderCreateData.status === 200) {
      yield put(builderListSuccess(builderCreateData));
      yield put(
        showAlert({
          message: "Constructora creada con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Constructora creada con exito.",
          type: "success",
          show: false,
        })
      );
    } else {
      yield put(builderListError(builderCreateData));

      yield put(
        showAlert({
          message: "Error al crear la constructora",
          type: "danger",
          show: true,
        })
      );
    }
  } catch (error) {
    yield put(builderListError(error));
    yield put(
      showAlert({
        message: "Error al crear la constructora",
        type: "danger",
        show: true,
      })
    );
  }
}

const builderUpdateAsync = async (
  builder: any,
  id: any
): Promise<builderResponse> =>
  await builderUpdateModel(builder, id)
    .then((builder) => builder)
    .catch((error) => error);

function* builderUpdate({ payload }: any): any {
  const { builder, id, history } = payload;
  try {
    const builderCreateData = yield call(builderUpdateAsync, builder, id);
    if (builderCreateData.status === 200) {
      yield put(builderListSuccess(builderCreateData));
      yield put(
        showAlert({
          message: "Constructora creada con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Constructora actualizada con exito.",
          type: "success",
          show: false,
        })
      );
    } else {
      yield put(builderListError(builderCreateData));
      yield put(
        showAlert({
          message: "Error al actualizar la constructora",
          type: "danger",
          show: true,
        })
      );
    }
  } catch (error) {
    yield put(builderListError(error));
    yield put(
      showAlert({
        message: "Error al actualizar la constructora",
        type: "danger",
        show: true,
      })
    );
  }
}

const builderDeleteAsync = async (builder: any): Promise<builderResponse> =>
  await builderDeleteModel(builder)
    .then((builder) => builder)
    .catch((error) => error);

function* builderDelete({ payload }: any): any {
  const { builder } = payload;
  try {
    const builderCreateData = yield call(builderDeleteAsync, builder);
    if (builderCreateData.status === 200) {
      yield put(builderListSuccess(builderCreateData));
      yield put(
        showAlert({
          message: "Constructora eliminada con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Constructora eliminada con exito.",
          type: "success",
          show: false,
        })
      );
    } else {
      yield put(builderListError(builderCreateData));
      yield put(
        showAlert({
          message: "Error al actualizar la constructora",
          type: "danger",
          show: true,
        })
      );
    }
  } catch (error) {
    yield put(builderListError(error));
    yield put(
        showAlert({
          message: "Error al actualizar la constructora",
          type: "danger",
          show: true,
        })
      );
  }
}

const builderOneAsync = async (builder: any): Promise<builderResponse> =>
  await builderOneModel(builder)
    .then((builder) => builder)
    .catch((error) => error);

function* builderOne({ payload }: any): any {
  const { builder, history } = payload;
  try {
    const builderCreateData = yield call(builderOneAsync, builder);
    if (builderCreateData.status === 200) {
      yield put(builderListSuccess(builderCreateData));
    } else {
      yield put(builderListError(builderCreateData));
    }
  } catch (error) {
    yield put(builderListError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchBuilderList),
    fork(watchBuilderCreate),
    fork(watchBuilderUpdate),
    fork(watchBuilderDelete),
    fork(watchBuilderOne),
  ]);
}
