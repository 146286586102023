import axios from 'axios'

function authToken(token: any): void{
    if(token){
        axios.defaults.headers.common['Authorization'] = 'OK'
    }
    else {
        delete axios.defaults.headers.common['Authorization']
    }
}

export default authToken