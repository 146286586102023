import { INDEX_SIDEBAR, SUBINDEX_SIDEBAR} from './../actions';

/*eslint-disable*/

const INIT_STATE = {
    currentIndex: 0,
    currentSubindex: -1
}

export default (state = INIT_STATE, action: any) => {
    switch (action.type){
        case SUBINDEX_SIDEBAR:
            return {
                ...state,
                currentSubindex: action.payload
            }
        case INDEX_SIDEBAR:
            return {
                ...state,
                currentIndex: action.payload
            }
        default:
            return {
                ...state
            }
    }
}