import React, {useEffect, useState} from 'react'

const ShowGalleriesLinks = ({galleries, handleOpenFullScreen, handleUpdateImagesArray, isGalleries, modalGalleries}: any) => {

    const [tempArraySelected, setTempArraySelected]: any = useState(modalGalleries) 

    const handleSetTempArray = (payload: object) => {
        if(tempArraySelected.includes(payload)){
            tempArraySelected.splice(tempArraySelected.indexOf(payload, 1))
        }
        else{
            setTempArraySelected([...tempArraySelected, payload])
        }
    } 

    useEffect(() => {
        handleUpdateImagesArray(tempArraySelected)
    })

    useEffect(() => {
        if(!isGalleries){
            setTempArraySelected([])
        }
    }, [isGalleries])

    return(
        <div className='gallery-links-preview'>
            {
                galleries.map((link: any, index: any) => 
                    link.type === 'list' ?
                    <div className='gallery-link-container' style={{height: "20%"}} onClick={() => {handleSetTempArray(link)}}>
                        <div className='gallery-recorrido-name-des-container' >
                            <div className='gallery-recorrido-name'>{link.name}</div>
                            <div className='gallery-recorrido-desc'>{link.description}</div>
                        </div>
                    </div>:null
                )
            }
        </div>
    )
}

export default ShowGalleriesLinks