import {
    LIST_MAILING,
    LIST_MAILING_SUCCESS,
    LIST_MAILING_ERROR,
    SET_MAILING,
} from './../actions'

export const mailingList = (proyecto: any) => ({
    type: LIST_MAILING,
    payload: {proyecto}
})

export const mailingListSuccess = (mailing: any) => ({
    type: LIST_MAILING_SUCCESS,
    payload: mailing
})

export const mailingListError = (message: any) => ({
    type: LIST_MAILING_ERROR,
    payload: message
})

export const mailingSet = (proyecto: any) => ({
    type: SET_MAILING,
    payload: {proyecto}
})