import axios from 'axios';
import {
    ApiConfig
} from '../constants/defaultValues';

export async function list(project_id: any) {
    return axios.post(ApiConfig.URL2 + "amenities/get", 
        project_id,
    );
}
export async function create(e: any) {
    let result = await axios.post(ApiConfig.URL2 + "amenities/set", e);
    
    return result
    /*if (result.status === 200) {
        e.gallery_files.forEach((file: any) => {
            let FileFormData = new FormData();
            FileFormData.append("myFile", file);
            FileFormData.append("asset", file.newName);
            FileFormData.append("format", "webp");
            FileFormData.append("route", file.routeAsset);
            axios.post(`${ApiConfig.URL3}coord/`, FileFormData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
        });
        axios.post(`${ApiConfig.URL3}coord/`, e.assets, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return result
    }*/
}
export async function update(proyecto: any) {

    return axios.post(ApiConfig.URL2 + "amenities/update", proyecto);
}
export async function eliminar(proyecto: any) {

    return axios.post(ApiConfig.URL2 + "amenities/delete", proyecto);
}

export async function setGalleries(e: any){

    return axios.post(ApiConfig.URL2 + "amenities/galleries", e)
}