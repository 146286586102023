/*eslint-disable*/

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { list, set, remove, update, listScript } from './../../models/spin';

import {
    LIST_SPIN,
    SET_SPIN,
    UPDATE_SPIN,
    DELETE_SPIN,
    UPDATE_SCRIPT,
    LIST_SCRIPT
} from '../actions';

import {
    spinListSuccess,
    spinListError,
} from './actions';
import {
    projectSelectedSuccess,
    projectSelectedError,
} from '../project/actions';
import {
    builderSelectedSuccess,
} from '../builder/actions';

//import { attributesListSuccess } from "../attribute/actions";


interface spinResponse {
    spinItf: any;
  }

export function* watchspinList() {
    yield takeEvery(LIST_SPIN, spinList);
}
export function* watchspinSet() {
    yield takeEvery(SET_SPIN, spinSet);
}
export function* watchspinUpdate() {
    yield takeEvery(UPDATE_SPIN, spinUpdate);
}
export function* watchscriptUpdate() {
    yield takeEvery(UPDATE_SCRIPT, scriptUpdate);
}
export function* watchspinDelete() {
    yield takeEvery(DELETE_SPIN, spinDelete);
}
export function* watchscriptList() {
    yield takeEvery(LIST_SCRIPT, scripList);
}


const spinListAsync = async (spin: any): Promise<spinResponse> =>
    await list(spin)
        .then(spinItf => spinItf)
        .catch(error => error);

function* spinList({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const spinListData = yield call(spinListAsync, proyecto);
        if (spinListData.status === 200) {
            yield put(spinListSuccess(spinListData.data));
            yield put(projectSelectedSuccess(spinListData.data.project));
            yield put(builderSelectedSuccess(spinListData.data.builder));
        } else {
            yield put(spinListError(spinListData));
            yield put(projectSelectedError(spinListData));
            yield put(builderSelectedSuccess(spinListData));
        }
    } catch (error) {
        yield put(spinListError(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


const spinSetAsync = async (spin: any): Promise<spinResponse> =>
    await set(spin)
        .then(spinItf => spinItf)
        .catch(error => error);

function* spinSet({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const spinListData = yield call(spinSetAsync, proyecto);
        if (spinListData.status === 200) {
            yield put(spinListSuccess(spinListData.data));
            yield put(projectSelectedSuccess(spinListData.data.project));
            yield put(builderSelectedSuccess(spinListData.data.builder));
        } else {
            yield put(spinListError(spinListData));
            yield put(projectSelectedError(spinListData));
            yield put(builderSelectedSuccess(spinListData));
        }
    } catch (error) {
        yield put(spinListError(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


const spinUpdateAsync = async (spin: any): Promise<spinResponse> =>
    await set(spin)
        .then(spinItf => spinItf)
        .catch(error => error);

function* spinUpdate({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const spinListData = yield call(spinUpdateAsync, proyecto);
        if (spinListData.status === 200) {
            yield put(spinListSuccess(spinListData.data));
            yield put(projectSelectedSuccess(spinListData.data.project));
            yield put(builderSelectedSuccess(spinListData.data.builder));
        } else {
            yield put(spinListError(spinListData));
            yield put(projectSelectedError(spinListData));
            yield put(builderSelectedSuccess(spinListData));
        }
    } catch (error) {
        yield put(spinListError(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}

const scriptUpdateAsync = async (spin: any): Promise<spinResponse> =>
    await update(spin)
        .then(spinItf => spinItf)
        .catch(error => error);

function* scriptUpdate({ payload}: any): any {
    const { proyecto } = payload;

    try {
        const spinListData = yield call(scriptUpdateAsync, proyecto);
        if (spinListData.status === 200) {
            yield put(spinListSuccess(spinListData.data));
            yield put(projectSelectedSuccess(spinListData.data.project));
            yield put(builderSelectedSuccess(spinListData.data.builder));
        } else {
            yield put(spinListError(spinListData));
            yield put(projectSelectedError(spinListData));
            yield put(builderSelectedSuccess(spinListData));
        }
    } catch (error) {
        yield put(spinListError(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}

const spinDeleteAsync = async (spin: any): Promise<spinResponse> =>
    await remove(spin)
        .then(spinItf => spinItf)
        .catch(error => error);

function* spinDelete({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const spinListData = yield call(spinDeleteAsync, proyecto);
        if (spinListData.status === 200) {
            yield put(spinListSuccess(spinListData.data));
            yield put(projectSelectedSuccess(spinListData.data.project));
            yield put(builderSelectedSuccess(spinListData.data.builder));
        } else {
            yield put(spinListError(spinListData));
            yield put(projectSelectedError(spinListData));
            yield put(builderSelectedSuccess(spinListData));
        }
    } catch (error) {
        yield put(spinListError(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}

const scriptListAsync = async (spin: any): Promise<spinResponse> =>
    await listScript(spin)
        .then(spinItf => spinItf)
        .catch(error => error);

function* scripList({ payload}: any): any {
    const { proyecto } = payload;

    try {
        const spinListData = yield call(scriptListAsync, proyecto);
        if (spinListData.status === 200) {
            yield put(spinListSuccess(spinListData.data));
        } else {
            yield put(spinListError(spinListData));
            yield put(projectSelectedError(spinListData));
            yield put(builderSelectedSuccess(spinListData));
        }
    } catch (error) {
        yield put(spinListError(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}



export default function* rootSaga() {
    yield all([
        fork(watchspinList),
        fork(watchspinSet),
        fork(watchspinUpdate),
        fork(watchscriptUpdate),
        fork(watchspinDelete),
        fork(watchscriptList),
    ]);
}
