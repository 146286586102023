import { all, call, delay, fork, put, takeEvery } from 'redux-saga/effects';
import { list, set, update, remove} from './../../models/contact';

import {
    LIST_CONTACT,
    SET_CONTACT,
    UPDATE_CONTACT,
    DELETE_CONTACT
} from '../actions';

import {
    contactListSuccess,
    contactListError,
} from './actions';
import {
    projectSelectedSuccess,
    projectSelectedError,
} from '../project/actions';
import {
    builderSelectedSuccess,
} from '../builder/actions';
import { showAlert } from "../general/actions";



interface contactResponse {
    contactItf: any;
  }

export function* watchcontactList() {
    yield takeEvery(LIST_CONTACT, contactList);
}
export function* watchcontactSet() {
    yield takeEvery(SET_CONTACT, contactSet);
}
export function* watchcontactUpdate() {
    yield takeEvery(UPDATE_CONTACT, contactUpdate);
}

export function* watchcontactDelete() {
    yield takeEvery(DELETE_CONTACT, contactDelete);
}


const contactListAsync = async (contact: any): Promise<contactResponse> =>
    await list(contact)
        .then(contactItf => contactItf)
        .catch(error => error);

function* contactList({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const contactListData = yield call(contactListAsync, proyecto);
        if (contactListData.status === 200) {
            yield put(contactListSuccess(contactListData.data.contact));
            yield put(projectSelectedSuccess(contactListData.data.project));
            yield put(builderSelectedSuccess(contactListData.data.builder));
        } else {
            yield put(contactListError(contactListData));
            yield put(projectSelectedError(contactListData));
            yield put(builderSelectedSuccess(contactListData));
        }
    } catch (error) {
        yield put(contactListError(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


const contactSetAsync = async (contact: any): Promise<contactResponse> =>
    await set(contact)
        .then(contactItf => contactItf)
        .catch(error => error);

function* contactSet({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const contactListData = yield call(contactSetAsync, proyecto);
        if (contactListData.status === 200) {
            yield put(contactListSuccess(contactListData.data.contact));
            yield put(projectSelectedSuccess(contactListData.data.project));
            yield put(builderSelectedSuccess(contactListData.data.builder));
            yield put(
              showAlert({
                message: "Directorio de contacto creado con exito.",
                type: "success",
                show: true,
              })
            );
      
            yield delay(3000);
      
            yield put(
              showAlert({
                message: "Directorio de contacto creado con exito.",
                type: "success",
                show: false,
              })
            );
        } else {
            yield put(contactListError(contactListData));
            yield put(projectSelectedError(contactListData));
            yield put(builderSelectedSuccess(contactListData));
            yield put(
              showAlert({
                message: "Error al crear el Directorio de contacto",
                type: "danger",
                show: true,
              })
            );
        }
    } catch (error) {
        yield put(contactListError(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));
        yield put(
          showAlert({
            message: "Error al crear el Directorio de contacto",
            type: "danger",
            show: true,
          })
        );

    }
}


const contactUpdateAsync = async (contact: any): Promise<contactResponse> =>
    await update(contact)
        .then(contactItf => contactItf)
        .catch(error => error);

function* contactUpdate({ payload }: any): any {
    const { proyecto } = payload;
    try {
        const contactListData = yield call(contactUpdateAsync, proyecto);
        if (contactListData.status === 200) {
            yield put(contactListSuccess(contactListData.data.contact));
            yield put(projectSelectedSuccess(contactListData.data.project));
            yield put(builderSelectedSuccess(contactListData.data.builder));
            yield put(
              showAlert({
                message: "Directorio de contacto actualizado con exito.",
                type: "success",
                show: true,
              })
            );
      
            yield delay(3000);
      
            yield put(
              showAlert({
                message: "Directorio de contacto actualizado con exito.",
                type: "success",
                show: false,
              })
            );
        } else {
            yield put(contactListError(contactListData));
            yield put(projectSelectedError(contactListData));
            yield put(builderSelectedSuccess(contactListData));
            yield put(
              showAlert({
                message: "Error al actualizar el Directorio de contacto",
                type: "danger",
                show: true,
              })
            );
        }
    } catch (error) {
        yield put(contactListError(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));
        yield put(
          showAlert({
            message: "Error al actualizar el Directorio de contacto",
            type: "danger",
            show: true,
          })
        );

    }
}


const contactDeleteAsync = async (contact: any): Promise<contactResponse> =>
    await remove(contact)
        .then(contactItf => contactItf)
        .catch(error => error);

function* contactDelete({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const contactListData = yield call(contactDeleteAsync, proyecto);
        if (contactListData.status === 200) {
            yield put(contactListSuccess(contactListData.data.contact));
            yield put(projectSelectedSuccess(contactListData.data.project));
            yield put(builderSelectedSuccess(contactListData.data.builder));
            yield put(
              showAlert({
                message: "Directorio de contacto eliminado con exito.",
                type: "success",
                show: true,
              })
            );
      
            yield delay(3000);
      
            yield put(
              showAlert({
                message: "Directorio de contacto eliminado con exito.",
                type: "success",
                show: false,
              })
            );
        } else {
            yield put(contactListError(contactListData));
            yield put(projectSelectedError(contactListData));
            yield put(builderSelectedSuccess(contactListData));
            yield put(
              showAlert({
                message: "Error al eliminar el Directorio de contacto",
                type: "danger",
                show: true,
              })
            );
        }
    } catch (error) {
        yield put(contactListError(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));
        yield put(
          showAlert({
            message: "Error al eliminar el Directorio de contacto",
            type: "danger",
            show: true,
          })
        );

    }
}


export default function* rootSaga() {
    yield all([
        fork(watchcontactList),
        fork(watchcontactSet),
        fork(watchcontactUpdate),
        fork(watchcontactDelete)
    ]);
}
