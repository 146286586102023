import {
    LIST_USER,
    LIST_USER_SUCCESS,
    LIST_USER_ERROR,
    CREATE_USER,
    UPDATE_USER,
    DELETE_USER,
} from '../actions';

const INIT_STATE = {
    users: [],
    loading: false,
    message: ''
};

const usersReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case LIST_USER:
            return { ...state, loading: true, message: '' };
        case LIST_USER_SUCCESS:
            return { ...state, users: action.payload.data.data, loading: false, message: action.payload.message };
        case LIST_USER_ERROR:
            return { ...state, users: [], loading: false, message: action.payload.message };
        case CREATE_USER:
            return { ...state, loading: true, message: '' }; 
        case UPDATE_USER:
            return { ...state, loading: true, message: '' };
        case DELETE_USER:
            return { ...state, loading: true, message: '' }; 
        default: return { ...state };
    }
}

export default usersReducer
