import React from "react";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";

import Navbar from "../navbar";
import Sidebar from "../sidebar";

import {
  logoutUser,
  inmueblesList,
  inmueblesUpdate,
  mailingSet,
} from "../../redux/actions";

import dashBackground from "./../../UI/backgrounds/BG_PUBLIC.webp";
import IconQuotation from "./icon.component";
import MailingQuotation from "./mailing.component";
import PricingQuotation from "./pricing.module";

interface Props {
  logoutUser: any;
  loading: any;
  projectSelected: any;
  selectedBuilder: any;
  params: any;
  inmueblesList: any;
  inmueblesUpdate: any;
  mailingSet: any;
  properties: any;
  attributes: any;
}

interface State {
  userString: any;
  user: any;
  idProject: any;
}

function withParams(component: any) {
  return (props: any) => <CotizacionView {...props} params={useParams()} />;
}

class CotizacionView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userString: localStorage.getItem("user"),
      user: "",
      idProject: this.props.params.id,
    };
  }

  componentDidMount(): void {
    if (this.state.userString) {
      this.setState(
        {
          user: JSON.parse(this.state.userString),
        },
        () => {
          this.props.inmueblesList({
            user: this.state.user.id,
            project: this.state.idProject,
          });
        }
      );
    }
  }

  handleUploadMailing = (name: string, desc: string, file: any) => {
    if (
      name.length > 0 &&
      desc.length > 0 &&
      (file !== undefined || file !== null)
    ) {
    let InstFormData = new FormData();
    InstFormData.append("myFile", file);
    InstFormData.append("name", `galleries/${this.props.selectedBuilder.id}/${this.state.idProject}/files/mailing_quotation.js`);
      this.props.mailingSet({
        data: {
          user: this.state.user.id,
          project: this.state.idProject,
          name: name,
          descriptio: desc,
          file: `galleries/${this.props.selectedBuilder.id}/${this.state.idProject}/files/mailing_quotation.js`
        },
        assets: InstFormData
      });
    }
  };

  handleUpdatePricing = (id: string, price: any) => {
    this.props.inmueblesUpdate({
      user: this.state.user.id,
      project: this.state.idProject,
      id: id,
      price: price,
    });
  };

  render(): React.ReactNode {
    return (
      <div className="cotizacion-main-container">
        <img className="dashboard-background" src={dashBackground} alt="" />
        <Sidebar project={this.props.projectSelected} />
        <IconQuotation />
        <MailingQuotation handleUploadMailing={this.handleUploadMailing} />
        <PricingQuotation
          attributesList={this.props.attributes}
          inmueblesList={this.props.properties}
          handleUpdatePricing={this.handleUpdatePricing}
        />
        <Navbar logout={this.props.logoutUser} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

const mapStateToProps = ({
  projectReducer,
  builderReducer,
  propertiesReducer,
  attributeReducer,
}: any) => {
  const { projectSelected } = projectReducer;
  const { selectedBuilder } = builderReducer;
  const { properties } = propertiesReducer;
  const { attributes } = attributeReducer;
  return { projectSelected, selectedBuilder, properties, attributes };
};

export default connect(mapStateToProps, {
  logoutUser,
  inmueblesList,
  inmueblesUpdate,
  mailingSet,
})(withParams(CotizacionView));
