import React from 'react'
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import { Backdrop, CircularProgress } from "@mui/material";

import Navbar from '../navbar';
import Sidebar from '../sidebar';
//import AttributesComponent from './attributes.component';

import TableNew from './tableNew.component';
import Create from './create.component';
import Edit from './edit.component';

import dashBackground from './../../UI/backgrounds/BG_PUBLIC.webp'

import {
    logoutUser,
    attributesList,
    attributesCreate,
    attributesUpdate,
    attributesDelete,
    hierarchicalAttributesUpdate,
    attributesUpdateValues
} from "../../redux/actions";


interface Props{
  logoutUser: any,
  loading: any
  projectSelected: any,
  attributes: any,
  attributesList: any,
  attributesCreate: any,
  attributesUpdate: any,
  attributesDelete: any,
  hierarchicalAttributesUpdate: any
  attributesUpdateValues: any
  params: any,
  
}

interface State{
  userString: any,
  user: any,
  idProject: any,
  selectedAttributes: any,

  attributeToEdit: any,
  openAttributeToEdit: boolean,

  currentEditIndex: number
}

function withParams(Component: any) {
  return (props: any) => <AttributesViews {...props} params={useParams()} />;
}
class AttributesViews extends React.Component<Props,State>{

  constructor(props: Props){
    super(props);
    this.state = {
      userString: localStorage.getItem("user"),
      user: "",
      idProject: this.props.params.id,
      selectedAttributes: {},
      attributeToEdit: {},
      openAttributeToEdit: false,
      currentEditIndex: -1
    }
  }

  componentDidMount(): void {
    
    this.setState({
      user: JSON.parse(this.state.userString)
    }, () => {
      this.props.attributesList({ user: this.state.user.id, project: this.state.idProject });
    })

  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
      console.log(this.props)
  }
  
  handleSelectAttribute = (payload: any) => {
    this.setState({
      selectedAttributes: payload
    })
  }

  handleUpdateAttributeValue = (id: string, type: string, colors: any, value: string) => {
    this.props.attributesUpdateValues({
      user: this.state.user.id, 
      project: this.state.idProject,
      id: id,
      type: type,
      value: value,
      layoutFill: colors ? colors.layoutColor:'',
      billboardFill: colors ? colors.billboardFillColor:'',
      billboardStroke: colors ? colors.billboardStrokeColor: ''
    })
  }

  handleCreateAttribute = (payload: any) => {
    payload.user = this.state.user.id
    payload.project = this.state.idProject

    this.props.attributesCreate(payload)
  }

  handleDeleteAttribute = (payload: any) => {
    this.props.attributesDelete({
      data: payload,
      user: this.state.user.id,
      project: this.state.idProject
    })
  } 

  handleOpenEditModal = (payload: any, index: number) => {
    this.setState({
      currentEditIndex: index,
      openAttributeToEdit: false,
      attributeToEdit: payload
    }, () => {
      this.setState({
        openAttributeToEdit: true
      })
    })
  }

  handleUpdateAttribute = (payload: any) => {
    payload.user = this.state.user.id
    payload.project = this.state.idProject

    this.props.attributesUpdate(payload)

    this.setState({
      openAttributeToEdit: false
    })
  }

  handleUpdateListOrder = (payload: any) => {

    this.props.attributesUpdate({
      user: this.state.user.id,
      project: this.state.idProject,
      attributes: payload
    })
  }

  handleCloseEditionModal = () => {
    this.setState({
      currentEditIndex: -1,
      openAttributeToEdit: false
    })
  }

  handleChangeActive = (id: any, payload: boolean) => {

    this.props.attributesUpdate({
      user: this.state.user.id,
      project: this.state.idProject,
      id: id,
      active: payload ? 1 : 0
    })

  }

  render(): React.ReactNode {
      return(
        <div className='attributes-main-container'>
          <img className='dashboard-background' src={dashBackground} alt=""/>
          <Sidebar
            project={this.props.projectSelected}
          />
          <TableNew
            data={this.props.attributes}
            project={this.props.projectSelected}
            updateHierarchical={this.props.hierarchicalAttributesUpdate}
            handleDeleteAttribute={this.handleDeleteAttribute}
            handleOpenEditModal = {this.handleOpenEditModal}
            handleUpdateListOrder = {this.handleUpdateListOrder}
            currentEdiIndex={this.state.currentEditIndex}
            handleChangeActive = {this.handleChangeActive}
          />

          <Create
            handleCreateAttribute = {this.handleCreateAttribute}
          />

          {
            this.state.openAttributeToEdit ?
            <Edit
              handleUpdateAttribute = {this.handleUpdateAttribute}
              attributeToEdit={this.state.attributeToEdit}
              handleCloseEditionModal = {this.handleCloseEditionModal}
            />:null 
          }

          <Navbar
            logout={this.props.logoutUser}
          />

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.props.loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )
  }
}

const mapStateToProps = ({
    projectReducer,
    builderReducer,
    attributeReducer,
  }: any) => {
    const { attributes, loading } = attributeReducer;
    const { projectSelected } = projectReducer;
    const { selectedBuilder } = builderReducer;
    return { attributes, loading, projectSelected, selectedBuilder };
  };
  
  export default connect(mapStateToProps, {
    attributesList,
    attributesCreate,
    attributesUpdate,
    attributesDelete,
    hierarchicalAttributesUpdate,
    attributesUpdateValues,
    logoutUser,
  })(withParams(AttributesViews));