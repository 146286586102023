import React from 'react'
import axios from "axios";
import SidebarHeader from './header.component';
import ProjectsSidebar from './projects.components';
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';

import { Backdrop, CircularProgress } from "@mui/material";

import update from '../../UI/general/actualizacion.webp'
import mac from '../../assets/macos.png'
import win from '../../assets/windows.svg'

import {
    changeCurrentIndex,
    changeCurrentSubIndex,
    locationsListOne,
    projectUpdateTemplate,
    projectDownloadTemplateOffline
} from "../../redux/actions";

interface Props{
    project: any,
    currentIndex: number,
    currentSubindex: number,
    changeCurrentIndex: any,
    changeCurrentSubIndex: any,
    categories_pois: any,
    locationsListOne: any,
    projectUpdateTemplate: any,
    projectDownloadTemplateOffline: any,
    params: any,
    loading: any,
}

interface State{
    userString: any,
    user: any,
    idProject: any,
    currentDate: string,
    isUpdateConfirmation: boolean,
    isUpdateConfirmationDownload: boolean,
    isLoading: boolean,
    progress: number
}

function withParams(Component: any) {
    return (props: any) => <Sidebar {...props} params={useParams()} />;
}

class Sidebar extends React.Component<Props,State>{
    constructor(props: Props){
        super(props);
        this.state = {
            userString: localStorage.getItem("user"),
            user: "",
            idProject: this.props.params.id,
            currentDate: '',
            isUpdateConfirmation: false,
            isUpdateConfirmationDownload: false,
            isLoading: false,
            progress: 0
        }
    }

    componentDidMount(): void {
        if(this.state.userString){
            this.setState({
                user: JSON.parse(this.state.userString)
            }, () => {
                this.props.locationsListOne({user: this.state.user.id, project: this.state.idProject})
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        console.log(this.props.project);
        
        if(this.props.project !== prevProps.project && this.props.project.date_clear_cache !== null){
                var dateDic: any = {
                    'jan' : '01',
                    'feb' : '02',
                    'mar' : '03',
                    'apr' : '04',
                    'may' : '05',
                    'jun' : '06',
                    'jul' : '07',
                    'aug' : '08',
                    'sep' : '09',
                    'oct' : '10',
                    'nov' : '11',
                    'dec' : '12',
                    'january' : '01',
                    'febraury' : '02',
                    'march' : '03',
                    'april' : '04',
                    'june' : '06',
                    'july' : '07',
                    'august' : '08',
                    'september' : '09',
                    'october' : '10',
                    'november' : '11',
                    'december' : '12',
                }
                if(this.props.project.date_clear_cache){
                    var newDate = this.props.project.date_clear_cache.replaceAll('-',' ').toUpperCase().split(' ').slice(0,3)
                    newDate[1] = dateDic[newDate[1].toLowerCase()] 
                    var newTime = this.props.project.date_clear_cache.replaceAll('-',' ').toUpperCase().split(' ').slice(3,4)
                    var finalDate = newDate.join('/') + ' ' + newTime[0].split(':').splice(0,2).join(':')+this.props.project.date_clear_cache.replaceAll('-',' ').toUpperCase().split(' ').slice(4,5)
                    this.setState({
                        currentDate: finalDate
                    })
                }
            }
    }

    handleChange = (index: number) => {
        this.props.changeCurrentIndex(index)
    }

    handleChangeSubIndex = (index: number) => {
        this.props.changeCurrentSubIndex(index)
    }

    handleOpenUpdateConfirmation = () => {
        this.setState({
            isUpdateConfirmation: !this.state.isUpdateConfirmation
        })
    }

    handleOpenUpdateConfirmationDownload = () => {
        this.setState({
            isUpdateConfirmationDownload: !this.state.isUpdateConfirmationDownload
        })
    }

    handleUpdateMasterplanTemplate = () => {
        this.props.projectUpdateTemplate({
            project: this.state.idProject,
            builder: this.props.project.builder,
            builder_name: (this.props.project.builder_name).toLowerCase()
            .split(" ")
            .join("-")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
            slug: this.props.project.slug
        })
        
    }

    handleDownloadTemplateOffline = () => {
        this.props.projectDownloadTemplateOffline({
            project: this.state.idProject,
            builder: this.props.project.builder,
            builder_name: (this.props.project.builder_name).toLowerCase()
            .split(" ")
            .join("-")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
            slug: this.props.project.slug
        })
        
    }

    handleDeleteCache = () => {
        let Timer = setInterval(() => {
            if(this.state.progress < 90){
                this.setState({
                    progress: this.state.progress + 1
                })
            }
            else{
                clearInterval(Timer)
            }
        }, 111)
        this.setState({
            isLoading: true
        }, () => {
            axios.post('https://service-cloud-admin-container.etm6qeo1eo20q.us-east-1.cs.amazonlightsail.com/api/client/clear-cache/', {
                project: this.state.idProject
            })
            .then((response) => {
                if(response.status === 200){
                    this.setState({
                        isLoading: false,
                        progress: 0
                    })
                }
            })
        })

    }

    render(): React.ReactNode {
        
        return(
            <React.Fragment>
                
                <div className='sidebar-main'>
                    <SidebarHeader
                        project={this.props.project}
                    />
                    <div className='sidebar-divisor'/>
                    <ProjectsSidebar
                        project={this.props.project}
                        currentIndexReducer={this.props.currentIndex}
                        currentSubIndex = {this.props.currentSubindex}
                        handleChange={this.handleChange}
                        handleChangeSubIndex = {this.handleChangeSubIndex}
                        pois = {this.props.categories_pois}
                        user = {this.state.user}
                    />
                    {/* {
                        this.state.user.name === 'ADMIN' ?
                        <div className='update-template-masterplan-cache' style={{marginBottom: '1rem'}} onClick={() => {this.handleDeleteCache()}}>
                                <b>PUBLICAR CAMBIOS</b>
                                <div>Última vez {this.state.currentDate}</div>
                            </div>:null
                    } */}
                    <div className='update-template-masterplan-cache' style={{marginBottom: '1rem'}} onClick={() => {this.handleDeleteCache()}}>
                                <b>PUBLICAR CAMBIOS</b>
                                <div>Última vez {this.state.currentDate}</div>
                            </div>
                    {
                        this.state.user.name === 'ADMIN'   ?
                        <React.Fragment>
                            <div className='sidebar-divisor'/>
                            <div className='sideba-dev-text'>
                                Opciones para desarrolladores
                            </div>
                            <div className='update-template-masterplan' style={{marginBottom: '1rem'}} onClick={() => {this.handleOpenUpdateConfirmation()}}>
                                Actualizar template de maqueta
                            </div>
                            <div className='update-template-masterplan' style={{marginBottom: '1rem', pointerEvents: this.props.project.offline !== "null" && this.props.project.offline === "creando" ? 'none' : 'auto'}} onClick={() => {this.handleDownloadTemplateOffline()}}>
                                {this.props.project.offline !== "null" && this.props.project.offline === "creando" ? "Creando maqueta Offline..." : "Crear maqueta offline"}
                            </div>

                            
                            {this.props.project.offline !== "null" && this.props.project.offline === "creado" ? <div className='update-template-masterplan' style={{marginBottom: '1rem'}} onClick={() => {this.handleOpenUpdateConfirmationDownload()}}>
                                Descargar maqueta offline
                            </div>: <></>}
                            
                        </React.Fragment>:null
                    }
                </div>
                {this.state.isUpdateConfirmation ?
                    <div className={this.state.isUpdateConfirmation ? 'update-template-confirmation fade-in':'update-template-confirmation fade-out'}>
                    <div className='update-info-container'>
                        <img src={update} alt=''>
                        </img>
                        <div className='update-info-container-title'>¿Estás seguro?</div>
                        <div className='update-info-container-text'>Este cambio no puede ser revertido y puede generar incompatibilidades</div>
                        <div className='update-info-container-btns'>
                            <div className='update-accept' onClick={() => {
                                this.handleUpdateMasterplanTemplate()
                                this.handleOpenUpdateConfirmation()
                                }}>¡Si, Actualizar!</div>
                            <div className='update-cancel' onClick={() => {this.handleOpenUpdateConfirmation()}}>Cancelar</div>
                        </div>
                    </div>
                </div>:null
                }
                {this.state.isUpdateConfirmationDownload ?
                    <div className={this.state.isUpdateConfirmationDownload ? 'update-template-confirmation fade-in':'update-template-confirmation fade-out'}>
                    <div className='update-info-container'>
                        <div style={{display: 'flex'}}>
                            <a style={{width: '50%'}} href={this.props.project.url + '/' + this.props.project.slug + '_mac.zip'}><img src={mac} alt=''></img></a>
                            <a style={{width: '50%'}} href={this.props.project.url + '/' + this.props.project.slug + '_win.zip'}><img style={{width: '44%', paddingTop: '4%'}} src={win} alt=''></img></a>
                        </div>
                        
                        
                        <div className='update-info-container-title'>Descargar Maqueta Offline</div>
                        <div className='update-info-container-text'>Puedes descargar el archivo comprimido (.zip) con el ejecutable de ésta maqueta Offline para sistemas Windows y Mac</div>
                        <div className='update-info-container-btns'>
                            <div className='update-cancel' onClick={() => {this.handleOpenUpdateConfirmationDownload()}}>Cancelar</div>
                        </div>
                    </div>
                </div>:null
                }
                {
                <Backdrop sx={{ color: "#fff" }} open={this.state.isLoading || this.props.loading}>
                    <CircularProgress color="inherit" />
                    {!this.props.loading ? <div className='progress-bar-parent'>
                        <div className='progress-bar-child' style={{
                            width: this.state.progress + '%'
                        }}></div>
                    </div>:null}
                    {!this.props.loading ? <div className='progress-number'>{this.state.progress}%</div>:null}
                </Backdrop>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({
    sidebarReducer,
    locationsReducer,
    projectReducer
}: any) => {
    const { categories_pois } = locationsReducer;
    const {currentIndex, currentSubindex} = sidebarReducer
    const { loading } = projectReducer;
    return {currentIndex, currentSubindex, categories_pois, loading}
}

export default connect(mapStateToProps, {
    changeCurrentIndex,
    changeCurrentSubIndex,
    locationsListOne,
    projectUpdateTemplate,
    projectDownloadTemplateOffline
})(withParams(Sidebar))

