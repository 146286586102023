const SidebarHeader = ({project, collapse}: any) => {
    return(
        <div className='sidebar-header'>
            <div className='sidebar-header-title'>
                {
                    project.name
                }
            </div>
            <div className='sidebar-header-subtitle'>
                {
                    project.builder_name
                }
            </div>
        </div>
    )
}

export default SidebarHeader