import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import profile from "../../UI/navbar/avatar.jpg";

// material-ui
import { useTheme } from "@mui/material/styles";

import { Avatar, Box, Chip, ClickAwayListener, List, ListItemButton, ListItemIcon, ListItemText, Paper, Popper, Typography } from "@mui/material";
import { IconLogout, IconSettings } from "@tabler/icons";

/*eslint-disable*/

function ProfileNavBar({logOut}: any): any {
  const theme: any = useTheme();
  const anchorRef: any = useRef(null);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState('');
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [hour, setHour] = useState(new Date())
  

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
      }
      setOpen(false);
  };

  const handleListItemClick = (event: any, index: number, route: string = '') => {
      setSelectedIndex(index);
      handleClose(event);

      if (route && route !== '') {
          navigate(route);
      }
  };

  return (
    <Box sx={{ float: "right", display: "flex" }}>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: "rgb(255, 240, 247)",
          backgroundColor: "rgb(255, 240, 247)",
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: "rgb(237, 8, 116)",
            background: `${"rgb(237, 8, 116)"}!important`,
            color: "rgb(255, 240, 247)",
            "& svg": {
              stroke: "rgb(255, 240, 247)",
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={profile}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            color={"rgb(237, 8, 116)"}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        sx={{
            position: "absolute !important",
            right: "3%",
            top: "100% !important",
            left:"auto !important",
            width:"20%",
            minWidth:"400px"
        }}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }: any) => (
        <Paper sx={{}}>
        <ClickAwayListener onClickAway={handleClose}>
            <div className="menu-container">
            <Box sx={{ p: 2 }}>
            {/*<Divider />
                <Card
                sx={{
                bgcolor: theme.palette.primary.light,
                my: 2,
                }}
            >
                <CardContent>
                <Grid container spacing={3} direction="column">
                    <Grid item>
                    <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item>
                        <Typography variant="subtitle1">
                            Start DND Mode
                        </Typography>
                        </Grid>
                        <Grid item>
                        <Switch
                            color="primary"
                            checked={sdm}
                            onChange={(e) => setSdm(e.target.checked)}
                            name="sdm"
                            size="small"
                        />
                        </Grid>
                    </Grid>
                    </Grid>
                    <Grid item>
                    <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item>
                        <Typography variant="subtitle1">
                            Allow Notifications
                        </Typography>
                        </Grid>
                        <Grid item>
                        <Switch
                            checked={notification}
                            onChange={(e) =>
                            setNotification(e.target.checked)
                            }
                            name="sdm"
                            size="small"
                        />
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
                </CardContent>
            </Card> */}
            <List
                component="nav"
                sx={{
                width: "100%",
                maxWidth: 350,
                minWidth: 300,
                backgroundColor: theme.palette.background.paper,
                borderRadius: "10px",
                [theme.breakpoints.down("md")]: {
                    minWidth: "100%",
                },
                "& .MuiListItemButton-root": {
                    mt: 0.5,
                },
                }}
            >
                <ListItemButton
                sx={{
                    borderRadius: `12px`,
                }}
                selected={selectedIndex === 4}
                onClick={() => logOut(navigate)}
                >
                <ListItemIcon>
                    <IconLogout stroke={1.5} size="1.3rem" />
                </ListItemIcon>
                <ListItemText
                    primary={
                    <Typography variant="body2">Logout</Typography>
                    }
                />
                </ListItemButton>
            </List>
            </Box>
            </div>
        </ClickAwayListener>
        </Paper>
        )}
      </Popper>
    </Box>
  );
}

export default ProfileNavBar;