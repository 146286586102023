import axios from 'axios'
import {ApiConfig} from './../constants/defaultValues'

export async function login(email: string, password: string) {
    return axios.post(ApiConfig.URL2 + "auth/", {
        email: email,
        password: password
    });
}
export async function twoFactorValidateModel(secret: any) {
    return axios.post(ApiConfig.URL2 + "auth/two-factor/validate", secret);
}
export async function twoFactorRegisterModel(secret: any) {
    return axios.post(ApiConfig.URL2 + "auth/two-factor/register", secret);
}