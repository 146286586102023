import {
    LIST_PROJECT,
    LIST_PROJECT_SUCCESS,
    LIST_PROJECT_SUCCESS2,
    LIST_PROJECT_ERROR,
    SELECTED_PROJECT_SUCCESS,
    SELECTED_PROJECT_ERROR,
    LIST_PROJECT_ONE,
    CREATE_PROJECT,
    UPDATE_PROJECT,
    ONE_PROJECT,
    ADD_BASE_MODULES_PROJECT,
    ADD_LOGO_ADICIONAL_PROJECT,
    DELETE_PROJECT,
    UPDATE_INSIDE_PROJECT,
    UPDATE_TEMPLATE_PROJECT,
    DOWNLOAD_TEMPLATE_PROJECT,
    UPDATE_TEMPLATE_PROJECT_SUCCESS
} from '../actions';

export const projectList = (project: any) => ({
    type: LIST_PROJECT,
    payload: { project }
});
export const projectListSuccess = (project: any) => ({
    type: LIST_PROJECT_SUCCESS,
    payload: project
});
export const projectListSuccess2 = (project: any) => ({
    type: LIST_PROJECT_SUCCESS2,
    payload: project
});
export const projectListError = (message: any) => ({
    type: LIST_PROJECT_ERROR,
    payload: { message }
});
export const projectSelectedSuccess = (project: any) => ({
    type: SELECTED_PROJECT_SUCCESS,
    payload: project
});
export const projectSelectedError = (message: any) => ({
    type: SELECTED_PROJECT_ERROR,
    payload: { message }
});
export const projectListOne = (builder: any, pageSize: any = null, currentPage: any = null, orderBy: any = null, search: any = null, user: any = null) => {
    return ({
        type: LIST_PROJECT_ONE,
        payload: { builder, pageSize, currentPage, orderBy, search, user }
    })
};
export const projectOne = (project: any) =>({
    type: ONE_PROJECT,
    payload: { project }
})
export const projectAddLogo = (project: any) =>({
    type: ADD_LOGO_ADICIONAL_PROJECT,
    payload: { project }
})
export const projectAddBase = (baseModules: any, id: any) => {
    return ({
        type: ADD_BASE_MODULES_PROJECT,
        payload: { baseModules, id }
    })
};
export const projectCreate = (project: any, history: any) => ({
    type: CREATE_PROJECT,
    payload: { project, history }
});
export const projectUpdate = (project: any, id: any, history: any = null) => ({
    type: UPDATE_PROJECT,
    payload: { project, id, history }
});
export const projectDelete = (builder: any, id: any) => ({
    type: DELETE_PROJECT,
    payload: { builder, id }
});

export const projectUpdateInside = (project: any) => ({
    type: UPDATE_INSIDE_PROJECT,
    payload: { project }
}); 

export const projectUpdateTemplate = (project: any) => ({
    type: UPDATE_TEMPLATE_PROJECT,
    payload: { project }
});

export const projectDownloadTemplateOffline = (project: any) => ({
    type: DOWNLOAD_TEMPLATE_PROJECT,
    payload: { project }
}); 

export const projectUpdateTemplateSucces = () => ({
    type: UPDATE_TEMPLATE_PROJECT_SUCCESS,
    payload: {}
}); 