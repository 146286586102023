/* GENERAL */
export const UPDATE_ALERT = "UPDATE_ALERT";
export const CLOSE_ALERT = "CLOSE_ALERT";
/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_STATE = "LOGIN_USER_STATE"
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_TWO_FACTOR_REGISTER = "LOGIN_TWO_FACTOR_REGISTER";
export const LOGIN_TWO_FACTOR_VALIDATE = "LOGIN_TWO_FACTOR_VALIDATE";

/* USER */
export const LIST_USER = "LIST_USER";
export const LIST_USER_SUCCESS = "LIST_USER_SUCCESS";
export const LIST_USER_ERROR = "LIST_USER_ERROR";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

/* BUILDER */
export const LIST_BUILDER = "LIST_BUILDER";
export const LIST_BUILDER_SUCCESS = "LIST_BUILDER_SUCCESS";
export const LIST_BUILDER_ERROR = "LIST_BUILDER_ERROR";
export const SELECTED_BUILDER_SUCCESS = "SELECTED_BUILDER_SUCCESS";
export const SELECTED_BUILDER_ERROR = "SELECTED_BUILDER_ERROR";
export const CREATE_BUILDER = "CREATE_BUILDER";
export const UPDATE_BUILDER = "UPDATE_BUILDER";
export const DELETE_BUILDER = "DELETE_BUILDER";
export const ONE_BUILDER = "ONE_BUILDER";

/* PROJECT */
export const LIST_PROJECT = "LIST_PROJECT";
export const LIST_PROJECT_SUCCESS = "LIST_PROJECT_SUCCESS";
export const LIST_PROJECT_SUCCESS2= "LIST_PROJECT_SUCCESS2";
export const LIST_PROJECT_ERROR = "LIST_PROJECT_ERROR";
export const SELECTED_PROJECT_SUCCESS = "SELECTED_PROJECT_SUCCESS";
export const SELECTED_PROJECT_ERROR = "SELECTED_PROJECT_ERROR";
export const LIST_PROJECT_ONE = "LIST_PROJECT_ONE";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const ONE_PROJECT = "ONE_PROJECT";
export const ADD_BASE_MODULES_PROJECT = "ADD_BASE_MODULES_PROJECT";
export const UPDATE_INSIDE_PROJECT = "UPDATE_INSIDE_PROJECT"
export const UPDATE_TEMPLATE_PROJECT = "UPDATE_TEMPLATE_PROJECT";
export const DOWNLOAD_TEMPLATE_PROJECT = "DOWNLOAD_TEMPLATE_PROJECT";
export const UPDATE_TEMPLATE_PROJECT_SUCCESS = "UPDATE_TEMPLATE_PROJECT_SUCCESS";
export const ADD_LOGO_ADICIONAL_PROJECT = "ADD_LOGO_ADICIONAL_PROJECT";

/* MODULES */
export const LIST_MODULES = "LIST_MODULES";
export const LIST_MODULES_SUCCESS = "LIST_MODULES_SUCCESS";
export const LIST_MODULES_ERROR = "LIST_MODULES_ERROR";
export const UPDATE_MODULES = "UPDATE_MODULES";

/* PALETTE_CHROMATIC */
export const LIST_PALETTE_CHROMATIC = "LIST_PALETTE_CHROMATIC";
export const LIST_PALETTE_CHROMATIC_SUCCESS = "LIST_PALETTE_CHROMATIC_SUCCESS";
export const LIST_PALETTE_CHROMATIC_ERROR = "LIST_PALETTE_CHROMATIC_ERROR";
export const UPDATE_PALETTE_CHROMATIC = "UPDATE_PALETTE_CHROMATIC";

/* ATTRIBUTES */
export const LIST_ATTRIBUTES = "LIST_ATTRIBUTES";
export const LIST_ATTRIBUTES_SUCCESS = "LIST_ATTRIBUTES_SUCCESS";
export const LIST_ATTRIBUTES_ERROR = "LIST_ATTRIBUTES_ERROR";
export const LIST_ATTRIBUTES_ONE = "LIST_ATTRIBUTES_ONE";
export const CREATE_ATTRIBUTES = "CREATE_ATTRIBUTES";
export const UPDATE_ATTRIBUTES = "UPDATE_ATTRIBUTES";
export const DELETE_ATTRIBUTES = "DELETE_ATTRIBUTES";
export const ONE_ATTRIBUTES = "ONE_ATTRIBUTES";
export const ADD_BASE_MODULES_ATTRIBUTES = "ADD_BASE_MODULES_ATTRIBUTES";
export const UPDATE_ATTRIBUTES_HIERARCHICAL = "UPDATE_ATTRIBUTES_HIERARCHICAL";
export const UPDATE_ATTRIBUTES_VALUE = "UPDATE_ATTRIBUTES_VALUE";

/* SIDEBAR */
export const INDEX_SIDEBAR = "INDEX_SIDEBAR"
export const SUBINDEX_SIDEBAR = "SUBINDEX_SIDEBAR"

/* GALLERIES */
export const OPEN_GALLERIES = "OPEN_GALLERIES"
export const LIST_GALLERIES = "LIST_GALLERIES";
export const LIST_GALLERIES_SUCCESS = "LIST_GALLERIES_SUCCESS";
export const LIST_GALLERIES_ERROR = "LIST_GALLERIES_ERROR";
export const LIST_GALLERIES_ONE = "LIST_GALLERIES_ONE";
export const CREATE_GALLERIES = "CREATE_GALLERIES";
export const UPDATE_GALLERIES = "UPDATE_GALLERIES";
export const DELETE_GALLERIES = "DELETE_GALLERIES";
export const ONE_GALLERIES = "ONE_GALLERIES";
export const CREATE_GALLERIES_FOLDER = "CREATE_GALLERIES_FOLDER";
export const UPLOAD_GALLERIES_FILES = "UPLOAD_GALLERIES_FILES";
export const MODAL_GALLERIES_ARRAY = "MODAL_GALLERIES_ARRAY";

/*AMENITIES*/
export const LIST_AMENITIES = "LIST_AMENITIES";
export const LIST_AMENITIES_SUCCESS = "LIST_AMENITIES_SUCCESS";
export const LIST_AMENITIES_ERROR = "LIST_AMENITIES_ERROR";
export const ONE_AMENITIE = "ONE_AMENITIE";
export const CREATE_AMENITIES = "CREATE_AMENITIES";
export const UPDATE_AMENITIES = "UPDATE_AMENITIES";
export const DELETE_AMENITIES = "DELETE_AMENITIES";
export const SET_GALLERIES_AMENITIES = "SET_GALLERIES_AMENITIES";

/*LOCATION*/
export const LIST_LOCATION = "LIST_LOCATION";
export const LIST_LOCATION_SUCCESS = "LIST_LOCATION_SUCCESS";
export const LIST_LOCATION_ERROR = "LIST_LOCATION_ERROR";
export const LIST_ONE_LOCATION = "LIST_ONE_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELIMITACION_LOTE = "DELIMITACION_LOTE";
export const SET_ROADS_ACCESS = "SET_ROADS_ACCESS";
export const UPDATE_ROADS_ACCESS = "UPDATE_ROADS_ACCESS";
export const DELETE_ROADS_ACCESS = "DELETE_ROADS_ACCESS";
export const SET_POIS_CATEGORY = "SET_POIS_CATEGORY";
export const UPDATE_POIS_CATEGORY = "UPDATE_POIS_CATEGORY";
export const SET_POIS = "SET_POIS";
export const UPDATE_POIS = "UPDATE_POIS";
export const DELETE_POIS = "DELETE_POIS";

/*ICONS*/
export const OPEN_ICONS = "OPEN_ICONS";
export const LIST_ICONS = "LIST_ICONS";
export const LIST_ICONS_SUCCESS = "LIST_ICONS_SUCCESS"
export const LIST_ICONS_ERROR = "LIST_ICONS_ERROR"
export const CREATE_ICONS = "CREATE_ICONS"

/*INMUEBLES*/
export const LIST_INMUEBLES = "LIST_INMUEBLES";
export const LIST_INMUEBLES_SUCCESS = "LIST_INMUEBLES_SUCCESS"
export const LIST_INMUEBLES_ERROR = "LIST_INMUEBLES_ERROR"
export const SET_INMUEBLES = "SET_INMUEBLES";
export const UPDATE_INMUEBLES = "UPDATE_INMUEBLES";
export const UPDATE_INMUEBLES_SUCCESS = "UPDATE_INMUEBLES_SUCCESS"
export const UPDATE_INMUEBLES_ERROR = "UPDATE_INMUEBLES_ERROR"
export const DELETE_INMUEBLES = "DELETE_INMUEBLES";
export const COPY_INMUEBLES = "COPY_INMUEBLES";
export const SET_GALLERIES_INMUEBLES = "SET_GALLERIES_INMUEBLES";
export const UPDATE_INMUEBLES_TYPE_FILTER = "UPDATE_INMUEBLES_TYPE_FILTER";
export const SET_GALLERIES_ATTRIBUTES = "SET_GALLERIES_ATTRIBUTES";
export const ACTIVE_AVAILABILITY = "ACTIVE_AVAILABILITY";

/*MAILING*/
export const LIST_MAILING = "LIST_MAILING";
export const LIST_MAILING_SUCCESS = "LIST_MAILING_SUCCESS"
export const LIST_MAILING_ERROR = "LIST_MAILING_ERROR"
export const SET_MAILING = "SET_MAILING";

/*SOLAR*/
export const LIST_SOLAR = "LIST_SOLAR";
export const LIST_SOLAR_SUCCESS = "LIST_SOLAR_SUCCESS"
export const LIST_SOLAR_ERROR = "LIST_SOLAR_ERROR"
export const SET_SOLAR = "SET_SOLAR";
export const UPDATE_SOLAR = "UPDATE_SOLAR";
export const DELETE_SOLAR = "DELETE_SOLAR";
export const TXT_SOLAR = "TXT_SOLAR";

/*CONTACT*/
export const LIST_CONTACT = "LIST_CONTACT";
export const LIST_CONTACT_SUCCESS = "LIST_CONTACT_SUCCESS"
export const LIST_CONTACT_ERROR = "LIST_CONTACT_ERROR"
export const SET_CONTACT = "SET_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";

/*SPIN*/
export const LIST_SPIN  = "LIST_SPIN";
export const LIST_SPIN_SUCCESS = "LIST_SPIN_SUCCESS"
export const LIST_SPIN_ERROR = "LIST_SPIN_ERROR"
export const SET_SPIN  = "SET_SPIN";
export const UPDATE_SPIN = "UPDATE_SPIN";
export const DELETE_SPIN = "DELETE_SPIN";
export const UPDATE_SCRIPT = "UPDATE_SCRIPT"
export const LIST_SCRIPT = "LIST_SCRIPT"


export * from "./general/actions";
export * from "./auth/actions";
export * from "./user/actions";
export * from "./builder/actions";
export * from "./project/actions";
export * from "./module/actions";
export * from "./palettechromatic/actions";
export * from "./attribute/actions";
export * from "./sidebar/actions";
export * from "./galleries/actions";
export * from './amenities/actions';
export * from './location/actions';
export * from './icons/actions';
export * from './properties/actions';
export * from './mailing/actions';
export * from './solar/actions';
export * from './spin/actions';
export * from './contact/actions';