import React from 'react'

import Edit from './../../UI/general/Edit.svg'
import Delete from './../../UI/general/close.svg'

interface ListAsoleacionProps{
    solarList: any,
    handleUpdate: any,
    handleDelete: any,
    handleSelectedToUpdate: any
}

const ListAsoleacion = ({solarList, handleUpdate, handleDelete, handleSelectedToUpdate}: ListAsoleacionProps) => {
    return(
        <div className='asoleacion-list'>
            {
                solarList.map((video: any, index: number) => 
                    <div className='asoleacion-video-container'>
                        <div className='asoleacion-video-title'>
                            {video.name}
                        </div>
                        <div className='asoleacion-video-desc'>
                            {video.description}
                        </div>
                        <div className='asoleacion-edit' onClick={() => {handleSelectedToUpdate(video)}}>
                            <img src={Edit} alt=""/>
                        </div>
                        <div className='asoleacion-delete' onClick={() => {handleDelete(video.id)}}>
                            <img src={Delete} alt=""/>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default ListAsoleacion