import {
  LIST_ATTRIBUTES,
  LIST_ATTRIBUTES_SUCCESS,
  LIST_ATTRIBUTES_ERROR,
  CREATE_ATTRIBUTES,
  UPDATE_ATTRIBUTES,
  DELETE_ATTRIBUTES,
  UPDATE_ATTRIBUTES_HIERARCHICAL,
  UPDATE_ATTRIBUTES_VALUE
  } from '../actions';

  export const attributesList = (proyecto: object) => ({
    type: LIST_ATTRIBUTES,
    payload: { proyecto }
  });
  export const attributesListSuccess = (attribute: any) => ({
    type: LIST_ATTRIBUTES_SUCCESS,
    payload: attribute
  });
  export const attributesListError = (message: any) => ({
    type: LIST_ATTRIBUTES_ERROR,
    payload: { message }
  });
  export const attributesCreate = (attribute: any) => ({
    type: CREATE_ATTRIBUTES,
    payload: { attribute }
  });
  export const attributesDelete = (attribute: any) => ({
    type: DELETE_ATTRIBUTES,
    payload: { attribute }
  });
  export const attributesUpdate = (attribute: any) => ({
    type: UPDATE_ATTRIBUTES,
    payload: { attribute }
  });
  export const hierarchicalAttributesUpdate = (attribute: any) => ({
    type: UPDATE_ATTRIBUTES_HIERARCHICAL,
    payload: { attribute }
  });
  export const attributesUpdateValues = (attribute: any) => ({
    type: UPDATE_ATTRIBUTES_VALUE,
    payload: { attribute }
  });