import React from 'react'
import galleryIco from './../../UI/general/gallery.svg'

const Cards = ({currentArraySelected, handleSelectedGallery}: any) => {



    return(
        <div className='gallery-cards-container-modal'>
            {
                currentArraySelected.map((element: any, index: number) => 
                    <div className='individual-gallery-card-modal' key={element.nombre + index} 
                        onClick={() => {handleSelectedGallery(element.nombre, element.slug, element.innerArray, index, element.isImage, element.slugEn, element.padre)}}
                    >
                        <img src={galleryIco} alt=""
                        />
                        <div className='individual-gallery-name-modal'
                        >
                            {element.nombre}
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Cards 