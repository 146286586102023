import {
  LIST_INMUEBLES,
  LIST_INMUEBLES_SUCCESS,
  LIST_AMENITIES_ERROR,
  SET_INMUEBLES,
  UPDATE_INMUEBLES,
  COPY_INMUEBLES,
  DELETE_INMUEBLES,
  SET_GALLERIES_INMUEBLES,
  UPDATE_INMUEBLES_SUCCESS,
  UPDATE_INMUEBLES_ERROR,
} from "../actions";

/*eslint-disable*/

const INIT_STATE = {
  properties: [],
  attibutes_galleries: [],
  filter: "",
  loading: false,
  message: "",
};

export default (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case LIST_INMUEBLES:
      return { ...state, loading: true, message: "" };
    case LIST_INMUEBLES_SUCCESS:
      return {
        ...state,
        properties: action.payload.data,
        attibutes_galleries: action.payload.attibutes_galleries,
        filter: action.payload.filter,
        loading: false,
        message: "Exito",
      };
    case LIST_AMENITIES_ERROR:
      return {
        ...state,
        attributes: [],
        attibutes_galleries: [],
        loading: false,
        message: action.payload.message,
      };
    case UPDATE_INMUEBLES_SUCCESS:
        console.log(action.payload);
        
      const findIndexById = (array: any, id: any) => {
        return array.findIndex((obj: any) => obj.id === id);
      };
      let tempProperties: any = state.properties;

      const objetoAActualizar = action.payload.inmueble.data.find(
        (obj: any) => obj.id === action.payload.id
      );

      if (objetoAActualizar) {
        // Encuentra el índice del objeto en array1
        const index = findIndexById(tempProperties, objetoAActualizar.id);

        if (index !== -1) {
          // Actualiza el objeto en array1 con el objeto correspondiente de array2
          tempProperties[index] = objetoAActualizar;
        }
        console.log(tempProperties);
        
      }
      return {
        ...state,
        properties: tempProperties,
        attibutes_galleries: action.payload.inmueble.attibutes_galleries,
        filter: action.payload.inmueble.filter,
        loading: false,
        message: "Exito",
      };
    case UPDATE_INMUEBLES_ERROR:
      return {
        ...state,
        attributes: [],
        attibutes_galleries: [],
        loading: false,
        message: action.payload.message,
      };
    case SET_INMUEBLES:
      return { ...state, loading: true, message: "" };
    case UPDATE_INMUEBLES:
      return { ...state, loading: true, message: "" };
    case COPY_INMUEBLES:
      return { ...state, loading: true, message: "" };
    case DELETE_INMUEBLES:
      return { ...state, loading: true, message: "" };
    case SET_GALLERIES_INMUEBLES:
      return { ...state, loading: true, message: "" };
    default:
      return { ...state };
  }
};
