import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { 
  list,
  create, 
  update, 
  remove, 
  createFolder, 
  uploadFiles ,
} from "../../models/galleries";

/*eslint-disable*/

import {  setGalleries } from './../../models/amenities'

import { LIST_GALLERIES, CREATE_GALLERIES, UPDATE_GALLERIES, DELETE_GALLERIES, CREATE_GALLERIES_FOLDER, UPLOAD_GALLERIES_FILES } from "../actions";

import { galleriesListSuccess, galleriesListError } from "./actions";

import {
  projectSelectedSuccess,
  projectSelectedError,
} from "../project/actions";
import {
  builderSelectedSuccess,
  builderSelectedError,
} from "../builder/actions";

/*eslint-disable*/

interface categorieResponse {
  categorieItf: any;
}

export function* watchGalleriesList() {
  yield takeEvery(LIST_GALLERIES, galleriesList);
}

export function* watchGalleriesCreate() {
  yield takeEvery(CREATE_GALLERIES, galleriesCreate);
}

export function* watchGalleriesUpdate() {
  yield takeEvery(UPDATE_GALLERIES, galleriesUpdate);
}

export function* watchGalleriesDelete() {
  yield takeEvery(DELETE_GALLERIES, galleriesDelete);
}

export function* watchGalleriesCreateFolder() {
  yield takeEvery(CREATE_GALLERIES_FOLDER, galleriesCreateFolder);
}

export function* watchGalleriesUploadFiles() {
  yield takeEvery(UPLOAD_GALLERIES_FILES, galleriesUploadFiles);
}

const galleriesListAsync = async (proyecto: any): Promise<categorieResponse> =>
  await list(proyecto)
    .then((categorieItf) => categorieItf)
    .catch((error) => error);

function* galleriesList({ payload }: any): any {
  const { proyecto, history } = payload;
  try {
    const galleriesListData = yield call(galleriesListAsync, proyecto);

    if (galleriesListData.status === 200) {
      yield put(galleriesListSuccess(galleriesListData.data.galleries));
      yield put(projectSelectedSuccess(galleriesListData.data.project));
      yield put(builderSelectedSuccess(galleriesListData.data.builder));
    } else {
      yield put(galleriesListError(galleriesListData));
      yield put(projectSelectedError(galleriesListData));
      yield put(builderSelectedError(galleriesListData));
    }
  } catch (error) {
    yield put(galleriesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const galleriesCreateAsync = async (
  categorie: any
): Promise<categorieResponse> =>
  await create(categorie)
    .then((categorieItf) => categorieItf)
    .catch((error) => error);

function* galleriesCreate({ payload }: any): any {
  const { categorie } = payload;
  try {
    const galleriesListData = yield call(galleriesCreateAsync, categorie);
    if (galleriesListData.status === 200) {
      yield put(galleriesListSuccess(galleriesListData.data.data.galleries));
      yield put(projectSelectedSuccess(galleriesListData.data.data.project));
      yield put(builderSelectedSuccess(galleriesListData.data.data.builder));
    } else {
      yield put(galleriesListError(galleriesListData));
      yield put(projectSelectedError(galleriesListData));
      yield put(builderSelectedError(galleriesListData));
    }
  } catch (error) {
    yield put(galleriesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const galleriesUpdateAsync = async (
  gallerie: any
): Promise<categorieResponse> =>
  await update(gallerie)
    .then((categorieItf) => categorieItf)
    .catch((error) => error);

function* galleriesUpdate({ payload }: any): any {
  const { gallerie } = payload;
  try {
    const galleriesListData = yield call(galleriesUpdateAsync, gallerie);
    if (galleriesListData.status === 200) {
      yield put(galleriesListSuccess(galleriesListData.data.galleries));
      yield put(projectSelectedSuccess(galleriesListData.data.project));
      yield put(builderSelectedSuccess(galleriesListData.data.builder));
    } else {
      yield put(galleriesListError(galleriesListData));
      yield put(projectSelectedError(galleriesListData));
      yield put(builderSelectedError(galleriesListData));
    }
  } catch (error) {
    yield put(galleriesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const galleriesDeleteAsync = async (
  categorie: any
): Promise<categorieResponse> =>
  await remove(categorie)
    .then((categorieItf) => categorieItf)
    .catch((error) => error);

function* galleriesDelete({ payload }: any): any {
  const { gallerie } = payload;
  try {
    const galleriesListData = yield call(galleriesDeleteAsync, gallerie);
    if (galleriesListData.status === 200) {
      yield put(galleriesListSuccess(galleriesListData.data.galleries));
      yield put(projectSelectedSuccess(galleriesListData.data.project));
      yield put(builderSelectedSuccess(galleriesListData.data.builder));
    } else {
      yield put(galleriesListError(galleriesListData));
      yield put(projectSelectedError(galleriesListData));
      yield put(builderSelectedError(galleriesListData));
    }
  } catch (error) {
    yield put(galleriesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const galleriesCreateFolderAsync = async (
  folder: any
): Promise<categorieResponse> =>
  await createFolder(folder)
    .then((categorieItf) => categorieItf)
    .catch((error) => error);

function* galleriesCreateFolder({ payload }: any): any {
  const { folder } = payload;
  try {
    const galleriesListData = yield call(galleriesCreateFolderAsync, folder);
    if (galleriesListData.status === 200) {
      yield put(galleriesListSuccess(galleriesListData.data.data.galleries));
      yield put(projectSelectedSuccess(galleriesListData.data.data.project));
      yield put(builderSelectedSuccess(galleriesListData.data.data.builder));
    } else {
      yield put(galleriesListError(galleriesListData));
      yield put(projectSelectedError(galleriesListData));
      yield put(builderSelectedError(galleriesListData));
    }
  } catch (error) {
    yield put(galleriesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const galleriesUploadFilesAsync = async (
  files: any
): Promise<categorieResponse> =>
  await uploadFiles(files)
    .then((categorieItf) => categorieItf)
    .catch((error) => error);

function* galleriesUploadFiles({ payload }: any): any {
  const { files } = payload;
  try {
    const galleriesListData = yield call(galleriesUploadFilesAsync, files);
    
    if (galleriesListData.status === 200) {
      yield put(galleriesListSuccess(galleriesListData.data.galleries));
      yield put(projectSelectedSuccess(galleriesListData.data.project));
      yield put(builderSelectedSuccess(galleriesListData.data.builder));
    } else {
      yield put(galleriesListError(galleriesListData));
      yield put(projectSelectedError(galleriesListData));
      yield put(builderSelectedError(galleriesListData));
    }
  } catch (error) {
    yield put(galleriesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGalleriesList), 
    fork(watchGalleriesCreate),
    fork(watchGalleriesUpdate),
    fork(watchGalleriesDelete), 
    fork(watchGalleriesCreateFolder),
    fork(watchGalleriesUploadFiles),
  ]);
}
