import React from 'react'
import close from './../../UI/general/close.svg'

import { IconEdit } from "@tabler/icons";

const ShowGalleriesLinks = ({galleries, handleDeleteGallery, handleOpenEditionModeLink}: any) => {

    return(
        <div className='gallery-links-preview'>
            {
                galleries.map((link: any, index: number) => 
                    link.type === "list" ?
                    <div className='gallery-link-container'>
                        <div className='gallery-recorrido-name-des-container'>
                            <div className='gallery-recorrido-name'>{link.name}</div>
                            <div className='gallery-recorrido-desc'>{link.description}</div>
                        </div>
                        <div className='preview-container'>
                                <a href={link.link} rel='noreferrer' target='_blank'>Vista preview</a>
                        </div>
                        <div className='edit-links' onClick={() => {handleOpenEditionModeLink(link)}}>
                            <IconEdit />
                        </div>
                        <div className='close-gallery' onClick={() => {handleDeleteGallery(link.id)}}>
                            <img src={close} alt=""/>
                        </div>
                        <div className='gallery-link-url'>{link.value}</div>
                    </div>:null
                )
            }
        </div>
    )
}

export default ShowGalleriesLinks