import { all, call, delay, fork, put, takeEvery } from "redux-saga/effects";
import {
  list,
  create,
  remove,
  update,
  hierarchicalUpdate,
  valuesUpdate,
} from "../../models/attributes";

import {
  LIST_ATTRIBUTES,
  CREATE_ATTRIBUTES,
  UPDATE_ATTRIBUTES,
  DELETE_ATTRIBUTES,
  UPDATE_ATTRIBUTES_HIERARCHICAL,
  UPDATE_ATTRIBUTES_VALUE
} from "../actions";

import { attributesListSuccess, attributesListError } from "./actions";

import {
  projectSelectedSuccess,
  projectSelectedError,
} from "../project/actions";
import {
  builderSelectedSuccess,
  builderSelectedError,
} from "../builder/actions";
import { showAlert } from "../general/actions";

interface attributeResponse {
  AttributeItf: any;
}

/*eslint-disable*/

export function* watchattributesList() {
  yield takeEvery(LIST_ATTRIBUTES, attributesList);
}

export function* watchattributesCreate() {
  yield takeEvery(CREATE_ATTRIBUTES, attributesCreate);
}

export function* watchattributesUpdate() {
  yield takeEvery(UPDATE_ATTRIBUTES, attributesUpdate);
}

export function* watchattributesDelete() {
  yield takeEvery(DELETE_ATTRIBUTES, attributesDelete);
}

export function* watchHierarchicalAttributesUpdate() {
  yield takeEvery(UPDATE_ATTRIBUTES_HIERARCHICAL, hierarchicalAttributesUpdate);
}

export function* watchattributesUpdateValue() {
  yield takeEvery(UPDATE_ATTRIBUTES_VALUE, attributesUpdateValues);
}

const attributesListAsync = async (proyecto: any): Promise<attributeResponse> =>
  await list(proyecto)
    .then((AttributeItf) => AttributeItf)
    .catch((error) => error);

function* attributesList({ payload }: any): any {
  const { proyecto } = payload;
  try {
    const attributesListData = yield call(attributesListAsync, proyecto);
    if (attributesListData.status === 200) {
      yield put(attributesListSuccess(attributesListData.data.attributes));
      yield put(projectSelectedSuccess(attributesListData.data.projects));
      yield put(builderSelectedSuccess(attributesListData.data.builders));
    } else {
      yield put(attributesListError(attributesListData));
      yield put(projectSelectedError(attributesListData));
      yield put(builderSelectedError(attributesListData));
    }
  } catch (error) {
    yield put(attributesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
  }
}

const attributesCreateAsync = async (
  attribute: any
): Promise<attributeResponse> =>
  await create(attribute)
    .then((AttributeItf) => AttributeItf)
    .catch((error) => error);

function* attributesCreate({ payload }: any): any {
  const { attribute } = payload;
  try {
    const attributesListData = yield call(attributesCreateAsync, attribute);
    if (attributesListData.status === 200) {
      yield put(attributesListSuccess(attributesListData.data.attributes));
      yield put(projectSelectedSuccess(attributesListData.data.projects));
      yield put(builderSelectedSuccess(attributesListData.data.builders));
      yield put(
        showAlert({
          message: "Atributo de inmuebles creado con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Atributo de inmuebles creado con exito.",
          type: "success",
          show: false,
        })
      );
    } else {
      yield put(attributesListError(attributesListData));
      yield put(projectSelectedError(attributesListData));
      yield put(builderSelectedError(attributesListData));
      yield put(
        showAlert({
          message: "Error al crear el Atributo de inmuebles",
          type: "danger",
          show: true,
        })
      );
    }
  } catch (error) {
    yield put(attributesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
    yield put(
      showAlert({
        message: "Error al crear el Atributo de inmuebles",
        type: "danger",
        show: true,
      })
    );
  }
}

const attributesDeleteAsync = async (
  attribute: any
): Promise<attributeResponse> =>
  await remove(attribute)
    .then((AttributeItf) => AttributeItf)
    .catch((error) => error);

function* attributesDelete({ payload }: any): any {
  const { attribute } = payload;
  try {
    const attributesListData = yield call(attributesDeleteAsync, attribute);
    if (attributesListData.status === 200) {
      yield put(attributesListSuccess(attributesListData.data.attributes));
      yield put(projectSelectedSuccess(attributesListData.data.projects));
      yield put(builderSelectedSuccess(attributesListData.data.builders));
      yield put(
        showAlert({
          message: "Atributo de inmuebles eliminado con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Atributo de inmuebles eliminado con exito.",
          type: "success",
          show: false,
        })
      );
    } else {
      yield put(attributesListError(attributesListData));
      yield put(projectSelectedError(attributesListData));
      yield put(builderSelectedError(attributesListData));
      yield put(
        showAlert({
          message: "Error al eliminar el Atributo de inmuebles",
          type: "danger",
          show: true,
        })
      );
    }
  } catch (error) {
    yield put(attributesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
    yield put(
      showAlert({
        message: "Error al eliminar el Atributo de inmuebles",
        type: "danger",
        show: true,
      })
    );
  }
}

const attributesUpdateAsync = async (
  attribute: any
): Promise<attributeResponse> =>
  await update(attribute)
    .then((AttributeItf) => AttributeItf)
    .catch((error) => error);

function* attributesUpdate({ payload }: any): any {
  const { attribute } = payload;
  try {
    const attributesListData = yield call(attributesUpdateAsync, attribute);
    if (attributesListData.status === 200) {
      yield put(attributesListSuccess(attributesListData.data.attributes));
      yield put(projectSelectedSuccess(attributesListData.data.projects));
      yield put(builderSelectedSuccess(attributesListData.data.builders));
      yield put(
        showAlert({
          message: "Atributo de inmuebles actualizado con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Atributo de inmuebles actualizado con exito.",
          type: "success",
          show: false,
        })
      );
    } else {
      yield put(attributesListError(attributesListData));
      yield put(projectSelectedError(attributesListData));
      yield put(builderSelectedError(attributesListData));
      yield put(
        showAlert({
          message: "Error al actualizar el Atributo de inmuebles",
          type: "danger",
          show: true,
        })
      );
    }
  } catch (error) {
    yield put(attributesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
    yield put(
      showAlert({
        message: "Error al actualizar el Atributo de inmuebles",
        type: "danger",
        show: true,
      })
    );
  }
}

const hierarchicalAttributesUpdateAsync = async (
  attribute: any
): Promise<attributeResponse> =>
  await hierarchicalUpdate(attribute)
    .then((AttributeItf) => AttributeItf)
    .catch((error) => error);

function* hierarchicalAttributesUpdate({ payload }: any): any {
  const { attribute } = payload;
  let arrAttributes: any = [];
  attribute.hierarchical.forEach((primero: any) => {
    arrAttributes.push(primero);
    if (primero.children !== undefined && primero.children.length > 0) {
      primero.children.forEach((segundo: any) => {
        arrAttributes.push(segundo);
        if (segundo.children !== undefined && segundo.children.length > 0) {
          segundo.children.forEach((tercero: any) => {
            arrAttributes.push(tercero);
            if (tercero.children !== undefined && tercero.children.length > 0) {
              tercero.children.forEach((cuarto: any) => {
                arrAttributes.push(cuarto);
                if (cuarto.children !== undefined && cuarto.children.length > 0) {
                  cuarto.children.forEach((quinto: any) => {
                    arrAttributes.push(quinto);
                    if (quinto.children !== undefined && quinto.children.length > 0) {
                      quinto.children.forEach((sexto: any) => {
                        arrAttributes.push(sexto);
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  });
  attribute.estructura = arrAttributes
  try {
    const attributesListData = yield call(
      hierarchicalAttributesUpdateAsync,
      attribute
    );
    if (attributesListData.status === 200) {
      yield put(attributesListSuccess(attributesListData.data.attributes));
      yield put(projectSelectedSuccess(attributesListData.data.projects));
      yield put(builderSelectedSuccess(attributesListData.data.builders));
      yield put(
        showAlert({
          message: "Jerarquia de atributo de inmuebles actualizado con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Jerarquia de atributo de inmuebles actualizado con exito.",
          type: "success",
          show: false,
        })
      );
    } else {
      yield put(attributesListError(attributesListData));
      yield put(projectSelectedError(attributesListData));
      yield put(builderSelectedError(attributesListData));
      yield put(
        showAlert({
          message: "Error al actualizar la jerarquia del atributo de inmuebles",
          type: "danger",
          show: true,
        })
      );
    }
  } catch (error) {
    yield put(attributesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
    yield put(
      showAlert({
        message: "Error al actualizar la jerarquia del atributo de inmuebles",
        type: "danger",
        show: true,
      })
    );
  }
}

const attributesUpdateValuesAsync = async (
  attribute: any
): Promise<attributeResponse> =>
  await valuesUpdate(attribute)
    .then((AttributeItf) => AttributeItf)
    .catch((error) => error);

function* attributesUpdateValues({ payload }: any): any {
  const { attribute } = payload;
  try {
    const attributesListData = yield call(attributesUpdateValuesAsync, attribute);
    if (attributesListData.status === 200) {
      yield put(attributesListSuccess(attributesListData.data.attributes));
      yield put(projectSelectedSuccess(attributesListData.data.projects));
      yield put(builderSelectedSuccess(attributesListData.data.builders));
      yield put(
        showAlert({
          message: "Valor de atributo de inmuebles actualizado con exito.",
          type: "success",
          show: true,
        })
      );

      yield delay(3000);

      yield put(
        showAlert({
          message: "Valor de atributo de inmuebles actualizado con exito.",
          type: "success",
          show: false,
        })
      );
    } else {
      yield put(attributesListError(attributesListData));
      yield put(projectSelectedError(attributesListData));
      yield put(builderSelectedError(attributesListData));
      yield put(
        showAlert({
          message: "Error al actualizar el valor del atributo de inmuebles",
          type: "danger",
          show: true,
        })
      );
    }
  } catch (error) {
    yield put(attributesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedError(error));
    yield put(
      showAlert({
        message: "Error al actualizar el valor del atributo de inmuebles",
        type: "danger",
        show: true,
      })
    );
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchattributesList),
    fork(watchattributesCreate),
    fork(watchattributesUpdate),
    fork(watchattributesDelete),
    fork(watchHierarchicalAttributesUpdate),
    fork(watchattributesUpdateValue),
  ]);
}
