import { all, call, delay, fork, put, takeEvery } from "redux-saga/effects";
import { list, update } from "../../models/paletteChromatic";

import { LIST_PALETTE_CHROMATIC, UPDATE_PALETTE_CHROMATIC } from "../actions";

import { paletteChromaticListSuccess, paletteChromaticListError } from "./actions";

import {
  projectSelectedSuccess,
  projectSelectedError,
} from "../project/actions";
import {
  modulesListSuccess,
  modulesListError,
} from "../module/actions";
import {
  builderSelectedSuccess,
  builderSelectedError,
} from "../builder/actions";
import { showAlert } from "../general/actions";

/*eslint-disable*/

interface paletteChromaticResponse {
  ModuleItf: any;
}

export function* watchpaletteChromaticList() {
  yield takeEvery(LIST_PALETTE_CHROMATIC, paletteChromaticList);
}

export function* watchpaletteChromaticUpdate() {
  yield takeEvery(UPDATE_PALETTE_CHROMATIC, paletteChromaticUpdate);
}

const paletteChromaticListAsync = async (proyecto: any): Promise<paletteChromaticResponse> =>
  await list(proyecto)
    .then((ModuleItf) => ModuleItf)
    .catch((error) => error);

function* paletteChromaticList({ payload }: any): any {
  const { proyecto } = payload;
  try {
    const paletteChromaticListData = yield call(paletteChromaticListAsync, proyecto);
    if (paletteChromaticListData.status === 200) {
      yield put(paletteChromaticListSuccess(paletteChromaticListData.data.modules));
      yield put(projectSelectedSuccess(paletteChromaticListData.data.project));
      yield put(builderSelectedSuccess(paletteChromaticListData.data.builder));
    } else {
      yield put(paletteChromaticListError(paletteChromaticListData));
      yield put(projectSelectedError(paletteChromaticListData));
      yield put(builderSelectedSuccess(paletteChromaticListData));
    }
  } catch (error) {
    yield put(paletteChromaticListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedSuccess(error));
  }
}

const paletteChromaticUpdateAsync = async (
  paletteChromatic: any
): Promise<paletteChromaticResponse> =>
  await update(paletteChromatic)
    .then((ModuleItf) => ModuleItf)
    .catch((error) => error);

function* paletteChromaticUpdate({ payload }: any): any {
  const { module } = payload;
  try {
    const paletteChromaticListData = yield call(paletteChromaticUpdateAsync, module);
    if (paletteChromaticListData.status === 200) {
        yield put(modulesListSuccess(paletteChromaticListData.data.modules));
        yield put(projectSelectedSuccess(paletteChromaticListData.data.project));
        yield put(builderSelectedSuccess(paletteChromaticListData.data.builder));
        yield put(
          showAlert({
            message: "Paleta actualizada con exito.",
            type: "success",
            show: true,
          })
        );
  
        yield delay(3000);
  
        yield put(
          showAlert({
            message: "Paleta actualizada con exito.",
            type: "success",
            show: false,
          })
        );
      } else {
        yield put(modulesListError(paletteChromaticListData));
        yield put(projectSelectedError(paletteChromaticListData));
        yield put(builderSelectedError(paletteChromaticListData));
        yield put(
          showAlert({
            message: "Error al actualizar la paleta",
            type: "danger",
            show: true,
          })
        );
      }
    } catch (error) {
      yield put(modulesListError(error));
      yield put(projectSelectedError(error));
      yield put(builderSelectedError(error));
      yield put(
        showAlert({
          message: "Error al actualizar la paleta",
          type: "danger",
          show: true,
        })
      );
    }
}

export default function* rootSaga() {
  yield all([
      fork(watchpaletteChromaticList),
      fork(watchpaletteChromaticUpdate)
    ]);
}
