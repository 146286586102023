import axios from 'axios';
import { ApiConfig } from '../constants/defaultValues';
import { borrarCacheImagenes } from '../helpers/clearCache';

export async function list(solar: any) {
    return axios.post(ApiConfig.URL2 + "solar/get", solar);
}
export async function set(e: any) {
    let result = await axios.post(ApiConfig.URL2 + "solar/set", e.data);
    if (result.status === 200) {
        let resultFormData = await axios.post(ApiConfig.URL3 + "file/zip", e.assets, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        if (resultFormData.status === 200) {
          borrarCacheImagenes();
          return result;
        }
    }
}
export async function update(e: any) {
    return axios.post(ApiConfig.URL2 + "solar/update", e);
}
export async function remove(proyecto: any) {
    return axios.post(ApiConfig.URL2 + "solar/delete", proyecto);
}
export async function txt(e: any) {
    let result = await axios.post(ApiConfig.URL2 + "solar/txt", e.data);
    if (result.status === 200) {
        let resultFormData = await axios.post(`${ApiConfig.URL3}coord/`, e.assets, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        if (resultFormData.status === 200) {
          borrarCacheImagenes();
          return result;
        }
    }
}