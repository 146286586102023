import {
  LIST_PALETTE_CHROMATIC,
  LIST_PALETTE_CHROMATIC_SUCCESS,
  LIST_PALETTE_CHROMATIC_ERROR,
  UPDATE_PALETTE_CHROMATIC,
  } from '../actions';

  export const paletteChromaticList = (proyecto: object) => ({
    type: LIST_PALETTE_CHROMATIC,
    payload: { proyecto }
  });
  export const paletteChromaticListSuccess = (module: any) => ({
    type: LIST_PALETTE_CHROMATIC_SUCCESS,
    payload: module
  });
  export const paletteChromaticListError = (message: any) => ({
    type: LIST_PALETTE_CHROMATIC_ERROR,
    payload: { message }
  });
  export const paletteChromaticUpdate = (module: any) => ({
    type: UPDATE_PALETTE_CHROMATIC,
    payload: { module }
  });