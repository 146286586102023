import React, {useState} from 'react'

import U3DS from "./../../UI/general/Placeholder_02.svg";

import {
    Chip,
} from "@mui/material";

const EditAmenitie = ({
    handleAddLogo, 
    handleSelectIcon, 
    currentLogoSelected, 
    handleChangeNewAmenitieName, 
    currentName, 
    amenitie,
    handleUpdateAmenitieName,
    handleUpdateAmenitieImage,
    currentEditAmenitieImg
}: any) => {

    const [currentAmenitieName, setAmenitieName] = useState(amenitie.name)

    const handleChange = (e: any) => {
        setAmenitieName(e.target.value)
        handleUpdateAmenitieName(e.target.value)
    }

    return(
        <div className='edit-amenitie-container'>
            <div className="logo-new-amenitie-container">
            <div className='logo-new-amenitie'>
                <img
                    src={
                        currentEditAmenitieImg ?
                        `https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${currentEditAmenitieImg}`:
                        `https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${amenitie.logo}`
                    }
                    alt=""
                    width={"100%"}
                    id="favicon"
                    onError={() => {
                        var image: any = document.getElementById("favicon")
                        if(image){
                        image.src = U3DS
                        } 
                    }}
                />
            
            </div>
            <div className="logo-new-amenitie-info">
                <div className='logo-name-title-amenities-new'>
                    Seleccionar Ícono
                </div>
                <div className='logo-format-dubtitle-amenities-new'>
                    El archivo debe estar en formato .SVG
                </div>
                <input
                    type="file"
                    name="imagen"
                    id="upload-photo-nuevo-amenities"
                    className="upload-photo-nuevo-amenities inputfile-amenities"
                    onChange={(e: any) => {handleUpdateAmenitieImage(e)}}
                    accept="image/svg+xml"
                />
                <label htmlFor="upload-photo-nuevo-amenities">
                    <Chip label="Subir ícono" />
                </label>
                <div className='select-icon' onClick={() => {handleSelectIcon(true)}}>
                    Seleccionar
                </div>
            </div>
            <div className='create-new-name'>
                <input
                    type="text"
                    value={currentAmenitieName}
                    onChange = {(e) => {handleChange(e)}}
                    maxLength = {25}
                />
            </div>
            </div>
            
        </div>
    )
}

export default EditAmenitie