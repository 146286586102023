import {
    LIST_INMUEBLES,
    LIST_INMUEBLES_SUCCESS,
    LIST_INMUEBLES_ERROR,
    SET_INMUEBLES,
    UPDATE_INMUEBLES,
    UPDATE_INMUEBLES_TYPE_FILTER,
    COPY_INMUEBLES,
    DELETE_INMUEBLES,
    SET_GALLERIES_INMUEBLES,
    SET_GALLERIES_ATTRIBUTES,
    UPDATE_INMUEBLES_SUCCESS,
    UPDATE_INMUEBLES_ERROR,
    ACTIVE_AVAILABILITY
} from './../actions'

export const activeAvailability = (proyecto: any) => ({
    type: ACTIVE_AVAILABILITY,
    payload: {proyecto}
})

export const inmueblesList = (proyecto: any) => ({
    type: LIST_INMUEBLES,
    payload: {proyecto}
})

export const inmueblesListSuccess = (inmueble: any) => ({
    type: LIST_INMUEBLES_SUCCESS,
    payload: inmueble
})

export const inmueblesListError = (message: any) => ({
    type: LIST_INMUEBLES_ERROR,
    payload: message
})

export const inmueblesUpdateSuccess = (inmueble: any, id: any) => ({
    type: UPDATE_INMUEBLES_SUCCESS,
    payload: {inmueble, id}
})

export const inmueblesUpdateError = (message: any) => ({
    type: UPDATE_INMUEBLES_ERROR,
    payload: message
})

export const inmueblesSet = (proyecto: any) => ({
    type: SET_INMUEBLES,
    payload: {proyecto}
})

export const inmueblesUpdate = (proyecto: any) => ({
    type: UPDATE_INMUEBLES,
    payload: {proyecto}
})

export const inmueblesCopy = (proyecto: any) => ({
    type: COPY_INMUEBLES,
    payload: {proyecto}
})

export const inmueblesDelete = (proyecto: any) => ({
    type: DELETE_INMUEBLES,
    payload: {proyecto}
})

export const inmueblesGalleries = (proyecto: any) => ({
    type: SET_GALLERIES_INMUEBLES,
    payload: {proyecto}
})

export const inmueblesAttributesGalleries = (proyecto: any) => ({
    type: SET_GALLERIES_ATTRIBUTES,
    payload: {proyecto}
})

export const inmueblesTypeFilter = (proyecto: any) => ({
    type: UPDATE_INMUEBLES_TYPE_FILTER,
    payload: {proyecto}
})