import React from 'react'
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import BreadCrumbs from '../gallery/breadcrumbs.component';
import Cards from './cards.component';
import ShowGalleriesImages from './show.component';
import ShowGalleriesLinks from './showLinks.component';
import GalleryFullscreen from '../gallery/fullscreen.component';
import ShowSelected from './showSelected.component';

import { galleryStructureData } from '../../data/gallery.data';

import {
    galleriesOpen,
    galleriesList,
    projectUpdate,
    galleriesCurrentModalSelection
} from "../../redux/actions";

/*eslint-disable*/

interface Props{
    params: any,
    galleries: any,
    isGalleriesModalOpened: boolean,
    galleriesOpen: any
    changeCurrentIndex: any,
    changeCurrentSubIndex: any
    projectSelected: any,
    selectedBuilder: any,
    galleriesList: any,
    modalGalleries: any,
    galleriesCurrentModalSelection: any,
    handleSetImages: any,
    selectedGallery: any
}

interface State{
    userString: any,
    user: any,
    idProject: any,
    breadCrumbs: any,
    currentSelectedArray: any,
    openImagesContainer: boolean,
    openLinksContainer: boolean,
    openFullscreen: boolean,
    currentIndexSubfolder: number,
    currentImageSelected: string,
    imagesArray: any,
    selectedType: string,
    selectedFolder: string,
    currentArrayImageSelected: any,
    renderSelection: boolean,
    currentGalleries: any
}

function withParams(compnent: any){
    return (props: any) => <GalleryModal {...props} params={useParams()}/> 
}

class GalleryModal extends React.Component<Props,State>{
    constructor(props: Props){
        super(props);
        this.state = {
            userString: localStorage.getItem("user"),
            user: "",
            idProject: this.props.params.id,
            breadCrumbs: ["Galeria"],
            currentSelectedArray: [],
            openImagesContainer: false,
            openLinksContainer: false,
            openFullscreen: false,
            currentIndexSubfolder: -1,
            currentImageSelected: "",
            imagesArray: [],
            selectedType: "",
            selectedFolder: "",
            currentArrayImageSelected: [],
            renderSelection: true,
            currentGalleries: []
        }
    }

    componentDidMount(): void {
        if(this.state.userString){
            this.setState({
                user: JSON.parse(this.state.userString)
            }, () => {
                this.props.galleriesList({user: this.state.user.id, project: this.state.idProject})
            })
        }
        var tempArray: any = []
        galleryStructureData().map((element: any, index: number) => {
            tempArray.push(element)
        })
        this.setState({
            currentSelectedArray: tempArray
        })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(this.props.isGalleriesModalOpened !== prevProps.isGalleriesModalOpened && this.props.isGalleriesModalOpened){
            this.props.galleriesCurrentModalSelection(this.props.selectedGallery)
        }
    }

    handleSelectCurrentGallery = (name: string, payload: any, payloadArray: any, indexSubfolder: number, isImage: boolean, slug: string, folderPadre: string) => {
        if(payloadArray){
            var tempArray: any = []
            let breadCrumbs = [...this.state.breadCrumbs]
            breadCrumbs.push(name)
            payloadArray.forEach((element: any) => {
                tempArray.push(element)
            });

            this.setState({
                currentSelectedArray: tempArray,
                breadCrumbs: breadCrumbs,
                currentIndexSubfolder: indexSubfolder,
                selectedType: slug,
            })
        }
        else{
            let breadCrumbs = [...this.state.breadCrumbs]
            breadCrumbs.push(name)
            this.setState({
                currentSelectedArray: [],
                breadCrumbs: breadCrumbs,
                openLinksContainer: !isImage,
                openImagesContainer: isImage,
                selectedFolder: slug
            })
            if (folderPadre !== undefined) {
                if (this.props.galleries[folderPadre].map((e: any) => e.folder).indexOf(payload) !== -1) {
                    let indice: any = this.props.galleries[folderPadre].map((e: any) => e.folder).indexOf(payload)
                    
                    this.setState({
                        currentGalleries: this.props.galleries[folderPadre][indice].galleries
                    })
                }
                
                
            }
        }
    }

    handleGoToTypes = () => {
        var tempArray: any = []
        let breadCrumbs = [...this.state.breadCrumbs]
        breadCrumbs.pop()
        galleryStructureData().map((element: any, index: number) => {
            tempArray.push(element)
        })
        this.setState({
            currentSelectedArray: tempArray,
            breadCrumbs: breadCrumbs,
            openLinksContainer: false,
            openImagesContainer: false,
            currentGalleries: []
        })
        
    }

    handleGoToSubfolder = (name: string, index: number ) => {
        let breadCrumbs = [...this.state.breadCrumbs]
        breadCrumbs.pop()
        this.setState({
            currentSelectedArray: galleryStructureData()[this.state.currentIndexSubfolder].innerArray,
            breadCrumbs: breadCrumbs,
            openLinksContainer: false,
            openImagesContainer: false,
            currentGalleries: []
        })
    }

    handleOpenFullScreen = (payload: boolean, image: string) => {
        this.setState({
            openFullscreen: payload,
            currentImageSelected: image
        })
    }

    handleDeleteSeletecion = (payload: any, index: number) => {
        this.setState({
            renderSelection: false
        }, () => {
            this.props.modalGalleries.splice(index,1)
            galleriesCurrentModalSelection(this.props.modalGalleries)
            this.setState({
                renderSelection: true
            })  
        })
    }

    handleDeleteGallery = () => {
        var data: any = []
        this.props.galleriesCurrentModalSelection(data)
    }

    render(): React.ReactNode {
        return(
            <div className={this.props.isGalleriesModalOpened ? 'gallery-modal-container fade-in':'gallery-modal-container fade-out'}>
                <div className='gallery-modal-main'>
                    <div className='gallery-modal-title'>
                        Galería
                    </div>
                    <div className='gallery-modal-subtitle'>
                        Selecccione el material que desea asignar al inmueble
                    </div>
                    <div className='gallery-modal-cards-container'>
                        <BreadCrumbs
                            breadCrumbsArray={this.state.breadCrumbs}
                            handleGoToTypes = {this.handleGoToTypes}
                            handleGoToSubfolder = {this.handleGoToSubfolder}
                        />
                        <Cards
                            currentArraySelected = {this.state.currentSelectedArray}
                            handleSelectedGallery = {this.handleSelectCurrentGallery}
                        />
                        {
                        this.state.openImagesContainer ?
                            <ShowGalleriesImages
                                galleries={this.state.currentGalleries}
                                handleOpenFullScreen = {this.handleOpenFullScreen}
                                handleDeleteGallery = {this.handleDeleteGallery}
                                handleUpdateImagesArray= {this.props.galleriesCurrentModalSelection}
                                handleSetImages = {this.props.handleSetImages}
                                isGalleries = {this.props.isGalleriesModalOpened}
                                modalGalleries = {this.props.modalGalleries}
                            />: null
                        }
                        {
                        this.state.openLinksContainer ?
                            <ShowGalleriesLinks
                                galleries={this.state.currentGalleries}
                                handleOpenFullScreen = {this.handleOpenFullScreen}
                                handleUpdateImagesArray= {this.props.galleriesCurrentModalSelection}
                                handleSetImages = {this.props.handleSetImages}
                                isGalleries = {this.props.isGalleriesModalOpened}
                                modalGalleries = {this.props.modalGalleries}
                            />: null
                        }
                    </div>
                    <div className='gallery-modal-selected-material'>
                        {
                            this.state.renderSelection ? 
                            <ShowSelected
                                currentSelection = {this.props.modalGalleries}
                                handleDeleteSeletecion = {this.handleDeleteSeletecion}
                            />:null
                        }
                    </div>
                    <div className='gallery-modal-cancel' onClick={() => {
                        this.props.galleriesOpen(false)
                        this.handleDeleteGallery
                        }}>
                        Cerrar
                    </div>
                    <div className='gallery-modal-asign' onClick={() => {
                        this.props.handleSetImages()
                        this.props.galleriesOpen(false)
                        }}>
                        Asignar
                    </div>
                </div>
                {
                    this.state.openFullscreen ?
                    <GalleryFullscreen
                        currentImage = {this.state.currentImageSelected}
                        handleOpenFullScreen = {this.handleOpenFullScreen}
                    />:null
                }
            </div>
        )
    }
}

const mapStateToProps = ({
    projectReducer,
    builderReducer,
    moduleReducer,
    galleriesReducer,
}: any) => {
    const { galleries, loading, isGalleriesModalOpened, modalGalleries } = galleriesReducer;
    const { modules } = moduleReducer;
    const { projectSelected } = projectReducer;
    const { selectedBuilder } = builderReducer;
    return {galleries, loading, isGalleriesModalOpened, modules, projectSelected, selectedBuilder, modalGalleries}
}

export default connect(mapStateToProps, {
    galleriesOpen,
    galleriesList,
    projectUpdate,
    galleriesCurrentModalSelection
})(withParams(GalleryModal))

