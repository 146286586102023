import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { list, create } from "../../models/icons";

import { LIST_ICONS, CREATE_ICONS } from "../actions";

import { iconsListSuccess, iconsListError } from "./actions";

/*eslint-disable*/

export function* watchIconsList() {
    yield takeEvery(LIST_ICONS, iconsList);
}

export function* watchIconsCreate(){
    yield takeEvery(CREATE_ICONS, iconsCreate)
}

interface iconsResponse {
    categorieItf: any;
  }
  

const iconsListAsync = async (proyecto: any): Promise<iconsResponse> =>
  await list(proyecto)
    .then((categorieItf) => categorieItf)
    .catch((error) => error);

function* iconsList({ payload }: any): any {
    const { proyecto } = payload;
    try {
        const iconsListData = yield call(iconsListAsync, proyecto);

        if (iconsListData.status === 200) {
        yield put(iconsListSuccess(iconsListData.data.data));
        } else {
        yield put(iconsListError(iconsListData));
        }
    } catch (error) {
        yield put(iconsListError(error));
}
}

const iconsCreateAsync = async(icons: any) =>
    await create(icons)
    .then(icons => icons)
    .catch(error => error)

function* iconsCreate({payload}: any): any {
    const { icon } = payload
    try {
        const iconsListData = yield call(iconsCreateAsync, icon);

        if (iconsListData.status === 200) {
        yield put(iconsListSuccess(iconsListData.data.data));
        } else {
        yield put(iconsListError(iconsListData));
        }
    } catch (error) {
        yield put(iconsListError(error));
}
}

export default function* rootSaga() {
    yield all([
        fork(watchIconsList), 
        fork(watchIconsCreate)
    ]);
}