import React from 'react'

import U3DS from "./../../UI/general/Placeholder_02.svg";

const SetUpModules = ({VideoArray, handleUploadTxt}: any) => {
    return(
        <div className='asoleacion-setup-container-main'>
            <div className='asoleacion-setup-title'>Módulo de Asoleacion</div>
            <div className='asoleacion-icono-setup'>
                <div className='asoleacion-icono-preview'>
                    <img src={U3DS} alt=""/>
                </div>
                <div className='asoleacion-icono-selection-text-container'>
                    <div className='asoleacion-icono-selection-title'>Ícono de asoleación</div>
                    <div className='asolaecion-icono-selection-text'>
                        El archivo debe estar en formato .SVG
                    </div>
                    <input
                        type="file"
                        name="imagen"
                        className='upload-icon-asoleacion'
                        accept={"image/svg+xml"}
                    />
                    <div className='input-asoleacion-icon-btn'>
                        Modificar
                    </div>
                </div>
            </div>
            <div className='asoleacion-list-title'>
                Vistas de asoleación
            </div>
            <div className='asoleacion-list-text'>
                El módulo de asoleación debe contar con 3 vistas.
            </div>
        </div>
    )
}

export default SetUpModules