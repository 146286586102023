import React, {useEffect, useState} from 'react'

interface POISHeaderProps {
    currentPOI: string,
    handleChange: any,
    selectedName: string
}

const POIsHeaderComponent = ({currentPOI, handleChange, selectedName}: POISHeaderProps) => {

    const [currentName, setCurrentName] = useState("")

    useEffect(() => {
        setCurrentName(selectedName)
    }, [selectedName])
    

    const handleChangeName = (e: any) => {
        setCurrentName(e.target.value)
        handleChange(e.target.value)
    }

    return(
        <div className='pois-category-header-container'>
            <div className='pois-category-header-title'>Punto de Interés: {currentPOI}</div>
            <div className='pois-category-header-subtitle'>Crear nuevo punto de interés</div>
            <div className='pois-category-header-create'>
                <input type="text" value={currentName} onChange = {(e) => {handleChangeName(e)}} maxLength={50}></input>
                <div className='pois-category-header-create-btn'>Ubicar</div>
            </div>
            <div className='pois-category-header-text'>
                Utiliza el botón <b>ubicar</b> y haz click para fijar el punto de interés en el mapa.
            </div>
        </div>
    )
}

export default POIsHeaderComponent