export const ADMINISTRATION_ROUTE = '/'
export const LOGIN_ROUTE = '/login'
export const BUILDERS_ROUTE = '/builders'
export const PROJECTS_ROUTE = '/projects'
export const PROPERTIES_ROUTE = '/properties'
export const PROPERTIES_ATTRIBUTES_ROUTE = '/properties/attributes'
export const GALLERIES_ROUTE = '/galleries'
export const GENERAL_INFO_ROUTE = '/general-information'
export const CONTACT_INFO_ROUTE = '/contact'
export const AMENITIES_ROUTE = '/amenities'
export const LOCATION_ROUTE = '/location'
export const POI_ROUTE = '/poi' 
export const POI_CATEGORY_ROUTE = '/poi-category' 
export const PROPERTIES_GENERAL_ROUTE = '/general-properties'
export const SPINS_ROUTE = '/spins'
export const UI_ROUTE = '/interface'
export const ASOLEACION_ROUTE = '/modulos/asoleacion'
export const DISPONIBILIDAD_ROUTES = '/modulos/disponibilidad'
export const COTIZACION_ROUTES = '/modulos/cotizacion'