import axios from 'axios';
import { ApiConfig } from '../constants/defaultValues';
import { borrarCacheImagenes } from '../helpers/clearCache';

export async function list(spin: any) {
    return axios.post(ApiConfig.URL2 + "spin/get", spin);
}
export async function set(e: any) {
    let result = await axios.post(ApiConfig.URL2 + "spin/set", e);
    if (result.status === 200) {
        let result2 = await axios.post(ApiConfig.URL3 + "file/zipofzip", e.file, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        if(result2.status === 200){
            borrarCacheImagenes();
            return result
        }
        
    }
}
export async function update(e: any) {
    return axios.post(ApiConfig.URL2 + "spin/update", e);
}
export async function remove(proyecto: any) {
    return axios.post(ApiConfig.URL2 + "spin/delete", proyecto);
}
export async function txt(e: any) {
    let result = await axios.post(ApiConfig.URL2 + "spin/txt", e.data);
    if (result.status === 200) {
        let resultFormData = await axios.post(`${ApiConfig.URL3}coord/`, e.assets, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        if (resultFormData.status === 200) {
          borrarCacheImagenes();
          return result;
        }
    }
}
export async function listScript(spin: any) {

    return axios.post(ApiConfig.URL2 + "spin/scripts", spin);
}