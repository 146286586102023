import {
  LIST_MODULES,
  LIST_MODULES_SUCCESS,
  LIST_MODULES_ERROR,
  UPDATE_MODULES
  } from '../actions';

  export const modulesList = (proyecto: object) => ({
    type: LIST_MODULES,
    payload: { proyecto }
  });
  export const modulesListSuccess = (module: any) => ({
    type: LIST_MODULES_SUCCESS,
    payload: module
  });
  export const modulesListError = (message: any) => ({
    type: LIST_MODULES_ERROR,
    payload: { message }
  });
  export const modulesUpdate = (module: any) => ({
    type: UPDATE_MODULES,
    payload: { module }
  });