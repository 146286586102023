import React from 'react'
import { connect } from "react-redux";

import { loginUser, twoFactorValidate } from '../../redux/actions'

import background from './../../UI/backgrounds/BG.webp'
import CardLogin from './card.component';

interface Props{
    loginUser: any,
    user: any,
    twoFactorValidate: any,
    errorLogin: string
}

interface State{}

class LoginComponent extends React.Component<Props, State>{
    constructor(props: Props){
        super(props)
        this.state = {}
    }

    render(): React.ReactNode {
        return(
            <div className='login-screen'>
                <img src={background} alt="" className='login-background-img'/>
                <CardLogin
                    event={this.props.loginUser}
                    user={this.props.user}
                    eventValidate={this.props.twoFactorValidate}
                    errorLogin = {this.props.errorLogin}
                />
            </div>
        )
    }
}

const mapStateToProps = ({authReducer} : any) => {
    const { user, stateLogin, loading, error: errorLogin } = authReducer;
    return { user, stateLogin, loading, errorLogin };
}

export default connect(mapStateToProps, {
    loginUser,
    twoFactorValidate,
})(LoginComponent);
