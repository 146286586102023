import React from 'react'

import drag from './../../UI/general/drag.svg'
import Delete from './../../UI/general/close.svg'
import EditIcon from './../../UI/general/Edit.svg'

import { SketchPicker } from 'react-color'

interface Edit {
  dragItem: any,
  dragOverItem: any
}

interface Props{
  handleUpdateAttribute: any,
  attributeToEdit: any,
  handleCloseEditionModal: any
}

interface State {
  attributesName: any,
  name: any,
  defaultColors: any
  selectedName: string,
  selectedType: string,
  currentAttributesArray: any,
  currentAttributeName: any,
  showOnMasterplan: boolean,
  nameEditionIndex: number,
  openEditionName: boolean,
  tempName: string,
  openColorEditionModal: boolean,
  openColorEDitionModalIndex: number,
  tempColor: string
}

class Edit extends React.Component<Props,State>{
  constructor(props: Props){
    super(props);
    this.state = {
      attributesName : {},
      name : "",
      defaultColors: [],
      selectedName: '',
      selectedType: '',
      currentAttributesArray: [],
      currentAttributeName: '',
      showOnMasterplan: true,
      nameEditionIndex: -1,
      openEditionName: false,
      tempName: '',
      openColorEditionModal: false,
      openColorEDitionModalIndex: -1,
      tempColor: ''
    }

    this.dragItem = React.createRef()
    this.dragOverItem = React.createRef()
  }

  componentDidMount(): void {
    var tempAttributesName: any = {
      'Jerarquico #1':'Hierarchical',
      'Jerarquico #2':'Hierarchical',
      'Jerarquico #3':'Hierarchical',
      'Área privada':'Area',
      'Área construida': 'Area',
      'Área balcones': 'Area',
      'Áreas adicionales': 'Area',
      'Área total': 'Area',
      'Baños': 'Value',
      'Habitaciones':'Value',
      'Lotes':'Value',
      'Apto tipo':'Value',
      'Casas tipo':'Value',
      'Pisos': 'Value'
    }

    var tempDefaultColors: any = [
      '#4E3636','#867070','#9E6F21','#CBB279','#BFDB38',
      '#FFFFFF','#11009E','#362FD9','#8696FE','#5C469C',
      '#A459D1','#C4B0FF','#DDE3FF','#B1CAFE','#000000',
      '#212A3E','#394867','#545B77','#025464','#068DA9',
      '#79E0EE','#245953','#116D6E','#47A992','#98EECC',
      '#73A9AD','#CD1818','#FFAAAA','#F2D8D8','#B04759',
      '#E76161','#DB005B','#D14D72','#F266AB','#E55807',
      '#F79327','#FFB84C','#FFD966','#FFE569','#F5F0BB',
      '#00FFD1','#00F5FF','#F637EC','#5800FF','#14C48E',
      '#06FF00','#3B0000','#FCF876','#393E46','#A3D8F4',
    ]

    this.setState({
      attributesName: tempAttributesName,
      defaultColors: tempDefaultColors
    })

    console.log(this.props.attributeToEdit);
    

    this.setState({
      selectedName: tempAttributesName[this.props.attributeToEdit.name] !== "Hierarchical" ? this.props.attributeToEdit.name : this.props.attributeToEdit.hierarchicalLevel,
      name: this.props.attributeToEdit.name,
      selectedType: this.props.attributeToEdit.hierarchicalLevel !== null ? tempAttributesName[this.props.attributeToEdit.hierarchicalLevel] : tempAttributesName[this.props.attributeToEdit.name],
      currentAttributesArray: this.props.attributeToEdit.values,
      showOnMasterplan: this.props.attributeToEdit.active === 1
    })
  }

  handleChangeOptions = (e: any) => {
    var currentType = this.state.attributesName[e]
    console.log(currentType);
    this.setState({
      selectedName: e,
      selectedType: currentType
    })
  }

  handleChangeName = (e: any) => {
    this.setState({
      name: e
    })
  }

  handleAppendAttribute = () => {
    var TempAttributesArray = this.state.currentAttributesArray
    var tempObject: any = {}
    console.log(this.state.selectedType);

    tempObject['value'] = this.state.currentAttributeName
    tempObject['layoutFill'] = this.state.selectedType === 'Hierarchical' || this.state.selectedName === 'Apto tipo' || this.state.selectedName === 'Casas tipo' ? this.state.defaultColors[TempAttributesArray.length]:null
    tempObject['billboardFill'] = this.state.selectedType === 'Hierarchical' || this.state.selectedName === 'Apto tipo' || this.state.selectedName === 'Casas tipo' ? '#ffffff':null
    tempObject['billboardStroke'] = this.state.selectedType === 'Hierarchical' || this.state.selectedName === 'Apto tipo' || this.state.selectedName === 'Casas tipo' ? tempObject['layoutFill']:null

    TempAttributesArray.push(tempObject)

    this.setState({
      currentAttributesArray: TempAttributesArray,
      currentAttributeName: ''
    })
  }

  handleOnEnter = (event: any) => {
    if(event.keyCode === 13){
      this.handleAppendAttribute()
    }
  }

  handleCreateObjectToSend = () => {
    var tempObject: any = {}

    tempObject['id'] = this.props.attributeToEdit.id
    tempObject['type'] = this.state.selectedType 
    tempObject['name'] = this.state.name
    tempObject['hierarchicalLevel'] = this.state.selectedName
    tempObject['active'] = this.state.showOnMasterplan ? 1:0
    tempObject['values'] = this.state.currentAttributesArray

    this.props.handleUpdateAttribute(tempObject)
    this.setState({selectedType: '', selectedName: '', name: '', currentAttributesArray: [], currentAttributeName: ''})
  }

  handleDeleteFromArray = (index: number) => {
    var tempArray = this.state.currentAttributesArray
    tempArray.splice(index, 1)
    this.setState({
      currentAttributesArray: tempArray
    })
  }

  handleDragStart = (e: any, position: number) => {
    this.dragItem.current = position;
  }

    handleDragEnter = (e: any, position: number) => {
      this.dragOverItem.current = position;
  };

    handleDrop = (e: any) => {
      const copyListItems = [...this.state.currentAttributesArray];
      const dragItemContent = copyListItems[this.dragItem.current];
      copyListItems.splice(this.dragItem.current, 1);
      copyListItems.splice(this.dragOverItem.current, 0, dragItemContent);
      this.dragItem.current = null;
      this.dragOverItem.current = null;
      this.setState({
        currentAttributesArray: copyListItems
      })
  };

  render(): React.ReactNode {
      return(
        <div className='attributes-create-container'>
          <div className='attributes-create-title'>Actualizar un atributo</div>

          <div className='attributes-creation-container'>
            <div className='attribute-creation-name'>
              <div className='attribute-creation-title'>Atributo</div>
              <select className='attribute-creation-selector' disabled onChange={(e) => {
                this.handleChangeOptions(e.target.value)
              }}>
                <option defaultValue={""} hidden disabled selected={this.state.selectedName === ''}>Seleccionar</option>
                {
                  Object.keys(this.state.attributesName).map((attributeName: string, index: number) => 
                    <option value={attributeName} selected={this.state.selectedName === attributeName}>{attributeName}</option>
                  )
                }
              </select> 
            </div>
            <div className='attribute-creation-name'>
              <div className='attribute-creation-title'>Nombre</div>
              <input
              defaultValue={this.state.name} 
              disabled={this.state.attributesName[this.state.selectedName] !== "Hierarchical" ? true : false}
              onChange={(e) => {
                this.handleChangeName(e.target.value)
              }}
              />
            </div>
            <div className='attribute-creation-name'>
              <div className='attribute-creation-title'>Tipo de atributo</div>
              <select className='attribute-creation-selector' disabled={true}>
                <option defaultValue={""} hidden disabled selected={this.state.selectedType === ''}>Tipo de attributo</option>
                {
                  Object.keys(this.state.attributesName).map((attributeName: string, index: number) => 
                    <option value={this.state.attributesName[attributeName]} selected={this.state.selectedType === this.state.attributesName[attributeName]}>{this.state.attributesName[attributeName]}</option>
                  )
                }
              </select> 
            </div>
            <div className='attribute-creation-name-alt'>
              <input type='checkbox' checked={this.state.showOnMasterplan} onChange={() => {this.setState({showOnMasterplan: !this.state.showOnMasterplan})}}/>
              <div className='attribute-creation-name-alt-text'>Mostrar en filtro</div>
            </div>
          </div>

          <div className='attibutes-creation-value'>
            <input type='text' maxLength={30} onChange={(e) => {this.setState({currentAttributeName: e.target.value})}} value={this.state.currentAttributeName} onKeyDown={(e) => {this.handleOnEnter(e)}}/>
            <div className='create-attrbiute-value-btn' onClick={() => {this.handleAppendAttribute()}}>
              <div className='create-attribute-value-plus'>+</div>
              <div className='create-attribute-value-name'>Añadir valor</div>
            </div>
          </div>

         

          <div className='created-settings-attributes-container'>
          {
            this.state.currentAttributesArray.length > 0 ?
            <div className='attributes-values-name-container'>
              <div className='attributes-value-value'>Valor</div>
              {
                this.state.selectedType === 'Hierarchical' || this.state.selectedName === 'Apto tipo' || this.state.selectedName === 'Casas tipo'?
                <div className='attributes-value-colors'>{this.state.selectedType === 'Hierarchical' ? 'Color de layout y billboard':'Color de layout'}</div>:null
              }
            </div>:null
          }
            {
              this.state.currentAttributesArray.length > 0 ?
              this.state.currentAttributesArray.map((attribute: any, index: number) => 
              <div 
              className='created-attribute-individual'
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                this.setState({
                  openColorEditionModal: false,
                  openColorEDitionModalIndex: -1
                })
              }}
              >
                <div className='attributes-individual-drag-controller'
                  draggable
                  onDragStart={(e) => {this.handleDragStart(e,index)}}
                  onDragEnter={(e) => this.handleDragEnter(e, index)}
                  onDragEnd={this.handleDrop}
                >
                    <img src={drag} alt=''/>
                </div>
                <div className='attribute-individual-value-name'>
                  {
                    this.state.openEditionName && this.state.nameEditionIndex === index ?
                    <div className='edition-input-container'>
                      <input type='text' value={this.state.tempName} onChange={(e) => {this.setState({tempName: e.target.value})}}/>
                      <div onClick={() => {
                        var tempValue = this.state.currentAttributesArray
                          tempValue[index].value = this.state.tempName
                          this.setState({
                            currentAttributesArray: tempValue,
                            openEditionName: false,
                            nameEditionIndex: -1
                          })
                      }}>Guardar</div>
                    </div>:
                    <div className='attribute-value-container'>
                      <div>{attribute.value}</div>
                      <img src={EditIcon} alt='' 
                      onClick={() => {
                        this.setState({
                          openEditionName: true, 
                          nameEditionIndex: index, 
                          tempName: attribute.value, 
                          openColorEditionModal: false, 
                          openColorEDitionModalIndex: index
                          })}}/>
                    </div>
                  }
                </div>
                <div className='attributes-individual-color-container'>
                  {
                    attribute.layoutFill && attribute.layoutFill !== 'null' ?
                    <div className='attribute-individual-color'>
                      <div className='attribute-individual-color-name'>Layout</div>
                      <div className='attribute-current-color-selected' 
                      style={{backgroundColor: attribute.layoutFill}} 
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.setState({openColorEDitionModalIndex: index, openColorEditionModal: !this.state.openColorEditionModal, tempColor: attribute.layoutFill, openEditionName: false, nameEditionIndex: -1})
                        }}></div>
                    </div>:null
                  }
                  {
                    attribute.billboardFill && attribute.billboardFill !== 'null' && this.state.selectedName !== 'Apto tipo' && this.state.selectedName !== 'Casas tipo'  ?
                    <div className='attribute-individual-color'>
                      <div className='attribute-individual-color-name'>Relleno</div>
                      <div className='attribute-current-color-selected' style={{backgroundColor: attribute.billboardFill}}></div>
                    </div>:null
                  }
                  {
                    attribute.billboardStroke && attribute.billboardFill !== 'null' && this.state.selectedName !== 'Apto tipo' && this.state.selectedName !== 'Casas tipo' ?
                    <div className='attribute-individual-color'>
                      <div className='attribute-individual-color-name'>Trazo</div>
                      <div 
                      className='attribute-current-color-selected' 
                      style={{backgroundColor: attribute.billboardStroke}}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.setState({openColorEDitionModalIndex: index, openColorEditionModal: !this.state.openColorEditionModal, tempColor: attribute.layoutFill,openEditionName: false, nameEditionIndex: -1})
                        }}
                      ></div>
                    </div>:null
                  }
                                        {
                        this.state.openColorEDitionModalIndex === index && this.state.openColorEditionModal ?
                        <div className='color-picker-container'
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                        draggable={false}
                        >
                          <SketchPicker
                            color={this.state.tempColor}
                            disableAlpha={true}
                            onChange={(color) => {
                              this.setState({
                                tempColor: color.hex
                              }, () => {
                                var tempArray = this.state.currentAttributesArray
                                tempArray[index].layoutFill = this.state.tempColor
                                tempArray[index].billboardStroke = this.state.tempColor
                                this.setState({
                                  currentAttributesArray: tempArray
                                })
                              })
                            }}
                          />
                        </div>:null
                      }
                </div>

                <div className='attributes-delete' onClick={() => {this.handleDeleteFromArray(index)}}>
                  <img src={Delete} alt=''/>
                </div>
              </div>
              ):null
            }
          </div>

          <div className='save-cancel-attributes-btns'>
          <div className='cancel-attributes-btn' onClick={() => {this.setState({selectedType: '', selectedName: '', name: '', currentAttributesArray: [], currentAttributeName: ''}, () => {
            this.props.handleCloseEditionModal()
          })}}>
            {/* <div className='cancel-attributes-btn' onClick={() => {this.props.handleCloseEditionModal()}}> */}
              Cancelar
            </div>
            <div className='save-attributes-btn' onClick={() => {this.handleCreateObjectToSend()}}> 
              Guardar
            </div>
          </div>
        </div>
      )
  }
}

export default Edit