import { all, call, delay, fork, put, takeEvery } from "redux-saga/effects";
import { list, update } from "../../models/modules";

import { LIST_MODULES, UPDATE_MODULES } from "../actions";

import { modulesListSuccess, modulesListError } from "./actions";

import {
  projectSelectedSuccess,
  projectSelectedError,
} from "../project/actions";
import {
  builderSelectedSuccess,
  builderSelectedError,
} from "../builder/actions";
import { showAlert } from "../general/actions";

/*eslint-disable*/

interface moduleResponse {
  ModuleItf: any;
}

export function* watchmodulesList() {
  yield takeEvery(LIST_MODULES, modulesList);
}

export function* watchmodulesUpdate() {
  yield takeEvery(UPDATE_MODULES, modulesUpdate);
}

const modulesListAsync = async (proyecto: any): Promise<moduleResponse> =>
  await list(proyecto)
    .then((ModuleItf) => ModuleItf)
    .catch((error) => error);

function* modulesList({ payload }: any): any {
  const { proyecto } = payload;
  try {
    const modulesListData = yield call(modulesListAsync, proyecto);
    if (modulesListData.status === 200) {
      yield put(modulesListSuccess(modulesListData.data.modules));
      yield put(projectSelectedSuccess(modulesListData.data.project));
      yield put(builderSelectedSuccess(modulesListData.data.builder));
    } else {
      yield put(modulesListError(modulesListData));
      yield put(projectSelectedError(modulesListData));
      yield put(builderSelectedSuccess(modulesListData));
    }
  } catch (error) {
    yield put(modulesListError(error));
    yield put(projectSelectedError(error));
    yield put(builderSelectedSuccess(error));
  }
}

const modulesUpdateAsync = async (
  module: any
): Promise<moduleResponse> =>
  await update(module)
    .then((ModuleItf) => ModuleItf)
    .catch((error) => error);

function* modulesUpdate({ payload }: any): any {
  const { module, history } = payload;
  try {
    const modulesListData = yield call(modulesUpdateAsync, module);
    if (modulesListData.status === 200) {
        yield put(modulesListSuccess(modulesListData.data.modules));
        yield put(projectSelectedSuccess(modulesListData.data.project));
        yield put(builderSelectedSuccess(modulesListData.data.builder)); 
        yield put(
          showAlert({
            message: "Modulo actualizado con exito.",
            type: "success",
            show: true,
          })
        );
  
        yield delay(3000);
  
        yield put(
          showAlert({
            message: "Modulo actualizado con exito.",
            type: "success",
            show: false,
          })
        );
      } else {
        yield put(modulesListError(modulesListData));
        yield put(projectSelectedError(modulesListData));
        yield put(builderSelectedError(modulesListData)); 
        yield put(
          showAlert({
            message: "Error al actualizar el modulo",
            type: "danger",
            show: true,
          })
        );
      }
    } catch (error) {
      yield put(modulesListError(error));
      yield put(projectSelectedError(error));
      yield put(builderSelectedError(error));
      yield put(
        showAlert({
          message: "Error al actualizar el modulo",
          type: "danger",
          show: true,
        })
      );
    }
}

export default function* rootSaga() {
  yield all([
      fork(watchmodulesList),
      fork(watchmodulesUpdate)
    ]);
}
