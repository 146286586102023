import axios from 'axios';
import { ApiConfig } from '../constants/defaultValues';

export async function list(e: any) {
    return axios.post(ApiConfig.URL2 + "modules/get", e);
}
export async function create(e: any) {
    return axios.post(ApiConfig.URL2 + "modules/set", e);
}
export async function update(e: any) {
    return axios.post(ApiConfig.URL2 + "modules/update", e);
}
export async function remove(e: any) {
    return axios.post(ApiConfig.URL2 + "modules/delete", e);
}