import React from 'react'

import U3DS from "./../../UI/general/Placeholder_02.svg";
import dashBackground from './../../UI/backgrounds/BG_PUBLIC.webp'

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';

import Navbar from '../navbar';
import Sidebar from '../sidebar';

import {
    logoutUser,
    inmueblesTypeFilter,
    inmueblesList
} from "../../redux/actions";

interface Props{
    logoutUser: any,
    projectSelected: any,
    params: any,
    inmueblesTypeFilter: any,
    inmueblesList: any
}

interface State{
    selectedMenu: any;
    userString: any;
    user: any;
    idProject: any;
}

function withParams(Component: any) {
    return (props: any) => <GeneralPropertiesView {...props} params={useParams()} />;
}

class GeneralPropertiesView extends React.Component<Props,State>{
    constructor(props: Props){
        super(props);
        this.state = {
            selectedMenu: 1,
            userString: localStorage.getItem("user"),
            idProject: this.props.params.id,
            user: "",
        }
    }

    componentDidMount(): void {
        this.setState(
          {
            user: JSON.parse(this.state.userString),
          },
          () => {
            this.props.inmueblesList({
              user: this.state.user.id,
              project: this.state.idProject,
            });
          }
        );
      }

    handleChangeValue = (value: any) => {
        this.props.inmueblesTypeFilter({
            user: this.state.user.id,
            project: this.state.idProject,
            type: value
        })
        this.setState({
            selectedMenu: value
        })
    }

    render(): React.ReactNode {
        return(
            <div className='general-properties-container-main'>
                <img className='dashboard-background' src={dashBackground} alt=""/>
                <Sidebar
                    project={this.props.projectSelected}
                />
                <div className='general-properties-container'>
                    <div className='general-properties-select-icon-container'>
                        <div className='general-propertoes-title'>
                            Inmuebles
                        </div>
                        <div className='general-properties-icon-select-container'>
                            <div className='general-properties-icon-selected-container'>
                                <img src={U3DS} alt=""/>
                            </div>
                            <div className='general-properties-icon-text-container'>
                                <div className='general-propertiess-info-title'>
                                    Ícono del menú general
                                </div>
                                <div className='general-properties-info-subtitle'>
                                    Se mostrará en el menú principal
                                </div>
                                <div className='general-properties-info-text'>
                                    El archivo debe estar en .SVG
                                </div>
                                <input
                                    type="file"
                                    name="imagen"
                                    id="upload-photo-nuevo-icons"
                                    className="upload-photo-nuevo-icons inputfile-icons"
                                    //onChange={(e: any) => {handleAddLogo(e)}}
                                    accept="image/svg+xml"
                                />
                                <div className='input-icons-btn-general-properties'>
                                    Seleccionar
                                </div>
                            </div>
                        </div>
                        <div className='general-properties-filter-menu-container'>
                            <div className='general-properties-filter-name'>Seleccionar tipo de menú de filtrado:</div>
                            <div className='general-properties-filter-radios'>
                                <label>
                                    <input type='radio' name='filter' value={'1'} checked={this.state.selectedMenu === '1'} onChange={(e) => {this.handleChangeValue(e.target.value)}}>
                                    </input>
                                    Filtro aditivo
                                </label>
                                <label>
                                    <input type='radio' name='filter' value={'0'} checked={this.state.selectedMenu === '0'} onChange={(e) => {this.handleChangeValue(e.target.value)}}>
                                    </input>
                                    Filtro sustractivo
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className='general-properties-select-view-container'>
                        <div className='general-properties-attributes-view'>
                            <div className='general-properties-attributes-title'>
                                Configurar atributos
                            </div>
                            <div className='general-properties-attributes-text'>
                                Dirígete a la sección de atributos para configurar las características que tendrán los inmuebles del proyecto
                            </div>
                            <Link to={`/properties/attributes/${this.props.projectSelected.id}`}>
                                <div className='general-properties-attributes-btn'>
                                    Ir a Atributos
                                </div>
                            </Link>
                        </div>
                        <div className='general-properties-imuebles-view'>
                            <div className='general-properties-imuebles-title'>
                                Crear inmuebles
                            </div>
                            <div className='general-properties-imuebles-text'>
                                Dirígete a la sección de <b>Crear inmuebles</b> para configurar cada uno de los inmuebles que tendrá el proyecto. <br/><br/>
                                Ten en cuenta que no podrás crear inmuebles si no has configurado atributos previamente
                            </div>
                            <Link to={`/properties/${this.props.projectSelected.id}`}>
                                <div className='general-properties-imuebles-btn'>
                                    Ir a creación de inmuebles
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <Navbar
                    logout={this.props.logoutUser}
                />
            </div>
        )
    }
}

const mapStateToProps = ({
    projectReducer,
  }: any) => {
    const { projectSelected } = projectReducer;
    return { projectSelected};
  };

export default connect(mapStateToProps, {
    logoutUser,
    inmueblesTypeFilter,
    inmueblesList
})(withParams(GeneralPropertiesView));