import React from 'react'
import CreateRoadsComponent from './createRoads.component'
import RoadsIconComponent from './roadsIcon.component'
import ListRoads from './listRoads.component'

interface RoadsProps {
    handleCreateRoad: any,
    handleSaveVia: any,
    logo: string,
    viasArray: any,
    handleUpdateViaName: any,
    handleDeleteVia: any,
    handleUpdateViaColor: any
} 

const RoadsComponent = ({
    handleCreateRoad, 
    handleSaveVia, 
    logo, 
    viasArray, 
    handleUpdateViaName,
    handleDeleteVia,
    handleUpdateViaColor

}: RoadsProps) => {
    return(
        <div className='roads-create-container'>
            <div className='roads-create-title'>Vías de acceso</div>
            <RoadsIconComponent logo = {logo}/>
            <CreateRoadsComponent
                handleCreateRoad = {handleCreateRoad}
                handleSaveVia = {handleSaveVia}
            />
            <ListRoads
                handleDeleteVia = {handleDeleteVia}
                handleUpdateViaColor = {handleUpdateViaColor}
                handleUpdateViaName = {handleUpdateViaName}
                viasArray = {viasArray}
            />
        </div>
    )
}

export default RoadsComponent