import React from 'react'

import U3DS from "./../../UI/general/Placeholder_02.svg";

const CreateNewAmenitie = ({handleAddLogo, handleSelectIcon, currentLogoSelected, handleChangeNewAmenitieName, currentName, handleOpenUploadIconsModal}: any) => {

    const handleChange = (e: any) => {
        var upperCased = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1) 
        var slug = e.target.value.replace(" ","").replace("!","").replace("ñ","n").replace("´","").replace("-","").toLowerCase()
        handleChangeNewAmenitieName(upperCased, slug)
    }

    return(
        <div className='create-new-container'>
            <div className="logo-new-amenitie-container">
                <div className='logo-new-amenitie'>
                    <img
                        src={
                            currentLogoSelected ? 
                            `https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${currentLogoSelected}` : U3DS
                        }
                        alt=""
                        width={"100%"}
                        id="favicon"
                        onError={() => {
                            var image: any = document.getElementById("favicon")
                            if(image){
                            image.src = U3DS
                            }
                        }}
                    />
                </div>
                <div className="logo-new-amenitie-info">
                    <div className='logo-name-title-amenities-new'>
                        Ícono de Nueva Amenidad
                    </div>
                    <div className='logo-format-dubtitle-amenities-new'>
                        El archivo debe estar en formato .SVG
                    </div>
                    <div className='select-icon' onClick={() => {handleOpenUploadIconsModal(false)}}>
                        Seleccionar
                    </div>
                    <div className='input-amenities-icon' onClick={() => {handleOpenUploadIconsModal(true)}}>
                        Nuevo ícono
                    </div>
                </div>
                <div className='create-new-name'>
                    <input
                        type="text"
                        value={currentName}
                        onChange = {(e) => {handleChange(e)}}
                        maxLength = {25}
                    />
                </div>
            </div>
        </div>
    )
}

export default CreateNewAmenitie