import React, {useState, useRef, useEffect} from 'react'

import {
    GoogleMap,
    useJsApiLoader,
    Marker
} from "@react-google-maps/api";

/*eslint-disable*/

interface MapContainerProps{
    locations: any,
    currentImage: string,
    handleUpdatePOISArray: any,
    selectedCategoryPOI: any,
    locationUpdatePoisCategory: any,
    currentPOISArray: any
}

const containerStyle = {
    width: "100%",
    height: "100%",
};

const getPixelPositionOffset = (offsetWidth: any, offsetHeight: any, labelAnchor: any) => {
    return {
        x: offsetWidth + labelAnchor.x,
        y: offsetHeight + labelAnchor.y,
    };
};

const MapContainerPOICategory = ({locations, currentImage, handleUpdatePOISArray, selectedCategoryPOI, locationUpdatePoisCategory, currentPOISArray}: MapContainerProps) => {

    const [currentLat, setCurrentLat] = useState(parseFloat(locations.latitud));
    const [currentLng, setcurrentLng] = useState(parseFloat(locations.longitud));
    const [currentZoom, setZoom] = useState(parseInt(selectedCategoryPOI.zoom));
    const [map, setMap] = React.useState(null);
    const [POISArray, setPOISArray]: any = useState([])
    const [center, setCenter] = useState({
        lat: currentLat,
        lng: currentLng,
    });
    const [currentTempZoom, setCurrentTempZoom] = useState(0);
    const ref: any = useRef();

    const OPTIONS = {
        zoom: currentZoom,
        minZoom: 10,
        maxZoom: 20,
        disableDoubleClickZoom: true,
        draggableCursor: "pointer",
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        styles: [
          {
            "featureType": "administrative",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "landscape",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#878787"
              }
            ]
          }
        ]
    };

    useEffect(() => {
        setCurrentLat(selectedCategoryPOI.latitud === "0" ? parseFloat(locations.latitud) : parseFloat(selectedCategoryPOI.latitud))
        setcurrentLng(selectedCategoryPOI.longitud === "0" ? parseFloat(locations.longitud) : parseFloat(selectedCategoryPOI.longitud))
        setZoom(parseInt(selectedCategoryPOI.zoom))
    }, [selectedCategoryPOI])

    useEffect(() => {
        setPOISArray(currentPOISArray)
    }, [currentPOISArray])

    useEffect(() => {
      setCenter({
        lat: isNaN(currentLat) ? locations.latitud: currentLat,
        lng: isNaN(currentLng) ? locations.longitud: currentLng
      })
      setZoom(isNaN(currentZoom) ? locations.zoom: currentZoom)
    }, [
      currentLat, currentLng, currentZoom
    ])

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyBFIueHL6ufyFll26ee6TfN-qVpSvtp0sw",
    });

    const onLoad = React.useCallback(function callback(map: any) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);
    }, []);
    
    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null);
    }, []);

    const handleAddMarkerTo = (e: any) => {

        //setPOISArray([...POISArray, {lat: e.latLng.lat(),lng: e.latLng.lng()}])
        handleUpdatePOISArray(POISArray, {lat: e.latLng.lat(),lng: e.latLng.lng()})
    }

    const handleZoomChange = () => {
        if(ref.current){
            setCurrentTempZoom(parseInt(ref.current.state.map.zoom))
        }
    }

    const setCurrentView = () => {
        setZoom(ref.current.state.map.zoom)
        setCenter({
            lat: ref.current.state.map.center.lat(),
            lng: ref.current.state.map.center.lng(),
        });

        if(ref.current){
          locationUpdatePoisCategory(
            {
                zoom: ref.current.state.map.zoom,
                latitud: ref.current.state.map.center.lat(),
                longitud: ref.current.state.map.center.lng()
            }
        )
              }

        
        // SAME FUNCTION AND VARIABLES AS IN LOCATION
    }

    const createPOICanvas = (poi: any) => {

      const canvas = document.createElement('canvas');
      const context = canvas.getContext("2d");
      const text = poi.name;

      if(context){
        // Set font properties
        const fontSize = 16;
        const fontFamily = "Montserrat Regular";
        context.font = `${fontSize}px ${fontFamily}`;

        // Calculate text width
        const textWidth = context.measureText(text).width;

        // Set canvas width
        canvas.width = textWidth + 50;
        canvas.height = 35
        canvas.id = 'canvas-map'

        context.fillStyle = '#000';
        context.fillRect(0, 0, canvas.width, canvas.height);

        // Draw text on the canvas
        context.fillStyle = '#fff';
        context.font = '1rem Montserrat Regular';
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(text, (canvas.width/2), (canvas.height / 2));
      }

      var url = canvas.toDataURL()


      const anchorPoint = new window.google.maps.Point(-20, 55)

      return(
        <Marker
        position={
          {
            lat: parseFloat(poi.lat),
            lng: parseFloat(poi.lng)
          }
        }
        icon={{
            url: url,
            fillColor: "#EB00FF",
            anchor: anchorPoint
        }}
        />
      )
    }

    const createPOICircle = (poi: any) => {

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext("2d");

      if(ctx){
        var centerX = canvas.width / 2;
        var centerY = canvas.height / 2;
        var radius = 35;
  
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
        ctx.fillStyle = '#fff';

        ctx.fill()

        ctx.lineWidth = 5;
        ctx.strokeStyle = '#000';
        ctx.stroke();

        var img = new Image();
        
        img.onload = function() {
          var imgWidth = img.width / 2;
          var imgHeight = img.height / 2;
          var imgX = centerX - imgWidth / 2;
          var imgY = centerY - imgHeight / 2;
          ctx.drawImage(img, imgX, imgY, imgWidth, imgHeight);
        }

        img.src = 'https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/galleries/CB66/CP46/POIS/Salud.svg'
      }

      var url = canvas.toDataURL()


      const anchorPoint = new window.google.maps.Point(150, 112)

      return(
        <Marker
        position={
          {
            lat: parseFloat(poi.lat),
            lng: parseFloat(poi.lng)
          }
        }
        icon={{
            url: url,
            fillColor: "#EB00FF",
            anchor: anchorPoint
        }}
        zIndex={400}
        />
      )
    } 

    return(
        <div className='pois-category-map-container'>
            {
                isLoaded  ?(
                    <GoogleMap
                        options={OPTIONS}
                        ref={ref}
                        mapContainerStyle={containerStyle}
                        center={center}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        //onZoomChanged={handleZoomChange}
                        //onCenterChanged = {handleCenterChange}
                        onClick = {(e) => {handleAddMarkerTo(e)}}
                    >
                        {
                            POISArray.length > 0 ?
                            POISArray.map((poi: any, index: number) => {
                                return(
                                    <React.Fragment>
                                      {
                                        createPOICircle(poi)
                                      }
                                      {
                                        createPOICanvas(poi)
                                      }
                                        <Marker
                                        key = {index}
                                        position={poi}
                                        icon={{
                                            url: currentImage,
                                            fillColor: "#EB00FF",
                                            scaledSize: new window.google.maps.Size(40, 40),
                                            anchor: new google.maps.Point(40 / 2, 57.5)
                                        }}
                                        zIndex={500}
                                        >
                                        </Marker>
                                    </React.Fragment>
                                )
                            }):null
                        }
                    </GoogleMap>
                ) : (
                    <></>
                )
            }
            <div className='pois-category-fijar-container'>
                <div className='pois-category-fijar-btn' onClick = {() => {setCurrentView()}}>Fijar encuadre de mapa dínamico</div>
            </div>
        </div>
    )
}

export default MapContainerPOICategory