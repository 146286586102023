import React from 'react'

import spins from './../../UI/general/spins.svg'

const IconQuotation = () => {
    return(
        <div className='quotation-icon-main-container'>
            <div className='quotation-icon-title'>Módulo de Cotizacion</div>
            <div className='quotation-icon-container'>
                <div className='quotation-icon-holder'>
                    <img alt="" src={spins}/>
                </div>
                <div className='quotation-icon-text-container'>
                    <div className='quotation-icon-container-title'>Icono para el panel de cotización</div>
                    <div className='quotatuib-icon-text'>El material deve estar en formato .SVG</div>
                    <input 
                        type={"file"}
                        className="quotation-icon-input"
                    />
                    <div className='quotation-icon-btn'>
                        Modificar
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IconQuotation