import {
  LIST_PALETTE_CHROMATIC,
  LIST_PALETTE_CHROMATIC_SUCCESS,
  LIST_PALETTE_CHROMATIC_ERROR,
  UPDATE_PALETTE_CHROMATIC,
} from '../actions'

/*eslint-disable*/

const INIT_STATE = {
  paletteChromatic: [],
  loading: false,
  message: '',
}

export default (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case LIST_PALETTE_CHROMATIC:
      return { ...state, loading: true, message: '' }
    case LIST_PALETTE_CHROMATIC_SUCCESS:
      return {
        ...state,
        paletteChromatic: action.payload,
        loading: false,
        message: "Exito",
      }
    case LIST_PALETTE_CHROMATIC_ERROR:
      return {
        ...state,
        paletteChromatic: [],
        loading: false,
        message: action.payload.message,
      }
    case UPDATE_PALETTE_CHROMATIC:
      return { ...state, loading: true, message: '' }
    default:
      return { ...state }
  }
}
