import React from 'react'
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';

import U3DS from "./../../UI/general/Placeholder_02.svg";
import UR from './../../UI/general/url.svg'
import spins from "./../../UI/general/spins.svg";
import collapse from "./../../UI/general/collapse.svg";

import {
    Backdrop,
    Chip,
    CircularProgress,
    TextField,
  } from "@mui/material";

import Navbar from '../navbar';
import Sidebar from '../sidebar';
import SetupGeneral from './setup.component';
import GeneralModules from './modules.component';
import ColorComponent from './color.component';

import dashBackground from './../../UI/backgrounds/BG_PUBLIC.webp'

import {
    logoutUser,
    modulesList,
    paletteChromaticUpdate,
    modulesUpdate,
    projectUpdateInside,
    changeCurrentIndex,
    changeCurrentSubIndex,
    projectAddLogo
} from "../../redux/actions";

interface Props{
    logoutUser: any,
    changeCurrentIndex: any,
    changeCurrentSubIndex: any
    projectSelected: any,
    modulesList: any,
    modulesUpdate: any,
    paletteChromaticUpdate: any,
    projectUpdateInside: any,
    modules: any,
    loading: any,
    selectedBuilder: any,
    params: any,
    projectAddLogo: any
}

interface State{
    userString: any,
    user: any,
    name: string,
    legalText: string,
    secondaryLegalText: string,
    moduleLogo: boolean,
    moduleRoads: boolean,
    modulePOIS: boolean,
    moduleLocation: boolean,
    moduleTownplanning: boolean,
    moduleAmenities: boolean,
    moduleProperties: boolean,
    moduleGaleries: boolean,
    moduleDispo: boolean,
    moduleCoti: boolean,
    moduleAsoleacion: boolean,
    open: boolean,
    idProject: any,
    logo: any,
    logoDefault: any,
    frame: any,
    frameDefault: any,
    openedAditionalLogos: any,
    numberOfAditionalLogos: any
}

function withParams(Component: any) {
    return (props: any) => <GeneralInformationView {...props} params={useParams()} />;
  }


class GeneralInformationView extends React.Component<Props,State>{
    constructor(props: Props){
        super(props);
        this.state = {
            userString: localStorage.getItem("user"),
            user: "",
            name: "",
            legalText: "",
            secondaryLegalText: "",
            moduleLogo: false,
            moduleRoads: false,
            modulePOIS: false,
            moduleLocation: false,
            moduleTownplanning: false,
            moduleAmenities: false,
            moduleProperties: false,
            moduleGaleries: false,
            moduleDispo: false,
            moduleCoti: false,
            moduleAsoleacion: false,
            open: false,
            idProject: this.props.params.id,
            logo: null,
            logoDefault: "",
            frame: null,
            frameDefault: "",
            openedAditionalLogos: false,
            numberOfAditionalLogos: [
              {'file':''}
            ]
        }
    }

    componentDidMount(): void {

        if(this.state.userString){
            this.setState({
                user: JSON.parse(this.state.userString)
            }, () => {
                this.props.changeCurrentIndex(0)
                this.props.changeCurrentSubIndex(-1)
                this.props.modulesList({ user: this.state.user.id, project: this.state.idProject})
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
      if(this.props.modules.length > 0 && prevProps.modules !== this.props.modules){
          this.props.modules.forEach((module: any, index: number) => {
            if (module.name === "Logo de Proyecto") {
              this.setState({moduleLogo: module.state === 1 ? true : false})
            } 
            else if (module.name === "Vias de Acceso") {
              this.setState({moduleRoads: module.state === 1 ? true : false})
            } 
            else if (module.name === "Puntos de Interes") {
              this.setState({modulePOIS: module.state === 1 ? true : false})
            } 
            else if (module.name === "Ubicación") {
              this.setState({moduleLocation: module.state === 1 ? true : false})
            } 
            else if (module.name === "Urbanismo") {
              this.setState({moduleTownplanning: module.state === 1 ? true : false})
            } 
            else if (module.name === "Amenidades") {
              this.setState({moduleAmenities: module.state === 1 ? true : false})
            } 
            else if (module.name === "Inmuebles") {
              this.setState({moduleProperties: module.state === 1 ? true : false})
            } 
            else if (module.name === "Galería") {
              this.setState({moduleGaleries: module.state === 1 ? true : false})
            } 
            else if (module.name === "Disponibilidad") {
              this.setState({moduleDispo: module.state === 1 ? true : false})
            } 
            else if (module.name === "Cotización") {
              this.setState({moduleCoti: module.state === 1 ? true : false})
            } 
            else if (module.name === "Asoleación") {
              this.setState({moduleAsoleacion: module.state === 1 ? true : false})
            } 
          });
        }
        if(this.props.projectSelected && Object.keys(this.props.projectSelected).length > 0 && prevProps.projectSelected !== this.props.projectSelected){
          console.log(this.props.projectSelected)
            this.setState({
                name: this.props.projectSelected.name,
                legalText: this.props.projectSelected.legalText,
                numberOfAditionalLogos: this.props.projectSelected.logos_adicionales,
                secondaryLegalText: this.props.projectSelected.secondaryLegalText
            })
        }
    }

    update = () => {

        //Secondary legal text
        console.log(this.state.secondaryLegalText)

        if (this.state.name !== "") {
          if (this.state.name !== undefined) {
            let user: any = localStorage.getItem("user");
    
            this.props.projectUpdateInside({
              data: {
                data: {
                  name: this.state.name,
                  legalText: this.state.legalText,
                  secondaryLegalText: this.state.secondaryLegalText,
                  ico: `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/favicon.ico`,
                  slug: this.state.name.toLowerCase().split(" ").join("-"),
                },
                lastProjectName: this.props.projectSelected.name.toLowerCase().split(" ").join("-"),
                builder: this.props.selectedBuilder.id,
                builder_name: this.props.selectedBuilder.slug,
                id: this.props.projectSelected.id,
                user: JSON.parse(user).id,
              }
            });
          } else {
            let user: any = localStorage.getItem("user");
    
            this.props.projectUpdateInside({
              data: {
                data: {
                  name: this.props.projectSelected.name,
                  legalText: this.state.legalText,
                  secondaryLegalText: this.state.secondaryLegalText,
                  ico: `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/favicon.ico`,
                  slug: this.props.projectSelected.name.toLowerCase().split(" ").join("-"),
                },
                lastProjectName: this.props.projectSelected.name.toLowerCase().split(" ").join("-"),
                builder: this.props.selectedBuilder.id,
                builder_name: this.props.selectedBuilder.slug,
                id: this.props.projectSelected.id,
                user: JSON.parse(user).id,
              },
            });
          }
        }
      };

    updateIco = () => {
        let InstFormData = new FormData();
        if (this.state.name !== "") {
          if (this.state.name !== undefined) {
            let user: any = localStorage.getItem("user");
            InstFormData.append("ico", this.state.logo);
            InstFormData.append(
              "name",
              `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/favicon.ico`
            );
    
            this.props.projectUpdateInside({
              data: {
                data: {
                  name: this.state.name,
                  legalText: this.state.legalText,
                  secondaryLegalText: this.state.secondaryLegalText,
                  ico: `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/favicon.ico`,
                  slug: this.state.name.toLowerCase().split(" ").join("-"),
                },
                lastProjectName: this.props.projectSelected.name.toLowerCase().split(" ").join("-"),
                builder: this.props.selectedBuilder.id,
                builder_name: this.props.selectedBuilder.slug,
                id: this.props.projectSelected.id,
                user: JSON.parse(user).id,
              },type: "Ico",
              assets: InstFormData,
            });
          } else {
            let user: any = localStorage.getItem("user");
            InstFormData.append("ico", this.state.logo);
            InstFormData.append(
              "name",
              `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/favicon.ico`
            );
    
            this.props.projectUpdateInside({
              data: {
                data: {
                  name: this.props.projectSelected.name,
                  legalText: this.state.legalText,
                  secondaryLegalText: this.state.secondaryLegalText,
                  ico: `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/favicon.ico`,
                  slug: this.props.projectSelected.name.toLowerCase().split(" ").join("-"),
                },
                lastProjectName: this.props.projectSelected.name.toLowerCase().split(" ").join("-"),
                builder: this.props.selectedBuilder.id,
                builder_name: this.props.selectedBuilder.slug,
                id: this.props.projectSelected.id,
                user: JSON.parse(user).id,
              },type: "Ico",
              assets: InstFormData,
            });
          }
        }
      };

      updateIntro = () => {
          let InstFormData = new FormData();
          if (this.state.name !== "") {
            if (this.state.name !== undefined) {
              let user: any = localStorage.getItem("user");
              InstFormData.append("myFile", this.state.frame);
              InstFormData.append(
                "name",
                `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/intro.webp`
              );
      
              this.props.projectUpdateInside({
                data: {
                  data: {
                    name: this.state.name,
                    legalText: this.state.legalText,
                    secondaryLegalText: this.state.secondaryLegalText,
                    intro: `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/intro.webp`,
                    slug: this.state.name.toLowerCase().split(" ").join("-"),
                  },
                  lastProjectName: this.props.projectSelected.name.toLowerCase().split(" ").join("-"),
                  builder: this.props.selectedBuilder.id,
                  builder_name: this.props.selectedBuilder.slug,
                  id: this.props.projectSelected.id,
                  user: JSON.parse(user).id,
                },
                type: "Intro",
                assets: InstFormData,
              });
            } else {
              let user: any = localStorage.getItem("user");
              InstFormData.append("myFile", this.state.frame);
              InstFormData.append(
                "name",
                `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/intro.webp`
              );
      
              this.props.projectUpdateInside({
                data: {
                  data: {
                    name: this.props.projectSelected.name,
                    legalText: this.state.legalText,
                    secondaryLegalText: this.state.secondaryLegalText,
                    intro: `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/intro.webp`,
                    slug: this.props.projectSelected.name.toLowerCase().split(" ").join("-"),
                  },
                  lastProjectName: this.props.projectSelected.name.toLowerCase().split(" ").join("-"),
                  builder: this.props.selectedBuilder.id,
                  builder_name: this.props.selectedBuilder.slug,
                  id: this.props.projectSelected.id,
                  user: JSON.parse(user).id,
                },
                type: "Intro",
                assets: InstFormData,
              });
            }
          }
        };

    handleAddLogo = (e: any) => {
      var image = URL.createObjectURL(e.target.files[0])
      this.setState({
        logoDefault: image,
        logo: e.target.files[0]
      }, () => this.updateIco())
    }

    handleAddFrameIntro = (e: any) => {
      if(e.target.files[0].size <= 550000){
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.addEventListener('load', () => {
          const img: any = new Image()
          if(reader.result){
            img.src = reader.result
            img.addEventListener('load', () => {
              var width = img.width
              var height = img.height
              if(height === 1190 && width === 1516){
                var image = URL.createObjectURL(e.target.files[0])
                this.setState({
                  frameDefault: image,
                  frame: e.target.files[0]
                }, () => this.updateIntro())
              }
              else{
                alert('La imagen tiene dimensiones incorrectas debe ser 1516 x 1190 y esta es de' + width + ' x ' + height + ' ¿qué paso ahí papu?')
              }
            })
          }

        })


      }
      else{
        alert('La imagen superan el peso máximo de 500kb y no sera agregada a la maqueta, ¿qué paso ahí papu?')
      }
    }

    handleChangeModuls = (e: any, element: any) => {
      this.setState({
        moduleLogo: element.name === "Logo de Proyecto" ? e.target.checked: this.state.moduleLogo,
        moduleRoads: element.name === "Vias de Acceso" ? e.target.checked: this.state.moduleRoads,
        modulePOIS: element.name === "Puntos de Interes" ? e.target.checked: this.state.modulePOIS,
        moduleLocation: element.name === "Ubicación" ? e.target.checked: this.state.moduleLocation,
        moduleTownplanning: element.name === "Urbanismo" ? e.target.checked: this.state.moduleTownplanning,
        moduleAmenities: element.name === "Amenidades" ? e.target.checked: this.state.moduleAmenities,
        moduleProperties: element.name === "Inmuebles" ? e.target.checked: this.state.moduleProperties,
        moduleGaleries: element.name === "Galería" ? e.target.checked: this.state.moduleGaleries,
        moduleDispo: element.name === "Disponibilidad" ? e.target.checked: this.state.moduleDispo,
        moduleCoti: element.name === "Cotización" ? e.target.checked: this.state.moduleCoti,
        moduleAsoleacion: element.name === "Asoleación" ? e.target.checked: this.state.moduleAsoleacion,
      }, () => {
        this.props.modulesUpdate({
          id: element.id,
          state: e.target.checked ? 1 : 0,
          user: this.state.user.id,
          project: element.project
        })
      })
      
    };

    handleAddNewToAditionalIcons = () => {
      var tempArray = this.state.numberOfAditionalLogos
      tempArray.push({'file':''})
      this.setState({
        numberOfAditionalLogos: tempArray
      })
    }

    handleAddFileToAditionalIcon = (payload: any, index: number) => {

      let InstFormData = new FormData();
      InstFormData.append("myFile", payload.target.files[0]);
      InstFormData.append("name", `galleries/${this.props.projectSelected.builder}/${this.props.projectSelected.id}/logos-adicionales/${payload.target.files[0].name}`);

      this.props.projectAddLogo({
        user: this.state.user.id,
        project: this.props.projectSelected.id,
        assets: InstFormData,
        index: index,
        route: `galleries/${this.props.projectSelected.builder}/${this.props.projectSelected.id}/logos-adicionales/${payload.target.files[0].name}`
      })
    }

    render(){
        return(
            <div className='general-information-main-container'>
                <img className='dashboard-background' src={dashBackground} alt=""/>
                <div className='general-info-container'>
                    <div className='general-info-main-container'>
                    <SetupGeneral
                        projectSelected = {this.props.projectSelected}
                        logoDefault = {this.state.logoDefault}
                        update = {this.update}
                    />
                    <div className='logo-name-container'>
                      <div className='logo-container'>
                        <div className='logo-image'> 
                          <img
                            src={
                              Object.keys(this.props.projectSelected).length > 0 && 
                              this.props.projectSelected.ico !== undefined &&
                              this.state.logoDefault === ""
                                ? `https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${this.props.projectSelected.ico}`
                                : this.props.projectSelected.ico === undefined &&
                                  this.state.logoDefault === ""
                                ? U3DS
                                : this.state.logoDefault
                            }
                            alt=""
                            width={"100%"}
                            id="favicon"
                            onError={() => {
                              var image: any = document.getElementById("favicon")
                              if(image){
                                image.src = U3DS
                              }
                            }}
                          />
                        </div>
                        <div className='logo-information'>
                          <div className='logo-name-title'>
                            Favicon del proyecto
                          </div>
                          <div className='logo-format-subtitle'>
                            El archivo debe estar en formato .PNG
                          </div>
                          <div className='logo-format-dubtitle'>
                            El tamaño debe ser 512px x 512px
                          </div>
                          <input
                            type="file"
                            name="imagen"
                            id="upload-photo-nuevo"
                            className="upload-photo-nuevo inputfile"
                            onChange={(e: any) => {this.handleAddLogo(e)}}
                            accept="image/png"
                          />
                          <label htmlFor="upload-photo-nuevo">
                            <Chip label="Seleccionar" />
                          </label>
                        </div>
                      </div>
                      <div className='frame-intro-container'>
                        <div className='logo-image'> 
                          <img
                            src={
                              Object.keys(this.props.projectSelected).length > 0 &&
                              this.props.projectSelected.intro !== undefined &&
                              this.state.frameDefault === ""
                                ? `https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${this.props.projectSelected.intro}`
                                : this.props.projectSelected.intro === undefined &&
                                  this.state.frameDefault === ""
                                ? U3DS
                                : this.state.frameDefault
                            }
                            alt=""
                            width={"100%"}
                            id="intro"
                            onError={() => {
                              var image: any = document.getElementById("intro")
                              if(image){
                                image.src = U3DS
                              }
                            }}
                          />
                        </div>
                        <div className='logo-information'>
                          <div className='logo-name-title'>
                          Frame de intro
                          </div>
                          <div className='logo-format-subtitle'>
                          El archivo debe estar en formato .WEBP
                          </div>
                          <div className='logo-format-dubtitle'>
                            El tamaño debe ser 1516px x 1190px
                          </div>
                          <input
                            type="file"
                            name="imagen-intro"
                            id="upload-photo-nuevo-intro"
                            className="upload-photo-nuevo inputfile"
                            onChange={(e: any) => {this.handleAddFrameIntro(e)}}
                            accept="image/webp"
                          />
                          <label htmlFor="upload-photo-nuevo-intro">
                            <Chip label="Seleccionar" />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='additional-logos-container'>
                      <div className='additional-logo-name-icon' onClick={() => {this.setState({openedAditionalLogos: !this.state.openedAditionalLogos})}}>
                        <div className='aditional-logos-name'>Logos adicionales</div>
                        <img src={collapse} alt=''/>
                      </div>
                      {
                        this.state.openedAditionalLogos ?
                        <div className='additional-logos-setup'>
                          <div className='aditioanl-logo-text'>
                            Cargue a la plataforma los logos de patrocinadores, asociados y entidades relacionadas con el proyecto. Estos se mostrarán al nivel de constructora.
                          </div>
                          <div className='aditional-logo-individual-general-container'>
                          {
                            this.state.numberOfAditionalLogos.map((aditionalLogo: any, index: number) => 
                              <div className='aditional-logo-individual-container'>
                                <div className='aditional-logo-icon'>
                                  <img src={aditionalLogo.link ? 'https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/'+aditionalLogo.link:spins} alt=''/>
                                </div>
                                <div className='aditional-logo-title-text'>
                                  <div className='aditional-logo-individual-title'>Subir un logo asociado</div>
                                  <div className='aditional-logo-individual-text'>El archivo debe estar en formato .SVG</div>
                                  <div className='aditional-logo-individual-input-container'>
                                    <input
                                      type="file"
                                      name="imagen-intro"
                                      className="aditional-logo-individual-upload-input"
                                      onChange={(e: any) => {this.handleAddFileToAditionalIcon(e, index)}}
                                      accept="image/svg+xml"
                                    />
                                    <div className='aditional-logo-individual-upload'>Subir archivo</div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                          </div>
                          <div className='additional-logo-btns' onClick={() => {this.handleAddNewToAditionalIcons()}}>
                            + Añadir otro logo asociado
                          </div>
                        </div>:null
                      }
                    </div>

                    <div className='logo-name-container-2'>
                      <div className='url-name-general-information'>
                          URL de maqueta
                      </div>
                      <div className='name-container'>
                        <input type={"text"} value={this.props.projectSelected.url}/>
                        <div className='general-info-copy-url' onClick = {() => {navigator.clipboard.writeText(this.props.projectSelected.url)}}>
                          <img src={UR} alt=""/>
                          <div className='general-info-copy-text' >Copiar URL</div>
                        </div>
                      </div>
                    </div>
                    <div className='legal-text-container'>
                      <div className='legal-text-title'>Texto legal de portada</div>
                      <TextField
                        sx={{ 
                          position: "relative",
                          width: "90%", 
                          bgcolor: "#fff", 
                        }}
                        id="outlined-basic"
                        label="-"
                        variant="outlined"
                        multiline
                        rows={4}
                        inputProps={{ maxLength: 800 }}
                        value={this.state.legalText}
                        onChange={(e) => {
                          this.setState({
                            legalText: e.target.value
                          })
                        }}
                        onBlur = {() => {
                          this.update()
                        }}
                      />
                    </div>
                    <div className='legal-text-container'>
                    <div className='legal-text-title'>Texto legal de maqueta</div>
                      <TextField
                        sx={{ 
                          position: "relative",
                          width: "90%", 
                          bgcolor: "#fff", 
                        }}
                        id="outlined-basic"
                        label="-"
                        variant="outlined"
                        multiline
                        rows={4}
                        inputProps={{ maxLength: 800 }}
                        value={this.state.secondaryLegalText}
                        onChange={(e) => {
                          this.setState({
                            secondaryLegalText: e.target.value
                          })
                        }}
                        onBlur = {() => {
                          this.update()
                        }}
                      />
                    </div>
                    </div>

                    <GeneralModules
                      modules={this.props.modules}
                      moduleLogo={this.state.moduleLogo} 
                      moduleRoads={this.state.moduleRoads} 
                      modulePOIS={this.state.modulePOIS}
                      moduleLocation={this.state.moduleLocation}
                      moduleTownplanning={this.state.moduleTownplanning}
                      moduleAmenities={this.state.moduleAmenities}
                      moduleProperties={this.state.moduleProperties}
                      moduleGaleries={this.state.moduleGaleries}
                      moduleDispo = {this.state.moduleDispo}
                      moduleCoti = {this.state.moduleCoti}
                      moduleAsoleacion = {this.state.moduleAsoleacion}
                      handleChangeModuls={this.handleChangeModuls}
                    />
                    <ColorComponent proyecto={this.props.projectSelected} paletteChromaticUpdate={this.props.paletteChromaticUpdate} user={localStorage.getItem("user")}/>
                </div>
                <Sidebar
                    project={this.props.projectSelected}
                />
                <Navbar
                    logout={this.props.logoutUser}
                />
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.props.loading}
                    //onClick={handleClose}
                    >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

const mapStateToProps = ({
    projectReducer,
    builderReducer,
    moduleReducer,
}: any) => {
    const { modules, loading } = moduleReducer;
    const { projectSelected } = projectReducer;
    const { selectedBuilder } = builderReducer;
    return { modules, projectSelected, loading, selectedBuilder };
};

export default connect(mapStateToProps, {
    modulesList,
    modulesUpdate,
    paletteChromaticUpdate,
    projectAddLogo,
    projectUpdateInside,
    logoutUser,
    changeCurrentIndex,
    changeCurrentSubIndex
})(withParams(GeneralInformationView));