import React from 'react'
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';

import {
    iconsList,
    iconsCreate,
    openIconsModal
} from "../../redux/actions";

import IconsContainer from './icons.component';
import IconsCreateSelector from './selector.component';
import UploadNewIcon from './upload.component';

import {
    Backdrop,
    CircularProgress,
} from "@mui/material";

/*eslint-disable*/

interface Props{
    params: any,
    loading: any,
    projectSelected: any,
    selectedBuilder: any,
    iconsList: any,
    iconsCreate: any
    isIconsModalOpened: any,
    icons: any,
    openIconsModal: any,
    handleSelectLogo: any,
    showIconsUpload: boolean
}

interface State{
    userString: any,
    user: any,
    idProject: any,
    isUploadActive: boolean,
    icon: string,
    iconName: string,
    iconArray: Array<String>,
    currentSelected: any
}

function withParams(compnent: any){
    return (props: any) => <IconsView {...props} params={useParams()}/> 
}

class IconsView extends React.Component<Props,State>{
    constructor(props: Props){
        super(props);
        this.state = {
            userString: localStorage.getItem("user"),
            user: "",
            idProject: this.props.params.id,
            isUploadActive: false,
            icon: "",
            iconName:"",
            iconArray: [],
            currentSelected: ""
        }
    }

    componentDidMount(): void {
        if(this.state.userString){
            this.setState({
                user: JSON.parse(this.state.userString)
            }, () => {
                this.props.iconsList({user: this.state.user.id, project: this.state.idProject})
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.showIconsUpload !== this.props.showIconsUpload && this.props.showIconsUpload){
            this.setState({
                isUploadActive: true
            })
        }
        if(prevProps.showIconsUpload !== this.props.showIconsUpload && this.props.showIconsUpload === false){
            this.setState({
                isUploadActive: false
            })
        }
    }

    handleOpenUploadModal = () => {
        this.setState({
            isUploadActive: !this.state.isUploadActive
        })
    }

    handleSetIcon = (icon: string) => {
        this.setState({
            icon: icon
        })
    }

    handleSetIconName = (iconName: string) => {
        this.setState({
            iconName: iconName
        })
    }

    handleSetIconsFolders = (iconsArray: Array<String>) => {
        this.setState({
            iconArray: iconsArray
        })
    }

    handleCreateIcon = () => {

        var array: any = {data:[], icons:[]}

        this.state.iconArray.map((folder: any, index: number) => {

            array.data.push(
                {
                    folder_id: folder.id,
                    name: this.state.iconName,
                    icon: `icons/${folder.slug}/${this.state.iconName}.svg`,
                }
            )

            array.icons.push(
                {ico: this.state.icon}
            )
        })

        this.props.iconsCreate(array)
    }

    handleCurrentSelected = (payload: string) => {
        this.setState({
            currentSelected: payload
        })
    }

    handleSendIcon = () => {
        this.props.handleSelectLogo(this.state.currentSelected)
        this.props.openIconsModal(false)
    }

    render(): React.ReactNode {
        return(
            <div className={this.props.isIconsModalOpened ? 'icons-main-container fade-in':'icons-main-container fade-out'}>
                {
                    !this.state.isUploadActive ?
                    <div className='icons-container'>
                        <div className='icons-name'>Íconos</div>
                        <div className='icons-text'>Seleccione el material que desea asignar.</div>
                        <div className='create-icons' onClick={() => {this.handleOpenUploadModal()}}>
                            Subir nuevo ícono
                        </div>
                        <IconsContainer
                            icons = {this.props.icons}
                            handleCurrentSelected = {this.handleCurrentSelected}
                        />
                        <div className='cancel-btn-icons' onClick={() => {this.props.openIconsModal(false)}}>
                            Cancelar
                        </div>
                        <div className='asign-btn-icons' onClick={() => {this.handleSendIcon()}}>
                            Asignar
                        </div>
                    </div>:
                    <div className='icons-upload-container'>
                        <div className='icons-name'>Subir nuevo ícono</div>
                        <UploadNewIcon
                            handleSetIcon={this.handleSetIcon}
                            handleSetIconName={this.handleSetIconName}
                        />
                        <IconsCreateSelector
                            icons={this.props.icons}
                            handleSetIconsFolders = {this.handleSetIconsFolders}
                        />
                        <div className='cancel-btn-icons' onClick={() => {this.props.showIconsUpload ? this.props.openIconsModal(false):this.handleOpenUploadModal()}}>
                            Cancelar
                        </div>
                        <div className='save-icon-btn' onClick={() => {this.handleCreateIcon()}}>
                            Guardar
                        </div>
                    </div>
                }
                <Backdrop
                    sx={{ color: "#fff"}}
                    open={this.props.loading}
                    >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

const mapStateToProps = ({
    projectReducer,
    builderReducer,
    iconsReducer
}: any) => {
    const {projectSelected} = projectReducer;
    const {selectedBuilder} = builderReducer;
    const {isIconsModalOpened, icons, loading} = iconsReducer;

    return {projectSelected, selectedBuilder, isIconsModalOpened, icons, loading}
}

export default connect(mapStateToProps, {
    iconsList,
    iconsCreate,
    openIconsModal
})(withParams(IconsView))