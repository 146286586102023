import React, {useEffect, useState} from 'react'

import spins from './../../UI/general/spins.svg'

const LinkCreate = ({breadCrumbs, handleUploadLink, openEditionModeLinks, linkToEdit, handleUpdateLink, handleAddFile}: any) => {

    const [linkName, setLinkName] = useState("")
    const [linkURL, setLinkURL] = useState("")
    const [linkDesc, setLinkDesc] = useState("")
    const [PDFFile, setPDFFile]: any = useState(null)

    useEffect(() => {
        setLinkName(linkToEdit.name)
        setLinkURL(linkToEdit.link)
        setLinkDesc(linkToEdit.description)
    }, [linkToEdit])


    const handleValidateLink = (linkName: any, linkDesc: any, linkURL: any) => {
        if(linkURL.includes("youtube")){
            if(linkURL.includes("embed")){
                handleUploadLink(linkName, linkDesc, "list", linkURL)
            }
            else{
                alert('El link no es un link embed de Youtube, por lo tanto no funcionará en la maqueta')
            }
        }
        else{
            handleUploadLink(linkName, linkDesc, "list", linkURL)
        }
    }


    return(
        <div className='link-create-container'>
            <div className='links-create-name'>{openEditionModeLinks ? `Editar ${breadCrumbs[2].slice(0,-1)}`:breadCrumbs[2]}</div>
            <div className='links-create-text'>
                Si el inmueble cuenta con un {breadCrumbs[2].toLowerCase()} por favor ingresa la URL o sube un archivo en PDF.
            </div>
            <div className='links-create-name-container'>
                <div className='links-create-input-title'>
                    Nombre
                </div>
                <input
                    type={"text"}
                    value = {linkName}
                    onChange = {(e) => {setLinkName(e.target.value)}}
                />
            </div>
            <div className='links-create-url-container'>
                <div className='links-create-input-title'>
                    URL
                </div>
                <input
                    type={"text"}
                    value = {linkURL}
                    onChange = {(e) => {setLinkURL(e.target.value)}}
                    disabled = {PDFFile !== null}
                />
            </div>
            {
                breadCrumbs[2] === 'Brochures' ?
                <div className='brochures-upload-file'>
                    <div className='brochures-preview-image-upload'>
                        <img src={spins} alt=""/>
                    </div>
                    <div className='brochures-preview-link-info'>
                        <div className='brochures-preview-link-title'>Subir un brochure</div>
                        <div className='brochures-preview-subtitle'>El archivo debe estar en formato .PDF</div>
                        <div className='brochures-preview-subtitle'>El peso debe ser máximo de 5 MB</div>
                            <div className='info-upload-new-image'>
                                <input
                                    type={"file"}
                                    accept={"application/pdf"}
                                    className="asoleacion-upload-input"
                                    onChange = {(e) => {setPDFFile(e.target.files !== null ? e.target.files[0]:null)}}
                                />
                                <div className='asoleacion-upload-btn'>
                                    Subir Archivo
                                </div>
                            </div>
                    </div>
                </div>:null  
            }
            <div className='links-create-desc-container'>
                <div className='links-create-input-title'>
                    Descripción
                </div>
                <textarea 
                    className='input-desc-links-create'
                    cols={40}
                    value={linkDesc}
                    onChange = {(e) => {setLinkDesc(e.target.value)}}
                />
            </div>
            <div className='links-create-save' onClick={
                () => {
                    openEditionModeLinks ?
                    handleUpdateLink(linkName, linkDesc, linkURL, linkToEdit.id):
                    PDFFile !== null  ?
                    handleAddFile(linkName, linkDesc, 'list', PDFFile):
                    handleValidateLink(linkName, linkDesc, linkURL)
                }}>
                Guardar
            </div>
        </div>
    )
}

export default LinkCreate