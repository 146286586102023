const HeadGeneralInfo = ({title, event}: any) => {
    return(
        <div className='header-general-info-container'>
            <div className='general-title'>
                {title}
            </div>
        </div>
    )
}

export default HeadGeneralInfo