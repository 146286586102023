import React from 'react'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'

import Delete from './../../UI/general/close.svg'

interface Props {
  handleAddSalaToArray: any
}

interface State {
  currentName: any,
  currentDirection: any,
  currentHour: any,
  currentHourFrom: any,
  currentHourTo: any,
  currentHoursArray: any,
  currentNumber: any,
  currentEmail: any,
  currentEmailsArray: any
  hasWhatsapp: any,
  numberArray: any,
  currentWazeLink: any,
  currentGoogleMapsLinks: any
}

class CreateNew extends React.Component<Props,State>{
  constructor(props: Props){
    super(props);
    this.state = {
      currentName: '',
      currentDirection: '',
      currentHour: '',
      currentHourFrom: '',
      currentHourTo: '',
      currentHoursArray: [],
      currentNumber: '',
      currentEmail: '',
      currentEmailsArray: [],
      hasWhatsapp: '',
      numberArray: [],
      currentWazeLink: '',
      currentGoogleMapsLinks: ''
    }
  }

  handleCompileInfo = () => {

    if(this.state.currentNumber !== ""){
      var tempData = this.state.numberArray
      tempData.push({
        'number': this.state.currentNumber,
        'whatsapp': this.state.hasWhatsapp
      })
      this.setState({
        numberArray: tempData
      })
    }

    if(this.state.currentEmail !== ""){
      var tempArray = this.state.currentEmailsArray
      tempArray.push({
        'email': this.state.currentEmail
      })
      this.setState({
        currentEmailsArray: tempArray
      })
    }

    setTimeout(() => {
      const compiledInfo = {
        name: this.state.currentName,
        location: this.state.currentDirection,
        URLWaze: this.state.currentWazeLink,
        URLMaps: this.state.currentGoogleMapsLinks,
        phones: this.state.numberArray,
        emails: this.state.currentEmailsArray,
        hour: this.state.currentHoursArray
      }

      this.props.handleAddSalaToArray(compiledInfo)
      this.handleResetStates()
    }, 500)
  }

  handleResetStates = () => {
    this.setState({
      currentName: '',
      currentDirection: '',
      currentHour: '',
      currentHourFrom: '',
      currentHourTo: '',
      currentHoursArray: [{
        'days':'',
        'from':'',
        'to':''
      }],
      currentNumber: '',
      currentEmail: '',
      currentEmailsArray: [],
      hasWhatsapp: '',
      numberArray: [],
      currentWazeLink: '',
      currentGoogleMapsLinks: ''
    })
  }

  render(): React.ReactNode {
      return(
        <div className='create-new-contact-sala-main-container'>
          <div className='create-new-contact-title'>Crear un directorio de contacto</div>
          <div className='create-new-contact-create-container'>

            <div className='create-new-contact-item'>
              <div className='create-new-item-title'>Nombre</div>
              <input type='text' value={this.state.currentName} onChange={(e) => {this.setState({currentName: e.target.value})}}/>
            </div>

            <div className='create-new-contact-item'>
              <div className='create-new-item-title'>Dirección</div>
              <input type='text' value={this.state.currentDirection} onChange={(e) => {this.setState({currentDirection: e.target.value})}}/>
            </div>

            <div className='create-new-contact-item-alt'>
              <div className='create-new-item-title-alt-container'>
                <div className='hour-item-alt-container'>Días de atención</div>
                <div className='time-item-alt-container'>Franja horaria</div>
              </div>
              {
                <div className='create-new-item-input-container'>
                  <input type='text' value={this.state.currentHour}  className='input-container-large' onChange={(e) => {
                    this.setState({
                      currentHour: e.target.value
                    })
                    }}/>
                  <div>de</div>
                  <input type='text' value={this.state.currentHourFrom} className='input-container-short' onChange={(e) => {
                    this.setState({
                      currentHourFrom: e.target.value
                    })
                    }}/>
                  <div>a</div>
                  <input type='text' value={this.state.currentHourTo} className='input-container-short' onChange={(e) => {
        
                    this.setState({
                      currentHourTo: e.target.value
                    })
                    }}/>
                </div>
              }
            </div>

            <div className='create-new-phone-btn' style={{marginBottom: '0.5rem'}} onClick={() => {
              var tempArray = this.state.currentHoursArray
              tempArray.push({
                'days': this.state.currentHour,
                'from': this.state.currentHourFrom,
                'to':this.state.currentHourTo
              })
              this.setState({
                currentHoursArray: tempArray,
                currentHour: '',
                currentHourFrom: '',
                currentHourTo: ''
              })
            }}>
              Guardar horario
            </div>

            {
                this.state.currentHoursArray.map((hour: any, index: number) => 
                <div className='numbers-to-show-contact'>
                  {hour.days + ' de ' + hour.from + ' a ' + hour.to}
                  <div className='close-numbers-array' onClick={() => {
                      var tempArray = this.state.currentHoursArray
                      tempArray.splice(index, 1)
                      this.setState({
                        currentHoursArray: tempArray
                      })
                    }}>
                      <img src={Delete} alt=''/>
                    </div>
                </div>
                )
              }

            <div className='create-new-contact-item'>
              <div className='create-new-item-title'>URL Waze</div>
              <input type='text' value={this.state.currentWazeLink} onChange={(e) => {this.setState({currentWazeLink: e.target.value})}}/>
            </div>

            <div className='create-new-contact-item'>
              <div className='create-new-item-title'>URL Google Maps</div>
              <input type='text' value={this.state.currentGoogleMapsLinks} onChange={(e) => {this.setState({currentGoogleMapsLinks: e.target.value})}}/>
            </div>
            

            <div className='create-new-contact-item'>
              <div className='create-new-item-title'>Correos electrónicos</div>
              <input type='text' value={this.state.currentEmail} onChange={(e) => {this.setState({currentEmail: e.target.value})}}/>
            </div>



            <div className='create-new-phone-btn' onClick={() => {
              var tempArray = this.state.currentEmailsArray
              tempArray.push({'email': this.state.currentEmail})
              this.setState({
                currentEmailsArray: tempArray
              }, () => {
                this.setState({
                  currentEmail: ''
                })
              })
            }} style={{marginBottom: '0.5rem'}}>
              Guardar correo
            </div>

            {
                this.state.currentEmailsArray.length > 0 ?
                this.state.currentEmailsArray.map((email: any, index: number) => 
                  <div className='numbers-to-show-contact'>
                    {email.email}
                    <div className='close-numbers-array' onClick={() => {
                      var tempArray = this.state.currentEmailsArray
                      tempArray.splice(index, 1)
                      this.setState({
                        currentEmailsArray: tempArray
                      })
                    }}>
                      <img src={Delete} alt=''/>
                    </div>
                  </div>
                ):null
              }


            <div className='create-new-contact-item'>
              <div className='create-new-item-title'>Número de contacto</div>
              
              <div className='create-new-items-phone-container'>
                <PhoneInput
                  placeholder=""
                  value={this.state.currentNumber}
                  onChange={(e) => {this.setState({currentNumber: e})}}
                  flags={flags}
                  country="CO"
                />

                <div className='whatsapp-container-contact'>
                  <input type='checkbox' onChange={(e) => {this.setState({hasWhatsapp: e.currentTarget.checked})}}/>
                  <div>Chat Whatsapp</div>
                </div>
              </div>
            </div>

            <div className='create-new-phone-btn' onClick={() => {
              var tempArray = this.state.numberArray
              tempArray.push( {
                'number': this.state.currentNumber,
                'whatsapp': this.state.hasWhatsapp
              })
              this.setState({
                numberArray: tempArray
              }, () => {
                this.setState({
                  currentNumber: '',
                  hasWhatsapp: ''
                })
              })
            }}>
              Guardar número
            </div>

            {
                this.state.numberArray.length > 0 ?
                this.state.numberArray.map((numbers: any, index: number) => 
                  <div className='numbers-to-show-contact'>
                    {numbers.number}{numbers.whatsapp === true ? ' - Habilitado para Whatsapp':''}
                    <div className='close-numbers-array' onClick={() => {
                      var tempArray = this.state.numberArray
                      tempArray.splice(index, 1)
                      this.setState({
                        numberArray: tempArray
                      })
                    }}>
                      <img src={Delete} alt=''/>
                    </div>
                  </div>
                ):null
              }

          </div>

          <div className='create-cancel-contact-btn-contaienr'>
            <div className='cancel-contact-btn' onClick={() => {this.handleResetStates()}}>
              Cancelar
            </div>
            <div className='create-contact-btn' onClick={() => {
              this.handleCompileInfo()
              
              }}>
              Guardar
            </div>
          </div>
        </div>
      )
  }
}

export default CreateNew