import {
  OPEN_GALLERIES,
  LIST_GALLERIES,
  LIST_GALLERIES_SUCCESS,
  LIST_GALLERIES_ERROR,
  CREATE_GALLERIES,
  UPDATE_GALLERIES,
  DELETE_GALLERIES,
  CREATE_GALLERIES_FOLDER,
  UPLOAD_GALLERIES_FILES,
  MODAL_GALLERIES_ARRAY
} from "../actions";

/*eslint-disable*/

const INIT_STATE = {
  isGalleriesModalOpened: false,
  galleries: [],
  modalGalleries: [],
  loading: false,
  message: "",
};

export default (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case OPEN_GALLERIES:
      return {...state, isGalleriesModalOpened: action.payload}
    case LIST_GALLERIES:
      return { ...state, loading: true, message: "" };
    case LIST_GALLERIES_SUCCESS:
      return {
        ...state,
        galleries: action.payload,
        loading: false,
        message: "Exito",
      };
    case LIST_GALLERIES_ERROR:
      return {
        ...state,
        galleries: [],
        loading: false,
        message: action.payload.message,
      };
    case CREATE_GALLERIES:
      return { ...state, loading: true, message: "" };
    case DELETE_GALLERIES:
      return { ...state, loading: true, message: "" };
    case UPDATE_GALLERIES:
      return { ...state, loading: true, message: "" };
    case CREATE_GALLERIES_FOLDER:
      return { ...state, loading: true, message: "" };
    case UPLOAD_GALLERIES_FILES:
      return { ...state, loading: true, message: "" };
    case MODAL_GALLERIES_ARRAY:
      return {...state, modalGalleries: action.payload}
    default:
      return { ...state };
  }
};
