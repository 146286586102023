import React, {useState, useEffect} from 'react'

interface LocationIconProps {
    currentLogo: string,
    handleAddLogo: any,
    logo: string,
    handleUpdateZoom: any,
    handleUploadIllustratedMap: any
} 

/*eslint-disable*/

const GeneralIconComponent = ({currentLogo, handleAddLogo, logo, handleUpdateZoom, handleUploadIllustratedMap}: LocationIconProps) => {

    
    const [zoomActive, setZoomActive] = useState(true)

    useEffect(() => {
        handleUpdateZoom(zoomActive)
    }, [zoomActive, handleUpdateZoom])

    return(
        <div className='general-roads-icon-container'>
            <div className='general-roads-icon-general-container'>
                <div className='general-roads-icon-selector'>
                    <div className='icon-roads'>
                        <img src={ logo} alt=""/>
                    </div>
                    <div className='icon-text-info-roads'>
                        <div className='info-roads-title'>
                            Ícono del menú Ubicación
                        </div>
                        <div className='info-roads-text'>
                            El material debe estar en formato .SVG
                        </div>
                        <input
                            type="file"
                            name="imagen"
                            id="upload-photo-nuevo-icons"
                            className="upload-photo-nuevo-icons inputfile-icons"
                            //onChange={(e: any) => {handleUploadIllustratedMap(e)}}
                            accept="image/svg+xml"
                        />
                        <div className='input-icons-btn'>
                            Modificar
                        </div>
                    </div>
                </div>
                <div className='general-roads-icon-selector'>
                    <div className='icon-roads'>
                        <img src={''} alt=""/>
                    </div>
                    <div className='icon-text-info-roads'>
                        <div className='info-roads-title'>
                            Subir Mapa ilustrado
                        </div>
                        <div className='info-roads-text'>
                            El material debe estar en formato .WEBP <br/> y tener una resolución de 3000 x 3000 px
                        </div>
                        <div className='info-roads-text'>
                            Debe pesar máximo 3MB
                        </div>
                        <input
                            type="file"
                            name="imagen"
                            id="upload-photo-nuevo-icons"
                            className="upload-photo-nuevo-icons inputfile-icons"
                            onChange={(e) => {handleUploadIllustratedMap(e)}}
                            accept={"image/webp"}
                        />
                        <div className='input-icons-btn'>
                            Subir archivo
                        </div>
                    </div>
                </div>
            </div>
            {/*
                <div className='general-roads-icon-selector-oval'>
                <div className='icon-roads-oval'>
                    <img src={currentLogo !== "" ? currentLogo : U3DS} alt=""/>
                </div>
                <div className='icon-text-info-roads-oval'>
                    <div className='info-roads-title-oval'>
                        Ícono del proyecto en el mapa dinámico
                    </div>
                    <div className='info-roads-text-oval'>
                        El material debe estar en formato .SVG
                    </div>
                    <input
                        type="file"
                        name="imagen"
                        id="upload-photo-nuevo-icons"
                        className="upload-photo-nuevo-icons inputfile-icons"
                        onChange={(e: any) => {handleAddLogo(e)}}
                        accept="image/svg+xml"
                    />
                    <div className='input-icons-btn'>
                        Modificar
                    </div>
                </div>
                <div className='information-logo-icon-oval'>
                    El icono no debe tener fondo
                </div>
            </div>
            */}
            {/* <div className='header-disabled-zoom'>
                <input type='checkbox' checked={zoomActive} onChange={() => {
                    setZoomActive(!zoomActive)
                }}></input>
                <div className='text-header-zoom'>Activar zoom</div>
            </div> */}
        </div>
    )
}

export default GeneralIconComponent