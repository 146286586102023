import {
  OPEN_GALLERIES,
  LIST_GALLERIES,
  LIST_GALLERIES_SUCCESS,
  LIST_GALLERIES_ERROR,
  CREATE_GALLERIES,
  UPDATE_GALLERIES,
  DELETE_GALLERIES,
  CREATE_GALLERIES_FOLDER,
  UPLOAD_GALLERIES_FILES,
  MODAL_GALLERIES_ARRAY
  } from '../actions';

  /*eslint-disable*/

  export const galleriesOpen = (openGalleryModal: boolean) => ({
    type: OPEN_GALLERIES,
    payload: openGalleryModal
  })
  export const galleriesList = (proyecto: object, history: any) => ({
    type: LIST_GALLERIES,
    payload: { proyecto, history }
  });
  export const galleriesListSuccess = (galleries: any) => ({
    type: LIST_GALLERIES_SUCCESS,
    payload: galleries
  });
  export const galleriesListError = (message: any) => ({
    type: LIST_GALLERIES_ERROR,
    payload: { message }
  });
  export const galleriesCreate = (gallerie: any) => ({
    type: CREATE_GALLERIES,
    payload: { gallerie }
  });
  export const galleriesUpdate = (gallerie: any) => ({
    type: UPDATE_GALLERIES,
    payload: { gallerie }
  });
  export const galleriesDelete = (gallerie: any) => ({
    type: DELETE_GALLERIES,
    payload: { gallerie }
  });
  export const galleriesCreateFolder = (folder: any) => ({
    type: CREATE_GALLERIES_FOLDER,
    payload: { folder }
  });
  export const galleriesUploadFiles = (files: any) => ({
    type: UPLOAD_GALLERIES_FILES,
    payload: { files }
  });

  export const galleriesCurrentModalSelection = (array: any) => ({
      type: MODAL_GALLERIES_ARRAY,
      payload: array
  })