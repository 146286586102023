import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { list, set} from './../../models/mailing';

import {
    LIST_MAILING,
    SET_MAILING
} from '../actions';

import {
    mailingListSuccess,
    mailingListError,
} from '../mailing/actions';
import {
    projectSelectedSuccess,
    projectSelectedError,
} from '../project/actions';
import {
    builderSelectedSuccess,
} from '../builder/actions';

import { attributesListSuccess } from "../attribute/actions";


interface mailingResponse {
    mailingItf: any;
  }

export function* watchmailingList() {
    yield takeEvery(LIST_MAILING, mailingList);
}
export function* watchmailingSet() {
    yield takeEvery(SET_MAILING, mailingSet);
}


const mailingListAsync = async (mailing: any): Promise<mailingResponse> =>
    await list(mailing)
        .then(mailingItf => mailingItf)
        .catch(error => error);

function* mailingList({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const mailingListData = yield call(mailingListAsync, proyecto);
        if (mailingListData.status === 200) {
            yield put(mailingListSuccess(mailingListData.data.data));
            yield put(attributesListSuccess(mailingListData.data.attributes));
            yield put(projectSelectedSuccess(mailingListData.data.projects));
            yield put(builderSelectedSuccess(mailingListData.data.builders));
        } else {
            yield put(mailingListError(mailingListData));
            yield put(attributesListSuccess(mailingListData));
            yield put(projectSelectedError(mailingListData));
            yield put(builderSelectedSuccess(mailingListData));
        }
    } catch (error) {
        yield put(mailingListError(error));
        yield put(attributesListSuccess(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


const mailingSetAsync = async (mailing: any): Promise<mailingResponse> =>
    await set(mailing)
        .then(mailingItf => mailingItf)
        .catch(error => error);

function* mailingSet({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const mailingListData = yield call(mailingSetAsync, proyecto);
        if (mailingListData.status === 200) {
            yield put(mailingListSuccess(mailingListData.data.data));
            yield put(attributesListSuccess(mailingListData.data.attributes));
            yield put(projectSelectedSuccess(mailingListData.data.projects));
            yield put(builderSelectedSuccess(mailingListData.data.builders));
        } else {
            yield put(mailingListError(mailingListData));
            yield put(attributesListSuccess(mailingListData));
            yield put(projectSelectedError(mailingListData));
            yield put(builderSelectedSuccess(mailingListData));
        }
    } catch (error) {
        yield put(mailingListError(error));
        yield put(attributesListSuccess(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


export default function* rootSaga() {
    yield all([
        fork(watchmailingList),
        fork(watchmailingSet)
    ]);
}
