import {
  LIST_PROJECT,
  LIST_PROJECT_SUCCESS,
  LIST_PROJECT_SUCCESS2,
  LIST_PROJECT_ERROR,
  SELECTED_PROJECT_SUCCESS,
  SELECTED_PROJECT_ERROR,
  LIST_PROJECT_ONE,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  ONE_PROJECT,
  ADD_BASE_MODULES_PROJECT,
  DELETE_PROJECT,
  UPDATE_INSIDE_PROJECT,
  UPDATE_TEMPLATE_PROJECT,
  DOWNLOAD_TEMPLATE_PROJECT,
  UPDATE_TEMPLATE_PROJECT_SUCCESS
} from "../actions";

/*eslint-disable*/

const INIT_STATE = {
  project: [],
  projectSelected: {},
  builder_name: "",
  loading: false,
  message: "",
};

export default (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case LIST_PROJECT:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case LIST_PROJECT_SUCCESS2:
      return {
        ...state,
        project: action.payload.data.data,
        projectSelected: {},
        loading: false,
      };
    case SELECTED_PROJECT_SUCCESS:
      localStorage.setItem('create_offline', action.payload.offline)
      localStorage.setItem('project_offline', action.payload.id)
      return {
        ...state,
        projectSelected: action.payload,
        loading: false,
      };
    case SELECTED_PROJECT_ERROR:
      return {
        ...state,
        projectSelected: {},
        loading: false,
        message: action.payload.message,
      };
    case LIST_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.payload,
        projectSelected: {},
        loading: false,
      };
    case LIST_PROJECT_ERROR:
      return {
        ...state,
        project: [],
        projectSelected: {},
        loading: false,
        message: action.payload.message,
      };
    case LIST_PROJECT_ONE:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case ONE_PROJECT:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case ADD_BASE_MODULES_PROJECT:
      return {
        ...state,
        loading: true,
        message: "add",
      };
    case CREATE_PROJECT:
      return {
        ...state,
        loading: true,
        message: "new project",
      };
    case UPDATE_PROJECT:
      return {
        ...state,
        loading: true,
        message: "update project",
      };
    case DELETE_PROJECT:
      return {
        ...state,
        loading: true,
        message: "delete project",
      };
    case UPDATE_INSIDE_PROJECT:
      return {
        ...state,
        loading: true,
        message: "update project"
      }
    case UPDATE_TEMPLATE_PROJECT:
      return {
        ...state,
        loading: true,
        message: "update project"
      }
      case DOWNLOAD_TEMPLATE_PROJECT:
        return {
          ...state,
          loading: true,
          message: "update project"
        }
    case UPDATE_TEMPLATE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "update success"
      }
    default:
      return {
        ...state,
      };
  }
};
