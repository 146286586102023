
import {
    LIST_SOLAR,
    LIST_SOLAR_SUCCESS,
    LIST_SOLAR_ERROR,
    SET_SOLAR,
    UPDATE_SOLAR,
    DELETE_SOLAR,
    TXT_SOLAR
} from '../actions'

/*eslint-disable*/

const INIT_STATE = {
    solar: [],
    loading: false,
    message: '',
}

export default (state = INIT_STATE, action: any) => {
switch (action.type) {
    case  LIST_SOLAR:
    return { ...state, loading: true, message: '' }
    case LIST_SOLAR_SUCCESS:
    return {
        ...state,
        solar: action.payload,
        loading: false,
        message: "Exito",
    }
    case LIST_SOLAR_ERROR:
    return {
        ...state,
        solar: [],
        loading: false,
        message: action.payload.message,
    }
    case  SET_SOLAR:
    return { ...state, loading: true, message: '' }
    case  UPDATE_SOLAR:
    return { ...state, loading: true, message: '' }
    case  DELETE_SOLAR:
    return { ...state, loading: true, message: '' }
    case  TXT_SOLAR:
    return { ...state, loading: true, message: '' }
    default:
    return { ...state }
}
}