import React, {useEffect, useState} from 'react'

import spins from './../../UI/general/spins.svg'

/*eslint-disable*/

const UploadAsoleacionVideo = ({
    handleChangeNameState, 
    handleUploadVideo, 
    handlePushToArray, 
    isEdit, 
    currentVideoSelected,
    handleCurrentDescState,
    handleUpdate
}: any) => {

    const [currentVideoName, setCurrentVideoName] = useState(isEdit ? currentVideoSelected.name:"")
    const [currentDescription, setCurrentDescription] = useState(isEdit ? currentVideoSelected.description:"")

    const handleChangeName = (e: any) => {
        setCurrentVideoName(e.target.value)
    }

    useEffect(() => {
        handleChangeNameState(currentVideoName)
    }, [currentVideoName])

    useEffect(() => {
        handleCurrentDescState(currentDescription)
    }, [currentDescription])

    useEffect(() => {
        setCurrentVideoName(currentVideoSelected.name)
        setCurrentDescription(currentVideoSelected.description)
    }, [currentVideoSelected])

    return (
        <div className='asoleacion-upload-container-main'>
            <div className='asoleacion-upload-title'>{isEdit ? "Editar":"Subir una"} vista</div>
            <div className='asoleacion-upload-name-text'>
                Nombre
            </div>
            <input
                type={"text"}
                value = {currentVideoName}
                className = "input-name-asoleacion"
                onChange={(e) => {handleChangeName(e)}}
            />
            <div className='asoleacion-upload-desc-text'>
                Descripción
            </div>
            <textarea 
                className='input-desc-asoleacion'
                cols={40}
                onChange = {(e) => {setCurrentDescription(e.target.value)}}
            />
            {
                !isEdit ? 
                <div className='asoleacion-upload-icon-preview-container'>
                    <div className='asolaecion-upload-icon'>
                        <img src={spins} alt=""/>
                    </div>
                    <div className='asolecion-upload-text-container'>
                        <div className='asoelacion-upload-title'>Subir archivo de asoleación</div>
                        <div className='asoleacion-upload-text'>El archivo debe estar en formato .zip</div>
                        <input
                            type={"file"}
                            accept={"zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"}
                            className="asoleacion-upload-input"
                            onChange = {(e) => {
                                handleUploadVideo(e)
                            }}
                        />
                        <div className='asoleacion-upload-btn'>
                            Subir Archivo
                        </div>
                    </div>
                </div>:null
            }
            <div className='asoleacion-upload-save-btn' onClick={
                isEdit ? () => {handleUpdate(currentVideoSelected.id, currentVideoName, currentDescription)}:() => {handlePushToArray()}
            }>
                Guardar
            </div>
        </div>
    )
}

export default UploadAsoleacionVideo