import {useState} from 'react'
import ReCAPTCHA  from 'react-google-recaptcha'
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
} from '@mui/material'
import * as Yup from "yup"
import { Formik } from 'formik'

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

/*eslint-disable*/

const LoginFormAuth = ({event, errorLogin}: any) => {

    const [showPassword, setShowPassword] = useState(false)
    const [checked, setChecked] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [reCaptchaValue, setReCaptchaValue] = useState(null);


    const validateReCAPTCHA = (value: any) => {
        setReCaptchaValue(value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    
    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    return(
        <Formik
            initialValues={{
                email: email,
                password: password,
                submit: null
            }}

            validationSchema={
                Yup.object().shape({
                    email: Yup.string()
                    .email("Se require un email valido")
                    .max(255)
                    .required('Email is required')
                })
            }

            onSubmit={async(
                values, {errors, setErrors, setStatus, setSubmitting}: any
            ) => {
                try {
                    event(values)
                }
                catch(err: any){
                    console.log(err)
                }
            }}
        >
            {
                ({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form noValidate onSubmit={handleSubmit} className="form-login">
                        <FormControl
                            fullWidth
                            error={Boolean(touched.email && errors.email)}
                            sx={{ 
                                mb: 3,
                                fontFamily: "Montserrat Bold"
                            }}
                        >
                            <InputLabel htmlFor="outlined-adornment-email-login" sx={{fontFamily: "Montserrat SemiBold"}}>
                                Email Address / Username
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="email"
                                value={values.email}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="Email Address / Username"
                                sx={{fontFamily: "Montserrat Regular"}}
                                inputProps={{}}
                            />
                            {touched.email && errors.email && (
                                <FormHelperText
                                    error
                                    id="standard-weight-helper-text-email-login"
                                >
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{}}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-login" sx={{fontFamily: "Montserrat SemiBold"}}>
                                Password
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-login"
                                type={showPassword ? "text" : "password"}
                                value={values.password}
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className="outlined-input"
                                sx={{fontFamily: "Montserrat Regular"}}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                inputProps={{}}
                            />
                            {touched.password && errors.password && (
                            <FormHelperText
                                error
                                id="standard-weight-helper-text-password-login"
                            >
                                {errors.password}
                            </FormHelperText>
                            )}
                        </FormControl>

                        {
                            errorLogin !== '' ?
                            <div className='error-code-login'>{errorLogin}</div>:null
                        }

                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={1}
                            sx ={{
                                marginTop: "2%"
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={checked}
                                    onChange={(event) => setChecked(event.target.checked)}
                                    name="checked"
                                    color="primary"
                                    sx={{
                                        fontFamily:"Montserrar Regular",
                                        color: "rgb(237, 8, 116)",
                                        '&.Mui-checked': {
                                            color: "rgb(237, 8, 116)"
                                        }
                                    }}
                                    />
                                }
                                label="Remember me"
                            />
                            <Typography
                                variant="subtitle1"
                                sx={{ textDecoration: "none", cursor: "pointer", color: "rgb(237, 8, 116)", fontFamily:"Montserrat SemiBold"}}
                            >
                                Forgot Password?
                            </Typography>
                        </Stack>

                        <Box sx={{ 
                            mt: 3,
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <ReCAPTCHA
                                className="g-recaptcha"
                                sitekey="6Lety4sgAAAAAKJH7pOeKwuB8N80oAq9NpU8dNCE"
                                onChange={validateReCAPTCHA}
                            />
                        </Box>

                        

                        <Box sx={{ 
                            mt: 3, 
                            mb: 4,
                            width: '100%', 
                            position: 'relative' ,
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                sx={{
                                    bgcolor: "rgb(237, 8, 116)", 
                                    position:'absolute', 
                                    width: '70%',
                                    '&:hover': {
                                        backgroundColor: 'rgb(191, 30, 96)'
                                    }
                                }}
                            >
                                Sign in
                            </Button>
                        </Box>
                    </form>
            )}
        </Formik>
    )

}

export default LoginFormAuth