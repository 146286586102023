import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { list as userListModel, create as userCreateModel, update as userUpdateModel, remove as userDeleteModel } from '../../models/users';

import {
    LIST_USER,
    CREATE_USER,
    UPDATE_USER,
    DELETE_USER,
} from '../actions';

import {
    userListSuccess,
    userListError,
} from './actions';

/*eslint-disable*/

interface userResponse {
    user: any;
}

export function* watchUserList() {
    yield takeEvery(LIST_USER, userList);
}

export function* watchUserCreate() {
    yield takeEvery(CREATE_USER, userCreate);
}

export function* watchUserUpdate() {
    yield takeEvery(UPDATE_USER, userUpdate);
}

export function* watchUserDelete() {
    yield takeEvery(DELETE_USER, userDelete);
}

const userListAsync = async (user: any): Promise<userResponse> =>
    await userListModel(user)
        .then((user: any) => user)
        .catch((error: any) => error);

function* userList({ payload }: any): any {
    const { user } = payload;
    try {
        const userListData = yield call(userListAsync, user);
        if (userListData.status === 200) {
            yield put(userListSuccess(userListData));
        } else {
            yield put(userListError(userListData));
        }
    } catch (error) {
        yield put(userListError(error));
    }
}

const userCreateAsync = async (user: any): Promise<userResponse> =>
    await userCreateModel(user)
        .then((user: any) => user)
        .catch((error: any) => error);

function* userCreate({ payload }: any): any {
    const { history, user } = payload;
    try {
        const userCreateData = yield call(userCreateAsync, user);
        if (userCreateData.status === 200) {
            yield put(userListSuccess(userCreateData));
        } else {
            yield put(userListError(userCreateData));
        }
    } catch (error) {
        yield put(userListError(error));
    }
}

const userUpdateAsync = async (user: any, id: any): Promise<userResponse> =>
    await userUpdateModel(user, id)
        .then((user: any) => user)
        .catch((error: any) => error);

function* userUpdate({ payload }: any): any {
    const { history, user, id } = payload;
    try {
        const userUpdateData = yield call(userUpdateAsync, user, id);
        if (userUpdateData.status === 200) {
            yield put(userListSuccess(userUpdateData));
        } else {
            yield put(userListError(userUpdateData));
        }
    } catch (error) {
        yield put(userListError(error));
    }
}

const userDeleteAsync = async (id: any) =>
    await userDeleteModel(id)
        .then(user => user)
        .catch(error => error);

function* userDelete({ payload }: any): any {
    const { history, id } = payload;
    try {
        const userDeleteData = yield call(userDeleteAsync, id);
        if (userDeleteData.status === 200) {
            yield put(userListSuccess(userDeleteData));
        } else {
            yield put(userListError(userDeleteData));
        }
    } catch (error) {
        yield put(userListError(error));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchUserList),
        fork(watchUserCreate),
        fork(watchUserUpdate),
        fork(watchUserDelete),
    ]);
}
