import {
  LIST_AMENITIES,
  LIST_AMENITIES_SUCCESS,
  LIST_AMENITIES_ERROR,
  CREATE_AMENITIES,
  UPDATE_AMENITIES,
  DELETE_AMENITIES,
  SET_GALLERIES_AMENITIES,
} from "../actions";

/*eslint-disable*/

const INIT_STATE = {
  amenities: [],
  loading: false,
  logo_module: "",
  message: "",
  amenities_suggested: [],
  spins: []
};

export default (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case LIST_AMENITIES:
      return { ...state, loading: true, message: "" };
    case LIST_AMENITIES_SUCCESS:


      return {
        ...state,
        amenities: action.payload.amenities,
        amenities_suggested: action.payload.amenities_suggested,
        logo_module: action.payload.logo_module,
        spins: action.payload.spins,
        loading: false,
      };
    case LIST_AMENITIES_ERROR:
      return {
        ...state,
        amenities: [],
        amenities_suggested: [],
        logo_module: "",
        loading: false,
        message: action.payload.message,
      };
    case CREATE_AMENITIES:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case UPDATE_AMENITIES:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case DELETE_AMENITIES:
      return {
        ...state,
        loading: true,
        message: "",
      };
    case SET_GALLERIES_AMENITIES:
      return {
        ...state,
        loading: true,
        message: "",
      };
    default:
      return { ...state };
  }
};
