import axios from 'axios';
import { ApiConfig } from '../constants/defaultValues';
import { borrarCacheImagenes } from '../helpers/clearCache';

export async function list(mailing: any) {
    return axios.post(ApiConfig.URL2 + "mailing/get", mailing);
}
export async function set(e: any) {
    let result = await axios.post(ApiConfig.URL2 + "mailing/quotation", e.data);
    if (result.status === 200) {
        let resultFormData = await axios.post(`${ApiConfig.URL3}coord/`, e.assets, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        if (resultFormData.status === 200) {
          borrarCacheImagenes();
          return result;
        }
    }
}