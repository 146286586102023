import { combineReducers } from "redux";
import generalReducer from "./general/reducers";
import authReducer from "./auth/reducer";
import userReducer from './user/reducer';
import builderReducer from './builder/reducer';
import projectReducer from './project/reducer';
import moduleReducer from './module/reducer';
import paletteChromaticReducer from './palettechromatic/reducer';
import attributeReducer from './attribute/reducer';
import propertiesReducer from './properties/reducers'
import sidebarReducer from './sidebar/reducer';
import galleriesReducer from './galleries/reducer';
import amenitiesReducer from './amenities/reducers';
import locationsReducer from './location/reducers';
import iconsReducer from './icons/reducers'
import solarReducer from './solar/reducers'
import spinReducer from './spin/reducers'
import contactReducer from './contact/reducers'

const reducers = combineReducers({
    generalReducer,
    authReducer,
    userReducer,
    builderReducer,
    projectReducer,
    moduleReducer,
    paletteChromaticReducer,
    attributeReducer,
    sidebarReducer,
    galleriesReducer,
    amenitiesReducer,
    locationsReducer,
    iconsReducer,
    solarReducer,
    propertiesReducer,
    spinReducer,
    contactReducer
})

export default reducers