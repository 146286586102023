import React, {useState} from 'react'
import { SketchPicker } from 'react-color'

interface CreateRoadsProps{
    handleCreateRoad: any,
    handleSaveVia: any,
} 

const CreateRoadsComponent = ({handleCreateRoad, handleSaveVia}: CreateRoadsProps) => {

    const [currentColor, setCurrentColor] = useState("#000")
    const [openColorModal, setOpenColorModal] = useState(false)
    const [currentName, setCurrentRoadName] = useState("")
    
    const handleOpenColorModal = () => {
        setOpenColorModal(!openColorModal)
    }

    const handleChangeColor = (color: any) => {
        setCurrentColor(color.hex)
    }

    const handleChangeColorInput = (value: string) => {
        setCurrentColor(value)
    } 

    return(
        <div className='road-create-container'>
            <div className='roads-create-title-main'>
                Nueva vía
            </div>
            <div className='create-roads-set-container'>
                <div className='create-roads-set-name'>
                    <div className='create-roads-name-title'>
                        Nombre
                    </div>
                    <input 
                        type={"text"} 
                        maxLength={40} 
                        value={currentName}
                        onChange = {(e) => {setCurrentRoadName(e.target.value)}}
                    />
                </div>
                <div className='create-roads-set-color'>
                    <div className='create-roads-color-title'>
                        Color
                    </div>
                    <div 
                    className='selected-color-container' 
                    style={{
                        backgroundColor: currentColor
                    }}
                    onClick = {handleOpenColorModal}
                    />
                    {openColorModal ?
                        <SketchPicker 
                        color={currentColor}
                        disableAlpha={true}
                        className="colorPicker"
                        onChangeComplete={handleChangeColor}
                        />:null
                    }
                </div>
                <div className='color-selector-input'>
                    <input type="text" defaultValue={currentColor} value={currentColor} onChange={(e) => {handleChangeColorInput(e.target.value)}}/> 
                </div>
            </div>
            <div className='create-roads-btns'>
                <div className='map-draw-btn' onClick={() => {handleCreateRoad()}}>
                    Dibujar en mapa
                </div>
                <div className='road-save-btn' onClick = {() => {
                    setCurrentColor("#000")
                    setOpenColorModal(false)
                    setCurrentRoadName("")
                    handleSaveVia(currentColor, currentName)
                    }}>
                    Guardar
                </div>
            </div>
        </div>
    )
}

export default CreateRoadsComponent