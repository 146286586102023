import { useState, useEffect } from "react";

import { Chip, TextField } from "@mui/material";

import close from "./../../UI/general/close.svg";
import U3DS from "./../../UI/general/Placeholder_02.svg";

/*eslint-disable*/

const CreateProjects = ({
  event,
  handleSave,
  handleEdit,
  data,
  builder,
  open,
  showAlert,
  closeAlert
}: any) => {
  const [name, setName] = useState("");
  const [logo, setlogo]: any = useState(null);
  const [logoDefault, setlogoDefault] = useState("");
  //Saves contact info array contactInfo
  //const [contactInfo, setContactInfo]: any = useState([]);
  const [SalesName, setSalesName] = useState("");
  const [SalesLocation, setSalesLocation] = useState("");
  const [SalesWaze, setSalesWaze] = useState("");
  const [SalesContact, setSalesContact] = useState("");
  const [SalesContactPrefix, setSalesContactPrefix] = useState("");
  const [SalesConsultant, setSalesConsultant] = useState("");
  const [SalesConsultantPrefix, setSalesConsultantPrefix] = useState("");

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setName(data.name);

      /* let arrContact: any = []
      data.contact.forEach((element: any) => {
        arrContact.push(element)
      });
      setContactInfo(arrContact); */
    }
  }, [data]);

  const handleAddLogo = (e: any) => {
    setlogoDefault(URL.createObjectURL(e.target.files[0]));
    setlogo(e.target.files[0]);
  };

  const save = () => {
    let InstFormData = new FormData();

    if (name !== "" && name !== undefined) {
      //InstFormData.append('name', );
      let user: any = localStorage.getItem("user");
      InstFormData.append("myFile", logo);

      handleSave({
        data: {
          builder: builder.id,
          builder_name: builder.slug,
          name: name,
          slug: name.toLowerCase().split(" ").join("-").normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          user: JSON.parse(user).id,
          //contact: contactInfo,
        },
        assets: InstFormData,
      });

      setName("");
      setlogo(null);
      setlogoDefault("");

      event(false);
    }else{
      showAlert({
        message: "No puedes crear un projectp sin nombre.",
          type: "warning",
          show: true,
      })
      setTimeout(() => {
        closeAlert(false)
      }, 3000);
    }
  };

  const update = () => {
    let InstFormData = new FormData();
    if (name !== "") {
      if (name !== undefined) {
        let user: any = localStorage.getItem("user");
        InstFormData.append("myFile", logo);
        InstFormData.append("name", data.logo);
        

        handleEdit({
          data: {
            data: {
              name: name,
              slug: name.toLowerCase().split(" ").join("-").normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            },
            lastProjectName: data.name.toLowerCase().split(" ").join("-").normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            builder: builder.id,
            builder_name: builder.slug,
            id: data.id,
            user: JSON.parse(user).id,
            //contact: contactInfo,
          },
          assets: InstFormData,
        });
      } else {
        let user: any = localStorage.getItem("user");
        InstFormData.append("myFile", logo);
        InstFormData.append("name", data.logo);

        handleEdit({
          data: {
            data: {
              name: data.name,
              slug: data.name.toLowerCase().split(" ").join("-").normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            },
            lastProjectName: data.name.toLowerCase().split(" ").join("-").normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            builder: builder.id,
            builder_name: builder.slug,
            id: data.id,
            user: JSON.parse(user).id,
            //contact: contactInfo,
          },
          assets: InstFormData,
        });
      }

      setName("");
      setlogo(null);
      setlogoDefault("");

      event(false);
    }else{
      showAlert({
        message: "No puedes actualizar un projectp sin nombre.",
          type: "warning",
          show: true,
      })
      setTimeout(() => {
        closeAlert(false)
      }, 3000);
    }
  };

  const handleAddContactInfo = () => {
    const currentContactInfo = {
      name: SalesName,
      location: SalesLocation,
      waze: SalesWaze,
      salesContact: SalesContactPrefix + " " + SalesContact,
      salesConsultant: SalesConsultantPrefix + " " + SalesConsultant,
    };
    //setContactInfo([...contactInfo, currentContactInfo]);
    ClearAndClose();
  };

  const ClearAndClose = () => {
    setSalesName("");
    setSalesLocation("");
    setSalesWaze("");
    setSalesContact("");
    setSalesContactPrefix("");
    setSalesConsultant("");
    setSalesConsultantPrefix("");
  };

  

  return (
    <div
      className={
        open
          ? "create-builder-container fade-in"
          : "create-builder-container fade-out"
      }
    >
      <div
        className="create-builder-header"
        onClick={() => {
          event(false);
        }}
      >
        <img src={close} alt="" className="create-header-close" />
      </div>
      <div className="create-title">
        {Object.keys(data).length === 0 ? "Nuevo proyecto" : "Editar proyecto"}
      </div>
      <div className="create-image-container">
        <img
          src={
            data.logo !== undefined && logoDefault === ""
              ? `https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${data.logo}`
              : data.logo === undefined && logoDefault === ""
              ? U3DS
              : logoDefault
          }
          alt=""
          width={"100%"}
        />
      </div>
      <div className="caption-logo-builder">
        El archivo debe estar en formato .SVG
      </div>
      <input
        type="file"
        name="imagen"
        id="upload-photo-nuevo"
        className="upload-photo-nuevo inputfile"
        onChange={(e: any) => handleAddLogo(e)}
        accept="image/svg+xml"
      />
      <label htmlFor="upload-photo-nuevo" className="upload-photo-nuevo-label">
        <Chip label="Upload file" />
      </label>
      <TextField
        value={name}
        id="outlined-basic"
        label="Nombre Projecto"
        variant="outlined"
        onChange={(e) => setName(e.target.value)}
        size="small"
        sx={{
          width: "80%",
          position: "absolute",
          top: "32%",
          left: "50%",
          transform: "translate(-50%,0%)",
        }}
      />
      {Object.keys(data).length === 0 ? (
        <div className="create-btn" onClick={() => save()}>
          Crear
        </div>
      ) : (
        <div className="create-btn" onClick={() => update()}>
          Actualizar
        </div>
      )}
    </div>
  );
};

export default CreateProjects;
