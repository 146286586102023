import React, {useState} from 'react'

import spins from './../../UI/general/spins.svg'

const MailingQuotation = ({handleUploadMailing}: any) => {

    const [mailingName, setMailingName] = useState("")
    const [mailingDesc, setMailingDesc] = useState("")
    const [mailingFile, setMailingFile]: any = useState("")

    return(
        <div className='mailing-quotation-main-container'>
            <div className='mailing-container-main'>
                <div className='mailing-quotation-title'>Módulo de Mailing</div>
                <div className='mailing-quotation-text'>
                    Debe cargarse un archivo JavaScript con la información del mailing que se enviará en la cotización.
                </div>
                <div className='mailing-quoatuion-name'>
                    <div className='mailing-quotatuion-name-text'>Nombre</div>
                    <input
                        type={"text"}
                        className="input-mailin-name"
                        value = {mailingName}
                        onChange = {(e) => {setMailingName(e.target.value)}}
                    />
                </div>
                <div className='mailing-quoatuion-des'>
                    <div className='mailing-quotatuion-name-desc'>descripción</div>
                    <input
                        type={"text"}
                        className="input-mailin-desc"
                        value = {mailingDesc}
                        onChange = {(e) => {setMailingDesc(e.target.value)}}
                    />
                </div>
                <div className='quotation-mailing-container'>
                    <div className='quotation-mailing-holder'>
                        <img alt="" src={spins}/>
                    </div>
                    <div className='quotation-mailing-text-container'>
                        <div className='quotation-mailing-title'>Mailing de cotización</div>
                        <div className='quotatuib-mailing-text'>El material debe estar en formato .JS</div>
                        <input 
                            type={"file"}
                            className="quotation-mailing-input"
                            onChange={(e) => {setMailingFile(e.target.files !== null ? e.target.files[0]: "")}}
                        />
                        <div className='quotation-mailing-btn'>
                            Subir Archivo
                        </div>
                    </div>
                </div>
                <div className='mailing-quotation-save-btn' onClick={() => {handleUploadMailing(mailingName, mailingDesc, mailingFile)}}>
                    Guardar
                </div>
            </div>
        </div>
    )
}

export default MailingQuotation