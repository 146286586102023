import React from 'react'

const HeaderComponent = () => {


    return(
        <div className='header-poi-container'>
            <div className='header-poi-title'>
                Tipos de puntos de interés
            </div>
            <div className='header-poi-first-text'>
                Hay diferentes tipos de puntos de interés. Activa / Desactiva los botones para seleccionar los que aparecerán en el mapa dinámico.
            </div>
            <div className='header-poi-second-text'>
                Cuando los actives el menú de edición (izquierda) se actualizará con las selecciones que realices. Ve a cada uno para añadir la información del proyecto
            </div>
        </div>
    )
}

export default HeaderComponent