import React /*,{useState}*/ from 'react'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from "@mui/material/Checkbox";

interface PricingQuotationProps{
    attributesList: any,
    inmueblesList: any,
    handleUpdatePricing: any
}

const PricingQuotation = ({attributesList, inmueblesList, handleUpdatePricing}: PricingQuotationProps) => {

   //const [currentPrice, setCurrentPrice] = useState("")

    const handleChangePrice = (id: string) => {
        var price = document.getElementById(id)! as HTMLInputElement | null
        if(price !== null){
            var pricing = price.value
            handleUpdatePricing(id, pricing)
        }
    }

    return(
        <div className='princing-quotation-main-container'>
            <div className='pricing-quotation-main-title'>Módulo de Precios</div>
            <div className='pricing-btn-filter-container'>
                {
                    attributesList.map((attribute: any, index: number) => 
                        attribute.type === "Hierarchical" ? 
                        <div style={{padding: "2%"}} key={attribute.name + index}>
                            <select className='select-filters'>
                                <option selected hidden>Filtrar {attribute.name}</option>
                                {
                                    attribute.values.map((att: any , indexAtt: number) => 
                                        <option  key={att.id + indexAtt} value={att.value}>{att.value}</option>
                                    )
                                }
                            </select>
                        </div>:null
                    )
                }
            </div>
            <div className='pricing-table-container'>
                <TableContainer
                    component={Paper} 
                    sx={{
                        height: "100%",
                        overflowX:"hidden",
                        boxShadow: "none"
                    
                    }}
                >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox/>
                                </TableCell>
                                <TableCell
                                        sx={{
                                            width: "10%"
                                        }}
                                    >Inmuebles</TableCell>
                                    {
                                        attributesList && attributesList.length > 0 && attributesList !== undefined ? attributesList?.map((attribute: any, index: number) => 
                                            attribute.type === "Hierarchical" ? <TableCell sx={{width: "10%"}}>{attribute.name}</TableCell>:null
                                        ): null
                                    }
                                <TableCell sx={{width: "10%"}}>
                                    Precio
                                </TableCell>
                                <TableCell sx={{width: "25%"}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            inmueblesList.map((inmueble: any, index: number) => 
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                        />
                                    </TableCell>
                                    <TableCell>{inmueble.property}</TableCell>
                                    {
                                        inmueble.values ?
                                        inmueble.values.map((att: any, indexAtt: number) => 
                                            att.type === "Hierarchical" ? <TableCell>{att.value}</TableCell>:null
                                        ):null
                                    } 
                                    <TableCell>
                                        <input
                                            id={inmueble.id}
                                            type={"text"}
                                            defaultValue={inmueble.price}
                                            //onChange = {(e) => {setCurrentPrice(e.target.value)}}
                                        />
                                    </TableCell>
                                    <TableCell sx={{height: "80%"}}>
                                        <div className='dispo-actaulizar-btn' 
                                        onClick={() => {handleChangePrice(inmueble.id)}}>Actualizar</div>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default PricingQuotation