import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { list, set, update, remove, txt} from './../../models/solar';

import {
    LIST_SOLAR,
    SET_SOLAR,
    UPDATE_SOLAR,
    DELETE_SOLAR,
    TXT_SOLAR
} from '../actions';

import {
    solarListSuccess,
    solarListError,
} from './actions';
import {
    projectSelectedSuccess,
    projectSelectedError,
} from '../project/actions';
import {
    builderSelectedSuccess,
} from '../builder/actions';



interface solarResponse {
    solarItf: any;
  }

export function* watchsolarList() {
    yield takeEvery(LIST_SOLAR, solarList);
}
export function* watchsolarSet() {
    yield takeEvery(SET_SOLAR, solarSet);
}
export function* watchsolarUpdate() {
    yield takeEvery(UPDATE_SOLAR, solarUpdate);
}

export function* watchsolarDelete() {
    yield takeEvery(DELETE_SOLAR, solarDelete);
}

export function* watchsolarTxt() {
    yield takeEvery(TXT_SOLAR, solarTxt);
}



const solarListAsync = async (solar: any): Promise<solarResponse> =>
    await list(solar)
        .then(solarItf => solarItf)
        .catch(error => error);

function* solarList({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const solarListData = yield call(solarListAsync, proyecto);
        if (solarListData.status === 200) {
            yield put(solarListSuccess(solarListData.data.data));
            yield put(projectSelectedSuccess(solarListData.data.project));
            yield put(builderSelectedSuccess(solarListData.data.builder));
        } else {
            yield put(solarListError(solarListData));
            yield put(projectSelectedError(solarListData));
            yield put(builderSelectedSuccess(solarListData));
        }
    } catch (error) {
        yield put(solarListError(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


const solarSetAsync = async (solar: any): Promise<solarResponse> =>
    await set(solar)
        .then(solarItf => solarItf)
        .catch(error => error);

function* solarSet({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const solarListData = yield call(solarSetAsync, proyecto);
        if (solarListData.status === 200) {
            yield put(solarListSuccess(solarListData.data.data));
            yield put(projectSelectedSuccess(solarListData.data.project));
            yield put(builderSelectedSuccess(solarListData.data.builder));
        } else {
            yield put(solarListError(solarListData));
            yield put(projectSelectedError(solarListData));
            yield put(builderSelectedSuccess(solarListData));
        }
    } catch (error) {
        yield put(solarListError(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


const solarUpdateAsync = async (solar: any): Promise<solarResponse> =>
    await update(solar)
        .then(solarItf => solarItf)
        .catch(error => error);

function* solarUpdate({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const solarListData = yield call(solarUpdateAsync, proyecto);
        if (solarListData.status === 200) {
            yield put(solarListSuccess(solarListData.data.data));
            yield put(projectSelectedSuccess(solarListData.data.project));
            yield put(builderSelectedSuccess(solarListData.data.builder));
        } else {
            yield put(solarListError(solarListData));
            yield put(projectSelectedError(solarListData));
            yield put(builderSelectedSuccess(solarListData));
        }
    } catch (error) {
        yield put(solarListError(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


const solarDeleteAsync = async (solar: any): Promise<solarResponse> =>
    await remove(solar)
        .then(solarItf => solarItf)
        .catch(error => error);

function* solarDelete({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const solarListData = yield call(solarDeleteAsync, proyecto);
        if (solarListData.status === 200) {
            yield put(solarListSuccess(solarListData.data.data));
            yield put(projectSelectedSuccess(solarListData.data.project));
            yield put(builderSelectedSuccess(solarListData.data.builder));
        } else {
            yield put(solarListError(solarListData));
            yield put(projectSelectedError(solarListData));
            yield put(builderSelectedSuccess(solarListData));
        }
    } catch (error) {
        yield put(solarListError(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


const solarTxtAsync = async (solar: any): Promise<solarResponse> =>
    await txt(solar)
        .then(solarItf => solarItf)
        .catch(error => error);

function* solarTxt({ payload }: any): any {
    const { proyecto } = payload;

    try {
        const solarListData = yield call(solarTxtAsync, proyecto);
        if (solarListData.status === 200) {
            yield put(solarListSuccess(solarListData.data.data));
            yield put(projectSelectedSuccess(solarListData.data.project));
            yield put(builderSelectedSuccess(solarListData.data.builder));
        } else {
            yield put(solarListError(solarListData));
            yield put(projectSelectedError(solarListData));
            yield put(builderSelectedSuccess(solarListData));
        }
    } catch (error) {
        yield put(solarListError(error));
        yield put(projectSelectedError(error));
        yield put(projectSelectedError(error));

    }
}


export default function* rootSaga() {
    yield all([
        fork(watchsolarList),
        fork(watchsolarSet),
        fork(watchsolarUpdate),
        fork(watchsolarDelete),
        fork(watchsolarTxt),
    ]);
}
