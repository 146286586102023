import React from "react";
import JSZip from "jszip";

import collapse from "./../../UI/general/collapse.svg";
import spins from "./../../UI/general/spins.svg";

import cheemsito from "./../../UI/general/spins/Arquicheems.jpg";

interface Props {
  spins: any;
  amenities: any;
  details: any;
  handleUploadCompleteSpin: any;
  handleCreateAmenitiesGroup: any;
  user: any;
  project: any;
  builder: any;
}

interface State {
  openSpinGeneral: boolean;
  openUrbanismo: boolean;
  openAmenidedes: boolean;
  openInmuebles: boolean;
  openDetalle: boolean;
  name_type: string;
  amenitiesSubcategoryIndex: number;
  detallesSubcategoryIndex: number;
  detallesTypesLength: number;
}

class UploadNew extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openSpinGeneral: false,
      openUrbanismo: false,
      openAmenidedes: false,
      openInmuebles: false,
      openDetalle: false,
      name_type: "",
      amenitiesSubcategoryIndex: -1,
      detallesSubcategoryIndex: -1,
      detallesTypesLength: 0,
    };
  }

  handleCreateNewFile = async (file: any, group: string) => {
    var originalFile = file.target.files[0];

    const zip = new JSZip();

    var imagesCount: any = [];
    var counter = 0;
    await zip.loadAsync(originalFile).then((entry: any) => {
      Object.keys(entry.files).map((name: any) => {
        const innerZip = entry.file(name);
        innerZip.async("uint8array").then((innerZipData: any) => {
          zip
            .loadAsync(innerZipData)
            .then(async (zip: any) => {
              zip.forEach((relativePath: any, element: any) => {
                if (element.name.includes(".webp")) {
                  imagesCount.push(element);
                }
              });
            })
            .then(() => {
              counter = counter + 1;
              let FileFormData = new FormData();
              FileFormData.append("zip", originalFile);
              FileFormData.append("name", this.state.name_type);
              FileFormData.append(
                "route",
                `galleries/${this.props.builder}/${this.props.project}/spin/${group}/${this.state.name_type}/`
              );

              var object: any = {};
              object["project"] = this.props.project;
              object["grupo"] = group;
              object["name"] = this.state.name_type;
              object["count"] = imagesCount.length;
              object["name_file"] = originalFile.name;
              object["user"] = this.props.user;
              object["type"] = ["landscape", "portrait"];
              object["file"] = FileFormData;
              object[
                "route"
              ] = `galleries/${this.props.builder}/${this.props.project}/spin/${group}/${this.state.name_type}/`;
              if (counter === 2) {
                this.props.handleUploadCompleteSpin(object);
              }
            });
        });

        return 0;
      });
    });
  };

  handleGetAmenitiesGroup = () => {
    var selector = document.getElementById("amenities-selector") as any;
    this.props.handleCreateAmenitiesGroup(selector?.value);
  };

  handleFormatDate = (payload: string) => {
    const date = new Date(payload);
    const formattedDate = date.toLocaleDateString("es-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "UTC",
    });

    return formattedDate;
  };

  render(): React.ReactNode {
    return (
      <div className="upload-spin-main-component">
        <div className="upload-spin-title">Biblioteca de Spin</div>
        <div className="upload-spins-category-container">
          <div className="upload-spin-individual-category">
            <div
              className="upload-spin-name-icon-container"
              onClick={() => {
                this.setState({
                  openSpinGeneral: !this.state.openSpinGeneral,
                  name_type: "Spin general",
                });
              }}
            >
              <div className="upload-spin-individual-name">Spin general</div>
              <img src={collapse} alt="" className="closed-spin-icon" />
            </div>
            {this.state.openSpinGeneral ? (
              <div className="upload-spin-control-container">
                <div className="upload-spin-control-left">
                  <div className="upload-spin-icon-control">
                    <img src={spins} alt="" />
                  </div>
                  <div className="upload-spin-control-info">
                    <div className="upload-spin-control-info-title">
                      Subir paquete de spins
                    </div>
                    <div className="upload-spin-control-info-text">
                      El archivo debe estar en formato .ZIP y contener el Spin
                      Landscape y Portrait.
                    </div>
                    <div className="upload-spin-control-input-container">
                      <div className="upload-spin-control-info-btn">
                        Subir archivo
                      </div>
                      <input
                        type="file"
                        accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                        onChange={(e) => {
                          this.handleCreateNewFile(e, "General");
                        }}
                      />
                    </div>
                  </div>
                </div>

                <hr />

                <div className="upload-spin-control-right">
                  <div className="upload-spin-info-user-title">
                    Información del .zip
                  </div>
                  {this.props.spins &&
                  this.props.spins.General &&
                  this.props.spins.General["subGroups"][0] ? (
                    <React.Fragment>
                      <div className="upload-spin-info-user-name">
                        Nombre del archivo:{" "}
                        {this.props.spins.General["subGroups"][0].name_file}
                      </div>
                      <div className="upload-spin-info-user-date">
                        Fecha de subida:{" "}
                        {this.handleFormatDate(
                          this.props.spins.General["subGroups"][0].date
                        )}
                      </div>
                      <div className="upload-spin-info-user-upload">
                        Subido por:{" "}
                        {this.props.spins.General["subGroups"][0].user}
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="upload-spin-info-user-name">
                      Todavía no has subido ningún spin
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>

          <div className="upload-spin-individual-category">
            <div
              className="upload-spin-name-icon-container"
              onClick={() => {
                this.setState({
                  openUrbanismo: !this.state.openUrbanismo,
                  name_type: "Spin urbanismo",
                });
              }}
            >
              <div className="upload-spin-individual-name">Spin urbanismo</div>
              <img src={collapse} alt="" className="closed-spin-icon" />
            </div>
            {this.state.openUrbanismo ? (
              <div className="upload-spin-control-container">
                <div className="upload-spin-control-left">
                  <div className="upload-spin-icon-control">
                    <img src={spins} alt="" />
                  </div>
                  <div className="upload-spin-control-info">
                    <div className="upload-spin-control-info-title">
                      Subir paquete de spins
                    </div>
                    <div className="upload-spin-control-info-text">
                      El archivo debe estar en formato .ZIP y contener el Spin
                      Landscape y Portrait.
                    </div>
                    <div className="upload-spin-control-input-container">
                      <div className="upload-spin-control-info-btn">
                        Subir archivo
                      </div>
                      <input
                        type="file"
                        accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                        onChange={(e) => {
                          this.handleCreateNewFile(e, "Urbanismo");
                        }}
                      />
                    </div>
                  </div>
                </div>

                <hr />

                <div className="upload-spin-control-right">
                  <div className="upload-spin-info-user-title">
                    Información del .zip
                  </div>
                  {this.props.spins &&
                  this.props.spins.Urbanismo &&
                  this.props.spins.Urbanismo["subGroups"] ? (
                    <React.Fragment>
                      <div className="upload-spin-info-user-name">
                        Nombre del archivo:{" "}
                        {this.props.spins.Urbanismo["subGroups"][0].name_file}
                      </div>
                      <div className="upload-spin-info-user-date">
                        Fecha de subida:{" "}
                        {this.handleFormatDate(
                          this.props.spins.Urbanismo["subGroups"][0].date
                        )}
                      </div>
                      <div className="upload-spin-info-user-upload">
                        Subido por:{" "}
                        {this.props.spins.Urbanismo["subGroups"][0].user}
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="upload-spin-info-user-name">
                      Todavía no has subido ningún spin
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>

          <div className="upload-spin-individual-category">
            <div
              className="upload-spin-name-icon-container"
              onClick={() => {
                this.setState({ openAmenidedes: !this.state.openAmenidedes });
              }}
            >
              <div className="upload-spin-individual-name">
                Spin de amenidades
              </div>
              <img src={collapse} alt="" className="closed-spin-icon" />
            </div>
            {this.state.openAmenidedes ? (
              <div className="upload-spin-control-container-alt">
                <div className="select-amenity-spin-container">
                  <div className="select-amenity-spin-title">
                    Asociar una amenidad
                  </div>
                  <select
                    name="select-amenties-selector"
                    className="select-amenties-selector"
                    id="amenities-selector"
                  >
                    <option defaultValue={""} hidden disabled selected>
                      Seleccionar
                    </option>
                    {this.props.amenities.map((amenity: any, index: number) =>
                      amenity.spinState === 0 ? (
                        <option defaultValue={""} value={amenity.id}>
                          {amenity.name}
                        </option>
                      ) : null
                    )}
                  </select>
                  <div
                    className="select-amenity-spin-btn"
                    onClick={() => {
                      this.handleGetAmenitiesGroup();
                    }}
                  >
                    crear
                  </div>
                </div>

                <div className="upload-spin-subcategory-container">
                  {this.props.amenities.map((amenity: any, index: number) =>
                    amenity.spinState === 1 ? (
                      <div className="upload-spin-subcategory-individual">
                        <div
                          className="upload-spin-subcategory-individual-name-icon"
                          onClick={() => {
                            this.setState({
                              amenitiesSubcategoryIndex:
                                this.state.amenitiesSubcategoryIndex === index
                                  ? -1
                                  : index,
                              name_type: amenity.name,
                            });
                          }}
                        >
                          <div className="upload-spin-subcategory-indiviudal-name">
                            {amenity.name}
                          </div>
                          <img
                            src={collapse}
                            alt=""
                            className="closed-subcategory"
                          />
                        </div>

                        {this.state.amenitiesSubcategoryIndex === index ? (
                          <div className="upload-spin-control-container">
                            <div className="upload-spin-control-left">
                              <div className="upload-spin-icon-control">
                                <img src={spins} alt="" />
                              </div>
                              <div className="upload-spin-control-info">
                                <div className="upload-spin-control-info-title">
                                  Subir paquete de spins
                                </div>
                                <div className="upload-spin-control-info-text">
                                  El archivo debe estar en formato .ZIP y
                                  contener el Spin Landscape y Portrait.
                                </div>
                                <div className="upload-spin-control-input-container">
                                  <div className="upload-spin-control-info-btn">
                                    Subir archivo
                                  </div>
                                  <input
                                    type="file"
                                    accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                                    onChange={(e) => {
                                      this.handleCreateNewFile(e, "Amenidades");
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <hr />

                            <div className="upload-spin-control-right">
                              <div className="upload-spin-info-user-title">
                                Información del .zip
                              </div>
                              {this.state.amenitiesSubcategoryIndex !== -1 &&
                              this.props.spins &&
                              this.props.spins.Amenidades &&
                              this.props.spins.Amenidades["subGroups"] ? (
                                this.props.spins.Amenidades["subGroups"].map(
                                  (amenityGroup: any, indexAmenity: number) =>
                                    amenity.name === amenityGroup.name ? (
                                      <React.Fragment>
                                        <div className="upload-spin-info-user-name">
                                          Nombre del archivo:{" "}
                                          {
                                            this.props.spins.Amenidades[
                                              "subGroups"
                                            ][indexAmenity].name_file
                                          }
                                        </div>
                                        <div className="upload-spin-info-user-date">
                                          Fecha de subida:{" "}
                                          {this.handleFormatDate(
                                            this.props.spins.Amenidades[
                                              "subGroups"
                                            ][indexAmenity].date
                                          )}
                                        </div>
                                        <div className="upload-spin-info-user-upload">
                                          Subido por:{" "}
                                          {
                                            this.props.spins.Amenidades[
                                              "subGroups"
                                            ][indexAmenity].user
                                          }
                                        </div>
                                      </React.Fragment>
                                    ) : null
                                )
                              ) : (
                                <div className="upload-spin-info-user-name">
                                  Todavía no has subido ningún spin
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            ) : null}
          </div>

          <div className="upload-spin-individual-category">
            <div
              className="upload-spin-name-icon-container"
              onClick={() => {
                this.setState({ openInmuebles: !this.state.openInmuebles });
              }}
            >
              <div className="upload-spin-individual-name">
                Spin de inmuebles
              </div>
              <img src={collapse} alt="" className="closed-spin-icon" />
            </div>
            {this.state.openInmuebles ? (
              <div className="upload-spin-control-container-alt">
                <div className="select-amenity-spin-container">
                  <div className="select-amenity-spin-title">
                    Asociar un apto tipo
                  </div>
                  <select
                    name="select-amenties-selector"
                    className="select-amenties-selector"
                    id="amenities-selector"
                  >
                    <option defaultValue={""} hidden disabled selected>
                      Seleccionar
                    </option>
                    {this.props.amenities.map((amenity: any, index: number) =>
                      amenity.spinState === 0 ? (
                        <option defaultValue={""} value={amenity.id}>
                          {amenity.name}
                        </option>
                      ) : null
                    )}
                  </select>
                  <div
                    className="select-amenity-spin-btn"
                    onClick={() => {
                      this.handleGetAmenitiesGroup();
                    }}
                  >
                    crear
                  </div>
                </div>

                <div className="upload-spin-subcategory-container">
                  {this.props.amenities.map((amenity: any, index: number) =>
                    amenity.spinState === 1 ? (
                      <div className="upload-spin-subcategory-individual">
                        <div
                          className="upload-spin-subcategory-individual-name-icon"
                          onClick={() => {
                            this.setState({
                              amenitiesSubcategoryIndex:
                                this.state.amenitiesSubcategoryIndex === index
                                  ? -1
                                  : index,
                              name_type: amenity.name,
                            });
                          }}
                        >
                          <div className="upload-spin-subcategory-indiviudal-name">
                            {amenity.name}
                          </div>
                          <img
                            src={collapse}
                            alt=""
                            className="closed-subcategory"
                          />
                        </div>

                        {this.state.amenitiesSubcategoryIndex === index ? (
                          <div className="upload-spin-control-container">
                            <div className="upload-spin-control-left">
                              <div className="upload-spin-icon-control">
                                <img src={spins} alt="" />
                              </div>
                              <div className="upload-spin-control-info">
                                <div className="upload-spin-control-info-title">
                                  Subir paquete de spins
                                </div>
                                <div className="upload-spin-control-info-text">
                                  El archivo debe estar en formato .ZIP y
                                  contener el Spin Landscape y Portrait.
                                </div>
                                <div className="upload-spin-control-input-container">
                                  <div className="upload-spin-control-info-btn">
                                    Subir archivo
                                  </div>
                                  <input
                                    type="file"
                                    accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                                    onChange={(e) => {
                                      this.handleCreateNewFile(e, "Amenidades");
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <hr />

                            <div className="upload-spin-control-right">
                              <div className="upload-spin-info-user-title">
                                Información del .zip
                              </div>
                              {this.state.amenitiesSubcategoryIndex !== -1 &&
                              this.props.spins &&
                              this.props.spins.Amenidades &&
                              this.props.spins.Amenidades["subGroups"] ? (
                                this.props.spins.Amenidades["subGroups"].map(
                                  (amenityGroup: any, indexAmenity: number) =>
                                    amenity.name === amenityGroup.name ? (
                                      <React.Fragment>
                                        <div className="upload-spin-info-user-name">
                                          Nombre del archivo:{" "}
                                          {
                                            this.props.spins.Amenidades[
                                              "subGroups"
                                            ][indexAmenity].name_file
                                          }
                                        </div>
                                        <div className="upload-spin-info-user-date">
                                          Fecha de subida:{" "}
                                          {this.handleFormatDate(
                                            this.props.spins.Amenidades[
                                              "subGroups"
                                            ][indexAmenity].date
                                          )}
                                        </div>
                                        <div className="upload-spin-info-user-upload">
                                          Subido por:{" "}
                                          {
                                            this.props.spins.Amenidades[
                                              "subGroups"
                                            ][indexAmenity].user
                                          }
                                        </div>
                                      </React.Fragment>
                                    ) : null
                                )
                              ) : (
                                <div className="upload-spin-info-user-name">
                                  Todavía no has subido ningún spin
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            ) : null}
          </div>

          <div className="upload-spin-individual-category">
            <div
              className="upload-spin-name-icon-container"
              onClick={() => {
                this.setState({ openDetalle: !this.state.openDetalle });
              }}
            >
              <div className="upload-spin-individual-name">Spin de detalle</div>
              <img src={collapse} alt="" className="closed-spin-icon" />
            </div>

            {this.state.openDetalle ? (
              this.props.details && this.props.details.length > 0 ? (
                this.props.details.map((detail: any, index: number) => (
                  <div className="upload-spin-control-container-alt">
                    <div className="upload-spin-subcategory-container">
                      <div className="upload-spin-subcategory-individual">
                        <div
                          className="upload-spin-subcategory-individual-name-icon"
                          onClick={() => {
                            this.setState({
                              detallesSubcategoryIndex:
                                this.state.detallesSubcategoryIndex === index
                                  ? -1
                                  : index,
                              name_type: detail.name,
                            });
                          }}
                        >
                          <div className="upload-spin-subcategory-indiviudal-name">
                            {detail.name}
                          </div>
                          <img
                            src={collapse}
                            alt=""
                            className="closed-subcategory"
                          />
                        </div>

                        {this.state.detallesSubcategoryIndex === index ? (
                          <div className="upload-spin-control-container">
                            <div className="upload-spin-control-left">
                              <div className="upload-spin-icon-control">
                                <img src={spins} alt="" />
                              </div>
                              <div className="upload-spin-control-info">
                                <div className="upload-spin-control-info-title">
                                  Subir paquete de spins
                                </div>
                                <div className="upload-spin-control-info-text">
                                  El archivo debe estar en formato .ZIP y
                                  contener el Spin Landscape y Portrait.
                                </div>
                                <div className="upload-spin-control-input-container">
                                  <div className="upload-spin-control-info-btn">
                                    Subir archivo
                                  </div>
                                  <input
                                    type="file"
                                    accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                                    onChange={(e) => {
                                      this.handleCreateNewFile(e, "Detalle");
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <hr />

                            <div className="upload-spin-control-right">
                              <div className="upload-spin-info-user-title">
                                Información del .zip
                              </div>
                              {this.props.spins &&
                              this.props.spins.Detalle &&
                              this.props.spins.Detalle["subGroups"][
                                this.props.spins.Detalle["subGroups"]
                                  .map((e: any) => e.name)
                                  .indexOf(detail.name)
                              ] ? (
                                <React.Fragment>
                                  <div className="upload-spin-info-user-name">
                                    Nombre del archivo:{" "}
                                    {
                                      this.props.spins.Detalle["subGroups"][
                                        this.props.spins.Detalle["subGroups"]
                                          .map((e: any) => e.name)
                                          .indexOf(detail.name)
                                      ].name_file
                                    }
                                  </div>
                                  <div className="upload-spin-info-user-date">
                                    Fecha de subida:{" "}
                                    {this.handleFormatDate(
                                      this.props.spins.Detalle["subGroups"][
                                        this.props.spins.Detalle["subGroups"]
                                          .map((e: any) => e.name)
                                          .indexOf(detail.name)
                                      ].date
                                    )}
                                  </div>
                                  <div className="upload-spin-info-user-upload">
                                    Subido por:{" "}
                                    {
                                      this.props.spins.Detalle["subGroups"][
                                        this.props.spins.Detalle["subGroups"]
                                          .map((e: any) => e.name)
                                          .indexOf(detail.name)
                                      ].user
                                    }
                                  </div>
                                </React.Fragment>
                              ) : (
                                <div className="upload-spin-info-user-name">
                                  Todavía no has subido ningún spin
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="cheemsito-container">
                  <div>¡Alto ahí parametrizador!</div>
                  <img src={cheemsito} alt="" className="cheems-image" />
                  <div>
                    Su modelador de confianza debe crear los layer de los
                    Hotspots en el archivo de 3Ds Max.
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default UploadNew;
