import {useState} from 'react'

import {Card} from '@mui/material'

import LogoUmbra from './../../UI/logos/logoUmbra.svg'
import LogoCloudPlatform from './../../UI/logos/logoCloudPlatform.svg'
import LoginFormAuth from './forms.component'
import MFaQR from './mfa_qr.component'
import MfaToken from './mfa_token.component'

const CardLogin = ({event, eventValidate, user, errorLogin}: any) => {

    const [validation, setValidation] = useState(false)

    return(
        <Card sx={{ 
            minWidth: 575, 
            width: 693, 
            height: 650, 
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            backgroundColor:"#fff",
            borderRadius: '10px'
        }}>
            <div className='logos-container'>
                <img src={LogoUmbra} className="logo-umbra-login" alt=""/>
                <div className='login-logo-divisor'/>
                <img src={LogoCloudPlatform} className='logo-cloud-login' alt=""/>
            </div>
            {
                Object.keys(user).length > 0 &&
                user.state_mfa === 0 &&
                !validation ?
                (<MFaQR user={user} toggleValidation={setValidation}/>):

                Object.keys(user).length === 0 &&
                (user.state_mfa === 0 || user.state_mfa === undefined || !validation) ?

                ( <LoginFormAuth event={event} errorLogin={errorLogin}/>):

                Object.keys(user).length > 0 && 
                (user.state_mfa > 0 || validation) ?

                (<MfaToken user={user.id} event={eventValidate}/>): null
            }
        </Card>
    )
}

export default CardLogin