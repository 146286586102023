import React from 'react'

const RoadsIconComponent = ({logo}: any) => {
    
    return(
        <div className='roads-create-icon-container'>
            <div className='roads-create-icon-selector'>
                <div className='roads-create-icons'>
                    <img src={logo} alt=""/>
                </div>
            </div>
            <div className='roads-create-info-text'>
                <div className='roads-info-title'>
                    Ícono de vías
                </div>
                <div className='roads-info-subtitle'>
                    Se mostrará en el menú principal
                </div>
                <div className='rodas-info-text'>
                    El archivo debe estar en .SVG
                </div>
                <input
                    type="file"
                    name="imagen"
                    id="upload-photo-nuevo-icons"
                    className="upload-photo-nuevo-icons inputfile-icons"
                    //onChange={(e: any) => {handleAddLogo(e)}}
                    accept="image/svg+xml"
                />
                <div className='input-icons-btn'>
                    Modificar
                </div>
            </div>
        </div>
    )
}

export default RoadsIconComponent