import React, {useState} from 'react'
import { SketchPicker } from 'react-color'

import Delete from './../../UI/general/close.svg'
import Edit from './../../UI/general/edit.png'

const ListRoads = ({
    viasArray, 
    handleUpdateViaName,
    handleUpdateViaColor,
    handleDeleteVia
}: any) => {

    const [nameEditModalOpen, setNameEditModal] = useState(false)
    const [currentNameEdition, setNameEdition] = useState("") 
    const [currentEditableName, setCurrentEditableName] = useState(-1)
    const [openColorModal, setOpenColorModal] = useState(false)
    const [currentColor, setCurrentColor] = useState("")
    const [currentColorIndexSelected, setCurrentColorIndexselected] = useState(-1)

    const handleEditName = (index: number, name: string, id: any) => {
        if(index !== currentEditableName && currentEditableName !== -1){
            handleUpdateViaName(currentNameEdition, id)
        }
        setNameEditModal(true)
        setCurrentEditableName(index)
        setNameEdition(name)
    }

    const handleCloseEditName = (id: string) => {
        handleUpdateViaName(currentNameEdition, id)
        setNameEditModal(false)
        setCurrentEditableName(-1)
        setNameEdition("")
    }

    const handleEditColor = (color: string, index: number, id: string) => {
        if(index !== currentColorIndexSelected && currentColorIndexSelected !== -1){
            handleUpdateViaColor(currentColor, id)
        }
        setOpenColorModal(true)
        setCurrentColor(color)
        setCurrentColorIndexselected(index)
    }

    const handleCloseEditColor = (id:string)=> {
        handleUpdateViaColor(currentColor, id)
        setOpenColorModal(false)
        setCurrentColor("")
        setCurrentColorIndexselected(-1)
    }

    const handleChangeColor = (color: any) => {
        setCurrentColor(color.hex)
    }

    return(
        <div className='list-roads-container'>
            {
                viasArray.length > 0 ?
                viasArray.map((via: any, index: number) => 
                    <div className='list-roads-individual-container'>
                        <div className='list-roads-name-container'>
                            {
                                nameEditModalOpen && currentEditableName === index ?
                                <input 
                                    className='list-roads-name list-roads-name-input' 
                                    type={"text"}
                                    value={currentNameEdition}
                                    onChange = {(e) => {setNameEdition(e.target.value)}}
                                    onBlur = {() => {handleCloseEditName(via.id)}}
                                />:
                                    <div className='list-roads-name'>{via.name}</div>
                            }
                            <img src={Edit} alt="" onClick={() => {
                                nameEditModalOpen && currentEditableName === index ? handleCloseEditName(via.id):handleEditName(index, via.name, via.id)
                            }}></img>
                        </div>
                        <div className='list-roads-color' style={{background: via.color}} 
                            onClick = {() => {
                                openColorModal && currentColorIndexSelected === index ? handleCloseEditColor(via.id):handleEditColor(via.color, index, via.id)
                        }}>
                        </div>
                        {openColorModal && currentColorIndexSelected === index ?
                                <SketchPicker 
                                    color={currentColor}
                                    disableAlpha={true}
                                    className="colorPicker-roads"
                                    onChangeComplete={handleChangeColor}
                                />:null
                            }
                        <div className='delete-road' onClick={() => {handleDeleteVia(via.id)}}>
                            <img src={Delete} alt=""/>
                        </div>
                    </div>
                ):null
            }
        </div>
    )
}

export default ListRoads