import {
    LIST_BUILDER,
    LIST_BUILDER_SUCCESS,
    LIST_BUILDER_ERROR,
    SELECTED_BUILDER_SUCCESS,
    SELECTED_BUILDER_ERROR,
    CREATE_BUILDER,
    UPDATE_BUILDER,
    ONE_BUILDER,
    DELETE_BUILDER
} from '../actions';

/*eslint-disable*/

const INIT_STATE = {
    builder: [],
    selectedBuilder: {},
    loading: false,
    message: ''
};

export default (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case LIST_BUILDER:
            return {
                ...state,
                loading: true,
                message: 'list builder'
            };
        case LIST_BUILDER_SUCCESS:
            return {
                ...state,
                builder: action.payload.data.data,
                loading: false,
                message: action.payload.message
            };
        case LIST_BUILDER_ERROR:
            return {
                ...state,
                builder: [],
                loading: false,
                message: action.payload.message
            };
            case SELECTED_BUILDER_SUCCESS:
                return {
                    ...state,
                    selectedBuilder: action.payload,
                    loading: false,
                    message: action.payload.message
                };
            case SELECTED_BUILDER_ERROR:
                return {
                    ...state,
                    selectedBuilder: {},
                    loading: false,
                    message: action.payload.message
                };
        case CREATE_BUILDER:
            return {
                ...state,
                loading: true,
                message: 'new builder'
            };
        case UPDATE_BUILDER:
            return {
                ...state,
                loading: true,
                message: 'update builder'
            };
        case DELETE_BUILDER:
            return {
                ...state,
                loading: true,
                message: 'delete builder'
            };
        case ONE_BUILDER:
            return {
                ...state,
                loading: true,
                message: 'one builder'
            };
        default:
            return {
                ...state
            };
    }
}