
import React from 'react'
import CloseWhite from './../../UI/general/Close_white.svg'

const ShowSelected = ({currentSelection, handleDeleteSeletecion}: any) => {

    return(
        <div className='show-selection-main'>
            {
                currentSelection && currentSelection.length > 0 ?
                currentSelection.map((image: any, index: number) => 
                        image.type === "card" || image.amenity || image.property ?
                        <div className='selected-card-main' key={image.id + index }>
                            <div className='selected-card-delete-btn' style={{position: "absolute", right: "2%", top: "2%", backgroundColor: "#ED0874", width: "2vh", height: "2vh", cursor: "pointer", borderRadius: "5px"}} onClick = {() => {handleDeleteSeletecion(image, index)}}>
                                <img src={CloseWhite} alt="" style={{position: "absolute", width: "80%", top: "50%", left:"50%", transform:"translate(-50%,-50%)", height: "80%", maxWidth: "70%", minWidth: "70%"}}/>
                            </div>
                            <img src={`https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${image.link}`} alt=""/>
                        </div>: 
                        <div 
                            className='selected-card-main' 
                            key={image.id + index } 
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontFamily: "Montserrat Regular",
                                textAlign: "center",
                                fontSize: "1.3vh"
                            }}
                            >
                            <div className='selected-card-delete-btn' style={{position: "absolute", right: "2%", top: "2%", backgroundColor: "#ED0874", width: "2vh", height: "2vh", cursor: "pointer", borderRadius: "2px"}} onClick = {() => {handleDeleteSeletecion(image, index)}}>
                                <img src={CloseWhite} alt="" style={{position: "absolute", width: "80%", top: "50%", left:"50%", transform:"translate(-50%,-50%)", height: "80%", maxWidth: "70%", minWidth: "70%"}}/>
                            </div>
                            {image.name}
                        </div>
                    
                ):null
            }
        </div>
    )
}

export default ShowSelected