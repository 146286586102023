import React from 'react'

const BreadCrumbs = ({breadCrumbsArray, handleGoToTypes, handleGoToSubfolder}: any) => {

    return( 
        <div className='bread-crumbs-container'>
            {
                breadCrumbsArray.length > 1 ?
                breadCrumbsArray.map((element: any, index: number) => 
                    index > 0 ?
                    <div 
                        className={index === breadCrumbsArray.length - 1 ? 'individual-bread-crumb-current':'individual-bread-crumb'} 
                        style={{cursor: index === breadCrumbsArray.length - 1 ? "default":"pointer"}} 
                        key={element + index}
                        onClick = {index !== breadCrumbsArray.length - 1 ?  () => { handleGoToSubfolder(element, index)}: () => {}}
                    >
                        {' / ' + element}
                    </div>:
                    <div className='individual-bread-crumb' style={{cursor: "pointer"}} onClick={() => {handleGoToTypes()}} key={element + index}>
                        {element}
                    </div>
                ):
                breadCrumbsArray.map((element: any, index: number) => 
                    <div className='individual-bread-crumb-current' key={element + index}>
                        {element}
                    </div>
                )
            }
        </div>
    )
}

export default BreadCrumbs