import {
    Switch,
} from "@mui/material";


const GeneralModules = ({
    modules, 
    moduleLogo, 
    moduleRoads, 
    modulePOIS,
    moduleLocation,
    moduleTownplanning,
    moduleAmenities,
    moduleProperties,
    moduleGaleries,
    moduleDispo,
    moduleCoti,
    moduleAsoleacion,
    handleChangeModuls
}: any) => {

    return(
        <div className='modules-container'>
            <div className='modules-name'>Menú principal</div>
            <div className='modules-card-container'>
                {
                    modules.map((module: any, index: number) => 
                        <div className='modules-card' key={module.name + index}>
                            <div className='module-name'>{module.name}</div>
                            <Switch
                                sx={{
                                    '&$checked + $track': {
                                        backgroundColor: '#ED0874',
                                        color: "#ED0874"
                                    },
                                }}
                                aria-label="switch"
                                checked={
                                    module.name === "Logo de Proyecto" ? 
                                    moduleLogo : 
                                    module.name === "Vias de Acceso" ?
                                    moduleRoads :
                                    module.name === "Puntos de Interes" ?
                                    modulePOIS :
                                    module.name === "Ubicación" ?
                                    moduleLocation :
                                    module.name === "Urbanismo" ?
                                    moduleTownplanning :
                                    module.name === "Amenidades" ?
                                    moduleAmenities :
                                    module.name === "Inmuebles" ?
                                    moduleProperties :
                                    module.name === "Galería" ?
                                    moduleGaleries : 
                                    module.name === "Disponibilidad" ?
                                    moduleDispo :
                                    module.name === "Cotización" ?
                                    moduleCoti :
                                    module.name === "Asoleación" ?
                                    moduleAsoleacion : false
                                }
                                onChange={(e: any) => {handleChangeModuls(e, module)}}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default GeneralModules