import React from 'react'
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';

import Navbar from '../navbar';
import Sidebar from '../sidebar';
import SidebarLogo from './sidebarLogo.component';
import ListAmenidades from './list.componet';
import CreateNewAmenitie from './createNew.component';
import IconsViews from './../icons/'
import GalleryModal from '../galleryModal';
import DefaultAmenitie from './default.component';
import EditAmenitie from './edit.component'

import dashBackground from './../../UI/backgrounds/BG_PUBLIC.webp';

import {
    Backdrop,
    CircularProgress,
} from "@mui/material";

import {
    logoutUser,
    projectUpdate,
    amenidadesList,
    openIconsModal,
    galleriesOpen,
    amenidadesCreate,
    amenidadesUpdate,
    amenidadesDelete,
    amenidadesSetGallery,
    galleriesCurrentModalSelection 
} from "../../redux/actions";

/*eslint-disable*/

interface AmenitiesViews {
    iconsRef: any
}

interface Props{
    logoutUser: any,
    projectSelected: any,
    loading: any,
    amenidadesList: any,
    amenities: any,
    spins: any
    params: any,
    logo_module: any,
    openIconsModal: any,
    galleriesOpen: any,
    amenidadesCreate: any,
    amenidadesUpdate: any,
    amenidadesDelete: any,
    modalGalleries: any,
    amenidadesSetGallery: any,
    amenities_suggested: any,
    galleriesCurrentModalSelection: any
}

interface State{
    userString: any,
    user: any,
    idProject: any,
    amenitieCreationSlug: string,
    currentLogo: string,
    newAmenitieName: string,
    selectedAmenitie: any,
    openEditModal: boolean,
    currentEditAmenitie: any,
    currentEditAmeitieName: any,
    currentEditAmenitieImg: any,
    currentAmenitieDefaultIcon: any,
    currentAmenitieDefaultName: any,
    currentAmenitieGallerie: any,
    showIconsUpload: boolean
}

function withParams(Component: any) {
    return (props: any) => <AmenitiesViews {...props} params={useParams()} />;
}

class AmenitiesViews extends React.Component<Props,State>{
    constructor(props: Props){
        super(props)
        this.state = {
            userString: localStorage.getItem("user"),
            user: "",
            idProject: this.props.params.id,
            amenitieCreationSlug: "",
            currentLogo: "",
            newAmenitieName: "",
            selectedAmenitie: [],
            openEditModal: false,
            currentEditAmenitie: {},
            currentEditAmeitieName: "",
            currentEditAmenitieImg: "",
            currentAmenitieDefaultIcon: "",
            currentAmenitieDefaultName: "",
            currentAmenitieGallerie: [],
            showIconsUpload: false
        }
    }

    componentDidMount(): void {
        if(this.state.userString){
            this.setState({
                user: JSON.parse(this.state.userString)
            }, () => {
                this.props.amenidadesList({user: this.state.user.id, project: this.state.idProject})
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(this.props.amenities_suggested !== prevProps.amenities_suggested){
            console.log(this.props.amenities_suggested)
        }
    }

    handleSelectLogo = (payload:string ) => {
        this.setState({
            currentLogo: payload
        }, () => {
            if(this.state.openEditModal){
                this.setState({
                    currentEditAmenitieImg: payload
                })
            }
        })
    }

    handleChangeNewAmenitieName = (payload:string, slug: string) => {
        this.setState({
            newAmenitieName: payload,
            amenitieCreationSlug: slug
        })
    }

    handleCreateAmenitie = () => {
        // slug in amenitieCreationSlug
        let user: any = localStorage.getItem("user");
        this.props.amenidadesCreate({
            user: JSON.parse(user).id, 
            project: this.state.idProject, 
            name: this.state.currentAmenitieDefaultName !== '' ?  this.state.currentAmenitieDefaultName : this.state.newAmenitieName, 
            logo: this.state.currentAmenitieDefaultIcon !== '' ?  this.state.currentAmenitieDefaultIcon : this.state.currentLogo,

        })
        this.setState({
            currentLogo: "",
            newAmenitieName: "",
            currentAmenitieDefaultName: '',
            currentAmenitieDefaultIcon: ''
        })
    }

    handleGetAmenitiesId = (id: any, amenitieGallery: any) => {
        this.setState({
            selectedAmenitie: id,
            currentAmenitieGallerie: amenitieGallery
        })
    }

    handleSetImages = () => {
        let stringsToSend: any = []
        let user: any = localStorage.getItem("user");
        this.props.modalGalleries.map((gallery: any) => {
            if(gallery.gallery){
                stringsToSend.push(gallery.gallery)
            }
            else {
                stringsToSend.push(gallery.id)
            }
        })
        let objectToSend = {
            user: JSON.parse(user).id, 
            project: this.state.idProject, 
            amenity: this.state.selectedAmenitie,
            galleries: stringsToSend
        }
        this.props.amenidadesSetGallery(objectToSend)
    }

    handleOpenEditModal = (amenitie: any) => {
        this.setState({
            currentEditAmenitie: amenitie,
            openEditModal: true
        })
    }

    handleDeleteAmenitie = () => {
        let arr = []
        let user: any = localStorage.getItem("user");

        /*variablecondatosseleccionados.forEach(element => {
            arr.push(element) 
        });*/

        arr.push(this.state.currentEditAmenitie.id)
        this.props.amenidadesDelete({
            user: JSON.parse(user).id, 
            project: this.state.idProject, 
            amenities: arr,
        })
        this.setState({
            currentEditAmenitie: {},
            currentEditAmeitieName: "",
            currentEditAmenitieImg: ""
        })
        
    }

    handleUpdateAmenitie = () => {
        let user: any = localStorage.getItem("user");
        this.props.amenidadesUpdate({
            user: JSON.parse(user).id, 
            project: this.state.idProject, 
            id: this.state.currentEditAmenitie.id, 
            name: this.state.currentEditAmeitieName === "" ? this.state.currentEditAmenitie.name : this.state.currentEditAmeitieName, 
            logo: this.state.currentEditAmenitieImg === "" ? this.state.currentEditAmenitie.logo : this.state.currentEditAmenitieImg
        })
        this.setState({
            currentEditAmenitie: {},
            currentEditAmeitieName: "",
            currentEditAmenitieImg: "",
            openEditModal: false
        })
    }

    handleUpdateAmenitieImage = (img: any) => {
        this.setState({
            currentEditAmenitieImg: img
        })
    }

    handleUpdateAmenitieName = (name: string) => {
        this.setState({
            currentEditAmeitieName: name
        })
    }

    handleDefaultAmenitieSelect = (DefaultAmenitie: any) => {
        this.setState({
            currentAmenitieDefaultIcon: DefaultAmenitie.logo,
            currentAmenitieDefaultName: DefaultAmenitie.name
        })
    }

    handleUpdateAmenitieSpin = (id: string, amenitieId: string) => {
        let user: any = localStorage.getItem("user");
        this.props.amenidadesUpdate({
            user: JSON.parse(user).id, 
            project: this.state.idProject, 
            id: amenitieId, 
            spinLandscape: id
        })
    }

    handleUpdateAmenitieSpinPortrait = (id: string, amenitieId: string) => {
        let user: any = localStorage.getItem("user");
        this.props.amenidadesUpdate({
            user: JSON.parse(user).id, 
            project: this.state.idProject, 
            id: amenitieId, 
            spinPortrait: id
        })
    }

    handleUpdateListOrder = (payload: any) => {
        let user: any = localStorage.getItem("user");
        this.props.amenidadesUpdate({
            user: JSON.parse(user).id, 
            project: this.state.idProject, 
            amenities: payload
        })
    }

    handleOpenUploadIconsModal = (payload: boolean) => {
        this.setState({
            showIconsUpload: payload
        }, () => {
            this.props.openIconsModal(true)
        })
    }

    render(): React.ReactNode {
        return(
            <div className='amenities-container-main'>
                <img className='dashboard-background' src={dashBackground} alt=""/>
                <Sidebar
                    ref={this.iconsRef}
                    project={this.props.projectSelected}
                />
                <div className='amenities-container'>
                    <div className='amenidades-main-title'>
                        Amenidades
                    </div>
                    <SidebarLogo logo={this.props.logo_module}/>
                    <ListAmenidades
                        amenities = {this.props.amenities}
                        galleriesOpen = {this.props.galleriesOpen}
                        handleGetAmenitiesId = {this.handleGetAmenitiesId}
                        handleOpenEditModal = {this.handleOpenEditModal}
                        spins = {this.props.spins}
                        handleUpdateAmenitieSpin = {this.handleUpdateAmenitieSpin}
                        handleUpdateAmenitieSpinPortrait = {this.handleUpdateAmenitieSpinPortrait}
                        handleUpdateListOrder = {this.handleUpdateListOrder}
                    />
                </div>
                <div className='create-amenities-default-container'>
                    <DefaultAmenitie
                        amenitiesSuggested = {this.props.amenities_suggested}
                        currentAmenitieDefaultIcon = {this.state.currentAmenitieDefaultIcon}
                        currentAmenitieDefaultName = {this.state.currentAmenitieDefaultName}
                        handleDefaultAmenitieSelect = {this.handleDefaultAmenitieSelect}
                        handleCreateAmenitie = {this.handleCreateAmenitie}
                    />
                </div>
                {
                    this.state.openEditModal ?
                    <div className='create-amenities-new-container'>
                        <div className="create-amenitie-new">
                            Editar amenidad
                        </div>
                        <EditAmenitie
                            handleSelectIcon = {this.props.openIconsModal}
                            currentLogoSelected = {this.state.currentLogo}
                            handleChangeNewAmenitieName = {this.handleChangeNewAmenitieName}
                            currentName = {this.state.newAmenitieName}
                            amenitie = {this.state.currentEditAmenitie}
                            handleUpdateAmenitieName = {this.handleUpdateAmenitieName}
                            handleUpdateAmenitieImage = {this.handleUpdateAmenitieImage}
                            currentEditAmenitieImg = {this.state.currentEditAmenitieImg}
                        />
                        <div className='create-edit-save-btn' onClick={() => this.handleUpdateAmenitie()}> 
                            Guardar
                        </div>
                        <div className='create-edit-delete-btn' onClick={() => this.handleDeleteAmenitie()}> 
                            Eliminar
                        </div>
                    </div>:
                    <div className='create-amenities-new-container'>
                        <div className="create-amenitie-new">
                            Crear nueva amenidad
                        </div>
                        <CreateNewAmenitie
                            handleSelectIcon = {this.props.openIconsModal}
                            currentLogoSelected = {this.state.currentLogo}
                            handleChangeNewAmenitieName = {this.handleChangeNewAmenitieName}
                            currentName = {this.state.newAmenitieName}
                            handleOpenUploadIconsModal = {this.handleOpenUploadIconsModal}
                        />
                        <div className='create-new-save-btn' onClick = {() => {this.handleCreateAmenitie()}}> 
                            Guardar
                        </div>
                    </div>
                }
                <IconsViews
                    showIconsUpload = {this.state.showIconsUpload}
                    handleSelectLogo = {this.handleSelectLogo}
                />
                <GalleryModal
                    handleSetImages = {this.handleSetImages}
                    selectedGallery = {this.state.currentAmenitieGallerie}
                />
                <Navbar
                    
                    logout={this.props.logoutUser}
                />
                <Backdrop
                    sx={{ color: "#fff"}}
                    open={this.props.loading}
                    >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

const mapStateToProps = ({
    projectReducer,
    builderReducer,
    moduleReducer,
    galleriesReducer,
    amenitiesReducer,

}: any) => {
    const { galleries, modalGalleries} = galleriesReducer;
    const { amenities , loading, amenities_suggested, logo_module, spins } = amenitiesReducer;
    const { modules } = moduleReducer;
    const { projectSelected } = projectReducer;
    const { selectedBuilder } = builderReducer;
    return { modules, projectSelected, loading, selectedBuilder, galleries, amenities, modalGalleries, amenities_suggested, logo_module, spins };
};

export default connect(mapStateToProps, {
    logoutUser,
    amenidadesList,
    projectUpdate,
    openIconsModal,
    galleriesOpen,
    amenidadesCreate,
    amenidadesUpdate,
    amenidadesDelete,
    amenidadesSetGallery,
    galleriesCurrentModalSelection 
})(withParams(AmenitiesViews));