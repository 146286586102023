import React from 'react'

import { connect } from "react-redux";
import { useParams } from 'react-router-dom';

import Navbar from '../navbar';
import Sidebar from '../sidebar';
import SetUpModules from './setup.component';
import UploadAsoleacionVideo from './upload.component';
import ListAsoleacion from './list.component';

import dashBackground from './../../UI/backgrounds/BG_PUBLIC.webp';

import {
    Backdrop,
    CircularProgress,
} from "@mui/material";

import {
    logoutUser,
    solarList,
    solarSet,
    solarUpdate,
    solarDelete,
    solarTxt
} from "../../redux/actions";

interface Props{
    logoutUser: any,
    solarList: any,
    solarSet: any,
    solarUpdate: any,
    solarDelete: any,
    solarTxt: any,
    projectSelected: any,
    selectedBuilder: any,
    loading: any,
    params: any,
    solar: any,
}

interface State{
    userString: any,
    user: any,
    idProject: any,
    VideoArray: any,
    currentVideo: any
    currentVideoName: string,
    currentVideoDesc: string,
    isEdit: boolean,
    currentVideoSelected: any
}

function withParams(Component: any) {
    return (props: any) => <AsoleacionView {...props} params={useParams()} />;
}

class AsoleacionView extends React.Component<Props,State>{
    constructor(props: Props){
        super(props);
        this.state = {
            userString: localStorage.getItem("user"),
            user: "",
            idProject: this.props.params.id,
            VideoArray: [],
            currentVideoName: "",
            currentVideoDesc: "",
            currentVideo: "",
            isEdit: false,
            currentVideoSelected: {}
        }
    }

    componentDidMount(): void {
      if (this.state.userString) {
        this.setState(
          {
            user: JSON.parse(this.state.userString),
          },
          () => {
            this.props.solarList({
              user: this.state.user.id,
              project: this.state.idProject,
            });
          }
        );
      }
    }

    handleCurrentNameState = (name: string) => {
        this.setState({
            currentVideoName: name
        })
    }

    handleCurrentDescState = (desc: string) => {
        this.setState({
            currentVideoDesc: desc
        })
    }

    handleUploadVideo = (video: any) => {
        this.setState({
            currentVideo: video.target.files[0]
        })
    }

    handlePushToArray = () => {
        this.setState({
            VideoArray: [...this.state.VideoArray, {"name":this.state.currentVideoName, "video": this.state.currentVideo}]
        })
        let FileFormData = new FormData();
        FileFormData.append("zip", this.state.currentVideo);
        FileFormData.append("name", this.state.currentVideoName.toLowerCase().split(" ").join("-"));
        FileFormData.append("route", `galleries/${this.props.selectedBuilder.id}/${this.state.idProject}/files/asoleacion/${this.state.currentVideoName.toLowerCase().split(" ").join("-")}/`);
        this.props.solarSet({
            data: {
                user: this.state.user.id,
                project: this.state.idProject,
                name: this.state.currentVideoName,
                slug: this.state.currentVideoName.toLowerCase().split(" ").join("-"),
                descriptio: "",
                link: `galleries/${this.props.selectedBuilder.id}/${this.state.idProject}/files/asoleacion/${this.state.currentVideoName.toLowerCase().split(" ").join("-")}/`,
                rango: 64
            },
            assets: FileFormData
        })
    }

    handleSelectedToUpdate = (video: any) => {
        this.setState({
            isEdit: true,
            currentVideoSelected: video
        })
    }

    handleUpdate = (id: string, name: string, desc: string) => {
        this.props.solarUpdate({
            user: this.state.user.id,
            project: this.state.idProject,
            id: id,
            name: name,
            descriptio: desc
        })
    }

    handleDelete = (id: string) => {
        this.props.solarDelete({
            user: this.state.user.id,
            project: this.state.idProject,
            id: id
        })
    }

    handleUploadTxt = (file: any) => {
        let InstFormData = new FormData();
        InstFormData.append("myFile", file);
        InstFormData.append("name", `galleries/${this.props.selectedBuilder.id}/${this.state.idProject}/files/config_vistas.txt`);
        
        this.props.solarTxt({
            data: {
                user: this.state.user.id,
                project: this.state.idProject,
                link: `galleries/${this.props.selectedBuilder.id}/${this.state.idProject}/files/config_vistas.txt`
            },
            assets: InstFormData
        })
    }

    render(): React.ReactNode {
        return(
            <div className='asoleacion-container-main'>
                <img className='dashboard-background' src={dashBackground} alt=""/>
                <Sidebar
                    project={this.props.projectSelected}
                />
                <SetUpModules
                    VideoArray = {this.state.VideoArray}
                    handleUploadTxt = {this.handleUploadTxt}
                />
                <UploadAsoleacionVideo
                    handleChangeNameState = {this.handleCurrentNameState}
                    handleUploadVideo = {this.handleUploadVideo}
                    handlePushToArray = {this.handlePushToArray}
                    isEdit = {this.state.isEdit}
                    currentVideoSelected = {this.state.currentVideoSelected}
                    handleCurrentDescState = {this.handleCurrentDescState}
                    handleUpdate = {this.handleUpdate}
                />
                <ListAsoleacion
                    solarList={this.props.solar}
                    handleUpdate = {this.handleUpdate}
                    handleDelete = {this.handleDelete}
                    handleSelectedToUpdate = {this.handleSelectedToUpdate}
                />
                <Navbar
                    logout={this.props.logoutUser}
                />
                <Backdrop
                    sx={{ color: "#fff"}}
                    open={this.props.loading}
                    >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }

}

const mapStateToProps = ({
    projectReducer,
    builderReducer,
    solarReducer,

}: any) => {
    const { loading, solar} = solarReducer;
    const { projectSelected } = projectReducer;
    const { selectedBuilder } = builderReducer;
    return {loading, solar, projectSelected, selectedBuilder };
};

export default connect(mapStateToProps, {
    logoutUser,
    solarList,
    solarSet,
    solarUpdate,
    solarDelete,
    solarTxt
})(withParams(AsoleacionView));