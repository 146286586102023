import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";

import Navbar from "../navbar";
import Sidebar from "../sidebar";
import GeneralRoadsComponent from "./general.component";
import RoadsComponent from "./roads.component";

import LoteNew from "./loteNew.component";

import dashBackground from "./../../UI/backgrounds/BG_PUBLIC.webp";

import {
  logoutUser,
  locationsList,
  locationsUpdate,
  locationsDelimitationLote,
  locationSetRoadsAccess,
  locationUpdateRoadsAccess,
  locationDeleteRoadsAccess,
} from "../../redux/actions";

interface Props {
  logoutUser: any;
  loading: any;
  logo_module: any;
  projectSelected: any;
  selectedBuilder: any;
  params: any;
  locations: any;
  modules: any;
  delimitations: any;
  routes_access: any;
  locationsList: any;
  locationsUpdate: any;
  locationsDelimitationLote: any;
  locationSetRoadsAccess: any;
  locationUpdateRoadsAccess: any;
  locationDeleteRoadsAccess: any;
}

interface State {
  userString: any;
  user: any;
  logo_ubicacion: string;
  logo_vias: string;
  idProject: any;
  createRoad: boolean;
  createLote: boolean;
  currentLogo: string;
  currentLogoPosition: any;
  currentLogoFile: any;
  currentViaArray: any;
  currentViaColor: any;
  currentViaName: any;
  currentLoteArray: any;
  currentColorLote: any;
  currentColorRoads: any;
  viasArray: any;
}

function withParams(Component: any) {
  return (props: any) => <LocationView {...props} params={useParams()} />;
}

class LocationView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userString: localStorage.getItem("user"),
      user: "",
      idProject: this.props.params.id,
      createRoad: false,
      createLote: false,
      currentLogo: "",
      currentLogoFile: null,
      currentLogoPosition: {},
      currentViaArray: [],
      currentViaColor: "",
      currentViaName: "",
      currentLoteArray: [],
      currentColorLote: "#000",
      currentColorRoads: "",
      logo_ubicacion: "",
      logo_vias: "",
      viasArray: [],
    };
  }

  componentDidMount(): void {
    if (this.state.userString) {
      this.setState(
        {
          user: JSON.parse(this.state.userString),
        },
        () => {
          this.props.locationsList({
            user: this.state.user.id,
            project: this.state.idProject,
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps: any, prevState: any): void {
    if (prevProps.locations !== this.props.locations) {
      this.setState({
        currentLogo: `https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${this.props.locations.logo}`,
        currentLogoPosition: {
          lat: parseFloat(this.props.locations.latitud_logo),
          lng: parseFloat(this.props.locations.longitud_logo),
        },
      });
    }
    if (prevProps.delimitations !== this.props.delimitations) {
      if (this.props.delimitations.state === 1) {
        let coordenates = JSON.parse(this.props.delimitations.coordenates);
        let newCoordenates: any = [];
        coordenates.forEach((element: any) => {
          newCoordenates.push({
            lat: parseFloat(element[0]),
            lng: parseFloat(element[1]),
          });
        });
        this.setState({
          currentLoteArray: newCoordenates,
          //CONECTAR COLOR ===> this.props.delimitations.color
          currentColorLote: this.props.delimitations.color,
        });
      }
    }
    if (prevProps.modules !== this.props.modules) {
      this.props.modules.forEach((module: any) => {
        if (module.name === "Ubicación") {
          this.setState({
            logo_ubicacion: module.logo,
          });
        } else if (module.name === "Vias de Acceso") {
          this.setState({
            logo_vias: module.logo,
          });
        }
      });
    }

    if (prevProps.routes_access !== this.props.routes_access) {
      var tempViasArray: any = [];

      this.props.routes_access.forEach((route: any) => {
        var tempCoordinatesArray: any = [];
        var coordinates = JSON.parse(route.coordenates);
        coordinates.forEach((coordinate: any) => {
          tempCoordinatesArray.push({ lat: coordinate[0], lng: coordinate[1] });
        });
        tempViasArray.push({
          color: route.color,
          coordenates: tempCoordinatesArray,
          id: route.id,
          name: route.name,
          project: route.project,
          state: route.state,
        });
      });

      this.setState({
        viasArray: tempViasArray,
      });
    }
  }

  handleCreateRoad = () => {
    this.setState({
      createRoad: true,
    });
  };

  handleUpdateCurrentRoad = (road: any) => {
    this.setState({
      currentViaArray: road,
    });
  };

  handleUpdateCurrentLote = (lote: any) => {
    //CORREGIR ESTA LLEGANDO UNA COORDENADA MENOS
    this.setState({
      currentLoteArray: lote,
    });
  };

  handleUpdateCurrentLogoPosition = (position: any) => {
    this.setState({
      currentLogoPosition: position,
    });
  };

  handleCreateLote = () => {
    this.setState({
      createLote: true,
    });
  };

  handleStopLote = (color: any) => {
    let coordenates: any = [];
    this.state.currentLoteArray.forEach((element: any) => {
      coordenates.push([element.lat, element.lng]);
    });

    this.props.locationsDelimitationLote({
      user: JSON.parse(this.state.userString).id,
      project: this.state.idProject,
      coordenates: JSON.stringify(coordenates),
      color: color,
      state: 1,
    });
    this.props.locationsUpdate({
      user: JSON.parse(this.state.userString).id,
      project: this.state.idProject,
      latitud_logo: this.state.currentLogoPosition.lat,
      longitud_logo: this.state.currentLogoPosition.lng,
    });
    this.setState({
      createLote: false,
    });
  };

  handleDeleteLote = () => {
    let coordenates: any = [];
    this.state.currentLoteArray.forEach((element: any) => {
      coordenates.push([element.lat, element.lng]);
    });

    this.props.locationsDelimitationLote({
      user: JSON.parse(this.state.userString).id,
      project: this.state.idProject,
      coordenates: "",
      color: "#000",
      state: 0,
    });
    this.props.locationsUpdate({
      user: JSON.parse(this.state.userString).id,
      project: this.state.idProject,
      latitud_logo: 0,
      longitud_logo: 0,
    });
    this.setState({
      createLote: false,
      currentLoteArray: [],
    });
  };

  handleAddLogo = (e: any) => {
    this.setState({
      currentLogo: URL.createObjectURL(e.target.files[0]),
      currentLogoFile: e.target.files[0],
    });
    let InstFormData = new FormData();
    InstFormData.append("myFile", e.target.files[0]);
    InstFormData.append(
      "name",
      `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/logo_project_map.svg`
    );
    this.props.locationsUpdate({
      data: {
        user: JSON.parse(this.state.userString).id,
        project: this.state.idProject,
        logo: `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/logo_project_map.svg`,
      },
      assets: InstFormData,
      type: "logo",
    });
  };

  handleSaveVia = (color: any, name: any) => {
    let coordenates: any = [];
    this.state.currentViaArray.forEach((element: any) => {
      coordenates.push([element.lat, element.lng]);
    });

    this.props.locationSetRoadsAccess({
      user: JSON.parse(this.state.userString).id,
      project: this.state.idProject,
      name: name,
      coordenates: JSON.stringify(coordenates),
      color: color,
      state: 1,
    });
    this.setState({
      createRoad: false,
      viasArray: [],
      currentViaArray: [],
    });
  };

  handleUpdateColorLote = (hex: any) => {
    this.setState({
      currentColorLote: hex,
    });
  };

  handleUpdateViaName = (name: string, id: string) => {
    this.props.locationUpdateRoadsAccess({
      user: JSON.parse(this.state.userString).id,
      project: this.state.idProject,
      id: id,
      name: name,
    });
  };

  handleUpdateViaColor = (color: string, id: string) => {
    this.props.locationUpdateRoadsAccess({
      user: JSON.parse(this.state.userString).id,
      project: this.state.idProject,
      id: id,
      color: color,
    });
  };

  handleDeleteVia = (id: string) => {
    this.props.locationDeleteRoadsAccess({
      user: JSON.parse(this.state.userString).id,
      project: this.state.idProject,
      id: id,
    });
  };

  handleUpdateZoom = (payload: boolean) => {
    console.log(payload);
  };

  handleUploadIllustratedMap = (payload: any) => {
    var file = payload.target.files[0];
    if (file.size / 1024 < 3200) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        const img: any = new Image();
        if (reader.result) {
          img.src = reader.result;
          img.addEventListener("load", () => {
            var width = img.width;
            var height = img.height;

            if (height === 3000 && width === 3000) {
              let InstFormData = new FormData();
              InstFormData.append("myFile", file);
              InstFormData.append(
                "name",
                `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/mapa_ilustrado/${file.name}`
              );
              this.props.locationsUpdate({
                data: {
                  user: JSON.parse(this.state.userString).id,
                  project: this.state.idProject,
                  mapa_ilustrado_vias: `galleries/${this.props.selectedBuilder.id}/${this.props.projectSelected.id}/mapa_ilustrado/${file.name}`,
                },
                assets: InstFormData,
                type: "mapa_ilustrado"
              });
            } else {
              alert(
                "La imagen tiene dimensiones incorrectas debe ser 3000 x 3000 y esta es de " +
                  width +
                  " x " +
                  height +
                  " ¿qué paso ahí papu?"
              );
            }
          });
        }
      });
    } else {
      alert("La imagen pesa mas de 3MB, ¿qué paso ahí papu?");
    }
  };

  render(): React.ReactNode {
    return (
      <div className="location-container">
        <img className="dashboard-background" src={dashBackground} alt="" />
        <Sidebar project={this.props.projectSelected} />
        <GeneralRoadsComponent
          createRoad={this.state.createRoad}
          createLote={this.state.createLote}
          locations={this.props.locations}
          locationsUpdate={this.props.locationsUpdate}
          user={this.state.user.id}
          project={this.state.idProject}
          currentLogo={this.state.currentLogo}
          handleCurrentAddLogo={this.handleAddLogo}
          logo={this.state.logo_ubicacion}
          handleUpdateCurrentRoad={this.handleUpdateCurrentRoad}
          handleUpdateCurrentLote={this.handleUpdateCurrentLote}
          handleUpdateCurrentLogoPosition={this.handleUpdateCurrentLogoPosition}
          currentLogoPosition={this.state.currentLogoPosition}
          currentLoteArray={this.state.currentLoteArray}
          viasArray={this.state.viasArray}
          currentColorLote={this.state.currentColorLote}
          currentViaArray={this.state.currentViaArray}
          handleUpdateZoom={this.handleUpdateZoom}
          handleUploadIllustratedMap={this.handleUploadIllustratedMap}
        />
        <RoadsComponent
          handleCreateRoad={this.handleCreateRoad}
          handleSaveVia={this.handleSaveVia}
          logo={this.state.logo_vias}
          viasArray={this.state.viasArray}
          handleUpdateViaName={this.handleUpdateViaName}
          handleUpdateViaColor={this.handleUpdateViaColor}
          handleDeleteVia={this.handleDeleteVia}
        />
        <LoteNew
          currentLogo={this.state.currentLogo}
          handleAddLogo={this.handleAddLogo}
          handleCreateLote={this.handleCreateLote}
          handleStopLote={this.handleStopLote}
          handleUpdateColorLote={this.handleUpdateColorLote}
          currentColorLote={this.state.currentColorLote}
          currentLoteArray={this.state.currentLoteArray}
          handleDeleteLote={this.handleDeleteLote}
        />
        <Navbar logout={this.props.logoutUser} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

const mapStateToProps = ({
  projectReducer,
  builderReducer,
  locationsReducer,
  moduleReducer,
}: any) => {
  const { locations, delimitations, routes_access, loading, logo_module } =
    locationsReducer;
  const { modules } = moduleReducer;
  const { projectSelected } = projectReducer;
  const { selectedBuilder } = builderReducer;
  return {
    locations,
    delimitations,
    routes_access,
    loading,
    logo_module,
    modules,
    projectSelected,
    selectedBuilder,
  };
};

export default connect(mapStateToProps, {
  locationsList,
  locationsUpdate,
  locationsDelimitationLote,
  locationSetRoadsAccess,
  locationUpdateRoadsAccess,
  locationDeleteRoadsAccess,
  logoutUser,
})(withParams(LocationView));
