import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux'
import {configureStore} from './redux/store' 
import authToken from './helpers/authToken';

import App from './App';
import LoadingComponent from './components/generals/loading.component';

import './styles/style.css'

if (localStorage.getItem('token')) {
  authToken(localStorage.getItem('token'));
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<LoadingComponent/>}>
      <App/>
    </Suspense>
  </Provider>
);
