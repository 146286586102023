import {
    LIST_USER,
    LIST_USER_SUCCESS,
    LIST_USER_ERROR,
    CREATE_USER,
    UPDATE_USER,
    DELETE_USER,
  } from '../actions';

  export const userList = (user: any) => ({
    type: LIST_USER,
    payload: { user }
  });
  export const userListSuccess = (user: any) => ({
    type: LIST_USER_SUCCESS,
    payload: user
  });
  export const userListError = (message: any) => ({
    type: LIST_USER_ERROR,
    payload: { message }
  });
  export const userCreate = (user: any, history: any) => ({
    type: CREATE_USER,
    payload: { user, history }
  });
  export const userUpdate = (user: any, id: any, history: any) => ({
    type: UPDATE_USER,
    payload: { user, history, id }
  });
  export const userDelete = (id: any, history: any) => ({
    type: DELETE_USER,
    payload: { id, history }
  });