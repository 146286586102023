import React, {useRef} from 'react'

import gallery from './../../UI/general/GalleryGeneral.svg'
import Edit from './../../UI/general/Edit.svg'
import drag from './../../UI/general/drag.svg'

const ListAmenidades = ({amenities, galleriesOpen, handleGetAmenitiesId, handleOpenEditModal, spins, handleUpdateAmenitieSpin, handleUpdateAmenitieSpinPortrait, handleUpdateListOrder}: any) => {

    const dragItem: any = useRef()
    const dragOverItem: any = useRef();

    const dragStart = (e: any, position: number) => {
        dragItem.current = position;
    }

    const dragEnter = (e: any, position: number) => {
        dragOverItem.current = position;
    };

    const drop = (e: any) => {
        const copyListItems = [...amenities];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        handleUpdateListOrder(copyListItems)
    };

    return(
        <div className='amenidades-list-container'>
            {
                amenities.map((amenitie: any, index: number) => 
                <div 
                className="amenitie-individual-container" 
                key={amenitie.name + index}
                draggable 
                onDragStart={(e) => {dragStart(e,index)}}
                onDragEnter={(e) => dragEnter(e, index)}
                onDragEnd={drop}
                >
                    <div className='attributes-individual-drag-controller'>
                        <img src={drag} alt=''/>
                    </div>
                    <img src={`https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${amenitie.logo}`} alt="" className='amenity-icon-list-image'/>
                    <div className='amenitie-individual-name'>{amenitie.name}</div>
                    <div className="amenitie-separator"/>
                    <div 
                        className="gallery-selector" 
                        onClick={() => {
                            handleGetAmenitiesId(amenitie.id, amenitie.galleries)
                            galleriesOpen(true)
                        }}
                    >
                        <img src={gallery} alt="gallery-selector"/>
                    </div>
                    <div className="edit-selector" onClick = {() => {handleOpenEditModal(amenitie)}}>
                        <img src={Edit} alt="edit-selector"/>
                    </div>
                </div>
                )
            }
        </div>
    )
}

export default ListAmenidades