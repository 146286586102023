import {
  LIST_MODULES,
  LIST_MODULES_SUCCESS,
  LIST_MODULES_ERROR,
  UPDATE_MODULES
} from '../actions'

/*eslint-disable*/

const INIT_STATE = {
  modules: [],
  loading: false,
  message: '',
}

export default (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case LIST_MODULES:
      return { ...state, loading: true, message: '' }
    case LIST_MODULES_SUCCESS:
      return {
        ...state,
        modules: action.payload,
        loading: false,
        message: "Exito",
      }
    case LIST_MODULES_ERROR:
      return {
        ...state,
        modules: [],
        loading: false,
        message: action.payload.message,
      }
    case UPDATE_MODULES:
      return { ...state, loading: true, message: '' }
    default:
      return { ...state }
  }
}
