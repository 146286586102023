import {
    LOGIN_USER,
    LOGIN_USER_STATE,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    LOGOUT_USER,
    LOGIN_TWO_FACTOR_REGISTER,
    LOGIN_TWO_FACTOR_VALIDATE
  } from '../actions';
  
  export const loginUser = (user: object) => ({
    type: LOGIN_USER,
    payload: { user }
  });
  
  export const twoFactorRegister = (secrect: any) => ({
    type: LOGIN_TWO_FACTOR_REGISTER,
    payload: { secrect }
  });
  
  export const twoFactorValidate = (secrect: any) => ({
    type: LOGIN_TWO_FACTOR_VALIDATE,
    payload: { secrect }
  });
  
  export const loginUserSuccess = (user: object) => ({
    type: LOGIN_USER_SUCCESS,
    payload: {user}
  });
  export const loginUserState = (state: boolean) => ({
    type: LOGIN_USER_STATE,
    payload: state
  });
  export const loginUserError = (message: string) => ({
    type: LOGIN_USER_ERROR,
    payload: { message }
  });
  
  export const logoutUser = (history: any) => ({
    type: LOGOUT_USER,
    payload: { history }
  });