import React, {useState} from 'react'

const IconsContainer = ({icons, handleCurrentSelected}: any) => {

    const [selected, setSelected] = useState(-1)
    const [categorySelected, setCategorySelected] = useState(-1)

    return(
        <div className='icons-grid-container'>
            {
                icons.map((iconElement: any, index: number) => 
                    <div className="icon-individual" key={iconElement.folder + index}>
                        <div className='icon-folder-name'>{iconElement.folder}</div>
                        <div className='icon-folder-container'>
                            {
                                iconElement.icons ?
                                    iconElement.icons.map((icon: any, indexIcon: number) =>
                                        <div 
                                        className='icon-folder-individual' 
                                        style={{
                                            border: selected === indexIcon && categorySelected === index ? '2px solid #043250':'0.5px solid #BEBEBE',
                                            boxSizing: 'border-box'
                                        }} 
                                        onClick = {() => {
                                            setSelected(indexIcon)
                                            setCategorySelected(index)
                                            handleCurrentSelected(`${icon.icon}`)
                                            }}>
                                            <img src={`https://s3-umbra-storage-cloud-v2.s3.amazonaws.com/${icon.icon}`} alt=""/>
                                        </div>
                                    )
                                    :null
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default IconsContainer