import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  DrawingManager,
  Marker,
  Polyline,
  Polygon,
} from "@react-google-maps/api";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";

/*eslint-disable*/

interface MyMapComponentProps {
  createRoad: boolean;
  createLote: boolean;
  currentLogo: string;
  currentColorLote: string;
  viasArray: Array<{
    color: string;
    coordenates: google.maps.LatLngLiteral[];
  }>;
  locations: any;
  currentLoteArray: google.maps.LatLngLiteral[];
  handleUpdateCurrentRoad: any;
  handleUpdateCurrentLote: any;
  handleUpdateCurrentLogoPosition: any;
  locationsUpdate: any;
  user: any;
  project: any;
}

const containerStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
};

let center: any = {
  lat: 0,
  lng: 0,
};

const MyMapComponent: React.FC<MyMapComponentProps> = ({
  createRoad,
  createLote,
  currentLogo,
  currentColorLote,
  viasArray,
  locations,
  currentLoteArray,
  handleUpdateCurrentRoad,
  handleUpdateCurrentLote,
  handleUpdateCurrentLogoPosition,
  locationsUpdate,
  user,
  project,
}) => {
  const ref: any = useRef();
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  const [zoomTemp, setZoomTemp] = useState(parseInt(locations.zoom, 10));
  const [mapaType, setMapaType] = useState(locations.mapa_type)
  const [centerTemp, setCenterTemp] =
    useState<google.maps.LatLngLiteral | null>({
      lat: parseFloat(locations.latitud),
      lng: parseFloat(locations.longitud),
    });
  const [drawingMode, setDrawingMode] =
    useState<google.maps.drawing.OverlayType | null>(null);
  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null); // Estado para el polígono actual
  const [polygonCoords, setPolygonCoords] = useState<
    google.maps.LatLngLiteral[] | null
  >(null);
  const [polylineCoords, setPolylineCoords] = useState<
    google.maps.LatLngLiteral[] | null
  >(null);
  const [polygonCenter, setPolygonCenter] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [currentPolygon, setCurrentPolygon] =
    useState<google.maps.Polygon | null>(null);

  console.log(currentLoteArray);

  const mapCenter = {
    lat: parseFloat(locations.latitud),
    lng: parseFloat(locations.longitud),
  };

  const mapZoom = parseInt(locations.zoom, 10);

  useEffect(() => {
    // Verifica si la biblioteca de Google Maps se ha cargado
    if (window.google && window.google.maps) {
      setIsGoogleMapsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (createLote) {
      // Iniciar la creación de polígonos automáticamente cuando createLote sea true
      setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
    }
  }, [createLote]);

  useEffect(() => {
    if (createRoad) {
      // Iniciar la creación de polilíneas automáticamente cuando createRoad sea true
      setDrawingMode(window.google.maps.drawing.OverlayType.POLYLINE);
    }
  }, [createRoad]);

  if (!isGoogleMapsLoaded) {
    return <div>Cargando...</div>;
  }

  const drawingOptions: google.maps.drawing.DrawingManagerOptions = {
    drawingControl: !createLote && !createRoad, // Mostrar control de dibujo si createLote y createRoad son false
    drawingControlOptions: {
      position: window.google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [],
    },
  };

  // Opciones para desactivar los botones no deseados
  const mapOptions: google.maps.MapOptions = {
    mapTypeControl: false, // Quitar botones de mapa/satélite
    streetViewControl: false, // Quitar botón de street view
    fullscreenControl: false, // Quitar botón de pantalla completa
  };

  const handlePolygonComplete = (newPolygon: google.maps.Polygon) => {
    if (currentPolygon) {
      // Borra el polígono anterior
      currentPolygon.setMap(null);
    }

    const vertices: any = newPolygon.getPath().getArray();

    const loteVertice: any = newPolygon
      .getPath()
      .getArray()
      .map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng(),
      }));

    // Calcula el centro del polígono
    let centerLat = 0;
    let centerLng = 0;

    vertices.forEach((vertex: any) => {
      centerLat += vertex.lat();
      centerLng += vertex.lng();
    });

    centerLat /= vertices.length;
    centerLng /= vertices.length;

    const newPolygonCenter = {
      lat: centerLat,
      lng: centerLng,
    };

    console.log(vertices);

    setPolygon(newPolygon); // Establece el polígono actual
    handleUpdateCurrentLote(loteVertice);
    handleUpdateCurrentLogoPosition(newPolygonCenter);
    setPolygonCoords(vertices);
    setPolygonCenter(newPolygonCenter); // Actualiza el centro del polígono
    console.log("Polygon Coords:", vertices);
    console.log("Polygon Center:", newPolygonCenter);

    // Define las opciones del polígono, incluido el color de relleno
    newPolygon.setOptions({
      fillColor: currentColorLote, // Cambia el color de relleno si es necesario
      fillOpacity: 0.5, // Define la opacidad del relleno
      strokeColor: currentColorLote, // Cambia el color del borde aquí
      strokeWeight: 2, // Define el grosor del borde
      // ... otras opciones
    });

    setCurrentPolygon(newPolygon);
  };

  const handlePolylineComplete = (polyline: google.maps.Polyline) => {
    const coords = polyline
      .getPath()
      .getArray()
      .map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng(),
      }));
    setPolylineCoords(coords);
    console.log("Polyline Coords:", coords);
    handleUpdateCurrentRoad(coords);
  };

  const setCurrentView = () => {
    if (ref.current) {
      locationsUpdate({
        user: user,
        project: project,
        zoom: ref.current.state.map.zoom,
        latitud: ref.current.state.map.center.lat(),
        longitud: ref.current.state.map.center.lng()
      });
    }
  };

  const handleGoToCoordinates = () => {
    if (ref.current && ref.current.state.map) {
      const map = ref.current.state.map;

      map.panTo(mapCenter, { duration: 1000, easing: "linear" });
      map.setZoom(mapZoom, { duration: 1000, easing: "linear" });
    }
  };

  let initialCenterLat = 0;
  let initialCenterLng = 0;

  currentLoteArray.forEach((coordinate) => {
    initialCenterLat += coordinate.lat;
    initialCenterLng += coordinate.lng;
  });

  initialCenterLat /= currentLoteArray.length;
  initialCenterLng /= currentLoteArray.length;

  const initialPolygonCenter: google.maps.LatLngLiteral = {
    lat: initialCenterLat,
    lng: initialCenterLng,
  };

  return (
    <div className="general-roads-set-container">
      <Row className="my-4">
        <Col md={6}>
          <h6>Ubicación del proyecto</h6>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={12}>
              <h6>Tipo de mapa en maqueta</h6>
            </Col>
            <Col md={6}>
              <FormGroup check>
                <Input name="radio1" type="radio" checked={mapaType === "roadmap" ? true : false} onChange={(e) => {
                  setMapaType("roadmap")
                  locationsUpdate({
                    user: user,
                    project: project,
                    mapa_type: "roadmap"
                  });
                }}/>{" "}
                <Label check>Vectorial</Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup check>
                <Input name="radio1" type="radio"  checked={mapaType === "satellite" ? true : false} onChange={(e) => {
                  setMapaType("satellite")
                  locationsUpdate({
                    user: user,
                    project: project,
                    mapa_type: "satellite"
                  });
                }}/>{" "}
                <Label check>Satelital</Label>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <div className="general-roads-location-config">
        <div className="general-roads-location-search-container">
          <div>Ubicación del proyecto</div>
        </div>
        <div className="general-roads-location-map-type">
          <p>Tipo de mapa en maqueta</p>
          <div>
            <input type="checkbox" name="" id="" />
            <label htmlFor="">Satelital</label>
          </div>
          <div>
            <input type="checkbox" name="" id="" />
            <label htmlFor="">Vectorial</label>
          </div>
        </div>
      </div> */}
      <div className="map-container">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={mapZoom}
          options={mapOptions}
          ref={ref}
        >
          <DrawingManager
            options={drawingOptions}
            drawingMode={drawingMode}
            onPolygonComplete={handlePolygonComplete}
            onPolylineComplete={handlePolylineComplete}
          />
          {/* Renderizar o mostrar las coordenadas si es necesario */}
          {polygonCoords && (
            <div>Coordenadas del polígono: {JSON.stringify(polygonCoords)}</div>
          )}
          {polylineCoords && (
            <div>
              Coordenadas de la polilínea: {JSON.stringify(polylineCoords)}
            </div>
          )}
          {viasArray.map((via, index) => (
            <Polyline
              key={index}
              path={via.coordenates}
              options={{
                strokeColor: via.color,
                strokeWeight: 3,
              }}
            />
          ))}
          {/* {currentPolygon && ( */}
          <Polygon
            paths={currentLoteArray}
            options={{
              fillColor: currentColorLote,
              fillOpacity: 0.5,
              strokeColor: currentColorLote,
              strokeWeight: 2,
            }}
          />
          {/* )} */}
          {polygonCenter && (
            <Marker
              position={polygonCenter}
              icon={{
                url: currentLogo,
                fillColor: currentColorLote,
                scaledSize: new window.google.maps.Size(80, 80),
                anchor: new google.maps.Point(40, 40),
              }}
            />
          )}
          {initialPolygonCenter && (
            <Marker
              position={initialPolygonCenter}
              icon={{
                url: currentLogo,
                fillColor: currentColorLote,
                scaledSize: new window.google.maps.Size(80, 80),
                anchor: new google.maps.Point(40, 40),
              }}
            />
          )}
        </GoogleMap>
      </div>
      <div className="fijar-container">
        <div
          className="fijar-btn"
          onClick={() => {
            setCurrentView();
          }}
        >
          Fijar encuadre de mapa dinámico
        </div>
        <div
          className="center-map"
          onClick={() => {
            handleGoToCoordinates();
          }}
        >
          Centrar mapa
        </div>
      </div>
    </div>
  );
};

export default MyMapComponent;
