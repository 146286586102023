import { UPDATE_ALERT, CLOSE_ALERT } from "../actions";

/*eslint-disable*/

const INIT_STATE = {
  message: "",
  type: "",
  show: false,
};

export default (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case UPDATE_ALERT:
       return {
        ...state,
        show: action.payload.show,
        type: action.payload.type,
        message: action.payload.message,
      };
      case CLOSE_ALERT:
        return {
          ...state,
          show: action.payload,
        };
    default:
      return { ...state };
  }
};
